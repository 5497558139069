var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('v-client-table',{ref:"userTable",staticClass:"blue-table",attrs:{"data":_vm.tableData,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"accountType",fn:function(ref){
var row = ref.row;
return _c('div',{key:("div+" + _vm.randomNumber)},[_c('input-select',{key:((row.firstName) + "+" + _vm.randomNumber),attrs:{"allow-empty":false,"input-param":{
          name: row.userAccountId,
          optionList: _vm.optionListAccountType,
          translate: false,
          value:
            row.accountStatusString === 'Owner'
              ? { value: 3, label: 'Head account manager' }
              : { value: row.roleId, label: row.role },
        },"translate-options":false,"translate-value":false},on:{"sendInput":function($event){return _vm.setRole($event, row)}}})],1)}},{key:"action",fn:function(ref){
        var row = ref.row;
return _c('div',{},[(row.accountType.value !== 3)?_c('button',{staticClass:"button-icon",on:{"click":function($event){return _vm.openConfirmRemoveUser(row)}}},[_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.$t('components.companyUsers.remove')))])]):_vm._e()])}},{key:"updateStatus",fn:function(ref){
        var row = ref.row;
return _c('div',{},[_c('status-indicator',{attrs:{"status":row.updateStatus,"size":"small"}})],1)}}])}),(_vm.gettingUsers)?_c('loading-indicator',{key:"rIndicatorUsers",attrs:{"no-space":true,"size":"small"}}):_vm._e(),_c('confirm',{attrs:{"show-modal-param":true,"alt-modal-key":"confirmHamChange","translate":false,"content-param":_vm.tableData[_vm.changedRow].name
        ? {
            title: ("" + (_vm.$t('components.companyUsers.hamChangeTitle'))),
            body: ("" + (_vm.$t('components.companyUsers.hamChangeParagraph', {
              user: _vm.tableData[_vm.changedRow].name,
            }))),
            confirmButton: ("" + (_vm.$t(
              'components.companyUsers.hamChangeConfirm'
            ))),
            cancelButton: ("" + (_vm.$t('components.companyUsers.hamChangeCancel'))),
          }
        : {}},on:{"onCancel":_vm.cancelHamChange,"onConfirm":_vm.confirmHamChange}}),_c('confirm',{attrs:{"show-modal-param":true,"alt-modal-key":"confirmRemoveUser","translate":false,"content-param":_vm.tableData[_vm.changedRow].name
        ? {
            title: ("" + (_vm.$root.$t('components.companyUsers.removeUserTitle'))),
            body: ("" + (_vm.$t('components.companyUsers.removeUserParagraph', {
              user: _vm.tableData[_vm.changedRow].name,
            }))),
            confirmButton: ("" + (_vm.$t(
              'components.companyUsers.removeUserConfirm'
            ))),
            cancelButton: ("" + (_vm.$t('components.companyUsers.removeUserCancel'))),
          }
        : {
            title: ("" + (_vm.$root.$t('components.companyUsers.removeUserTitle'))),
            body: ("" + (_vm.$t('components.companyUsers.removeUserParagraph', {
              user: _vm.tableData[_vm.changedRow].email,
            }))),
            confirmButton: ("" + (_vm.$t(
              'components.companyUsers.removeUserConfirm'
            ))),
            cancelButton: ("" + (_vm.$t('components.companyUsers.removeUserCancel'))),
          }},on:{"onConfirm":_vm.confirmRemoveUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }