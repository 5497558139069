
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Line } from 'vue-chartjs-typescript';

@Component({
  name: 'LineChart',
})
export default class LineChart extends Mixins(Line) {
  @Prop({ required: true }) chartDateTime!: string;
  @Prop({ required: true }) chartData!: any;
  @Prop({ required: true }) chartOptions!: any;

  mounted() {
    if (this.$data.chart) {
      this.$data.chart.destroy();
    }
    this.renderChart(this.chartData, this.chartOptions);
  }
}
