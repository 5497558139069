









































import Vue from 'vue';
import { Component, Prop, Inject, Watch } from 'vue-property-decorator';
import DatePicker from 'vuejs-datepicker';
import { isEmpty } from 'lodash';
import { Validator } from 'vee-validate';
import { namespace } from 'vuex-class';
import { CustomInput, InputObject, ReturnInput } from './inputTypes';
import { EventBus } from '@/main';

@Component({
  name: 'InputDateComponent',
  components: { DatePicker },
})
export default class InputDateComponent extends Vue {
  @Prop({}) inputParam!: InputObject;
  @Prop({}) dataTestId!: string;
  @Inject('$validator') $validator: Validator;

  dateValue: any = '';
  inputField: InputObject = {
    id: '',
    name: '',
    ref: '',
    type: 'text',
    placeHolder: '',
    class: '',
    vRule: '',
    vLabel: '',
    disabled: false,
    optionList: [],
    containerClass: '',
    value: '',
    hideVLabel: false,
    isValid: null,
  };

  $refs!: {
    datePicker: HTMLFormElement;
  };

  get inputName() {
    return isEmpty(this.inputField.name) ? ' ' : this.inputField.name;
  }

  get inputVRule() {
    if (this.inputField.vRule === null) {
      return ' ';
    }
    return this.inputField.vRule;
  }

  get disabledDates() {
    const options: any = this.inputField.optionList;
    let dates = {};
    const yesterday: Date = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (options && options.includes('limitDates')) {
      dates = {
        to: yesterday,
      };
    }
    return dates;
  }

  get inputType() {
    return isEmpty(this.inputField.type) ? 'text' : this.inputField.type;
  }

  get errorLabel() {
    if (
      isEmpty(this.inputField.vLabel) &&
      isEmpty(this.inputField.placeHolder)
    ) {
      return '';
    }
    if (isEmpty(this.inputField.vLabel) || this.inputField.vLabel === null) {
      return this.inputPlaceHolder;
    }
    return `${this.$t(this.inputField.vLabel)}`;
  }

  get inputPlaceHolder(): string {
    return isEmpty(this.inputField.placeHolder)
      ? ''
      : `${this.$t(this.inputField.placeHolder)}`;
  }

  get inputHasError(): boolean {
    return (
      this.$validator.errors.has(this.inputField.name) ||
      this.inputParam.isValid === false
    );
  }

  get isInputCorrect(): boolean {
    return (
      this.dateValue !== null &&
      this.dateValue !== undefined &&
      this.dateValue !== '' &&
      !this.$validator.errors.has(this.inputField.name) &&
      !(this.inputParam.isValid === false)
    );
  }

  get inputContainerClass(): string | null {
    return this.inputField.containerClass;
  }

  get clearDateIcon(): boolean {
    return this.dateValue !== undefined && this.dateValue !== '';
  }

  get customClass(): any {
    return {
      'calendar-error': this.inputHasError,
      'calendar-correct': this.isInputCorrect,
      'calendar-empty': !this.inputHasError && !this.isInputCorrect,
      smallest: this.inputField.class === 'smallest',
      big: this.inputField.class === 'big',
    };
  }

  get showVLabel(): boolean {
    return (
      !this.inputField.hideVLabel &&
      this.$validator.errors.has(this.inputField.name)
    );
  }

  get inputRef(): string {
    return this.inputField.ref === null
      ? this.inputField.name
      : `"${this.inputField.ref}"`;
  }

  get inputDisabled(): boolean {
    return this.inputField.disabled;
  }

  get inputTooltip(): any {
    if (this.inputField.defaultTooltip) {
      return {
        placement: 'left-center',
        content: this.inputPlaceHolder,
      };
    }
    if (
      this.inputField.tooltip === undefined ||
      this.inputField.tooltip === null
    ) {
      return null;
    }

    const tooltipContent: string | null = `${this.$t(this.inputField.tooltip)}`;
    return tooltipContent === null
      ? null
      : { placement: 'left-center', content: tooltipContent };
  }

  clearDate() {
    this.dateValue = '';
  }

  openCalendar() {
    this.$refs.datePicker.showCalendar();
  }

  created() {
    this.inputField = Object.assign({}, this.inputParam);
    EventBus.$on('reloadDateInput', (inputInformation: any) => {
      if (inputInformation.name === this.inputParam.name) {
        this.dateValue = inputInformation.value;
        this.inputField = {
          ...inputInformation,
          value: this.dateValue,
          isValid: inputInformation.isValid,
        };
      }
    });
  }

  @Watch('dateValue')
  onDateValueChange(newDateValue: string) {
    const returnInput: ReturnInput = {
      name: this.inputField.name,
      value: '',
    };
    if (newDateValue !== null && newDateValue !== '') {
      const date = new Date(newDateValue);
      const month: string =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`;
      const day: string =
        date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
      returnInput.value = `${date.getFullYear()}-${month}-${day}`;
    }
    this.$emit('sendInput', returnInput);
  }
}
