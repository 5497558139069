


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment';

const BackOfficeNameSpace = namespace('backOffice/');
interface Stamp {
  [key: string]: number;
}
@Component({
  name: 'HistoryViewer',
})
export default class AccountForm extends Vue {
  @BackOfficeNameSpace.Action('getAccount') getAccount: any;
  @Prop({ required: true }) timestamps: Stamp;

  get timestampsFormated() {
    const timestamps: any = {
      userAccountWasCreated: {
        string: 'Account created',
        value: '',
      },
      userAccountWasConfirmed: {
        string: 'Account email confirmed',
        value: '',
      },
      userAccountWasApproved: {
        string: 'Account approved',
        value: '',
      },
      userAccountUpgradeWasRequested: {
        string: 'Upgrade requested',
        value: '',
      },
      userAccountUpgradeWasDenied: {
        string: 'Upgrade denied',
        value: '',
      },
      connectedCompanyToAccount: {
        string: 'Company connected to account',
        value: '',
      },
      userAccountUpgradeWasApproved: {
        string: 'Upgrade approved',
        value: '',
      },
      userAccountWasBlocked: {
        string: 'Account blocked',
        value: '',
      },
    };
    Object.keys(this.timestamps).forEach((stampKey: string) => {
      if (timestamps[stampKey]) {
        timestamps[stampKey].value = moment(
          new Date(this.timestamps[stampKey] * 1000),
        ).format('LLL');
      } else {
        // fallback for old or unknown status events
        Object.assign(timestamps, {
          [stampKey]: {
            string: stampKey,
            value: moment(new Date(this.timestamps[stampKey] * 1000)).format(
              'LLL',
            ),
          },
        });
      }
    });
    return timestamps;
  }
}
