import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { forEach, startsWith, orderBy } from 'lodash';
import { FilterState, Filter, Order } from '@/types/types';
import { IOI } from '@/types/instrumentsTypes';
import { RootState } from '@/types/rootState';

const SAVE_FILTERED_IOIS = 'iois/saveFilteredIOI';

export const state: FilterState = {
  ioiFilters: [],
};

export const getters: GetterTree<FilterState, RootState> = {
  allFilters: state => state.ioiFilters,
  anyFilters(state): boolean {
    let anyFilter: boolean = false;
    forEach(state.ioiFilters, (iterFilter: Filter) => {
      if (iterFilter.values.length > 0) {
        anyFilter = true;
      }
    });
    return anyFilter;
  },
};

export const mutations: MutationTree<FilterState> = {
  SAVE_FILTERS(state, newFilters) {
    state.ioiFilters = newFilters;
  },

  REMOVE_FILTER(state, filterToRemove) {
    // state.ioiFilters = state.ioiFilters.filter(filter => filter.key !== filterToRemove.key);
  },

  CLEAR(state) {
    state.ioiFilters = [];
    state.ioiFilters.length = 0;
  },
};

export const actions: ActionTree<FilterState, RootState> = {
  saveFilters({ commit, dispatch, rootState }, newFilters: Filter[]): void {
    commit('SAVE_FILTERS', newFilters);
    dispatch('applyFilters');
  },

  applyFilters({ dispatch, rootState }): void {
    const newFilters: Filter[] = state.ioiFilters;
    let ioiSet: IOI[] = [];
    const previousFilteredState: string = rootState.iois.unfilteredIOIState;
    const activeState: string = rootState.iois.activeIOISet;
    if (activeState === 'open') {
      if (previousFilteredState === activeState) {
        ioiSet = rootState.iois.unfilteredOpenSet;
      } else {
        ioiSet = rootState.iois.iois;
      }
    } else if (activeState === 'off-the-market') {
      if (previousFilteredState === activeState) {
        ioiSet = rootState.iois.unfilteredOffTheMarket;
      } else {
        ioiSet = rootState.iois.myOffTheMarketIOIs;
      }
    } else if (activeState === 'draft') {
      if (previousFilteredState === activeState) {
        ioiSet = rootState.iois.unfilteredDraftSet;
      } else {
        ioiSet = rootState.iois.myDraftIOIs;
      }
    } else if (activeState === 'matched') {
      if (previousFilteredState === activeState) {
        ioiSet = rootState.iois.unfilteredMatchedSet;
      } else {
        ioiSet = rootState.iois.myMatchedIOIs;
      }
    }

    if (newFilters.length > 0) {
      forEach(newFilters, (iterFilter: any) => {
        const { key, values } = iterFilter;
        if (values.length > 0) {
          if (key === 'industry_or_sector') {
            ioiSet = ioiSet.filter((ioi: IOI) =>
              values.includes(ioi.industryOrSector),
            );
          }
          if (key === 'company') {
            ioiSet = ioiSet.filter((ioi: IOI) =>
              values.includes(ioi.companyId),
            );
          }
          if (key === 'bucket_size') {
            ioiSet = ioiSet.filter((ioi: IOI) =>
              values.includes(ioi.bucketSize),
            );
          }
          if (key === 'maturity_bucket') {
            ioiSet = ioiSet.filter((ioi: IOI) =>
              values.includes(ioi.maturityBucket),
            );
          }
          if (key === 'reference_number') {
            ioiSet = ioiSet.filter((ioi: IOI) =>
              startsWith(ioi.referenceNumber, values[0]),
            );
          }
          if (key === 'instrument_type') {
            ioiSet = ioiSet.filter((ioi: IOI) =>
              values.includes(ioi.instrumentType.toString()),
            );
          }
          if (key === 'transaction_date') {
            const filterDate: Date = new Date(values[0]);
            ioiSet = ioiSet.filter(
              (ioi: IOI) =>
                new Date(ioi.proposedTransactionDateStr) >= filterDate,
            );
          }
          if (key === 'with_offers') {
            const withOffers: boolean = values[0] === 'true';
            if (withOffers) {
              ioiSet = ioiSet.filter((ioi: IOI) => ioi.offerCount > 0);
            } else {
              ioiSet = ioiSet.filter((ioi: IOI) => ioi.offerCount === 0);
            }
          }
        }
      });
    }

    dispatch(
      SAVE_FILTERED_IOIS,
      {
        activeSet: activeState,
        filteredIOIs: ioiSet,
      },
      { root: true },
    );
  },

  removeFilter({ commit }, filterToRemove: Filter): void {
    commit('REMOVE_FILTER', filterToRemove);
  },

  clearFilters({ commit, dispatch }): void {
    commit('CLEAR');
    dispatch('applyFilters');
  },

  applyOrders({ dispatch, rootState }, newOrder: Order): void {
    let ioiSet: IOI[] = [];
    const activeState: string = rootState.iois.activeIOISet;
    if (activeState === 'open') {
      ioiSet = rootState.iois.iois;
    } else if (activeState === 'off-the-market') {
      ioiSet = rootState.iois.myOffTheMarketIOIs;
    } else if (activeState === 'draft') {
      ioiSet = rootState.iois.myDraftIOIs;
    } else if (activeState === 'matched') {
      ioiSet = rootState.iois.myMatchedIOIs;
    }

    ioiSet = orderBy(ioiSet, newOrder.key, newOrder.direction);
    dispatch(
      SAVE_FILTERED_IOIS,
      {
        activeSet: activeState,
        filteredIOIs: ioiSet,
      },
      { root: true },
    );
  },
};

export const filters = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
