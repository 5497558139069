
















import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';

const FiltersNameSpace = namespace('filters/');

@Component({
  name: 'SortElement',
  components: {},
})
export default class SortElement extends Vue {
  @Prop({ required: true }) sortTitle!: string;
  @Prop({ required: true }) sortKey!: string;

  // IOI store
  @FiltersNameSpace.Action('applyOrders') applyOrders: any;

  order(key: string, direction: string) {
    this.applyOrders({
      key,
      direction,
    });
  }
}
