
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EventAc } from '@/types/types';
import { EventTypes } from '@/store/actionCenter';

const AuthenticationNameSpace = namespace('authentication/');
const AcNs = namespace('actionCenter/');

@Component({
  name: 'EventItem',
})
export default class EventItem extends Vue {
  @Prop({ required: true }) event!: EventAc;
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AcNs.Action('markEventAsRead') markEventAsRead: any;
  EventTypes = EventTypes;
  readEvent() {
    this.markEventAsRead({
      notificationIds: [this.event.notificationId],
    });
  }
}
