var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ioi-offer-selection-container"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.offersFromCompany')))]),_c('input-select-company',{attrs:{"form-class":"ioi-spread-input__select-container","input-param":_vm.inputBehalfOff,"get-type":"linked","allow-empty":false},on:{"sendInput":function($event){return _vm.$emit('getData')}}}),_c('div',{staticClass:"ioi-offer__title"},[_vm._v(" "+_vm._s(_vm.$t('components.IOIOffer.myOfferTitle'))+" ")]),(!_vm.insertOffer)?_c('div',{staticClass:"ioi-spread-input"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.behalfOf')))]),_c('span',{staticClass:"behalf-off-value"},[_vm._v(_vm._s(_vm.inputBehalfOff.value.label))])]):_vm._e(),_c('div',{staticClass:"ioi-spread-input"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIForm.selectType')))]),_c('input-radio-component',{key:_vm.inputSelectType.id,attrs:{"input-param":_vm.inputSelectType},on:{"sendRadioInput":_vm.setInputValue}})],1),(_vm.showIOISpread)?_c('div',{staticClass:"ioi-spread-input"},[_c('h5',[_vm._v(_vm._s(_vm.spreadText))])]):_c('div',{staticClass:"ioi-spread-input no-space"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSpread),expression:"showSpread"}],staticClass:"ioi-spread-input"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.myOfferSpread')))]),_c('input-component-format',{key:_vm.inputOfferSpread.id,attrs:{"input-param":Object.assign({}, _vm.inputOfferSpread,
        {vRule: ("" + (!_vm.showSpread ? '' : 'required'))})},on:{"sendInput":_vm.setInputValue}}),_c('div',{staticClass:"offer-spread-information-icon"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.myOfferSpreadUnit')))])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSpread),expression:"showSpread"}],staticClass:"ioi-spread-input"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.myOfferSpreadOver')))]),_c('input-select',{attrs:{"input-param":_vm.inputSpreadOverWhat,"translate-value":false,"translate-options":false,"form-class":"ioi-spread-input__select-container"},on:{"sendInput":_vm.setInputValue}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSpread),expression:"!showSpread"}],staticClass:"ioi-spread-input"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.myOfferCoupon')))]),_c('input-component-format',{key:_vm.inputOfferCoupon.id,attrs:{"input-param":Object.assign({}, _vm.inputOfferCoupon,
        {vRule: ("" + (_vm.showSpread ? '' : 'required'))})},on:{"sendInput":_vm.setInputValue}}),_vm._m(0)],1),_c('div',{staticClass:"ioi-spread-input"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.myOfferInstrument')))]),_c('input-select',{attrs:{"input-param":_vm.inputInstrument,"allow-empty":false,"translate-value":false,"translate-options":false,"form-class":"ioi-spread-input__select-container"},on:{"sendInput":_vm.setInputValue}})],1),_c('div',{staticClass:"ioi-spread-input"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.myOfferMinSize')))]),_c('input-component-format',{key:_vm.inputMinOfferSize.id,attrs:{"input-param":_vm.inputMinOfferSize},on:{"sendInput":_vm.setInputValue}}),_c('div',{staticClass:"offer-information-icon"},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'bottom-center',
          content: ("" + (_vm.$t('components.IOIOffer.offerMinimumSizeInformation'))),
        }),expression:"{\n          placement: 'bottom-center',\n          content: `${$t('components.IOIOffer.offerMinimumSizeInformation')}`,\n        }"}],attrs:{"src":require("@/assets/images/information-icon.png"),"alt":"Information Icon"}})])],1),_c('div',{staticClass:"ioi-spread-input"},[_c('label',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.myOfferMaxSize')))]),_c('input-component-format',{key:_vm.inputMaxOfferSize.id,attrs:{"input-param":_vm.inputMaxOfferSize},on:{"sendInput":_vm.setInputValue}}),_c('div',{staticClass:"offer-information-icon"},[_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'bottom-center',
          content: ("" + (_vm.$t('components.IOIOffer.offerMaximumSizeInformation'))),
        }),expression:"{\n          placement: 'bottom-center',\n          content: `${$t('components.IOIOffer.offerMaximumSizeInformation')}`,\n        }"}],attrs:{"src":require("@/assets/images/information-icon.png"),"alt":"Information Icon"}})])],1),(_vm.ioiAdditionalInformation)?_c('div',{staticClass:"ioi-spread-input additional-information"},[_c('p',{staticClass:"additional-information__p"},[_c('strong',[_vm._v(_vm._s(_vm.$t('components.IOIOffer.myOfferAdditionalInformation')))]),_vm._v(" "+_vm._s(_vm.ioiAdditionalInformation)+" ")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      this.$validator.errors.has('myMinOfferSize') ||
        this.$validator.errors.has('myMaxOfferSize')
    ),expression:"\n      this.$validator.errors.has('myMinOfferSize') ||\n        this.$validator.errors.has('myMaxOfferSize')\n    "}],staticClass:"validation-error"},[_c('label',[_vm._v(_vm._s(_vm.errors.first('myMinOfferSize'))+" "+_vm._s(_vm.errors.first('myMaxOfferSize')))])]),_c('div',{staticClass:"empty-line-resizable"}),(_vm.tenMinutesBefore)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.$t('views.myInvestorAccount.errorMinutesSpread'))+" ")]):_vm._e(),_c('div',{staticClass:"ioi-offer-buttons"},[_c('button-component',{attrs:{"button-param":_vm.makeOfferButton},on:{"onClickSubmit":function($event){return _vm.validateOffer()}}}),_c('button-component',{attrs:{"button-param":_vm.clearOfferButton},on:{"onClickSubmit":function($event){return _vm.clearOffer()}}})],1),(_vm.companyCantVote)?_c('div',{staticClass:"cant-vote"},[_c('p',[_vm._v(_vm._s(_vm.$t('views.myInvestorAccount.cantVote')))])]):_vm._e(),_c('collapsible-component',{staticClass:"ioi-offer-collapsible",attrs:{"title":_vm.OfferInformationTitle,"content":_vm.OfferInformationBody,"icon":"information-blue.png"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offer-information-icon"},[_c('label',[_vm._v("%")])])}]

export { render, staticRenderFns }