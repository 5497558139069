






























































































































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IOI } from '@/types/instrumentsTypes';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import { InputFormatObject } from '@/components/inputs/inputTypes';
import { EventBus } from '@/main';
import {
  isDutchStateIndustry,
  getIndustry,
  getFrequency,
  getDayCount,
  getRankingType,
  getRankingGuarantee,
  getRedemptionForm,
  getBucketSize,
  getMaturityBucket,
  getSpread,
  getStructure,
  instrumentsLabel,
} from '@/util/ioiUtils';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import { createIOIOverviewPDF } from '@/api/pdf';
import IOISummary from '@/components/ioi/IOISummary.vue';

const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'IOIConfirm',
  components: {
    IOISummary,
    InputComponentFormat,
    ButtonComponent,
  },
})
export default class IOIConfirm extends Vue {
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @Prop({}) ioiParam!: IOI;
  @Prop({}) showModalParam!: boolean;
  @Prop({}) showActionsParam!: boolean;

  eventBus = EventBus;
  inputIOI: any = {};
  showModal: boolean = false;
  rawMinimumPiece: number = 0;
  dutchStateIndustry: boolean = false;
  showCompanyNameInformation: boolean = false;
  showMinimumPiece: boolean = false;
  instrumentsLabel = instrumentsLabel;
  showDocument: boolean = IOIConfirm.showDocument;

  get title(): string {
    if (this.showActionsParam) {
      return `${this.$t('components.IOIFormConfirm.title')}`;
    }
    return `${this.$t('components.IOIFormConfirm.titleSummary')}`;
  }

  inputSize: InputFormatObject = {
    id: 'issueNominalSize_',
    name: 'issueNominalSize',
    value: '',
    placeHolder: '',
    containerClass: 'ioi-confirm-formatted-input',
    vRule: '',
    validationClasses: false,
    precision: 0,
    disabled: true,
  };

  inputMinimumPiece: InputFormatObject = {
    id: 'minimumPiece_',
    name: 'minimumPiece',
    value: '',
    placeHolder: '',
    containerClass: 'ioi-confirm-formatted-input',
    vRule: '',
    validationClasses: false,
    precision: 0,
    disabled: true,
  };

  inputCoupon: InputFormatObject = {
    id: 'coupon_',
    name: 'coupon',
    value: '',
    placeHolder: '',
    containerClass: 'ioi-confirm-formatted-input',
    vRule: '',
    validationClasses: false,
    precision: 3,
    showNegative: true,
    disabled: true,
    showCurrency: false,
  };

  get showLMControlButton(): boolean {
    return this.inputIOI.state === 'draft' || this.inputIOI.state === '';
  }

  static get showDocument(): boolean {
    return false;
  }

  get requestLMControlButton(): any {
    return {
      containerClass: 'button-end',
      buttonClass: 'button-icon button-icon-very-big-alert',
      description: `${this.$t('components.IOIFormConfirm.controlIOIbyLM')}`,
      extendedDescription: `${this.$t(
        'components.IOIFormConfirm.controlIOIbyLMExtended',
      )}`,
      toolTip: `${this.$t('components.IOIFormConfirm.controlIOIbyLMTooltip')}`,
      icon: 'support-button.png',
      showExtended: true,
    };
  }

  get confirmIOIButton(): any {
    const description: string =
      this.inputIOI.state === 'draft'
        ? `${this.$t('components.IOIFormConfirm.saveDraftIOI')}`
        : `${this.$t('components.IOIFormConfirm.confirmIOI')}`;
    const toolTip: string =
      this.inputIOI.state === 'draft'
        ? `${this.$t('components.IOIFormConfirm.saveDraftIOITooltip')}`
        : `${this.$t('components.IOIFormConfirm.confirmIOITooltip')}`;

    return {
      containerClass: 'button-end',
      buttonClass: 'button-icon',
      description,
      toolTip,
      icon: 'send-to-market-blue.png',
      showExtended: false,
    };
  }

  get correctIOIButton(): any {
    return {
      buttonClass: 'button-icon button-icon-big-alert',
      description: `${this.$t('components.IOIFormConfirm.correctIOI')}`,
      toolTip: `${this.$t('components.IOIFormConfirm.correctIOI')}`,
      icon: 'market-cancel.png',
      showExtended: false,
    };
  }

  get cancelIOIButton(): any {
    return {
      buttonClass: 'button-icon button-icon-centered button-icon-big-alert',
      description: `${this.$t('components.IOIForm.cancelButton')}`,
      toolTip: `${this.$t('components.IOIFormConfirm.cancelIOITooltip')}`,
      showExtended: false,
    };
  }

  onConfirmIOI(): void {
    this.$emit('onConfirmIOI');
  }

  onControlIOI(): void {
    this.$emit('onControlIOI');
  }

  closeConfirmationWindow(): void {
    this.$emit('onCloseConfirmation');
  }

  onCancelIOI(): void {
    this.$emit('onCancelIOI');
  }

  get closeIOIButton(): any {
    return {
      containerClass: 'button-end',
      buttonClass: 'button-icon button-icon-large ',
      description: `${this.$t('components.IOIFormConfirm.closeIOIModal')}`,
      toolTip: `${this.$t('components.IOIFormConfirm.closeIOIModal')}`,
      icon: '',
      showExtended: false,
    };
  }

  onCloseIOI(): void {
    this.$emit('onCloseIOI');
  }

  printPDF() {
    let { referenceNumber } = this.inputIOI;
    if (!referenceNumber) {
      referenceNumber = null;
    }
    const overviewData = {
      accountId: this.getUserAccountId,
      indicationOfInterestId: this.inputIOI.indicationOfInterestId,
      companyName: this.inputIOI.issuerCompanyName,
      industryOrSector: this.inputIOI.industryOrSector,
      countryName: this.inputIOI.countryName,
      currencyType: this.inputIOI.currencyType,
      instrumentTypes: this.inputIOI.instrumentTypes,
      issueNominalSize: this.inputIOI.issueNominalSize,
      bucketSize: this.inputIOI.bucketSize,
      issueMaturity: this.inputIOI.issueMaturity,
      maturityBucket: this.inputIOI.maturityBucket,
      maturityDate: this.inputIOI.maturityDate,
      transactionDate: this.inputIOI.proposedTransactionDateStr,
      transactionTime: this.inputIOI.proposedTransactionTime,
      minimumPiece: this.rawMinimumPiece,
      settlementDate: this.inputIOI.proposedSettlementDate,
      brokenCouponDate: this.inputIOI.brokenCouponDate,
      couponFrequency: this.inputIOI.couponFrequency,
      redemptionForm: this.inputIOI.redemptionForm,
      repaymentFrequency: this.inputIOI.repaymentFrequency,
      indicativeCoupon: this.inputIOI.indicativeCoupon
        ? this.inputIOI.indicativeCoupon.toString()
        : this.inputIOI.indicativeCoupon,
      spreadMinValue: this.inputIOI.spreadMinValue,
      spreadMaxValue: this.inputIOI.spreadMaxValue,
      spreadOverWhat: this.inputIOI.spreadOverWhat,
      guarantee: this.inputIOI.rankingGuarantee,
      rankingType: this.inputIOI.rankingType,
      type: this.inputIOI.indicativeType,
      documentationForm: this.inputIOI.documentationForm,
      dayCount: this.inputIOI.dayCount,
      additionalInformation: this.inputIOI.additionalInformation,
      ratingSP: this.inputIOI.creditRatingS,
      ratingF: this.inputIOI.creditRatingF,
      ratingM: this.inputIOI.creditRatingM,
      referenceNumber,
    };
    createIOIOverviewPDF(overviewData);
  }

  created() {
    this.eventBus.$on('reloadConfirmIOI', (inputInformation: any) => {
      this.showModal = inputInformation.showModal;
      if (inputInformation.showModal) {
        const companyName: string =
          inputInformation.ioi.companyName === undefined
            ? inputInformation.ioi.issuerCompanyName
            : inputInformation.ioi.companyName;
        this.rawMinimumPiece = Number.parseInt(
          inputInformation.ioi.minimumPiece,
          10,
        );
        this.inputIOI = {
          indicationOfInterestId: inputInformation.ioi.indicationOfInterestId,
          referenceNumber: inputInformation.ioi.referenceNumber,
          state: inputInformation.ioi.state,
          issuerCompanyName: companyName,
          industryOrSectorCode: inputInformation.ioi.industryOrSector,
          industryOrSector: getIndustry(inputInformation.ioi.industryOrSector),
          countryName: inputInformation.ioi.countryName,
          currencyType: inputInformation.ioi.currencyType,
          instrumentTypes: inputInformation.ioi.instrumentTypes,
          instrumentTypeLabel: inputInformation.ioi.instrumentTypeLabel,
          instrumentTypeFullDescription:
            inputInformation.ioi.instrumentTypeLabel,
          ecaInsuredPercentage: inputInformation.ioi.ecaInsuredPercentage,
          structure: getStructure(inputInformation.ioi.structure),
          issueNominalSize: inputInformation.ioi.issueNominalSize,
          bucketSize: getBucketSize(inputInformation.ioi.bucketSize),
          issueMaturity: inputInformation.ioi.issueMaturity,
          maturityBucket: getMaturityBucket(
            inputInformation.ioi.maturityBucket,
          ),
          proposedTransactionDateStr:
            inputInformation.ioi.proposedPricingAndTransactionDate === undefined
              ? inputInformation.ioi.proposedTransactionDateStr
              : inputInformation.ioi.proposedPricingAndTransactionDate,
          proposedTransactionTime: inputInformation.ioi.proposedPricingAndTransactionTime.slice(
            0,
            5,
          ),
          minimumPiece: inputInformation.ioi.minimumPiece,
          proposedSettlementDate:
            inputInformation.ioi.proposedSettlementDate === undefined
              ? inputInformation.ioi.proposedSettlementDateStr
              : inputInformation.ioi.proposedSettlementDate,
          brokenCouponDate: inputInformation.ioi.brokenCouponDate,
          maturityDate:
            inputInformation.ioi.maturityDate === null
              ? '-'
              : inputInformation.ioi.maturityDate,
          couponFrequency: getFrequency(inputInformation.ioi.couponFrequency),
          redemptionForm: getRedemptionForm(
            inputInformation.ioi.redemptionForm,
          ),
          repaymentFrequency:
            inputInformation.ioi.repaymentFrequency === undefined
              ? ''
              : getFrequency(inputInformation.ioi.repaymentFrequency),
          enterSpread: inputInformation.ioi.enterSpread,
          spreadMinValue: inputInformation.ioi.spreadMinValue,
          spreadMaxValue: inputInformation.ioi.spreadMaxValue,
          spreadOverWhat:
            inputInformation.ioi.spreadOverWhat === ''
              ? ''
              : getSpread(inputInformation.ioi.spreadOverWhat),
          enterCoupon: inputInformation.ioi.enterCoupon,
          indicativeCoupon: inputInformation.ioi.indicativeCoupon,
          rankingGuarantee: getRankingGuarantee(
            inputInformation.ioi.rankingGuarantee,
          ),
          rankingType: getRankingType(inputInformation.ioi.rankingType),
          indicativeType: inputInformation.ioi.indicativeType,
          documentationForm: inputInformation.ioi.documentationForm,
          dayCount: getDayCount(inputInformation.ioi.dayCount),
          additionalInformation: inputInformation.ioi.additionalInformation,
          creditRatingS:
            inputInformation.ioi.creditRatingS === ''
              ? ''
              : inputInformation.ioi.creditRatingS,
          creditRatingF:
            inputInformation.ioi.creditRatingF === ''
              ? ''
              : inputInformation.ioi.creditRatingF,
          creditRatingM:
            inputInformation.ioi.creditRatingM === ''
              ? ''
              : inputInformation.ioi.creditRatingM,
        };
        this.dutchStateIndustry = isDutchStateIndustry(
          this.inputIOI.industryOrSectorCode,
        );
        this.showCompanyNameInformation = this.showActionsParam
          ? true
          : !isDutchStateIndustry(this.inputIOI.industryOrSectorCode);
        this.inputSize.value = this.inputIOI.issueNominalSize;
        this.inputSize.id = `issueNominalSize_${this.inputIOI.issueNominalSize}`;
        if (this.inputIOI.minimumPiece) {
          this.showMinimumPiece = true;
          this.inputMinimumPiece.value = this.inputIOI.minimumPiece;
          this.inputMinimumPiece.id = `issueNominalSize_${this.inputIOI.minimumPiece}`;
        } else {
          this.showMinimumPiece = false;
        }
        this.inputCoupon.value = this.inputIOI.indicativeCoupon;
        this.inputCoupon.id = `issueNominalSize_${this.inputIOI.indicativeCoupon}`;
      } else {
        delete this.inputIOI;
      }
    });
  }
}
