import api from './index';
import { CompanySettingsBody } from '@/types/types';

// account signs up and login
export function transferHamRights(payload: {
  companyId: string;
  userAccountId: string;
}) {
  return api.post('/company/transfer-ham-rights', payload);
}

export function getAccountManagers(payload: string) {
  return api.get(`company/account-managers/${payload}`);
}

export function removeAccountManager(payload: {
  companyId: string;
  userAccountId: string;
}) {
  return api.post('/company/remove-account-manager', payload);
}

export function getRoles(companyId: string) {
  return api.get(`/company/account-managers/${companyId}/roles`);
}

export function setRoles(payload: {
  name: string;
  companyId: string;
  permissions: string[];
}) {
  return api.post(
    `/company/account-managers/${payload.companyId}/roles`,
    payload,
  );
}

export function updateRoles(payload: {
  name: string;
  companyId: string;
  permissions: string[];
  id: string;
}) {
  return api.put(
    `/company/account-managers/${payload.companyId}/roles/${payload.id}`,
    payload,
  );
}

export function deleteRoles(payload: { companyId: string; id: string }) {
  return api.delete(
    `/company/account-managers/${payload.companyId}/roles/${payload.id}`,
  );
}

export function assignRole(
  payload: {
    roleId: string;
    accountManagerId: string;
  },
  companyId: string,
) {
  return api.post(
    `/company/account-managers/${companyId}/roles/assign`,
    payload,
  );
}

export function refuseAccountManagerInvite(payload: {
  companyId: string;
  userAccountId: string;
}) {
  return api.post('/company/refuse-account-manager-invite', payload);
}

export function leaveCompany(payload: {
  companyId: string;
  userAccountId: string;
}) {
  return api.post('/company/leave', payload);
}

export function getCompanyUserId(userAccountId: string) {
  return api.get(`/company/${userAccountId}`);
}

export function getCompanyHamUserId(userAccountId: string) {
  return api.get(`/company/${userAccountId}`);
}

export function createCompany(data: {
  companyName: string;
  industryOrSector: string;
  street: string;
  houseNumber: number;
  houseNumberSuffix: string;
  postalCode: string;
  city: string;
  userAccountId: string;
  countryCode: string;
}) {
  return api.post('company/create', data);
}

export function editCompany(data: {
  companyName: string;
  companyId: string;
  industryOrSector: string;
  type: number;
  street: string;
  houseNumber: number;
  houseNumberSuffix: string;
  postalCode: string;
  city: string;
  countryCode: string;
  currencyType: string;
  leiNumber: any;
  accountManagerStatus: number;
  accountManagerType: string;
}) {
  return api.put('company', data);
}

export function acceptAccountManagerInvite(payload: {
  companyId: string;
  userAccountId: string;
}) {
  return api.post('/company/accept-account-manager-invite', payload);
}

export function postCompanySettings(
  CompanyId: string,
  payload: CompanySettingsBody,
) {
  return api.post(`/company/${CompanyId}/settings`, payload);
}

export function putCompanySettings(
  CompanyId: string,
  payload: CompanySettingsBody,
) {
  return api.put(`/company/${CompanyId}/settings`, payload);
}

export function deleteCompanySettings(CompanyId: string) {
  return api.delete(`/company/${CompanyId}/settings`);
}

export function getCompanySettings(CompanyId: string) {
  return api.get(`/company/${CompanyId}/settings`);
}

// api
//   .post('company/create', data)
// api
//   .put('company', data)
