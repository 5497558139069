
































































































































































































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment';
import { isEmpty, forEach } from 'lodash';
import { permAvailable, redirectUserPage } from '@/util/generalUtils';
import ProgressBarComponent from '@/components/notifications/ProgressBarComponent.vue';
import InputComponent from '@/components/inputs/InputComponent.vue';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import InputSelectComponent from '@/components/inputs/InputSelectComponent.vue';
import InputSelectMultiComponent from '@/components/inputs/InputSelectMultiComponent.vue';
import InputRadioComponent from '@/components/inputs/InputRadioComponent.vue';
import InputDateComponent from '@/components/inputs/InputDateComponent.vue';
import CollapsibleComponent from '@/components/utils/CollapsibleComponent.vue';
import { EventBus } from '@/main';
import { spreadList } from '@/api/instrumentApi';
import {
  CustomInput,
  RadioButtonOption,
  CustomMultiInput,
  InputMultiSelectObject,
  InputFormatObject,
} from '@/components/inputs/inputTypes';
import {
  isDutchStateIndustry,
  getIndustry,
  getFrequency,
  getRedemptionForm,
  redemptionFormList,
  multipleInstruments,
  getIndicativeType,
  getRankingGuarantee,
  getDayCount,
  getStructure,
  getBucketSize,
  getSpread,
  getRankingType,
  getIOIDefaultValues,
  instrumentsFullLabel,
  instrumentsLabel,
  getDocumentationFormOptions,
  calculateDays,
  rankingTypeOptions,
  createMaturityString,
  indicativeTypeOptionList,
  dayCountOptionList,
} from '@/util/ioiUtils';
import IoiConfirm from './IOIConfirm.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import sentry, { sentryAdditional } from '@/util/sentry';
import InputSelect from '@/components/inputs/InputSelect.vue';
import InputSelectCompany from '@/components/inputs/InputSelectCompany.vue';
import * as Sentry from '@sentry/browser';
import { Permission } from '@/types/types';
const ProgressBarNameSpace = namespace('progressBar/');
const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');
const InstrumentNameSpace = namespace('iois/');
const CompanyStore = namespace('companies/');
const tabState = namespace('tabState/');

@Component({
  name: 'IOIForm',
  components: {
    InputSelectCompany,
    InputSelect,
    IoiConfirm,
    ProgressBarComponent,
    InputComponent,
    InputComponentFormat,
    InputSelectComponent,
    InputRadioComponent,
    InputDateComponent,
    ButtonComponent,
    CollapsibleComponent,
    InputSelectMultiComponent,
  },
})
export default class IOIForm extends Vue {
  // ProgressBar store
  @ProgressBarNameSpace.Getter('currentStep') currentStep: number;
  @ProgressBarNameSpace.Getter('type') type: string;
  @ProgressBarNameSpace.Getter('percentage') percentage: number;
  @ProgressBarNameSpace.Action('create') createProgressBar: any;
  @ProgressBarNameSpace.Action('setPercentage') setPercentage: any;
  @ProgressBarNameSpace.Action('incStep') incStepProgressBar: any;
  @ProgressBarNameSpace.Action('decStep') decStepProgressBar: any;

  // Notification store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;

  @InstrumentNameSpace.Action('prepopulateIOI') prepopulateIOI: any;
  @InstrumentNameSpace.Action('submitIOI') submitIOI: any;
  @InstrumentNameSpace.Action('prepopulateEditIOI') prepopulateEditIOI: any;
  @InstrumentNameSpace.Action('editIOI') editIOI: any;
  @InstrumentNameSpace.Action('ioiRequiredLists') ioiRequiredLists: any;
  @CompanyStore.Getter('multipleCompanies') multipleCompanies: boolean;
  @CompanyStore.Getter('selectedCompanyData') selectedCompanyData: any;
  @InstrumentNameSpace.Action('getIOIs') getIOIs: any;

  @tabState.Action('setTab') setTab: any;

  $refs!: {
    spreadMinimum: HTMLFormElement;
  };

  permAvailable = permAvailable;
  Permission = Permission;
  calculateDays = calculateDays;
  spreadList = spreadList;
  createMaturityString = createMaturityString;
  windowSize: number = 0;
  confirmModal: any = {
    showModal: false,
    ioi: {},
  };

  eventBus = EventBus;
  todayDate = moment()
    .utc()
    .format('YYYY-MM-DD');

  myAccount = '';
  ioiId: string = '';
  insertMode: boolean = false;
  issueType: number = 1; // { 1: IOI, 2:primaryIssue, 3: secondaryIssue }
  state: string = '';
  validateInputs: boolean = false;
  instrumentsLabel = instrumentsLabel;
  showDocument: boolean = IOIForm.showDocument;
  confirmingIoi: boolean = false;
  controllingIoi: boolean = false;

  companyId: string = '';
  inputCompanyName: any = {
    name: 'companyName',
    placeHolder: 'components.IOIForm.issuerCompanyName',
    vRule: 'required|alpha_spaces',
    defaultTooltip: true,
    containerClass: 'ioi-input',
    disabled: true,
  };

  inputCompanySelect: any = {
    name: 'companyName',
    placeHolder: 'components.IOIForm.issuerCompanyName',
    vRule: 'required',
    defaultTooltip: true,
    value: undefined,
    optionList: [],
  };

  inputIndustryCode: string = '';
  inputIndustry: any = {
    name: 'industry',
    placeHolder: 'components.IOIForm.industry',
    vRule: 'required',
    defaultTooltip: true,
    containerClass: 'ioi-input',
    disabled: true,
  };

  inputCountry: any = {
    name: 'country',
    placeHolder: 'components.IOIForm.country',
    vRule: 'required|alpha',
    defaultTooltip: true,
    containerClass: 'ioi-input',
    disabled: true,
  };

  countryCode: string = '';
  inputCurrency: any = {
    name: 'currency',
    placeHolder: 'components.IOIForm.currencyType',
    vRule: 'required|alpha',
    defaultTooltip: true,
    containerClass: 'ioi-input',
    disabled: true,
  };

  instrumentTypeLabel: string = '';

  preferencesPreloaded: boolean = false;
  inputMultiInstrumentType: InputMultiSelectObject = {
    name: 'instrumentType',
    placeHolder: 'components.IOIForm.instrumentType',
    containerClass: 'ioi-form-left',
    vRule: 'required',
    optionList: [],
    value: [],
    key: 'instrumentType',
    hideVLabel: true,
    multi: true,
  };

  inputEcaInsuredPercentage: any = {
    name: 'ecaInsuredPercentage',
    placeHolder: 'components.IOIForm.ecaInsuredPercentage',
    tooltip: 'components.IOIForm.ecaInsuredTooltip',
    vRule: this.validateEca,
    containerClass: 'ioi-input',
    hideVLabel: true,
  };

  inputStructure: any = {
    name: 'structure',
    placeHolder: 'components.IOIForm.structure',
    vRule: this.validateStructure,
    defaultTooltip: true,
    optionList: [],
    value: undefined,
  };

  sizeNoFormat: number = 0;
  inputSize: InputFormatObject = {
    id: 'issueNominalSize_',
    name: 'issueNominalSize',
    value: '',
    placeHolder: 'components.IOIForm.issueNominalSize',
    defaultTooltip: true,
    containerClass: 'ioi-input',
    vRule: 'required|isNumber|min_value:500000',
    validationClasses: true,
    vLabel: 'components.IOIForm.issueNominalSizeVLabel',
    precision: 0,
  };

  inputBucketSize: any = {
    name: 'bucketSize',
    placeHolder: 'components.IOIForm.bucketSize',
    vRule: 'required',
    defaultTooltip: true,
    containerClass: 'ioi-input',
    disabled: true,
  };

  bucketSizeCode: number = 0;
  inputMaturity: any = {
    name: 'issueMaturity',
    placeHolder: 'components.IOIForm.issueMaturity',
    vRule: 'required',
    defaultTooltip: true,
    containerClass: 'ioi-input',
    isValid: true,
    value: '',
  };

  maturity: number = 0;

  inputTransactionDate: any = {
    name: 'proposedTransactionDate',
    ref: 'proposedTransactionDate',
    placeHolder: 'components.IOIForm.proposedTransactionDate',
    tooltip: 'components.IOIForm.proposedTransactionDateTooltip',
    vRule: `required|date_format:yyyy-MM-dd|after:${this.todayDate},true`,
    value: '',
    vLabel: 'components.IOIForm.proposedTransactionDateVLabel',
    optionList: ['limitDates'],
  };

  commissionTitle: string = '';
  commissionBody: string[] = [];

  inputSettlementDate: any = {
    name: 'proposedSettlementDate',
    ref: 'proposedSettlementDate',
    placeHolder: 'components.IOIForm.proposedSettlementDate',
    tooltip: 'components.IOIForm.proposedSettlementDateTooltip',
    vRule: '',
    value: '',
    optionList: ['limitDates'],
  };

  inputBrokenCouponDate: any = {
    name: 'brokenCouponDate',
    ref: 'brokenCouponDate',
    placeHolder: 'components.IOIForm.brokenCouponDate',
    defaultTooltip: true,
    vRule: '',
    value: '',
    optionList: ['limitDates'],
  };

  get inputMaturityDateVrule() {
    return `required|minimumMaturity:${this.inputSettlementDate.value}`;
  }

  inputMaturityDate: any = {
    name: 'maturityDate',
    ref: 'maturityDate',
    placeHolder: 'components.IOIForm.maturityDate',
    defaultTooltip: true,
    vRule: this.inputMaturityDateVrule,
    value: '',
    optionList: ['limitDates'],
  };

  get showMinimumPiece(): boolean {
    return (
      this.inputMultiInstrumentType.value.filter(
        (instrument: any) => instrument.value == 2 || instrument.value == 3,
      ).length > 0
    );
  }

  inputMinimumPiece: InputFormatObject = {
    id: 'minimumPiece_',
    name: 'minimumPiece',
    value: '',
    placeHolder: 'components.IOIForm.minimumPiece',
    defaultTooltip: true,
    containerClass: 'ioi-input',
    vRule: 'required|minimum_piece_validation',
    validationClasses: true,
    vLabel: 'components.IOIForm.issueNominalSizeVLabel',
    precision: 0,
  };

  get expiringHourTooltip(): string {
    return `${this.$t('components.IOIForm.expiringHourTooltip')}`;
  }

  inputExpiringHour: any = {
    name: 'expiringHour',
    placeHolder: 'components.IOIForm.expiringHour',
    vRule: 'required',
    defaultTooltip: true,
    containerClass: 'form-select-small',
    optionList: [],
    hideVLabel: true,
    translate: false,
    value: undefined,
  };

  inputExpiringMinutes: any = {
    name: 'expiringMinutes',
    placeHolder: 'components.IOIForm.expiringMinutes',
    vRule: 'required',
    defaultTooltip: true,
    containerClass: 'form-select-small',
    optionList: [],
    hideVLabel: true,
    translate: false,
    value: undefined,
  };

  get showExpiringError(): boolean {
    return (
      (this.inputExpiringHour.value !== undefined &&
        this.inputExpiringHour.value.value === '') ||
      (this.inputExpiringMinutes.value !== undefined &&
        this.inputExpiringMinutes.value.value === '')
    );
  }

  inputCouponFrequency: any = {
    name: 'couponFrequency',
    placeHolder: 'components.IOIForm.couponFrequency',
    vRule: 'required',
    defaultTooltip: true,
    containerClass: 'select-dropdown-ioi',
    optionList: [],
    value: undefined,
  };

  inputRedemptionForm: any = {
    name: 'redemptionForm',
    placeHolder: 'components.IOIForm.redemptionForm',
    vRule: 'required',
    defaultTooltip: true,
    optionList: [],
    value: undefined,
  };

  inputRepaymentFrequency: any = {
    name: 'repaymentFrequency',
    placeHolder: 'components.IOIForm.repaymentFrequency',
    vRule: 'required',
    defaultTooltip: true,
    optionList: [],
    value: undefined,
  };

  inputRankingType: any = {
    name: 'rankingType',
    placeHolder: 'components.IOIForm.ranking',
    vRule: 'required',
    optionList: [],
    defaultTooltip: true,
    value: undefined,
  };

  inputRankingGuarantee: any = {
    name: 'rankingGuarantee',
    placeHolder: 'components.IOIForm.rankingGuarantee',
    vRule: 'required',
    defaultTooltip: true,
    optionList: [],
    value: undefined,
  };

  inputSpreadOrCouponType: any = {
    name: 'spreadCouponType',
    optionList: IOIForm.spreadTypeOptions,
    defaultTooltip: true,
    containerClass: 'input-row radio-container',
    verticalRadioButton: false,
    value: 'S',
    showImage: false,
  };

  inputSpreadMinimum: any = {
    name: 'spreadMinimum',
    placeHolder: 'components.IOIForm.spreadMinimum',
    defaultTooltip: true,
    vRule: 'required|decimal|min_value:-100',
    containerClass: 'ioi-input',
    ref: 'spreadMinimum',
    hideVLabel: true,
    value: '',
  };

  inputSpreadMaximum: any = {
    name: 'spreadMaximum',
    placeHolder: 'components.IOIForm.spreadMaximum',
    defaultTooltip: true,
    vRule: 'required|decimal',
    containerClass: 'ioi-input',
    ref: 'spreadMaximum',
    hideVLabel: true,
    value: '',
  };

  get spreadErrors(): string {
    return `${this.$validator.errors.first(
      'spreadMinimum',
    )} ${this.$validator.errors.first('spreadMaximum')}`;
  }

  inputSpreadOverWhat: any = {
    name: 'spreadOverWhat',
    placeHolder: 'components.IOIForm.spreadOverWhat',
    defaultTooltip: true,
    containerClass: 'form-select-medium',
    optionList: [],
    translate: false,
    hideVLabel: true,
    value: undefined,
  };

  spreadOverWhatCode: string = '';
  inputIndicativeCoupon: InputFormatObject = {
    id: 'indicativeCoupon_',
    name: 'indicativeCoupon',
    value: '',
    placeHolder: 'components.IOIForm.indicativeCoupon',
    tooltip: 'components.IOIForm.indicativeCouponTooltip',
    containerClass: 'ioi-input',
    vRule: 'required|regex:[1234567890]|min_value:-200',
    validationClasses: true,
    precision: 3,
    showNegative: true,
    currencySymbol: '%',
  };

  inputIndicativeType: any = {
    name: 'indicativeType',
    placeHolder: 'components.IOIForm.indicativeType',
    vRule: 'required',
    defaultTooltip: true,
    containerClass: 'select-dropdown-ioi',
    optionList: [],
    value: undefined,
  };

  inputDocumentationForm: any = {
    name: 'documentationForm',
    placeHolder: 'components.IOIForm.documentationForm',
    defaultTooltip: true,
    containerClass: 'select-dropdown-ioi',
    optionList: [],
    value: '',
  };

  inputDocumentationField: any = {
    name: 'documentationField',
    placeHolder: 'components.IOIForm.documentationForm',
    defaultTooltip: true,
    class: 'no-right-margin-field',
    value: undefined,
  };

  inputDayCount: any = {
    name: 'dayCount',
    placeHolder: 'components.IOIForm.dayCount',
    vRule: 'required',
    defaultTooltip: true,
    optionList: [],
    translate: false,
    value: undefined,
  };

  additionalInformation: string = ''; // string

  additionalInformationField: any = {
    name: 'additionalInformation',
    placeHolder: 'components.IOIForm.additionalInformation',
    defaultTooltip: true,
    containerClass: 'select-dropdown-ioi',
    optionList: [],
    vRule: 'max:255',
    type: 'textarea',
    value: '',
    class: 'textarea',
  };

  inputCreditRatingS: any = {
    name: 'creditRatingS',
    placeHolder: 'components.IOIForm.creditRatingS',
    defaultTooltip: true,
    containerClass: 'form-select-medium',
    optionList: [],
    hideVLabel: true,
    translate: true,
    value: undefined,
  };

  inputCreditRatingF: any = {
    name: 'creditRatingF',
    placeHolder: 'components.IOIForm.creditRatingF',
    vRule: '',
    defaultTooltip: true,
    containerClass: 'form-select-medium',
    optionList: [],
    hideVLabel: true,
    translate: true,
    value: undefined,
  };

  inputCreditRatingM: any = {
    name: 'creditRatingM',
    placeHolder: 'components.IOIForm.creditRatingM',
    defaultTooltip: true,
    containerClass: 'form-select-medium',
    optionList: [],
    hideVLabel: true,
    translate: true,
    value: undefined,
  };

  newIOI: any = {}; // as DtoIOI
  ioiLists: any = {};
  inputEnterSpread: any = {
    name: 'enterSpread',
    id: 'enterSpread',
    containerClass: 'ioi-radio-nomargin',
    optionList: [],
    value: false,
    verticalRadioButton: false,
  };

  enterSpread: boolean = false;
  enterCoupon: boolean = false;

  get activeInstrumentTypes() {
    // @ts-ignore
    return this.inputMultiInstrumentType.value.map((o: any) => o.value);
  }

  get daysBetweenSettlementAndMaturity() {
    return (
      calculateDays(
        this.inputMaturityDate.value,
        this.inputSettlementDate.value,
      ) || 0
    );
  }

  static get spreadTypeOptions(): RadioButtonOption[] {
    const options: RadioButtonOption[] = [];
    options.push(
      {
        id: 'spread',
        value: 'S',
        labelCode: 'components.IOIForm.spreadOption',
        static: false,
      },
      {
        id: 'coupon',
        value: 'C',
        labelCode: 'components.IOIForm.couponOption',
        static: false,
      },
    );
    return options;
  }

  static ratingValue(current: any): string {
    return current === undefined ||
      current === null ||
      current === '' ||
      current.value === null
      ? ''
      : current.value;
  }

  setCompany(company: any) {
    if (this.insertMode) {
      this.inputCompanySelect.value = {
        value: company.value,
        label: company.label,
      };
      this.inputCompanyName.value = company.label;
      this.companyId = company.value;
      this.inputIndustryCode = company.industry;
      this.inputIndustry.value =
        company.industry === '' ? null : getIndustry(company.industry);
      this.eventBus.$emit('reloadInput', this.inputIndustry);
      this.countryCode = company.countryCode;
      this.inputCountry.value = company.countryName;
      this.eventBus.$emit('reloadInput', this.inputCountry);
      this.inputCurrency.value =
        company.currency === '' ? null : company.currency;
      this.eventBus.$emit('reloadInput', this.inputCurrency);
      this.eventBus.$emit('reloadCompanyInput', this.inputCompanySelect);
      this.setDefaultValues();
    }
  }

  // handle regular input components
  setInputValue(inputValue: CustomInput) {
    switch (inputValue.name) {
      case 'companyName':
        if (this.isIssuer) {
          this.inputCompanyName.value = inputValue.value;
        }
        break;
      case 'ecaInsuredPercentage':
        this.inputEcaInsuredPercentage.value = inputValue.value;
        break;
      case 'structure':
        this.inputStructure.value = inputValue.value;
        break;
      case 'issueNominalSize':
        if (inputValue.value !== null && inputValue.value !== '') {
          this.sizeNoFormat = Number(inputValue.value);
          this.inputSize.value = inputValue.value;
          this.inputSize.id = `issueNominalSize_${inputValue.value}`;
        }
        this.calculateSizeBucket();
        break;
      case 'proposedTransactionDate':
        this.inputTransactionDate.value = inputValue.value;
        if (this.inputTransactionDate.value !== '') {
          this.inputTransactionDate.vRule = this.validateTransactionDate;
          this.eventBus.$emit('reloadDateInput', this.inputTransactionDate);
        }
        this.inputSettlementDate.vRule = this.validateSettlementDate;
        if (this.inputSettlementDate.value === '') {
          this.inputSettlementDate.value = inputValue.value;
        }
        this.eventBus.$emit('reloadDateInput', this.inputSettlementDate);
        break;
      case 'proposedSettlementDate':
        this.inputSettlementDate.value = inputValue.value;
        this.inputSettlementDate.vRule = this.validateSettlementDate;
        this.eventBus.$emit('reloadDateInput', this.inputSettlementDate);
        if (
          this.inputBrokenCouponDate.value !== '' &&
          this.inputBrokenCouponDate.value !== null
        ) {
          this.inputBrokenCouponDate.vRule = this.validateBrokenCouponDate;
          this.eventBus.$emit('reloadDateInput', this.inputBrokenCouponDate);
        }
        this.eventBus.$emit('reloadDateInput', {
          ...this.inputMaturityDate,
          vRule: this.inputMaturityDateVrule,
        });
        break;
      case 'brokenCouponDate':
        this.inputBrokenCouponDate.value = inputValue.value;
        if (this.inputBrokenCouponDate.value !== '') {
          this.inputBrokenCouponDate.vRule = this.validateBrokenCouponDate;
        } else {
          this.inputBrokenCouponDate.vRule = '';
        }
        this.eventBus.$emit('reloadDateInput', this.inputBrokenCouponDate);
        break;
      case 'maturityDate':
        this.inputMaturityDate.value = inputValue.value;
        this.eventBus.$emit('reloadDateInput', {
          ...this.inputMaturityDate,
          vRule: this.inputMaturityDateVrule,
        });
        break;
      case 'minimumPiece':
        if (inputValue.value !== null && inputValue.value !== '') {
          this.inputMinimumPiece.value = inputValue.value;
          this.inputMinimumPiece.id = `minimumPiece_${inputValue.value}`;
        }
        break;
      case 'couponFrequency':
        this.inputCouponFrequency.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'redemptionForm':
        this.inputRedemptionForm.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        if (this.inputRedemptionForm.value.value === 'BU') {
          this.inputRepaymentFrequency.value = '';
          this.inputRepaymentFrequency.disabled = true;
          this.inputRepaymentFrequency.vRule = '';
          this.eventBus.$emit(
            'reloadSelectInput',
            this.inputRepaymentFrequency,
          );
        } else {
          this.inputRepaymentFrequency.value = undefined;
          this.inputRepaymentFrequency.disabled = false;
          this.inputRepaymentFrequency.vRule = 'required';
          this.eventBus.$emit(
            'reloadSelectInput',
            this.inputRepaymentFrequency,
          );
        }
        break;
      case 'repaymentFrequency':
        this.inputRepaymentFrequency.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'rankingType':
        this.inputRankingType.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'rankingGuarantee':
        this.inputRankingGuarantee.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'spreadCouponType':
        this.inputSpreadOrCouponType.value = inputValue.value;
        if (inputValue.value === 'S') {
          this.enterSpread = true;
          this.enterCoupon = false;
        } else if (inputValue.value === 'C') {
          this.eventBus.$emit('reloadInput', this.inputIndicativeCoupon);
          this.enterCoupon = true;
          this.enterSpread = false;
        } else if (inputValue.value === '') {
          this.enterCoupon = false;
          this.enterSpread = false;
        }
        break;
      case 'spreadMinimum':
        this.inputSpreadMinimum.value = inputValue.value;
        this.inputSpreadMaximum.vRule = `between:${inputValue.value},300`;
        this.eventBus.$emit('reloadInput', this.inputSpreadMaximum);
        this.controlSpread();
        break;
      case 'spreadMaximum':
        this.inputSpreadMaximum.value = inputValue.value;
        this.inputSpreadMaximum.vRule = `between:${this.inputSpreadMinimum.value},300`;
        this.controlSpread();
        break;
      case 'spreadOverWhat':
        this.inputSpreadOverWhat.value.value = inputValue.value;
        if (this.inputSpreadOverWhat.value === '') {
          this.controlSpread();
        }
        break;
      case 'indicativeCoupon':
        if (inputValue.value !== null && inputValue.value !== '') {
          this.inputIndicativeCoupon.value = inputValue.value;
          this.inputIndicativeCoupon.id = `indicativeCoupon_${inputValue.value}`;
        }
        break;
      case 'indicativeType':
        this.inputIndicativeType.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'documentationForm':
        this.inputDocumentationForm.value = inputValue;
        break;
      case 'documentationField':
        this.inputDocumentationField.value = inputValue.value;
        break;
      case 'dayCount':
        this.inputDayCount.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'creditRatingS':
        this.inputCreditRatingS.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'creditRatingF':
        this.inputCreditRatingF.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'creditRatingM':
        this.inputCreditRatingM.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'expiringHour':
        this.inputExpiringHour.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'expiringMinutes':
        this.inputExpiringMinutes.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'additionalInformation':
        this.additionalInformationField.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        this.additionalInformation = inputValue.value;
        break;
      default:
        break;
    }
  }

  setInputMultiValue(inputValue: CustomMultiInput) {
    if (this.inputMultiInstrumentType.name === inputValue.name) {
      this.inputMultiInstrumentType.value = inputValue.value;
    }
    // when not available reset value
    if (!this.showMinimumPiece) {
      this.inputMinimumPiece.value = '';
    }
    this.setDefaultValues();
  }

  // handle file input components
  setInputRadioValue(inputValue: CustomInput) {
    switch (inputValue.name) {
      case 'inputEnterSpread':
        this.inputEnterSpread.value = false;
        this.controlSpread();
        break;
      case 'enterSpread':
        this.inputEnterSpread.value = inputValue.value;
        this.enterSpread = true;
        if (this.enterSpread) {
          this.eventBus.$emit('reloadInput', this.inputSpreadMinimum);
          this.eventBus.$emit('reloadInput', this.inputSpreadMaximum);
          this.eventBus.$emit('reloadInputSelect', this.inputSpreadOverWhat);
          this.inputIndicativeCoupon.value = '';
          this.inputIndicativeCoupon.id = 'indicativeCoupon';
        } else {
          this.inputSpreadMinimum.value = '';
          this.inputSpreadMaximum.value = '';
          this.inputSpreadOverWhat.value = '';
        }
        break;
      default:
        break;
    }
  }

  setDefaultValues() {
    this.preferencesPreloaded = false;
    if (
      this.isDutchStateIndustry ||
      (this.inputMultiInstrumentType.value &&
        this.inputMultiInstrumentType.value.length > 0)
    ) {
      const instrument: string = this.isDutchStateIndustry
        ? 'DS'
        : this.inputMultiInstrumentType.value[0].value;
      const {
        instruments,
        instrumentsDisabled,
        ecaInsured,
        ecaVRuled,
        structure,
        maturityVRule,
        couponFrequency,
        redemptionForm,
        rankingType,
        rankingGuarantee,
        rankingGuaranteeDisabled,
        indicativeType,
        dayCount,
        ratingSP,
        ratingSPLabel,
        ratingF,
        ratingFLabel,
        ratingM,
        ratingMLabel,
        minimumPiece,
        documentationFormOptions,
      } = getIOIDefaultValues(
        this.inputIndustryCode,
        this.inputMultiInstrumentType.value,
      );
      // instrument type

      if (this.isDutchStateIndustry) {
        // Export Finance's rules (Dutch State Guaranteed)
        this.inputEcaInsuredPercentage.value = ecaInsured;
        this.inputEcaInsuredPercentage.vRule = ecaVRuled;
        this.inputEcaInsuredPercentage.disabled = true;
        this.eventBus.$emit('reloadInput', this.inputEcaInsuredPercentage);
        this.inputStructure.value = {
          value: structure,
          label: getStructure(structure),
        };
        this.inputStructure.disabled = true;
        this.inputStructure.optionList = this.ioiLists.structureList;
        this.eventBus.$emit('reloadSelectInput', this.inputStructure);
        this.inputMultiInstrumentType.value = instruments;
        this.inputMultiInstrumentType.disabled = !!instrumentsDisabled;
        // rankings
        this.inputCreditRatingS.value = {
          value: ratingSP,
          label: ratingSPLabel,
        };
        this.inputCreditRatingS.disabled = true;
        this.eventBus.$emit('reloadSelectInput', this.inputCreditRatingS);
        this.inputCreditRatingF.value = {
          value: ratingF,
          label: ratingFLabel,
        };
        this.inputCreditRatingF.disabled = true;
        this.eventBus.$emit('reloadSelectInput', this.inputCreditRatingF);
        this.inputCreditRatingM.value = {
          value: ratingM,
          label: ratingMLabel,
        };
        this.inputCreditRatingM.disabled = true;
        this.eventBus.$emit('reloadSelectInput', this.inputCreditRatingM);
      } else {
        this.inputEcaInsuredPercentage.value = '';
        this.inputEcaInsuredPercentage.vRule = '';
        this.inputEcaInsuredPercentage.disabled = false;
        this.eventBus.$emit('reloadInput', this.inputEcaInsuredPercentage);
        this.inputStructure.value = '';
        this.eventBus.$emit('reloadSelectInput', this.inputStructure);
        this.inputCreditRatingS.disabled = false;
        this.eventBus.$emit('reloadSelectInput', this.inputCreditRatingS);
        this.inputCreditRatingF.disabled = false;
        this.eventBus.$emit('reloadSelectInput', this.inputCreditRatingF);
        this.inputCreditRatingM.disabled = false;
        this.eventBus.$emit('reloadSelectInput', this.inputCreditRatingM);
        this.inputMultiInstrumentType.value = instruments;
        this.inputMultiInstrumentType.disabled = !!instrumentsDisabled;
      }
      this.inputMaturity.vRule = maturityVRule;
      this.eventBus.$emit('reloadInput', this.inputMaturity);
      if (
        !isEmpty(couponFrequency) &&
        this.inputCouponFrequency.value === undefined
      ) {
        this.inputCouponFrequency.value = couponFrequency;
        this.eventBus.$emit('reloadSelectInput', this.inputCouponFrequency);
      }
      if (
        !isEmpty(redemptionForm) &&
        this.inputRedemptionForm.value === undefined
      ) {
        this.inputRedemptionForm.value = redemptionForm;
      }

      // redemption form
      const redemptionFormOptions = redemptionFormList(instrument);
      if (
        redemptionFormOptions.filter(
          o => o.value === this.inputRedemptionForm.value,
        ).length < 1
      ) {
        this.inputRedemptionForm.value = redemptionFormOptions[0];
      }
      this.inputRedemptionForm.optionList = redemptionFormList(instrument);

      this.eventBus.$emit('reloadSelectInput', this.inputRedemptionForm);

      //ranking type
      const rankingTypeOptionsLocal = rankingTypeOptions(instrument);
      if (
        rankingTypeOptionsLocal.filter(
          o => o.value === this.inputRankingType.value,
        ).length < 1
      ) {
        this.inputRankingType.value = rankingTypeOptionsLocal[0];
      }
      this.inputRankingType.optionList = rankingTypeOptionsLocal;

      if (!isEmpty(rankingType) && this.inputRankingType.value === undefined) {
        this.inputRankingType.value = rankingType;
        this.eventBus.$emit('reloadSelectInput', this.inputRankingType);
      }

      if (!isEmpty(rankingGuarantee)) {
        this.inputRankingGuarantee.value = rankingGuarantee;
        this.inputRankingGuarantee.disabled = rankingGuaranteeDisabled;
        this.inputRankingGuarantee.optionList = this.ioiLists.rankingGuaranteeList;
        this.eventBus.$emit('reloadSelectInput', this.inputRankingGuarantee);
      }

      // indicative type, (type)
      const indicativeTypeOptions = indicativeTypeOptionList(instrument);
      if (
        !isEmpty(indicativeType) &&
        this.inputIndicativeType.value === undefined
      ) {
        this.inputIndicativeType.value = indicativeType;
      }

      if (
        indicativeTypeOptions.filter(
          o => o.value === this.inputIndicativeType.value,
        ).length < 1
      ) {
        this.inputIndicativeType.value = indicativeTypeOptions[0];
      }
      this.inputIndicativeType.optionList = indicativeTypeOptions;
      this.eventBus.$emit('reloadSelectInput', this.inputIndicativeType);

      // day count
      if (!isEmpty(dayCount) && this.inputDayCount.value === undefined) {
        this.inputDayCount.value = dayCount;
      }
      const dayCountOptions = dayCountOptionList(instrument);
      if (
        dayCountOptions.filter(o => o.value === this.inputDayCount.value)
          .length < 1
      ) {
        this.inputDayCount.value = dayCountOptions[0];
      }
      this.inputDayCount.optionList = dayCountOptions;

      this.eventBus.$emit('reloadSelectInput', this.inputDayCount);
      if (!this.showMinimumPiece) {
        this.inputMinimumPiece.value = '';
      } else {
        this.inputMinimumPiece.value = minimumPiece;
        this.inputMinimumPiece.id = `inputMinimumPiece_${minimumPiece}`;
      }

      this.inputDocumentationForm.optionList = documentationFormOptions;
      this.inputDocumentationForm.value = documentationFormOptions[0];
      this.eventBus.$emit('reloadInput', this.inputDocumentationForm);
      this.eventBus.$emit('reloadInput', this.inputMinimumPiece);
    }
    // spreadoverwhat
    const resultList = this.spreadList(this.countryCode);
    if (
      resultList.filter((o: any) => o.value === this.inputSpreadOverWhat.value)
        .length < 1
    ) {
      this.inputSpreadOverWhat.value = resultList[0];
    }
    this.inputSpreadOverWhat.optionList = resultList;
    this.eventBus.$emit('reloadSelectInput', this.inputSpreadOverWhat);

    this.preferencesPreloaded = true;
  }

  get expiringHourError(): string {
    return this.showExpiringError
      ? `${this.$t('components.IOIForm.expiringHourError')}`
      : '';
  }

  get showRepaymentFrequency(): boolean {
    return (
      this.inputRedemptionForm.value === undefined ||
      this.inputRedemptionForm.value === null ||
      this.inputRedemptionForm.value.value !== 'BU'
    );
  }

  get previousButton(): any {
    return {
      containerClass: 'button-start',
      buttonClass: 'button-icon-pagination',
      description: '',
      toolTip: `${this.$t('components.IOIForm.submitIOI')}`,
      icon: 'previous-page-blue.png',
      showExtended: false,
    };
  }

  get nextButton(): any {
    return {
      containerClass: 'button-end',
      buttonClass: 'button-icon-pagination',
      description: '',
      toolTip: `${this.$t('components.IOIForm.submitIOI')}`,
      icon: 'next-page-blue.png',
      showExtended: false,
      disabled: this.insertMode
        ? !permAvailable(Permission.can_create_ioi)
        : !permAvailable(Permission.can_update_ioi),
    };
  }

  get confirmIOIButton(): any {
    return {
      containerClass: 'button-end',
      buttonClass: 'button-icon',
      description: `${this.$t('components.IOIForm.submitIOI')}`,
      toolTip: `${this.$t('components.IOIForm.submitIOI')}`,
      icon: 'edit-blue.png',
      showExtended: false,
      disabled: this.insertMode
        ? !permAvailable(Permission.can_create_ioi)
        : !permAvailable(Permission.can_update_ioi),
    };
  }

  get cancelIOIButton(): any {
    return {
      containerClass: 'button-start',
      buttonClass: 'button-icon button-icon-centered',
      description: `${this.$t('components.IOIForm.cancelButton')}`,
      toolTip: `${this.$t('components.IOIForm.cancelButton')}`,
      showExtended: false,
    };
  }

  calculateSizeBucket() {
    const issueSize: number = Number(this.inputSize.value);
    if (issueSize >= 500000 && issueSize < 5000000) {
      this.bucketSizeCode = 0;
    } else if (issueSize >= 5000000 && issueSize <= 10000000) {
      this.bucketSizeCode = 1;
    } else if (issueSize > 10000000 && issueSize <= 20000000) {
      this.bucketSizeCode = 2;
    } else if (issueSize > 20000000 && issueSize <= 30000000) {
      this.bucketSizeCode = 3;
    } else if (issueSize > 30000000 && issueSize <= 50000000) {
      this.bucketSizeCode = 4;
    } else if (issueSize >= 50000000) {
      this.bucketSizeCode = 5;
    }
    this.inputBucketSize.value = getBucketSize(this.bucketSizeCode);
    this.eventBus.$emit('reloadInput', this.inputBucketSize);
  }

  get isDutchStateIndustry(): boolean {
    // show or hide DS (Dutch State Guarantee Export) information
    return isDutchStateIndustry(this.inputIndustryCode);
  }

  get isGovernmentLocalIndustry(): boolean {
    return this.inputIndustryCode === 'GL';
  }

  static get showDocument(): boolean {
    return true;
  }

  get validateEca(): string {
    return this.isDutchStateIndustry ? 'required|integer|max_value:100' : '';
  }

  get validateStructure(): string {
    return this.isDutchStateIndustry ? 'required' : '';
  }

  get validateMaturity(): string {
    const maximumValue: string = this.inputIndustryCode === 'DS' ? '12' : '100';
    return `required|max_value:${maximumValue}`;
  }

  controlSpread() {
    return this.$validator.validate('spreadMaximum', this.inputSpreadMaximum);
  }

  get validateTransactionDate(): string {
    return `required|date_format:yyyy-MM-dd|after:${this.todayDate},true`;
  }

  get validateSettlementDate(): string {
    const vRule: string = 'required|date_format:yyyy-MM-dd';
    if (
      this.inputTransactionDate.value === '' ||
      this.inputTransactionDate.value === undefined
    ) {
      return vRule;
    }
    return `${vRule}|after:${this.inputTransactionDate.value},true`;
  }

  get validateBrokenCouponDate(): string {
    const vRule: string = 'date_format:yyyy-MM-dd';
    if (this.inputBrokenCouponDate.value === '') {
      return '';
    }
    return `${vRule}|after:${this.inputSettlementDate.value}`;
  }

  // Navigation Bar methods
  nextStep() {
    this.validateInputs = true;
    this.$validator.errors.clear();
    this.validateFirstTab().then((result: boolean[]) => {
      let allValidated: boolean = true;
      forEach(result, (itemValidated: boolean) => {
        if (!itemValidated) {
          allValidated = false;
        }
      });
      if (allValidated) {
        this.setPercentage({
          title: `${this.$t('progressBar.showProgressText', {
            start: '2',
            end: '2',
          })}`,
          percentage: 100,
          currentStep: 2,
        });
        this.incStepProgressBar();
      }
    });
  }

  previousStep() {
    this.setPercentage({
      title: `${this.$t('progressBar.showProgressText', {
        start: '1',
        end: '2',
      })}`,
      percentage: 50,
      currentStep: 1,
    });
    this.decStepProgressBar();
  }

  closeIOIModal(): void {
    const isDraft: boolean =
      this.newIOI.state === 'draft' || (this.insertMode && this.newIOI.draft);
    if (this.isIssuer && isDraft) {
      this.$router.push({ name: 'myaccount-issuer', params: { tabId: '4' } });
      this.getIOIs({
        userAccountId: this.myAccount,
        filters: { state: ['draft'] },
        state: 'draft',
        withOffers: 'true',
      });
      this.setTab({ key: 'myAccountIssuerTabs', value: 3 });
    }
    if (this.isIssuer && !isDraft) {
      this.$router.push({ name: 'myaccount-issuer', params: { tabId: '1' } });
      this.getIOIs({
        userAccountId: this.myAccount,
        filters: { state: ['open', 'closing', 'closed'] },
        state: 'outstanding',
        withOffers: 'true',
      });
      this.setTab({ key: 'myAccountIssuerTabs', value: 0 });
    }
    if (this.isLeadManager && isDraft) {
      this.$router.push({ name: 'myaccount-lm', params: { tabId: '4' } });
    }
    if (this.isLeadManager && !isDraft) {
      this.$router.push({ name: 'myaccount-lm', params: { tabId: '1' } });
    }
  }

  validateFirstTab() {
    this.validateInputs = true;
    const validations: any[] = [];
    const expiringHour: string =
      this.inputExpiringHour.value === undefined ||
      this.inputExpiringHour.value.value === undefined
        ? ''
        : this.inputExpiringHour.value.value;
    if (this.inputIndustryCode === 'DS') {
      validations.push(
        this.$validator.validate(
          'ecaInsuredPercentage',
          this.inputEcaInsuredPercentage.value,
        ),
      );
    }
    validations.push(
      this.$validator
        .validate('instrumentType', this.inputMultiInstrumentType.value)
        .catch(error => sentryAdditional(error, this.$validator)),
    );
    validations.push(
      this.$validator.validate('issueNominalSize', this.inputSize.value),
    );
    validations.push(
      this.$validator.validate('maturityDate', this.inputMaturityDate.value),
    );
    validations.push(
      this.$validator.validate(
        'proposedTransactionDate',
        this.inputTransactionDate.value,
      ),
    );
    validations.push(
      this.$validator.validate(
        'proposedSettlementDate',
        this.inputSettlementDate.value,
      ),
    );
    validations.push(
      this.$validator.validate(
        'brokenCouponDate',
        this.inputBrokenCouponDate.value,
      ),
    );
    validations.push(this.$validator.validate('expiringHour', expiringHour));
    validations.push(
      this.$validator.validate(
        'expiringMinutes',
        this.inputExpiringMinutes.value,
      ),
    );
    return Promise.all(validations);
  }

  get validateCouponSpread(): string {
    let validationString: string = '';
    if (
      this.enterSpread &&
      (this.inputSpreadOverWhat.value === '' ||
        this.inputSpreadMaximum.value === '' ||
        this.inputSpreadMinimum.value === '')
    ) {
      validationString = `${this.$t('components.IOIForm.spreadError')}`;
    } else if (
      this.enterSpread &&
      this.inputSpreadOverWhat.value !== '' &&
      this.inputSpreadMaximum.value !== '' &&
      this.inputSpreadMinimum.value !== '' &&
      this.inputSpreadMinimum > this.inputSpreadMaximum
    ) {
      validationString = `${this.$t('components.IOIForm.spreadMinMaxError')}`;
    } else if (this.enterCoupon && this.inputIndicativeCoupon.value === '') {
      validationString = `${this.$t('components.IOIForm.couponError')}`;
    }
    return validationString;
  }

  validateStepTwo() {
    // normally we do not scour the dom for information but I do not understand the rest of this
    // page sufficiently to know when to validate which fields
    const promises: any = [];
    const selectElsSecondPage = document.querySelectorAll(
      '.second-step div[name]',
    );
    const inputElsSecondPage = document.querySelectorAll(
      '.second-step input[name]',
    );
    const inputTextareaSecondPage = document.querySelectorAll(
      '.second-step textarea[name]',
    );

    [
      ...selectElsSecondPage,
      ...inputElsSecondPage,
      ...inputTextareaSecondPage,
    ].forEach(element => {
      promises.push(
        this.$validator.validate(element.getAttribute('name') || undefined),
      );
    });
    return Promise.all(promises);
  }

  // Form validations (step 2)
  validateForm(): void {
    const validationString: string = `${this.$t(
      'components.IOIForm.submittingError',
    )}`;
    this.validateStepTwo()
      .then(result => {
        if (result.includes(false)) {
          const validationError: string = this.validateCouponSpread;
          if (validationError !== '') {
            this.$validator.errors.add({
              id: 'spreadMaximum',
              field: 'spreadMaximum',
              msg: validationError,
              scope: '',
            });
            this.addNotification({
              message: `${validationError}`,
              type: 'error',
            });
          } else {
            throw new Error('Validation Error');
          }
        } else {
          this.createUpdateIOI();
        }
      })
      .catch((error: any) => {
        this.addNotification({
          message: `${validationString} ${error}`,
          type: 'error',
        });
      });
  }

  multipleInstrumentsDTO(): number[] {
    const formattedInstruments: number[] = [];
    forEach(this.inputMultiInstrumentType.value, (element: any) => {
      formattedInstruments.push(parseInt(element.value, 10));
    });
    return formattedInstruments;
  }

  createUpdateIOI() {
    const formattedInstruments: number[] = this.multipleInstrumentsDTO();
    const transactionDate = new Date(this.inputTransactionDate.value);
    const settlementDate = new Date(this.inputSettlementDate.value);
    let documentationForm: string =
      this.inputDocumentationForm.value.value === 'other'
        ? this.inputDocumentationField.value
        : this.inputDocumentationForm.value.value;
    let brokenCouponDate: string | null = '';
    let maturityDate: string | null = '';

    if (isEmpty(documentationForm)) {
      documentationForm = '';
    }
    if (this.insertMode) {
      this.state = '';
    }
    if (this.inputBrokenCouponDate.value === '') {
      brokenCouponDate = null;
    } else {
      brokenCouponDate = moment(this.inputBrokenCouponDate.value).format(
        'YYYY-MM-DD',
      );
    }
    if (this.inputMaturityDate.value === '') {
      maturityDate = null;
    } else {
      maturityDate = moment(this.inputMaturityDate.value).format('YYYY-MM-DD');
    }
    const spRating: string = IOIForm.ratingValue(this.inputCreditRatingS.value);
    const fRating: string = IOIForm.ratingValue(this.inputCreditRatingF.value);
    const mRating: string = IOIForm.ratingValue(this.inputCreditRatingM.value);
    const repaymentFrequency: string =
      this.inputRepaymentFrequency.value === undefined ||
      this.inputRepaymentFrequency.value.value === undefined
        ? ''
        : this.inputRepaymentFrequency.value.value;
    const transactionHour: string = this.inputExpiringHour.value.value;
    const transactionMinute: string = this.inputExpiringMinutes.value.value;
    const transactionTime: string = `${transactionHour}:${transactionMinute}:00`;

    const indicativeCoupon: string =
      this.enterCoupon && this.inputIndicativeCoupon.value !== null
        ? this.inputIndicativeCoupon.value.toString()
        : '';

    this.newIOI = {
      indicationOfInterestId: this.ioiId,
      state: this.state,
      createdByAccount: this.myAccount,
      createdForCompany: this.companyId,
      companyName: this.inputCompanyName.value,
      industryOrSector: this.inputIndustryCode,
      currencyType: this.inputCurrency.value,
      countryName: this.inputCountry.value,
      instrumentTypes: formattedInstruments,
      instrumentTypeLabel: instrumentsFullLabel(
        this.inputMultiInstrumentType.value,
      ),
      ecaInsuredPercentage: this.isDutchStateIndustry
        ? parseInt(this.inputEcaInsuredPercentage.value, 10)
        : 0,
      structureCode: this.isDutchStateIndustry
        ? this.inputStructure.value.value
        : '',
      structure: this.isDutchStateIndustry
        ? this.inputStructure.value.value
        : '',
      issueNominalSize: this.sizeNoFormat,
      issueNominalSizeWithFormat: this.sizeNoFormat,
      bucketSize: this.bucketSizeCode,
      // think this is no longer neccessary
      // issueMaturity: !this.inputMaturity.value
      //   ? 1
      //   : parseInt(this.inputMaturity.value, 10),
      issueMaturity: 0, // 0 requested but unused and replaced by issueMaturityInDays
      issueMaturityInDays: this.daysBetweenSettlementAndMaturity,
      proposedPricingAndTransactionDate: moment(transactionDate).format(
        'YYYY-MM-DD',
      ),
      proposedPricingAndTransactionTime: transactionTime,
      proposedSettlementDate: moment(settlementDate).format('YYYY-MM-DD'),
      brokenCouponDate,
      maturityDate,
      minimumPiece:
        this.inputMinimumPiece.value === ''
          ? null
          : Number(this.inputMinimumPiece.value),
      couponFrequency: this.inputCouponFrequency.value.value,
      repaymentFrequency,
      redemptionForm:
        this.inputRedemptionForm.value === undefined
          ? ''
          : this.inputRedemptionForm.value.value,
      rankingType:
        this.inputRankingType.value.value !== undefined
          ? this.inputRankingType.value.value
          : '',
      rankingGuarantee: this.inputRankingGuarantee.value.value,
      enterSpread: this.enterSpread,
      enterCoupon: this.enterCoupon,
      spreadOverWhat: this.enterSpread
        ? this.inputSpreadOverWhat.value.value
        : null,
      spreadMinValue: this.enterSpread
        ? parseInt(this.inputSpreadMinimum.value, 10)
        : 0,
      spreadMaxValue: this.enterSpread
        ? parseInt(this.inputSpreadMaximum.value, 10)
        : 0,
      indicativeCoupon,
      indicativeType: this.inputIndicativeType.value.value,
      dayCount: this.inputDayCount.value.value,
      documentationForm,
      additionalInformation: this.additionalInformation,
      spRating,
      creditRatingS: spRating,
      fRating,
      creditRatingF: fRating,
      mRating,
      creditRatingM: mRating,
      editByAccount: this.insertMode ? '' : this.myAccount,
    };
    // sending the information to the confirmation screen
    this.confirmModal.showModal = true;
    this.confirmModal.ioi = this.newIOI;
    this.eventBus.$emit('reloadConfirmIOI', this.confirmModal);
  }

  closeConfirmationWindow() {
    this.confirmModal.showModal = false;
    this.confirmModal.ioi = {};
    this.eventBus.$emit('reloadConfirmIOI', this.confirmModal);
  }

  saveIOI() {
    return this.insertMode
      ? this.submitIOI(this.newIOI)
      : this.editIOI(this.newIOI);
  }

  onConfirmIOI() {
    if (!this.confirmingIoi) {
      this.confirmingIoi = true;
      this.newIOI.draft = false;
      this.saveIOI()
        .then(() => this.closeIOIModal())
        .catch((error: any) => {
          this.confirmingIoi = false;
          this.confirmModal.showModal = false;
          this.confirmModal.ioi = {};
          this.eventBus.$emit('reloadConfirmIOI', this.confirmModal);
          Sentry.captureException(error, this.newIOI);
        });
    }
  }

  onControlIOI() {
    if (!this.controllingIoi) {
      this.controllingIoi = true;
      this.newIOI.draft = true;
      this.saveIOI()
        .then(() => this.closeIOIModal())
        .catch(() => {
          this.controllingIoi = false;
          this.confirmModal.showModal = false;
          this.confirmModal.ioi = {};
          this.eventBus.$emit('reloadConfirmIOI', this.confirmModal);
          this.addNotification({
            message: `${this.$t('components.IOIForm.createIOIError')}`,
            type: 'error',
          });
          sentry(this.$t('components.IOIForm.createIOIError'));
        });
    }
  }

  prepopulate() {
    // if the user has signed up properly, then the ioi prepopulation will take place
    return this.insertMode
      ? this.prepopulateIOI({ userAccountId: this.myAccount })
      : this.prepopulateEditIOI({
          userAccountId: this.myAccount,
          indicationOfInterestId: this.ioiId,
        });
  }

  created() {
    this.commissionTitle = 'components.IOIForm.commissionTitle';
    this.commissionBody = ['components.IOIForm.commissionBody1'];

    // IOI id obtained from the url
    this.ioiId = this.$route.params.ioiId;
    this.insertMode = this.ioiId === '0';
    // A new progress bar is created with 2 steps
    const showProgressText: string = `${this.$t(
      'progressBar.showProgressText',
      { start: '1', end: '2' },
    )}`;
    this.createProgressBar({
      type: 'percentage',
      title: showProgressText,
      percentage: 50,
      steps: 2,
      currentStep: 1,
    });

    const loginError: string = `${this.$t('components.IOIForm.loginError')}`;
    // control if the user is logged in; if not, then the user will be sent to login page
    this.authenticateLogin(loginError)
      .then(() => {
        this.myAccount = this.getUserAccountId;
        if (!this.isUserLoggedIn || (!this.isIssuer && !this.isLeadManager)) {
          this.addNotification({
            id: 0,
            message: `${loginError}`,
            type: 'error',
          });
          this.$router.push({ name: redirectUserPage() });
        }
      })
      .then(() => this.ioiRequiredLists())
      .then((ioiLists: any) => {
        this.ioiLists = ioiLists;
      })
      .then(() => this.prepopulate())
      .then((ioiData: any) => {
        this.issueType = 1;
        this.state = ioiData.data.ioi.state;
        const firstInstrumentType =
          ioiData.data.ioi.instrumentTypes &&
          ioiData.data.ioi.instrumentTypes[0]
            ? ioiData.data.ioi.instrumentTypes[0]
            : '';
        // company and dependencies (country, currency)
        if (!this.insertMode) {
          this.companyId = ioiData.data.ioi.createdForCompany;
          this.inputCompanySelect.value = {
            label: ioiData.data.ioi.createdForCompanyName,
            value: ioiData.data.ioi.createdForCompany,
          };
          this.inputCompanyName.value = ioiData.data.ioi.createdForCompanyName;
          this.inputCurrency.value = ioiData.data.ioi.currencyType;
          this.inputCountry.value = ioiData.data.ioi.countryName;
          this.inputIndustry.value = getIndustry(
            ioiData.data.ioi.industryOrSector,
          );
          this.eventBus.$emit('reloadInput', this.inputCurrency);
          this.eventBus.$emit('reloadInput', this.inputCountry);
          this.eventBus.$emit('reloadInput', this.inputIndustry);
        }
        // this.inputCompanyName.value = ioiData.data.ioi.createdForCompanyName;

        this.eventBus.$emit('reloadInput', this.inputCompanyName);
        this.countryCode = ioiData.data.ioi.countryCode;
        if (ioiData.data.ioi.instrumentTypes !== undefined) {
          this.inputMultiInstrumentType.value = multipleInstruments(
            ioiData.data.ioi.instrumentTypes,
          );
        } else {
          this.inputMultiInstrumentType.value = [];
        }
        this.inputMultiInstrumentType.optionList = this.ioiLists.instrumentTypeList;
        this.preferencesPreloaded = true;
        // eca
        if (ioiData.data.ioi.ecaInsuredPercentage !== undefined) {
          this.inputEcaInsuredPercentage.value =
            ioiData.data.ioi.ecaInsuredPercentage;
          this.inputEcaInsuredPercentage.disabled = true;
          this.eventBus.$emit('reloadInput', this.inputEcaInsuredPercentage);
        }
        if (ioiData.data.ioi.structure !== undefined) {
          this.inputStructure.value = {
            value: ioiData.data.ioi.structure,
            label: getStructure(ioiData.data.ioi.structure),
          };
          this.inputStructure.disabled = true;
          this.eventBus.$emit('reloadSelectInput', this.inputStructure);
        }
        // size
        if (ioiData.data.ioi.issueNominalSize !== undefined) {
          this.sizeNoFormat = ioiData.data.ioi.issueNominalSize;
          this.inputSize.value = ioiData.data.ioi.issueNominalSize;
          this.inputSize.id = `nominalSize_${ioiData.data.ioi.issueNominalSize}`;
          this.calculateSizeBucket();
        }
        // maturity
        if (ioiData.data.ioi.issueMaturity !== undefined) {
          this.inputMaturity.value = ioiData.data.ioi.issueMaturity;
          this.inputMaturity.vRule = this.validateMaturity;
          this.maturity = ioiData.data.ioi.issueMaturity;
          this.eventBus.$emit('reloadInput', this.inputMaturity);
        }
        // dates
        if (ioiData.data.ioi.proposedPricingAndTransactionDate !== undefined) {
          this.inputTransactionDate.value =
            ioiData.data.ioi.proposedPricingAndTransactionDate;
        } else {
          this.inputTransactionDate.value = '';
        }
        this.eventBus.$emit('reloadDateInput', this.inputTransactionDate);
        // expiration time
        if (!isEmpty(ioiData.data.ioi.proposedPricingAndTransactionTime)) {
          this.inputExpiringHour.value = {
            value: ioiData.data.ioi.proposedPricingAndTransactionTime.substring(
              0,
              2,
            ),
            label: ioiData.data.ioi.proposedPricingAndTransactionTime.substring(
              0,
              2,
            ),
          };
          this.inputExpiringMinutes.value = {
            value: ioiData.data.ioi.proposedPricingAndTransactionTime.substring(
              3,
              5,
            ),
            label: ioiData.data.ioi.proposedPricingAndTransactionTime.substring(
              3,
              5,
            ),
          };
        } else {
          // default values
          this.inputExpiringHour.value = { value: '11', label: '11' };
          this.inputExpiringMinutes.value = { value: '00', label: '00' };
        }
        this.inputExpiringHour.optionList = this.ioiLists.hourList;
        this.inputExpiringMinutes.optionList = this.ioiLists.minutesList;
        this.eventBus.$emit('reloadSelectInput', this.inputExpiringHour);
        this.eventBus.$emit('reloadSelectInput', this.inputExpiringMinutes);
        if (ioiData.data.ioi.proposedSettlementDate !== undefined) {
          this.inputSettlementDate.value =
            ioiData.data.ioi.proposedSettlementDate;
        }
        this.eventBus.$emit('reloadDateInput', this.inputSettlementDate);
        if (
          ioiData.data.ioi.brokenCouponDate !== undefined &&
          ioiData.data.ioi.brokenCouponDate !== null
        ) {
          this.inputBrokenCouponDate.value = ioiData.data.ioi.brokenCouponDate;
          this.inputBrokenCouponDate.vRule = this.validateBrokenCouponDate;
        } else {
          this.inputBrokenCouponDate.value = '';
          this.inputBrokenCouponDate.vRule = '';
        }
        this.eventBus.$emit('reloadDateInput', this.inputBrokenCouponDate);
        if (
          ioiData.data.ioi.maturityDate !== undefined &&
          ioiData.data.ioi.maturityDate !== null
        ) {
          this.inputMaturityDate.value = ioiData.data.ioi.maturityDate;
        }
        this.eventBus.$emit('reloadDateInput', this.inputMaturityDate);
        // minimum piece
        if (ioiData.data.ioi.minimumPiece !== undefined) {
          this.inputMinimumPiece.value = ioiData.data.ioi.minimumPiece
            ? ioiData.data.ioi.minimumPiece
            : '';
          this.inputMinimumPiece.id = `minimumPiece_${ioiData.data.ioi.minimumPiece}`;
        }
        if (ioiData.data.ioi.couponFrequency !== undefined) {
          this.inputCouponFrequency.value = {
            value: ioiData.data.ioi.couponFrequency,
            label: getFrequency(ioiData.data.ioi.couponFrequency),
          };
        }
        this.inputCouponFrequency.optionList = this.ioiLists.frequencyList;
        this.eventBus.$emit('reloadSelectInput', this.inputCouponFrequency);
        if (ioiData.data.ioi.redemptionForm !== undefined) {
          this.inputRedemptionForm.value = {
            value: ioiData.data.ioi.redemptionForm,
            label: getRedemptionForm(ioiData.data.ioi.redemptionForm),
          };
        }
        this.inputRedemptionForm.optionList = redemptionFormList(
          this.inputIndustryCode,
        );
        this.eventBus.$emit('reloadSelectInput', this.inputRedemptionForm);
        if (ioiData.data.ioi.repaymentFrequency !== undefined) {
          this.inputRepaymentFrequency.value = {
            value: ioiData.data.ioi.repaymentFrequency,
            label: getFrequency(ioiData.data.ioi.repaymentFrequency),
          };
        } else {
          this.inputRepaymentFrequency.value = undefined;
        }
        if (
          this.inputRedemptionForm.value !== undefined &&
          this.inputRedemptionForm.value.value === 'BU'
        ) {
          this.inputRepaymentFrequency.value = undefined;
        }
        this.inputRepaymentFrequency.optionList = this.ioiLists.frequencyList;
        this.eventBus.$emit('reloadSelectInput', this.inputRepaymentFrequency);
        if (ioiData.data.ioi.rankingType !== undefined) {
          this.inputRankingType.value = {
            value: ioiData.data.ioi.rankingType,
            label: getRankingType(ioiData.data.ioi.rankingType),
          };
        }

        this.inputRankingType.optionList = rankingTypeOptions(
          firstInstrumentType,
        );
        this.eventBus.$emit('reloadSelectInput', this.inputRankingType);
        if (ioiData.data.ioi.rankingGuarantee !== undefined) {
          this.inputRankingGuarantee.value = {
            value: ioiData.data.ioi.rankingGuarantee,
            label: getRankingGuarantee(ioiData.data.ioi.rankingGuarantee),
          };
        }
        this.inputRankingGuarantee.optionList = this.ioiLists.rankingGuaranteeList;
        this.eventBus.$emit('reloadSelectInput', this.inputRankingGuarantee);
        // Coupon and Spread
        if (this.insertMode) {
          this.inputIndicativeCoupon.value = '';
          this.enterSpread = false;
          this.enterCoupon = false;
          this.inputSpreadOrCouponType.value = '';
          this.eventBus.$emit('reloadRadioInput', this.inputSpreadOrCouponType);
        } else if (ioiData.data.ioi.indicativeCoupon) {
          this.enterCoupon = true;
          this.enterSpread = false;
          this.inputIndicativeCoupon.value = ioiData.data.ioi.indicativeCoupon;
          this.inputIndicativeCoupon.id = `indicativeCoupon_${ioiData.data.ioi.indicativeCoupon}`;
          this.inputSpreadOrCouponType.value = 'C';
        } else if (
          ioiData.data.ioi.spreadOverWhat !== undefined &&
          ioiData.data.ioi.spreadOverWhat !== null
        ) {
          this.spreadOverWhatCode = ioiData.data.ioi.spreadOverWhat;
          this.inputSpreadOverWhat.value = this.spreadOverWhatCode;
          this.eventBus.$emit('reloadSelectInput', this.inputSpreadOverWhat);
          this.inputSpreadMinimum.value = ioiData.data.ioi.spreadMinValue;
          this.eventBus.$emit('reloadInput', this.inputSpreadMinimum);
          this.inputSpreadMaximum.value = ioiData.data.ioi.spreadMaxValue;
          this.eventBus.$emit('reloadInput', this.inputSpreadMaximum);
          this.inputIndicativeCoupon.value = '';
          this.inputSpreadOrCouponType.value = 'S';
          this.enterSpread = true;
          this.enterCoupon = false;
        } else {
          this.inputSpreadOrCouponType.value = '';
          this.eventBus.$emit('reloadRadioInput', this.inputSpreadOrCouponType);
        }
        this.eventBus.$emit('reloadRadioInput', this.inputSpreadOrCouponType);
        if (ioiData.data.ioi.indicativeType !== undefined) {
          this.inputIndicativeType.value = {
            value: ioiData.data.ioi.indicativeType,
            label: getIndicativeType(ioiData.data.ioi.indicativeType),
          };
        }

        this.inputIndicativeType.optionList = indicativeTypeOptionList(
          firstInstrumentType,
        );
        this.eventBus.$emit('reloadSelectInput', this.inputIndicativeType);

        if (ioiData.data.ioi.dayCount !== undefined) {
          this.inputDayCount.value = {
            value: ioiData.data.ioi.dayCount,
            label: getDayCount(ioiData.data.ioi.dayCount),
          };
        }

        this.inputDayCount.optionList = dayCountOptionList(firstInstrumentType);
        this.eventBus.$emit('reloadSelectInput', this.inputDayCount);
        if (ioiData.data.ioi.additionalInformation !== undefined) {
          this.additionalInformation = ioiData.data.ioi.additionalInformation;
          this.additionalInformationField.value =
            ioiData.data.ioi.additionalInformation;
          this.eventBus.$emit('reloadInput', this.additionalInformationField);
        }
        if (!isEmpty(ioiData.data.ioi.spRating)) {
          this.inputCreditRatingS.value = {
            value: ioiData.data.ioi.spRating,
            label: ioiData.data.ioi.spRating,
          };
        }
        this.inputCreditRatingS.optionList = this.ioiLists.ratings;
        this.eventBus.$emit('reloadSelectInput', this.inputCreditRatingS);
        if (!isEmpty(ioiData.data.ioi.fRating)) {
          this.inputCreditRatingF.value = {
            value: ioiData.data.ioi.fRating,
            label: ioiData.data.ioi.fRating,
          };
        }
        this.inputCreditRatingF.optionList = this.ioiLists.ratings;
        this.eventBus.$emit('reloadSelectInput', this.inputCreditRatingF);
        if (!isEmpty(ioiData.data.ioi.mRating)) {
          this.inputCreditRatingM.value = {
            value: ioiData.data.ioi.mRating,
            label: ioiData.data.ioi.mRating,
          };
        }
        this.inputCreditRatingM.optionList = this.ioiLists.moodysRatings;
        this.eventBus.$emit('reloadSelectInput', this.inputCreditRatingM);
        const documentationFormOptions = getDocumentationFormOptions(
          this.inputIndustryCode,
          firstInstrumentType,
        );
        this.inputDocumentationForm.optionList = documentationFormOptions;
        if (ioiData.data.ioi.documentationForm) {
          const docOption =
            this.inputDocumentationForm.optionList.filter(
              (option: any) =>
                option.value === ioiData.data.ioi.documentationForm,
            )[0] ||
            documentationFormOptions.filter(
              (option: any) => option.value === 'other',
            )[0];
          if (docOption.value === 'other') {
            this.inputDocumentationField.value =
              ioiData.data.ioi.documentationForm;
          }
          this.inputDocumentationForm.value = docOption;
        }
        this.eventBus.$emit('reloadInput', this.inputDocumentationForm);
        this.eventBus.$emit('reloadInput', this.inputDocumentationField);

        this.validateInputs = true;
        const resultList = this.spreadList(this.countryCode);
        this.inputSpreadOverWhat.value = {
          value: this.spreadOverWhatCode,
          label: getSpread(this.spreadOverWhatCode),
        };
        this.inputSpreadOverWhat.optionList = resultList;
        if (this.inputSpreadOverWhat.optionList.length > 0) {
          this.inputSpreadOverWhat.value = {
            value: resultList[0].value,
            label: resultList[0].label,
          };
        }
        this.eventBus.$emit('reloadSelectInput', this.inputSpreadOverWhat);
      });
    // .catch((error: any) => {
    //   this.addNotification({
    //     message: `${this.$t(
    //       'components.IOIForm.requiredInformationError',
    //     )} ${error}`,
    //     type: 'error',
    //   });
    //   sentry(error);
    // });
  }

  mounted() {
    // Window size
    window.addEventListener('resize', () => {
      this.windowSize = window.innerWidth;
    });
  }

  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.windowSize = window.innerWidth;
    });
  }
}
