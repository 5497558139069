




















































































































































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { forEach } from 'lodash';
import { IOI, FilterStateIOI } from '@/types/instrumentsTypes';
import InputRadioComponent from '@/components/inputs/InputRadioComponent.vue';
import { CustomInput } from '@/components/inputs/inputTypes';
import IoiRow from '@/components/ioi/IOIRow.vue';
import IoiConfirm from '@/components/ioi/IOIConfirm.vue';
import {
  noOffersExpiring,
  smallColumnClass,
  offersExpiring,
} from '@/views/myAccounts/myAccounts';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import confirm from '@/components/utils/Confirm.vue';
import AcceptOffers from '@/components/ioi/AcceptOffers.vue';
import CountDown from '@/components/utils/CountDown.vue';
import IoiRowDayColumn from '@/components/ioi/IOIRowDayColumn.vue';
import IoiRowMaturityColumn from '@/components/ioi/IOIRowMaturityColumn.vue';
import EmailInputs from '@/components/ioi/EmailInputs.vue';
import ModalWindow from '@/components/utils/Modal.vue';
import IoiSort from '../../components/ioi/IOISort.vue';
import ViewDeals from '@/components/ioi/ViewDeals.vue';
import desktopSizes from '@/assets/screen-sizes';
import { EventBus } from '@/main';
import NoDataAvailable from '@/components/utils/NoDataAvailable.vue';
import Tabs from '@/components/utils/Tabs.vue';
import IoiList from '@/components/ioi/IoiList.vue';
import IoiOfferCount from '@/components/ioi/IoiOfferCount.vue';
import { permAvailable } from '@/util/generalUtils';
import { Permission } from '@/types/types';

const ModalNameSpace = namespace('modals/');
const IOINameSpace = namespace('iois/');
const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');

const tabState = namespace('tabState/');
@Component({
  name: 'MyAccountIssuer',
  components: {
    IoiOfferCount,
    IoiList,
    InputRadioComponent,
    ButtonComponent,
    IoiRow,
    confirm,
    IoiConfirm,
    CountDown,
    AcceptOffers,
    IoiRowDayColumn,
    IoiRowMaturityColumn,
    ViewDeals,
    ModalWindow,
    EmailInputs,
    IoiSort,
    NoDataAvailable,
    Tabs,
  },
})
export default class MyAccountIssuer extends Vue {
  // Indication of Interest store
  @IOINameSpace.Getter('allIOIs') iois: IOI[];
  @IOINameSpace.Getter('ioiWithOffers') ioiWithOffers: IOI[];
  @IOINameSpace.Getter('matchedIOIs') matchedIOIs: IOI[];
  @IOINameSpace.Getter('offTheMarketIOIs') offTheMarketIOIs: IOI[];
  @IOINameSpace.Getter('draftIOIs') draftIOIs: IOI[];
  @IOINameSpace.Getter('outStandingIois') outStandingIois: IOI[];
  @IOINameSpace.Action('getIOIs') getIOIs: any;
  @IOINameSpace.Action('withdrawIOI') withdrawIOI: any;
  @IOINameSpace.Action('acceptOffer') acceptOffer: any;
  @IOINameSpace.Action('copyIOI') copyIOI: any;
  @IOINameSpace.Action('deleteIOI') deleteIOI: any;
  @IOINameSpace.Action('sendToMarketIOI') sendToMarketIOI: any;

  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Getter('isHAM') isHAM: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @AuthenticationNameSpace.Action('signUpFullUser') signUpFullUser: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // modal
  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  @tabState.Action('setTab') setTab: any;
  @tabState.Getter('tabState') tabState: any;

  eventBus = EventBus;
  windowSize: number = 0;
  showExtended: boolean = true;
  screenMediumBig: number = desktopSizes.mediumBig;
  showDaysColumn: boolean = true;
  showConfirmWithdrawModal: boolean = false;
  offerInputInformation: any = '';
  activeIoI: string = '';
  tabId: string = 'myAccountIssuerTabs';
  confirmWithdrawData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  showConfirmUpdateModal: boolean = false;
  confirmUpdateData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  showConfirmDeleteDraftModal: boolean = false;
  confirmDeleteDraftData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  showConfirmSendToMarketModal: boolean = false;
  confirmSendToMarketData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  displayModal: any = { showModal: false, ioi: {} };
  showAcceptOfferModal: any = { showModal: false, ioi: {} };
  permAvailable = permAvailable;
  Permission = Permission;
  get createIOIButton(): any {
    return {
      containerClass: '',
      buttonClass: 'button-icon',
      description: this.$t('views.myIssuerAccount.createIOIButton'),
      extendedDescription: this.$t('views.myIssuerAccount.createIOIButton'),
      icon: 'create-blue.png',
      showExtended: this.showExtended,
    };
  }

  createIOI(): void {
    this.$router.push({ name: 'ioi', params: { ioiId: '0' } });
  }

  // Edit IOI events
  get editIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.myIssuerAccount.update'),
      extendedDescription: this.$t('views.myIssuerAccount.updateIOI'),
      icon: 'edit-blue.png',
      showExtended: this.showExtended,
    };
  }

  editIOICancellation(): void {
    this.confirmUpdateData.genericId = '';
    this.showConfirmUpdateModal = false;
  }

  editIOIConfirmation(ioiId: string, cntOffers: number): void {
    this.confirmUpdateData.genericId = ioiId;
    if (cntOffers === 0) {
      this.editIOISubmit();
    } else {
      this.showConfirmUpdateModal = true;
      this.confirmUpdateData.genericId = ioiId;
      this.confirmUpdateData.title = 'views.myIssuerAccount.updateIOITitle';
      this.confirmUpdateData.body = 'views.myIssuerAccount.updateIOIBody';
      this.confirmUpdateData.confirmButton =
        'views.myIssuerAccount.updateIOIConfirmButton';
      this.confirmUpdateData.cancelButton =
        'views.myIssuerAccount.updateIOICancelButton';
    }
  }

  editIOISubmit(): void {
    const ioiId: string = this.confirmUpdateData.genericId;
    this.$router.push({ name: 'ioi', params: { ioiId } });
  }

  // Withdraw IOI events
  get withdrawIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.myIssuerAccount.withdraw'),
      extendedDescription: this.$t('views.myIssuerAccount.withdrawIOI'),
      icon: 'remove-blue.png',
      showExtended: this.showExtended,
    };
  }

  withdrawIOIConfirmation(ioiId: string): void {
    this.showConfirmWithdrawModal = true;
    this.confirmWithdrawData.genericId = ioiId;
    this.confirmWithdrawData.title = 'views.myIssuerAccount.withdrawIOITitle';
    this.confirmWithdrawData.body = 'views.myIssuerAccount.withdrawIOIBody';
    this.confirmWithdrawData.confirmButton =
      'views.myIssuerAccount.withdrawIOIConfirmButton';
    this.confirmWithdrawData.cancelButton =
      'views.myIssuerAccount.withdrawIOICancelButton';
  }

  withdrawIOICancellation(): void {
    this.confirmWithdrawData.genericId = '';
    this.showConfirmWithdrawModal = false;
  }

  withdrawIOISubmit(): void {
    this.withdrawIOI(this.confirmWithdrawData.genericId).then(() => {
      this.confirmWithdrawData.genericId = '';
      this.showConfirmWithdrawModal = false;
      this.changeTab(0); // get open IOIs
    });
  }

  get acceptOfferButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.myLMAccount.accept'),
      extendedDescription: this.$t('views.myLMAccount.acceptOffer'),
      icon: 'currency-euro-blue.png',
      showExtended: this.showExtended,
    };
  }

  acceptOfferSubmit(ioi: IOI): void {
    if (ioi.state === 'closed') {
      this.showAcceptOfferModal.showModal = true;
      this.showAcceptOfferModal.ioi = ioi;
      this.showAcceptOfferModal.accountParam = this.getUserAccountId;
      this.offerInputInformation = ioi;
      this.toggleModal('acceptOffer');
      this.eventBus.$emit('reloadCreateAnOffer', this.showAcceptOfferModal);
    }
  }

  onCloseIOI(): void {
    this.displayModal.showModal = false;
    this.displayModal.ioi = {};
    this.eventBus.$emit('reloadConfirmIOI', this.displayModal);
  }

  // Copy IOI events
  get copyIOIButton() {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: `${this.$t('views.myIssuerAccount.copyIOIButton')}`,
      extendedDescription: `${this.$t('views.myIssuerAccount.copyIOIButton')}`,
      icon: 'content-copy.svg',
      svg: true,
      showExtended: this.showExtended,
    };
  }

  copyIOIData(ioiId: string, state: string): void {
    return this.copyIOI({
      indicationOfInterestId: ioiId,
      state: state,
      userAccountId: this.getUserAccountId,
    });
  }

  // Delete Draft IOI events
  get deleteIOIButton() {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: `${this.$t('views.myIssuerAccount.deleteIOIButton')}`,
      extendedDescription: `${this.$t(
        'views.myIssuerAccount.deleteIOIButton',
      )}`,
      icon: 'remove-blue.png',
      showExtended: this.showExtended,
    };
  }

  deleteIOIConfirmation(ioiId: string): void {
    this.showConfirmDeleteDraftModal = true;
    this.confirmDeleteDraftData.genericId = ioiId;
    this.confirmDeleteDraftData.title = 'views.myIssuerAccount.deleteIOITitle';
    this.confirmDeleteDraftData.body = 'views.myIssuerAccount.deleteIOIBody';
    this.confirmDeleteDraftData.confirmButton =
      'views.myIssuerAccount.deleteIOIConfirmButton';
    this.confirmDeleteDraftData.cancelButton =
      'views.myIssuerAccount.deleteIOICancelButton';
  }

  deleteIOICancellation(): void {
    this.confirmDeleteDraftData.genericId = '';
    this.showConfirmDeleteDraftModal = false;
  }

  deleteIOISubmit(): void {
    this.deleteIOI(this.confirmDeleteDraftData.genericId).then(() => {
      this.confirmDeleteDraftData.genericId = '';
      this.showConfirmDeleteDraftModal = false;
    });
  }

  // Send to market events
  get sendToMarketIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass:
        'button-icon button-icon-grid button-icon-resize full-width-button',
      description: this.$t('views.myIssuerAccount.sendToMarketButton'),
      extendedDescription: this.$t('views.myIssuerAccount.sendToMarketButton'),
      icon: 'send-to-market-blue.png',
      showExtended: this.showExtended,
    };
  }

  sendToMarketIOIConfirmation(ioiId: string): void {
    const stringStart: string = 'views.myIssuerAccount';
    this.showConfirmSendToMarketModal = true;
    this.confirmSendToMarketData.genericId = ioiId;
    this.confirmSendToMarketData.title = `${stringStart}.sendToMarketIOITitle`;
    this.confirmSendToMarketData.body = `${stringStart}.sendToMarketIOIBody`;
    this.confirmSendToMarketData.confirmButton = `${stringStart}.sendToMarketIOIConfirmButton`;
    this.confirmSendToMarketData.cancelButton = `${stringStart}.sendToMarketIOICancelButton`;
  }

  sendToMarketIOICancellation(): void {
    this.confirmSendToMarketData.genericId = '';
    this.showConfirmSendToMarketModal = false;
  }

  sendToMarketIOISubmit(): void {
    this.sendToMarketIOI(this.confirmSendToMarketData.genericId).then(() => {
      this.confirmSendToMarketData.genericId = '';
      this.showConfirmSendToMarketModal = false;
      this.setTab({ key: this.tabId, value: 0 });
    });
  }

  changeTab(tabNumber: number): void {
    const ioiFilters: FilterStateIOI = {};
    if (this.isUserLoggedIn && this.isIssuer) {
      if (tabNumber === 0) {
        // get the open IOIs, the closing ones (half an hour to go)
        // and the closed IOIs too (so the user can accept offers)
        ioiFilters.state = ['open', 'closing', 'closed'];
        this.getIOIs({
          userAccountId: this.getUserAccountId,
          filters: ioiFilters,
          state: 'outstanding',
          withOffers: 'true',
        });
      } else if (tabNumber === 1) {
        // get matched iois (iois with deals)
        ioiFilters.state = ['matched'];
        this.getIOIs({
          userAccountId: this.getUserAccountId,
          filters: ioiFilters,
          state: 'matched',
          withOffers: 'true',
        });
      } else if (tabNumber === 2) {
        // get expired iois
        ioiFilters.state = ['expired', 'withdrawn'];
        this.getIOIs({
          userAccountId: this.getUserAccountId,
          filters: ioiFilters,
          state: 'off-the-market',
          withOffers: 'true',
        });
      } else if (tabNumber === 3) {
        // get draft iois
        ioiFilters.state = ['draft'];
        this.getIOIs({
          userAccountId: this.getUserAccountId,
          filters: ioiFilters,
          state: 'draft',
          withOffers: 'true',
        });
      }
    }
  }

  mounted() {
    // Window size
    if (this.tabState.myAccountIssuerTabs) {
      this.changeTab(this.tabState.myAccountIssuerTabs);
    } else {
      this.changeTab(0);
    }
    window.addEventListener('resize', () => {
      this.windowSize = window.innerWidth;
      this.showExtended = this.windowSize >= desktopSizes.large;
      this.showDaysColumn = this.windowSize >= this.screenMediumBig;
    });
  }

  @Watch('windowSize')
  onWindowSizeChange(newSize: number) {
    this.showExtended = newSize >= desktopSizes.large;
    this.showDaysColumn = this.windowSize >= this.screenMediumBig;
  }

  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.windowSize = window.innerWidth;
    });
  }
}
