'use strict';

import api from './index';
import { AccountPayload } from '@/types/accountTypes';

export default {
  getPendingAccounts() {
    return api.get('/backoffice/pending-accounts');
  },

  getApprovedAccounts() {
    return api.get('/backoffice/approved-accounts');
  },

  getPendingUpgradeAccounts() {
    return api.get('/backoffice/pending-upgraded-accounts');
  },

  getApprovedUpgradedAccounts() {
    return api.get('/backoffice/approved-upgraded-accounts');
  },

  getBlockedAccounts() {
    return api.get('/backoffice/blocked-accounts');
  },

  getCompanies() {
    return api.get('/company/all');
  },

  approveAccount(payload: AccountPayload) {
    return api.post('/backoffice/approve-user-account', payload);
  },

  approveUpgradeAccount(payload: AccountPayload) {
    return api.post('/backoffice/approve-upgrade', payload);
  },

  denyAccount(payload: AccountPayload) {
    return api.post('/backoffice/deny-user-account', payload);
  },

  denyUpgradeAccount(payload: AccountPayload) {
    return api.post('/backoffice/deny-upgrade', payload);
  },

  blockAccount(payload: AccountPayload) {
    return api.post('/backoffice/block-user-account', payload);
  },

  unblockAccount(payload: AccountPayload) {
    return api.post('/backoffice/unblock-user-account', payload);
  },

  getAccountDocuments(payload: AccountPayload) {
    return api.post('/account/documents', payload);
  },

  getAccount(userAccountId: string) {
    return api.get(`/backoffice/${userAccountId}`);
  },

  getPendingDeals() {
    return api.get('/instrument/deal/pending');
  },

  getClosedDeals() {
    return api.get('/instrument/deal/closed');
  },

  getAccountDeals() {
    return api.get('/instrument/deal/account');
  },

  getDeal(dealId: string) {
    return api.get(`/instrument/deal/${dealId}`);
  },

  generateTradeConfirmations(payload: any) {
    return api.post('/instrument/deal/generate-tcs', payload);
  },
};
