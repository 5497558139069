import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { forEach } from 'lodash';
import moment from 'moment';
import { RootState } from '@/types/rootState';
import { IRPState, IRP } from '@/types/irpTypes';
import irpApi from '@/api/irpApi';
import { getApiError } from '@/api/util';
import sentry from '@/util/sentry';
import router from '@/router';

export const IRSCurveTypes = {
  IRS: 'IRS',
  NETHER: 'NETHER',
  APD: 'AFS Bullet Coupon',
  SPREAD: 'IRS Spread',
};

export const state: IRPState = {
  irpsReady: false,
  irsIRP: {} as IRP, // IRS Curve
  apdIRP: {} as IRP, // AFS Public Debt Coupon
  netherIRP: {} as IRP, // Dutch Sovereign Curve
  spreadIRP: {} as IRP, // Spread Curve
  formattedCurve: {} as any,
};

const ADD_NOTIFICATION = 'notifications/add';

// getters
export const getters: GetterTree<IRPState, RootState> = {
  irsIRP: state => state.irsIRP,
  apdIRP: state => state.apdIRP,
  netherIRP: state => state.netherIRP,
  spreadIRP: state => state.spreadIRP,
  irpsReady: state => state.irpsReady,
  formattedCurve: state => state.formattedCurve,
};

// mutations
export const mutations: MutationTree<IRPState> = {
  SET_IRS_CURVE(state, irp: IRP): void {
    state.irsIRP = { ...irp };
  },
  SET_APD_CURVE(state, irp: IRP): void {
    state.apdIRP = { ...irp };
  },
  SET_NETHER_CURVE(state, irp: IRP): void {
    state.netherIRP = { ...irp };
  },
  SET_SPREAD_CURVE(state, irp: IRP): void {
    state.spreadIRP = { ...irp };
  },
  SET_FORMATTED_CURVE(state, data: any): void {
    if (data === '') {
      state.irpsReady = false;
    } else {
      state.formattedCurve = data;
      state.irpsReady = true;
    }
  },
};

function newCurveData(labelTag: string, color: string, irpData: any) {
  const fullTag: string = `views.IRPChart.${labelTag}`;
  const label: string = `${router.app.$t(fullTag)}`;
  const fontColor: string = 'rgb(26, 126, 214)';
  const data: any = Object.values(irpData);
  return {
    label,
    data,
    backgroundColor: 'transparent',
    borderColor: color,
    pointBackgroundColor: color,
    pointStyle: 'rect',
    pointRadius: 4,
    fontColor,
  };
}

// actions
export const actions: ActionTree<IRPState, RootState> = {
  getIRPCurves({ commit, dispatch }) {
    return irpApi
      .getInterestRatePage()
      .then((response: any) => {
        // reset all curves
        commit('SET_IRS_CURVE', []);
        commit('SET_NETHER_CURVE', []);
        commit('SET_APD_CURVE', []);
        commit('SET_SPREAD_CURVE', []);

        // set the new curve data
        if (response.data.length > 0) {
          forEach(response.data, (iterIRP: any) => {
            const curveInformation: IRP = {} as IRP;
            curveInformation.id = iterIRP.id;
            curveInformation.snapshotTime = moment
              .unix(iterIRP.timestamp)
              .format('MM/DD/YYYY HH:mm:ss');
            curveInformation.name = iterIRP.name;
            curveInformation.curveData = iterIRP.data;
            curveInformation.data = [];
            forEach(Object.keys(iterIRP.data), (key: string) => {
              if (curveInformation.data !== undefined) {
                curveInformation.data.push({
                  maturity: key,
                  value: iterIRP.data[key],
                });
              }
            });
            if (curveInformation.name === IRSCurveTypes.IRS) {
              curveInformation.title = `${router.app.$t(
                'views.IRPTable.irsDataTitle',
              )}`;
              commit('SET_IRS_CURVE', curveInformation);
            } else if (curveInformation.name === IRSCurveTypes.NETHER) {
              curveInformation.title = `${router.app.$t(
                'views.IRPTable.netherDataTitle',
              )}`;
              commit('SET_NETHER_CURVE', curveInformation);
            } else if (curveInformation.name === IRSCurveTypes.APD) {
              curveInformation.title = `${router.app.$t(
                'views.IRPTable.apdDataTitle',
              )}`;
              commit('SET_APD_CURVE', curveInformation);
            } else if (curveInformation.name === IRSCurveTypes.SPREAD) {
              curveInformation.title = `${router.app.$t(
                'views.IRPTable.spreadDataTitle',
              )}`;
              commit('SET_SPREAD_CURVE', curveInformation);
            }
          });
        }
      })
      .then(() => {
        // format data
        if (state.irsIRP.curveData) {
          const formattedData: any = {
            labels: Object.keys(state.irsIRP.curveData),
            datasets: [],
          };
          if (Object.values(state.irsIRP).length >= 1) {
            const newIRSData = newCurveData(
              'graphTitleIRS',
              'rgb(26, 126, 214)',
              state.irsIRP.curveData,
            );
            formattedData.datasets.push(newIRSData);
          }
          if (Object.values(state.apdIRP).length >= 1) {
            const newAPDData = newCurveData(
              'graphTitleAPD',
              'rgb(37, 26, 117)',
              state.apdIRP.curveData,
            );
            formattedData.datasets.push(newAPDData);
          }
          if (Object.values(state.netherIRP).length >= 1) {
            const newNetherData = newCurveData(
              'graphTitleNether',
              'rgb(190, 190, 190)',
              state.netherIRP.curveData,
            );
            formattedData.datasets.push(newNetherData);
          }
          if (Object.values(state.spreadIRP).length >= 1) {
            const newSpreadData = newCurveData(
              'graphTitleSpread',
              'rgb(178,151,0)',
              state.spreadIRP.curveData,
            );
            formattedData.datasets.push(newSpreadData);
          }
          commit('SET_FORMATTED_CURVE', formattedData);
        } else {
          commit('SET_FORMATTED_CURVE', '');
        }
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.IRPChart.errorFetchingIRCData',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  submitIRPData({ commit, dispatch, rootState }, irpPayload: any) {
    return irpApi
      .submitInterestRatePage(irpPayload)
      .then(() =>
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.IRPForm.irpSubmitMessage',
            type: 'success',
          },
          { root: true },
        ),
      )
      .catch((error: any) =>
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.IRPForm.irpSubmittingError',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        ),
      );
  },
};

export const irps = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
