import { ActionTree, GetterTree, MutationTree } from 'vuex';
import Vue from 'vue';

import { ModalState } from '@/types/types';
import { RootState } from '@/types/rootState';

export const state: ModalState = {
  modals: {},
};

// getters
export const getters: GetterTree<ModalState, RootState> = {
  modals: state => state.modals,
};

// mutations
export const mutations: MutationTree<ModalState> = {
  INIT_MODAL(state, modalKey): void {
    Vue.set(state.modals, modalKey, { key: modalKey, active: false });
  },
  INIT_MODAL_OPEN(state, modalKey): void {
    Vue.set(state.modals, modalKey, { key: modalKey, active: true });
  },
  TOGGLE_MODAL(state, modalKey): void {
    state.modals[modalKey].active = !state.modals[modalKey].active;
  },
};

// actions
export const actions: ActionTree<ModalState, RootState> = {
  initializeModal({ commit }, modalKey) {
    commit('INIT_MODAL', modalKey);
  },
  initializeModalOpen({ commit }, modalKey) {
    commit('INIT_MODAL_OPEN', modalKey);
  },
  toggleModal({ commit }, modalKey) {
    commit('TOGGLE_MODAL', modalKey);
  },
};

export const modals = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
