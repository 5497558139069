var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'form-container login-form-cont',
    _vm.inSettingsMenu ? 'in-settings' : '' ]},[(_vm.passwordChangeRequested)?_c('div',{staticClass:"change-password-feedback"},[_c('h2',{class:[
        'change-password-feedback__title',
        _vm.inSettingsMenu ? 'in-settings' : '' ]},[_vm._v(" "+_vm._s(_vm.$t('components.changePasswordForm.successChangePasswordRequestTitle'))+" ")]),_c('p',{staticClass:"change-password-feedback__p"},[_vm._v(" "+_vm._s(_vm.$t( 'components.changePasswordForm.successChangePasswordRequestMessage' ))+" ")]),(!_vm.inSettingsMenu)?_c('button',{staticClass:"button-icon",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.$t('components.general.previousPage')))])]):_vm._e()]):_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[(!_vm.inSettingsMenu)?_c('div',{staticClass:"login-form-title login-form-title-long"},[_c('h2',[_vm._v(_vm._s(_vm.title))])]):_vm._e(),(_vm.showChangePassword)?_c('div',{staticClass:"form-subtitle"},[_c('span',[_vm._v(_vm._s(this.$t('components.changePasswordForm.enterCurrentPasswordTitle')))])]):_vm._e(),(_vm.showChangePassword)?_c('input-component',{attrs:{"input-param":_vm.currentPassword},on:{"sendInput":_vm.setInputValue}}):_vm._e(),_c('div',{staticClass:"form-subtitle"},[_c('span',[_vm._v(_vm._s(this.$t('components.changePasswordForm.enterNewPassword')))])]),_c('input-password-component',{attrs:{"input-param":{
        name: 'password',
        ref: 'password',
        type: 'password',
        placeHolder: 'components.changePasswordForm.newPassword',
        containerClass: 'input-row',
        vRule: 'required|min:8|max:35',
      }},on:{"sendInput":_vm.setInputValue}}),_c('input-component',{attrs:{"input-param":_vm.repeatPassword},on:{"sendInput":_vm.setInputValue}}),_c('button-component',{attrs:{"button-param":_vm.passwordButton},on:{"onClickSubmit":function($event){return _vm.validateForm()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }