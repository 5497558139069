var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['signup-btn-row']},[(_vm.apiErrorMessage)?_c('div',{staticClass:"api-error-message"},[_c('p',{staticClass:"api-error-message__message"},[_vm._v(" "+_vm._s(_vm.apiErrorMessage)+" ")])]):_vm._e(),(_vm.currentStep === 1 && !_vm.upgrading)?_c('router-link',{staticClass:"known-user",attrs:{"to":{ name: 'login' }}},[_vm._v(" "+_vm._s(_vm.$t('components.signUpForm.login'))+" ")]):_vm._e(),(_vm.currentStep !== 1 && !_vm.finalStep)?_c('div',{on:{"click":_vm.stepBack}},[_c('button-component',{attrs:{"button-param":{
        containerClass: 'signup-btn-row__btn prev-button',
        buttonClass: 'button-icon',
        description: _vm.$t('components.general.previous'),
      }},on:{"onClickSubmit":_vm.stepBack}})],1):_vm._e(),(_vm.currentStep === 1 && _vm.inviting)?_c('div',[_c('button-component',{attrs:{"button-param":{
        containerClass: 'signup-btn-row__btn next-button',
        buttonClass: 'button-icon',
        description: _vm.$t('components.signUpForm.joinCompany'),
      }},on:{"onClickSubmit":function($event){return _vm.nextStep()}}})],1):(!_vm.finalStep && !_vm.lastFormStep)?_c('div',[_c('button-component',{attrs:{"button-param":{
        containerClass: 'signup-btn-row__btn next-button',
        buttonClass: 'button-icon',
        description: _vm.$t('components.general.next'),
      }},on:{"onClickSubmit":function($event){return _vm.nextStep()}}})],1):_vm._e(),(_vm.lastFormStep && _vm.accountType !== 'marketviewer')?_c('div',[_c('button-component',{attrs:{"button-param":{
        containerClass: 'signup-btn-row__btn complete-button',
        buttonClass: 'button-icon',
        description: _vm.$t('components.signUpForm.signUpCompleteButton'),
      }},on:{"onClickSubmit":function($event){return _vm.completeSignupFull()}}})],1):_vm._e(),(_vm.lastFormStep && _vm.accountType === 'marketviewer')?_c('div',[_c('button-component',{attrs:{"button-param":{
        containerClass: 'signup-btn-row__btn next-button',
        buttonClass: 'button-icon',
        description: _vm.$t('components.signUpForm.signUpCompleteButton'),
      }},on:{"onClickSubmit":function($event){return _vm.completeSignup()}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }