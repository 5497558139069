



























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CountryFlag from '@/components/utils/CountryFlag.vue';
import LineChart from '@/components/charts/LineChart.vue';

const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'DealExamples',
  components: {
    CountryFlag,
    LineChart,
  },
})
export default class DealExamples extends Vue {
  @AuthenticationNameSpace.Getter('windowWidth') windowWidth: number;

  deals: Object[] = [
    {
      country: 'NL',
      title: 'components.examples.one.title',
      price: 'components.examples.one.price',
      requestType: 'components.examples.one.requestType',
      rate: 'components.examples.one.rate',
    },
    {
      country: 'BE',
      title: 'components.examples.two.title',
      price: 'components.examples.two.price',
      requestType: 'components.examples.two.requestType',
      rate: 'components.examples.two.rate',
    },
    {
      country: 'FR',
      title: 'components.examples.three.title',
      price: 'components.examples.three.price',
      requestType: 'components.examples.three.requestType',
      rate: 'components.examples.three.rate',
    },
    {
      country: 'FR',
      title: 'components.examples.four.title',
      price: 'components.examples.four.price',
      requestType: 'components.examples.four.requestType',
      rate: 'components.examples.four.rate',
    },
    {
      country: 'NL',
      title: 'components.examples.five.title',
      price: 'components.examples.five.price',
      requestType: 'components.examples.five.requestType',
      rate: 'components.examples.five.rate',
    },
    {
      country: 'NL',
      title: 'components.examples.six.title',
      price: 'components.examples.six.price',
      requestType: 'components.examples.six.requestType',
      rate: 'components.examples.six.rate',
      hideMobile: true,
    },
    {
      country: 'BE',
      title: 'components.examples.seven.title',
      price: 'components.examples.seven.price',
      requestType: 'components.examples.seven.requestType',
      rate: 'components.examples.seven.rate',
    },
  ];

  get computedDeals() {
    if (this.windowWidth < 1050) {
      return this.deals.filter((deal: any) => !deal.hideMobile);
    }
    return this.deals;
  }
}
