






























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TabList, TabObject } from '@/types/types';

const AuthenticationNameSpace = namespace('authentication/');
const tabState = namespace('tabState/');
interface tabHeader {
  text: string;
  render: boolean;
}

@Component({
  name: 'Tabs',
  components: {},
})
export default class Tabs extends Vue {
  @Prop({}) tabHeaders!: tabHeader[];
  @Prop({}) id!: string;

  @tabState.Action('initTab') initTab: any;
  @tabState.Action('setTab') setTab: any;
  @tabState.Getter('tabState') tabState: object;

  @Watch('tabState', { deep: true })
  emitTabState(tabStateLocal: TabList) {
    this.$emit('changeTab', tabStateLocal[this.id]);
  }

  get serveTabHeaders() {
    return this.tabHeaders.filter(tab => tab.render);
  }

  changeTab(tabId: number): void {
    this.setTab({ key: this.id, value: tabId });
  }

  mounted() {
    this.initTab({ key: this.id, value: 0 } as TabObject);
  }
}
