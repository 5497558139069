


























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SignUpForm from '../../components/authentication/SignUpForm.vue';
import UpgradeForm from '../../components/authentication/UpgradeForm.vue';
import InvitationForm from '../../components/authentication/InvitationForm.vue';
import StepTracker from '../../components/navigation/StepTracker.vue';
import FinalizeSignup from '../../components/authentication/FinalizeSignup.vue';
import RoleSelector from '../../components/authentication/RoleSelector.vue';
import SignUpButtonRow from '../../components/authentication/SignUpButtonRow.vue';
import VisualSignupContent from '../../components/authentication/VisualSignupContent.vue';
import { prepopulateInviteSignup } from '@/api/authenticationApi';
import { signup } from '@/store/signup';

const SignupNameSpace = namespace('signup/');

@Component({
  name: 'SignUpNew',
  components: {
    SignUpForm,
    UpgradeForm,
    StepTracker,
    FinalizeSignup,
    RoleSelector,
    SignUpButtonRow,
    VisualSignupContent,
    InvitationForm,
  },
})
export default class SignUpNew extends Vue {
  @Prop({}) accountType!: string;
  @Prop({}) step!: string;
  @Prop({}) flow!: string; // upgrade or sign-up
  @Prop({}) uid: string;

  @SignupNameSpace.Getter('accountTypeFormatted') accountTypeFormatted: any;
  @SignupNameSpace.Getter('step') currentStep: number;
  @SignupNameSpace.Getter('flowType') flowType: string;
  @SignupNameSpace.Getter('finalStep') finalStep: boolean;
  @SignupNameSpace.Getter('upgrading') upgrading: boolean;
  @SignupNameSpace.Getter('inviting') inviting: boolean;
  @SignupNameSpace.Action('setStep') setStep: any;
  @SignupNameSpace.Action('setFlowType') setFlowType: any;
  @SignupNameSpace.Action('setAccountType') setAccountType: any;
  @SignupNameSpace.Action('setUid') setUid: any;

  invitationData: {
    email: string;
    companyName: string;
    industryOrSector: string;
  } = { email: '', companyName: '', industryOrSector: '' };

  @Watch('flow')
  onChangeFlow() {
    this.setFlowType(this.flow);
  }

  @Watch('accountType')
  onChangeAccount() {
    this.setAccountType(this.accountType);
  }

  @Watch('step')
  onChangeStep() {
    this.setStep(parseInt(this.step, 10));
  }

  get signupTitle() {
    let title: string = '';
    if (!this.finalStep && !this.upgrading) {
      title = `${this.$t('components.signUpForm.title')} ${
        this.accountTypeFormatted
      }`;
    } else if (this.finalStep && !this.upgrading) {
      title = `${this.$t('components.signUpForm.signUpCompleted')}`;
    } else if (!this.finalStep && this.upgrading) {
      title = `${this.$t('components.upgradeForm.title')} ${
        this.accountTypeFormatted
      }`;
    } else if (this.finalStep && this.upgrading) {
      title = `${this.$t('components.upgradeForm.upgradeCompleted')}`;
    }
    return title;
  }

  created() {
    if (!this.$store.state.signup) {
      // prevent duplicate register because of multiple event dispatch for development
      this.$store.registerModule('signup', signup);
    }
    if (this.uid) {
      prepopulateInviteSignup(this.uid).then(response => {
        this.invitationData = {
          email: response.data.userAccount.email,
          companyName: response.data.company.name,
          industryOrSector: response.data.company.industryOrSector,
        };
      });
      this.setUid(this.uid);
    }
  }

  mounted() {
    // set step and account based on url props
    this.setProps();
  }

  destroyed() {
    // only when not hot reloading
    if (!module.hot) {
      // prevent webpack HRM to destroy our store. But only for development.
      this.$store.unregisterModule('signup');
    }
  }

  setProps() {
    this.setFlowType(this.flow);
    this.setAccountType(this.accountType);
    this.setStep(parseInt(this.step, 10));
  }
}
