

































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import InputRadioComponent from '../inputs/InputRadioComponent.vue';
import { InputRadioObject } from '@/components/inputs/inputTypes';
import InputComponent from '@/components/inputs/InputComponent.vue';
import {
  deleteCompanySettings,
  getCompanySettings,
  postCompanySettings,
  putCompanySettings,
} from '@/api/companyApi';
import { namespace } from 'vuex-class';
import { Permission, UserItem } from '@/types/types';
import { EventBus } from '@/main';
import StatusIndicator from '@/components/utils/statusIndicator.vue';
import { permAvailable } from '@/util/generalUtils';
const CompanyStore = namespace('companies/');
@Component({
  name: 'CompanySettingsSwitcher',
  components: { StatusIndicator, InputComponent, InputRadioComponent },
})
export default class CompanySettingsSwitcher extends Vue {
  @Prop({}) accountManagers!: UserItem[];
  @CompanyStore.Getter('selectedCompanyIdGetter')
  selectedCompanyIdGetter: string;

  CompanySettingsSwitcherStatus: string = '';
  eventBus = EventBus;
  MultiEyesParamsSetOnServer: boolean = false;
  permAvailable = permAvailable;
  Permission = Permission;
  MultiEyesParams: InputRadioObject = {
    name: 'multiEyes',
    alwaysOneOptionActive: false,
    value: '',
    optionList: [
      {
        id: 'issuer',
        value: 1,
        labelCode: 'Activate multi eyes',
        static: false,
      },
    ],
    containerClass: 'radio-container',
    wrapperClass: 'input-container radio-no-stuff',
    verticalRadioButton: false,
    showImage: false,
  } as InputRadioObject;

  approverAmountParams = {
    value: '2',
  };

  @Watch('selectedCompanyIdGetter')
  getSettings() {
    if (this.selectedCompanyIdGetter) {
      getCompanySettings(this.selectedCompanyIdGetter)
        .then(({ data: { settings } }) => {
          this.MultiEyesParams.value = '1';
          this.MultiEyesParamsSetOnServer = true;
          this.approverAmountParams.value = settings.value.params.create;
          this.eventBus.$emit('reloadRadioInput', this.MultiEyesParams);
          this.eventBus.$emit('reloadInput', this.approverAmountParams);
        })
        .catch(() => {
          this.MultiEyesParams.value = '';
          this.approverAmountParams.value = '';
          this.eventBus.$emit('reloadRadioInput', this.MultiEyesParams);
          this.MultiEyesParamsSetOnServer = false;
          this.CompanySettingsSwitcherStatus = '';
        });
    }
  }

  get amountOfApprovers() {
    return this.accountManagers.filter(user => {
      if (user.permissions.includes('can_approve_multieye')) {
        return user;
      }
    }).length;
  }

  mounted() {
    this.getSettings();
  }

  setValue(returnValue: any) {
    if (returnValue.name === 'multiEyes') {
      this.MultiEyesParams.value = returnValue.value;
    }
    if (returnValue.name === 'approverAmount') {
      this.approverAmountParams.value = returnValue.value;
    }
  }

  updateCompanySettings() {
    this.CompanySettingsSwitcherStatus = 'loading';
    if (this.MultiEyesParams.value && !this.MultiEyesParamsSetOnServer) {
      // post
      postCompanySettings(this.selectedCompanyIdGetter, {
        settingKey: 'multi-eye',
        value: {
          rule: 'simple-rule',
          params: {
            create: Number(this.approverAmountParams.value),
            update: Number(this.approverAmountParams.value),
            withdraw: Number(this.approverAmountParams.value),
          },
        },
      })
        .then(() => {
          this.CompanySettingsSwitcherStatus = 'completed';
        })
        .catch(() => {
          this.CompanySettingsSwitcherStatus = 'failed';
        });
    }

    if (this.MultiEyesParams.value && this.MultiEyesParamsSetOnServer) {
      // put
      putCompanySettings(this.selectedCompanyIdGetter, {
        settingKey: 'multi-eye',
        value: {
          rule: 'simple-rule',
          params: {
            create: Number(this.approverAmountParams.value),
            update: Number(this.approverAmountParams.value),
            withdraw: Number(this.approverAmountParams.value),
          },
        },
      })
        .then(() => {
          this.CompanySettingsSwitcherStatus = 'completed';
        })
        .catch(() => {
          this.CompanySettingsSwitcherStatus = 'failed';
        });
    }

    if (!this.MultiEyesParams.value && this.MultiEyesParamsSetOnServer) {
      // delete
      deleteCompanySettings(this.selectedCompanyIdGetter)
        .then(() => {
          this.CompanySettingsSwitcherStatus = 'completed';
          this.MultiEyesParams.value = '';
          this.eventBus.$emit('reloadRadioInput', this.MultiEyesParams);
        })
        .then(() => {
          this.CompanySettingsSwitcherStatus = 'completed';
        })
        .catch(() => {
          this.CompanySettingsSwitcherStatus = 'failed';
        });
    }
  }
}
