























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'Error404',
})
export default class Error404 extends Vue {}
