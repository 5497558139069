


























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProgressBarState, ProgressBar } from '@/types/types';

const ProgressBarNameSpace = namespace('progressBar/');

@Component({})
export default class ProgressBarContainer extends Vue {
  @ProgressBarNameSpace.Getter('type') type: string;
  @ProgressBarNameSpace.Getter('currentStep') currentStep: number;
  @ProgressBarNameSpace.Getter('totalSteps') totalSteps: number;
  @ProgressBarNameSpace.Getter('percentage') percentage: number;
  @ProgressBarNameSpace.Getter('title') title: string;
  @ProgressBarNameSpace.Action('create') createProgressBar: any;

  get progressPercentage() {
    return `${this.percentage}%`;
  }

  progressBarClass(n: number) {
    return n <= this.currentStep
      ? 'progress-bar-advance'
      : 'progress-bar-remaining';
  }
}
