




















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
// more flag icons: http://flag-icon-css.lip.is/

@Component({
  name: 'CountryFlag',
})
export default class CountryFlag extends Vue {
  @Prop({}) language: string;
}
