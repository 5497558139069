





























































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import IoiRow from '@/components/ioi/IOIRow.vue';
import { namespace } from 'vuex-class';
import { redirectUserPage } from '@/util/generalUtils';
import { getIOIList, getIOIByReference } from '@/api/instrumentApi';
import { IOI } from '@/types/instrumentsTypes';
import { buildIOI } from '@/store/iois';
import moment from 'moment';
import IoiRowDayColumn from '@/components/ioi/IOIRowDayColumn.vue';
import IoiRowMaturityColumn from '@/components/ioi/IOIRowMaturityColumn.vue';
import MakeOffers from '@/components/ioi/MakeOffers.vue';
import IOISummary from '@/components/ioi/IOISummary.vue';
import ViewDeals from '@/components/ioi/ViewDeals.vue';
import ExpandElement from '@/components/utils/ExpandElement.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import AcceptOffers from '@/components/ioi/AcceptOffers.vue';
import IoiOfferCount from '@/components/ioi/IoiOfferCount.vue';
import IoiButtons from '@/components/ioi/IoiButtons.vue';
import StatusIndicator from '@/components/utils/statusIndicator.vue';
import LoadingIndicator from '@/components/utils/LoadingIndicator.vue';
import sentry from '@/util/sentry';
import { HistoryLogType } from '@/types/enums';
import HistoryLog from '@/components/utils/HistoryLog.vue';
import store from '@/store';
import { EventBus } from '@/main';
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'IOIDetail',
  components: {
    LoadingIndicator,
    StatusIndicator,
    IoiButtons,
    IoiOfferCount,
    AcceptOffers,
    ButtonComponent,
    ExpandElement,
    ViewDeals,
    IOISummary,
    MakeOffers,
    IoiRowDayColumn,
    IoiRow,
    IoiRowMaturityColumn,
    HistoryLog,
  },
})
export default class IOIDetail extends Vue {
  @Prop({}) IOIReference!: string;
  @Prop({}) IOI!: IOI;
  @Prop({ default: true }) pageView!: boolean;
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;

  ioiReady: boolean = false;
  ioiFailed: boolean = false;
  ioi: IOI = {} as IOI;
  summaryExpanded: boolean = false;
  redirectUserPage: string = redirectUserPage();
  HistoryLogType = HistoryLogType;

  get selectedIoI() {
    if (this.pageView) {
      return this.ioi;
    } else {
      return this.IOI;
    }
  }

  getPageName() {
    let response: string = `${this.$t('components.navigation.back')}`;
    let redirectPage: string = redirectUserPage();

    if (redirectPage === 'myaccount-issuer') {
      response = `${this.$t('components.navigation.myAccount')}`;
    } else if (redirectPage === 'ioi-list-investor') {
      response = `${this.$t('components.navigation.currentInvestments')}`;
    }

    return response;
  }

  getIoi() {
    const nowDate: string = moment()
      .utc()
      .format('YYYY-MM-DD');
    const todayUnix: number = moment(nowDate).unix();
    getIOIByReference(this.IOIReference || this.IOI.referenceNumber)
      .then(data => {
        this.ioi = buildIOI(data.data, true, todayUnix, this.getUserAccountId);
        this.ioiReady = true;
      })
      .catch(error => {
        sentry(error);
        this.ioiFailed = true;
      });
  }

  refreshAllIois() {
    this.ioiReady = false;
    this.ioi = {} as IOI;
    const nowDate: string = moment()
      .utc()
      .format('YYYY-MM-DD');
    const todayUnix: number = moment(nowDate).unix();
    getIOIList({
      state: 'all',
      withOffers: '1',
      userAccountId: this.getUserAccountId,
      filters: {
        interested: 'true',
        offers: 'true',
      },
      preferred: true,
    }).then((response: any) => {
      const referencedIoIs = response.data.filter(
        (ioi: IOI) => ioi.referenceNumber === this.IOIReference,
      );
      if (referencedIoIs.length > 0) {
        this.ioi = buildIOI(
          referencedIoIs[0],
          true,
          todayUnix,
          this.getUserAccountId,
        );
        this.ioiReady = true;
      } else {
        this.ioiFailed = true;
      }
    });
  }

  onSubmitOffer() {
    this.getIoi();
  }

  created() {
    this.getIoi();
    EventBus.$on('getIoi', this.getIoi);
  }
}
