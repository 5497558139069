// used after requireAuth
import { Route } from 'vue-router';
import store from '@/store';
import router from '@/router';
import { redirectUserPage } from '@/util/generalUtils';

export function proceed(to: Route, from: Route, next: any) {
  /*
    If the user has been logged in determine where we should
    send the user. (intended route or redirect)
  */
  let redirected = false;
  // marketViewer
  if (!to || !to.meta) {
    return;
  }
  if (to.meta.permission.indexOf('marketViewer') !== -1) {
    if (store.getters['authentication/isMarketViewer']) {
      next();
      redirected = true;
    }
  }
  if (to.meta.permission.indexOf('issuer') !== -1) {
    // issuer
    if (store.getters['authentication/isIssuer']) {
      next();
      redirected = true;
    }
  }
  if (to.meta.permission.indexOf('investor') !== -1) {
    // investor
    if (store.getters['authentication/isInvestor']) {
      next();
      redirected = true;
    }
  }
  if (to.meta.permission.indexOf('leadManager') !== -1) {
    // leadManager
    if (store.getters['authentication/isLeadManager']) {
      next();
      redirected = true;
    }
  }
  if (to.meta.permission.indexOf('backOffice') !== -1) {
    // backOffice
    if (store.getters['authentication/isBackOffice']) {
      next();
      redirected = true;
    }
  }
  if (!redirected) {
    store.dispatch(
      'notifications/add',
      {
        message: router.app.$t('components.login.redirectFailed', {
          fullPath: to.fullPath,
        }),
        type: 'error',
      },
      { root: true },
    );
    next({ name: redirectUserPage() });
  }
}
/*
    This will check to see if the user is authenticated or not.
*/
export function requireAuth(to: Route, from: Route, next: any) {
  // check if logged in
  if (store.getters['authentication/isUserLoggedIn']) {
    // proceed to routing
    proceed(to, from, next);
  } else {
    // authenticate
    store.dispatch('authentication/authenticateLoginRoute').then(() => {
      // proceed to routing if logged in
      if (store.getters['authentication/isUserLoggedIn']) {
        proceed(to, from, next);
      } else {
        // user not logged in but wants to open authrequiredPage
        // resets and sets redirect in localstorage + redirects to login page
        localStorage.removeItem('redirect');
        localStorage.setItem(
          'redirect',
          JSON.stringify({
            name: to.name,
            query: to.query,
            params: to.params,
          }),
        );
        next({ name: 'login' });
      }
    });
  }
}
