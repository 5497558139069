














import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { EventBus } from '@/main';
import LoginForm from '@/components/authentication/LoginForm.vue';
import LoginFormMobile from '@/components/authentication/LoginFormMobile.vue';
import ForgotPasswordForm from '@/components/authentication/ForgotPasswordForm.vue';

@Component({
  name: 'Login',
  components: { LoginFormMobile, LoginForm, ForgotPasswordForm },
})
export default class Login extends Vue {
  /*
   * This view is a home component that loads the loginForm by default.
   * If the ForgotPassword or some other component is needed, it will ask for that
   * */
  loadComponent: string = '';
  loadLogin: boolean = false;
  loadForgotPassword: boolean = false;

  @Watch('loadComponent')
  onComponentChange(newComponent: string) {
    this.loadComponent = newComponent;
    this.loadComponentSelection();
  }

  created() {
    EventBus.$on('reloadHome', (loadComponentParam: string) => {
      this.loadComponent = loadComponentParam;
    });
    this.loadComponentSelection();
  }

  loadComponentSelection() {
    if (this.loadComponent === 'forgotPassword') {
      this.loadLogin = false;
      this.loadForgotPassword = true;
    } else {
      this.loadLogin = true;
      this.loadForgotPassword = false;
    }
  }
}
