import { ActionTree, GetterTree, MutationTree } from 'vuex';
import Vue from 'vue';
import router from '../router';
import { SignupState } from '@/types/types';
import { RootState } from '@/types/rootState';
import {
  signUp,
  signUpAdditional,
  signUpByInvite,
  signUpUpgrade,
} from '@/api/authenticationApi';
import {
  MarketViewerAccount,
  SignUpProfessionalPayload,
  SignUpUpgradePayload,
} from '@/types/accountTypes';
import store from '@/store/index';

export const state: SignupState = {
  step: 1,
  accountType: '',
  accountTypeFormatted: '',
  flowType: '',
  finalStep: false,
  lastFormStep: false,
  uid: '',
  formAnswersOne: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    companyEmailRepeat: '',
    phoneNumber: '',
    password: '',
    passwordRepeat: '',
  },
  upgradeAnswersOne: {
    street: '',
    houseNumber: '',
    houseNumberSuffix: '',
    postalCode: '',
    city: '',
    countryCode: '',
  },
  formAnswersOneMV: {
    companyName: '',
    industryOrSector: '',
    companyType: 1,
    terms: '',
  },
  formAnswersTwo: {
    street: '',
    houseNumber: '',
    houseNumberSuffix: '',
    postalCode: '',
    city: '',
    countryCode: '',
    companyName: '',
    industryOrSector: '',
    kvkFile: '',
    leiNumber: '',
    identificationFile: '',
    terms: '',
  },
  upgradeAnswersTwo: {
    kvkFile: '',
    leiNumber: '',
    identificationFile: '',
    terms: '',
  },
  formAnswersOneInvitation: {
    email: '',
    companyEmailRepeat: '',
  },
  formAnswersTwoInvitation: {
    firstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    passwordRepeat: '',
    terms: '',
  },
};

// getters
export const getters: GetterTree<SignupState, RootState> = {
  step: state => state.step,
  flowType: state => state.flowType,
  totalSteps: (state, getters) => (getters.asMarketViewer ? 2 : 3),
  finalStep: (state, getters) => getters.totalSteps - state.step === 0,
  lastFormStep: state => state.lastFormStep,
  accountType: state => state.accountType,
  companyType: (state, getters) => {
    if (getters.accountType === 'issuer') {
      return 1;
    }
    if (getters.accountType === 'investor') {
      return 2;
    }
    return 0;
  },
  asMarketViewer: state => state.accountType === 'marketviewer',
  accountTypeFormatted: state => state.accountTypeFormatted,
  formQuestionsOne: (state, getters) => {
    let questions: object = {};
    if (getters.upgrading) {
      questions = state.upgradeAnswersOne;
    } else if (getters.asMarketViewer) {
      questions = { ...state.formAnswersOne, ...state.formAnswersOneMV };
    } else if (getters.inviting) {
      questions = state.formAnswersOneInvitation;
    } else {
      questions = state.formAnswersOne;
    }
    return questions;
  },
  formQuestionsTwo: (state, getters) => {
    let questions: object = {};
    if (getters.upgrading) {
      questions = state.upgradeAnswersTwo;
    } else if (getters.inviting) {
      questions = state.formAnswersTwoInvitation;
    } else {
      questions = state.formAnswersTwo;
    }
    return questions;
  },
  upgrading: state => state.flowType === 'upgrade',
  inviting: state => state.flowType === 'invitation',
};

// mutations
export const mutations: MutationTree<SignupState> = {
  SET_STEP(state, step) {
    state.step = step;
    let stepsRequired: number = 3;
    if (state.accountType === 'marketviewer') {
      stepsRequired = 2;
    }
    state.finalStep = state.step >= stepsRequired;
    state.lastFormStep = state.step === stepsRequired - 1;
  },

  SET_FLOW_TYPE(state, flowType) {
    state.flowType = flowType;
  },

  SET_ACCOUNT(state, account) {
    state.accountType = account;
    let stepsRequired: number = 3;
    if (account === 'marketviewer') {
      stepsRequired = 2;
    }
    state.finalStep = state.step >= stepsRequired;
    state.lastFormStep = state.step === stepsRequired - 1;
    if (account === 'marketviewer') {
      state.accountTypeFormatted = 'Market Viewer';
    } else if (state.accountType === 'issuer') {
      state.accountTypeFormatted = 'Issuer';
    } else if (state.accountType === 'investor') {
      state.accountTypeFormatted = 'Investor';
    }
  },

  SET_FORM_ANSWER_ONE_MV(state, answer): void {
    const answersBusinessMv: any = state.formAnswersOneMV;
    answersBusinessMv[answer.name] = answer.value;
    state.formAnswersOneMV = answersBusinessMv;
  },

  SET_FORM_ANSWER_ONE(state, answer): void {
    const answersone: any = state.formAnswersOne;
    answersone[answer.name] = answer.value;
    state.formAnswersOne = answersone;
  },

  SET_FORM_ANSWER_TWO(state, answer): void {
    const answerstwo: any = state.formAnswersTwo;
    answerstwo[answer.name] = answer.value;
    state.formAnswersTwo = answerstwo;
  },

  SET_UPGRADE_ANSWER_ONE(state, answer): void {
    const answersupgradeAnswersOne: any = state.upgradeAnswersOne;
    answersupgradeAnswersOne[answer.name] = answer.value;
    state.upgradeAnswersOne = answersupgradeAnswersOne;
  },

  SET_UPGRADE_ANSWER_TWO(state, answer): void {
    const answersupgradeAnswersTwo: any = state.upgradeAnswersTwo;
    answersupgradeAnswersTwo[answer.name] = answer.value;
    state.upgradeAnswersTwo = answersupgradeAnswersTwo;
  },

  SET_INVITATION_ANSWER_ONE(state, answer): void {
    const answersone: any = state.formAnswersOneInvitation;
    answersone[answer.name] = answer.value;
    state.formAnswersOneInvitation = answersone;
  },

  SET_INVITATION_ANSWER_TWO(state, answer): void {
    const answerstwo: any = state.formAnswersTwoInvitation;
    answerstwo[answer.name] = answer.value;
    state.formAnswersTwoInvitation = answerstwo;
  },

  SET_UID(state, uid): void {
    state.uid = uid;
  },
};

// actions
export const actions: ActionTree<SignupState, RootState> = {
  setStep({ commit, rootState }, payload) {
    commit('SET_STEP', payload);
  },

  setAccountType({ commit, rootState }, payload) {
    commit('SET_ACCOUNT', payload);
  },

  setFlowType({ commit, rootState }, payload) {
    commit('SET_FLOW_TYPE', payload);
  },

  setUid({ commit, rootState }, payload) {
    commit('SET_UID', payload);
  },

  setFormOption({ commit, rootState }, answer: any) {
    const answersone: any = state.formAnswersOne;
    const answersBusinessMv: any = state.formAnswersOneMV;
    const answerstwo: any = state.formAnswersTwo;
    const answersupgradeAnswersOne: any = state.upgradeAnswersOne;
    const answersupgradeAnswersTwo: any = state.upgradeAnswersTwo;
    const answersInvitationAnswersOne: any = state.formAnswersOneInvitation;
    const answersInvitationAnswersTwo: any = state.formAnswersTwoInvitation;

    if (
      store.getters['signup/asMarketViewer'] &&
      state.flowType === 'sign-up'
    ) {
      if (answersBusinessMv[answer.name] !== undefined) {
        commit('SET_FORM_ANSWER_ONE_MV', answer);
      } else if (answersone[answer.name] !== undefined) {
        commit('SET_FORM_ANSWER_ONE', answer);
      }
    } else if (state.flowType === 'sign-up') {
      if (answersone[answer.name] !== undefined) {
        commit('SET_FORM_ANSWER_ONE', answer);
      }
      if (answerstwo[answer.name] !== undefined) {
        commit('SET_FORM_ANSWER_TWO', answer);
      }
    } else if (state.flowType === 'upgrade') {
      if (answersupgradeAnswersOne[answer.name] !== undefined) {
        commit('SET_UPGRADE_ANSWER_ONE', answer);
      }
      if (answersupgradeAnswersTwo[answer.name] !== undefined) {
        commit('SET_UPGRADE_ANSWER_TWO', answer);
      }
    } else if (state.flowType === 'invitation') {
      if (answersInvitationAnswersOne[answer.name] !== undefined) {
        commit('SET_INVITATION_ANSWER_ONE', answer);
      }
      if (answersInvitationAnswersTwo[answer.name] !== undefined) {
        commit('SET_INVITATION_ANSWER_TWO', answer);
      }
    }
  },

  sendSignupForm({ state }) {
    const data: MarketViewerAccount = {
      ...state.formAnswersOne,
      ...state.formAnswersOneMV,
    };

    return new Promise((resolve, reject) => {
      signUp(data)
        .then(() => resolve())
        .catch((error: any) => {
          reject(error);
        });
    });
  },

  sendSignupFormFull({ state, getters }) {
    const data: any = {
      companyType: getters.companyType,
      ...state.formAnswersOne,
      ...state.formAnswersTwo,
    };
    data.houseNumber =
      data.houseNumber === '' ? '' : parseInt(data.houseNumber, 10);
    data.postalCode = data.postalCode.replace(/\s+/g, '');
    const dataProcessed: SignUpProfessionalPayload = data;

    return new Promise((resolve, reject) => {
      signUp(dataProcessed)
        .then(() => resolve())
        .catch((error: any) => reject(error));
    });
  },

  sendSignupFormInvitation({ state }) {
    const data: any = {
      ...state.formAnswersOneInvitation,
      ...state.formAnswersTwoInvitation,
      inviteUUID: state.uid,
    };

    return new Promise((resolve, reject) => {
      signUpByInvite(data)
        .then(() => resolve())
        .catch(error => {
          reject(error);
        });
    });
  },

  sendSignupUpgradeForm({ state, rootState, getters }) {
    const data: any = {
      companyType: getters.companyType,
      userAccountId: rootState.authentication.token.userAccountId,
      ...state.upgradeAnswersOne,
      ...state.upgradeAnswersTwo,
    };
    data.houseNumber =
      data.houseNumber === '' ? '' : parseInt(data.houseNumber, 10);
    data.postalCode = data.postalCode.replace(/\s+/g, '');
    const dataProcessed: SignUpUpgradePayload = data;

    return new Promise((resolve, reject) => {
      signUpUpgrade(dataProcessed)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  },

  sendAdditionalCompanyForm({ state, rootState, getters }, payload) {
    const data: any = payload;
    data.houseNumber =
      data.houseNumber === '' ? '' : parseInt(data.houseNumber, 10);
    data.postalCode = data.postalCode.replace(/\s+/g, '');
    return new Promise((resolve, reject) => {
      signUpAdditional(data)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  },
};

export const signup = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
