

























































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Offer } from '@/types/instrumentsTypes';
import {
  approveMultiEyeInstrument,
  getMultiEyeInstrumentData,
  rejectMultiEyeInstrument,
} from '@/api/multiEyeApi';
import { getCompanySettings } from '@/api/companyApi';
import { namespace } from 'vuex-class';
import { ApprovalData, CompanySettings } from '@/types/types';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import moment from 'moment';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
const CompanyStore = namespace('companies/');
const AuthenticationNameSpace = namespace('authentication/');
const ModalNameSpace = namespace('modals/');

@Component({
  name: 'OfferApprove',
  components: { InputComponentFormat, ButtonComponent },
})
export default class OfferApprove extends Vue {
  @CompanyStore.Getter('selectedCompanyIdGetter')
  selectedCompanyIdGetter: string;

  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  @Prop({}) offer!: Offer;
  approvalData: ApprovalData = {} as ApprovalData;
  multiEyeSettings: CompanySettings = {} as CompanySettings;
  approveMessage: string = '';
  errorMessage: string = '';
  moment = moment;

  get rejectedByMe() {
    return (
      this.approvalData.events.filter(event => event.eventType === 'Reject')
        .length > 0
    );
  }

  get approveEvents() {
    return this.approvalData.events.filter(
      event => event.eventType === 'Approve',
    );
  }

  get rejectEvents() {
    return this.approvalData.events.filter(
      event => event.eventType === 'Reject',
    );
  }

  get approvedByMe() {
    return (
      this.approveEvents.filter(event => event.author === this.getUserAccountId)
        .length > 0
    );
  }

  get confirmButton(): any {
    return {
      containerClass: '',
      buttonClass: 'button-icon button-icon-medium button-icon-green',
      description: this.$t('components.general.confirm'),
      toolTip: this.$t('components.general.confirm'),
      icon: 'check.png',
      showExtended: false,
    };
  }

  get cancelButton(): any {
    return {
      containerClass: '',
      buttonClass: 'button-icon button-icon-medium button-icon-big-alert',
      description: this.$t('components.general.reject'),
      toolTip: this.$t('components.general.reject'),
      icon: 'close-error.png',
      showExtended: false,
    };
  }

  mounted() {
    this.getData();
  }

  getData() {
    getMultiEyeInstrumentData(this.offer.offerId).then(response => {
      this.approvalData = response.data['multieye-flows'][0];
    });

    getCompanySettings(this.selectedCompanyIdGetter)
      .then(({ data: { settings } }) => {
        this.multiEyeSettings = settings;
      })
      .catch(error => (this.errorMessage = error));
  }

  approveOffer() {
    approveMultiEyeInstrument(this.offer.offerId, this.approveMessage)
      .then(() => this.getData())
      .catch(error => (this.errorMessage = error.response.data.message));
  }

  rejectOffer() {
    rejectMultiEyeInstrument(this.offer.offerId, this.approveMessage)
      .then(() => this.getData())
      .catch(error => (this.errorMessage = error.response));
  }
}
