var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ioi-list"},[(_vm.iois.length > 3)?_c('div',{staticClass:"sort-filter-bar"},[_c('div',{staticClass:"sort-filter-bar__item sort"},[_c('label',{staticClass:"sort-filter-bar__select-label"},[_vm._v(_vm._s(_vm.$t('components.ioiList.sortBy')))]),_c('div',{staticClass:"sort-filter-bar__select-container"},[_c('input-select',{attrs:{"form-class":'sort-filter-bar__select',"input-param":_vm.orderBy,"close-on-select":true,"allow-empty":false},on:{"sendInput":_vm.setInputValue}}),_c('div',{staticClass:"up-down"},[_c('svg',{class:[
              'up-down__icon up',
              _vm.orderDirection === 'asc' ? 'active' : '' ],attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'},on:{"click":function($event){_vm.orderDirection = 'asc'}}},[_c('path',{attrs:{"d":"M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42z"}})]),_c('svg',{class:[
              'up-down__icon',
              _vm.orderDirection === 'desc' ? 'active' : '' ],attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'},on:{"click":function($event){_vm.orderDirection = 'desc'}}},[_c('path',{attrs:{"d":"M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42z"}})])])],1)]),(_vm.activeFilters.any)?_c('div',{staticClass:"sort-filter-bar__item filter"},[(_vm.companyList.length > 1 || _vm.industryList.length > 1)?_c('label',{staticClass:"sort-filter-bar__select-label"},[_vm._v(_vm._s(_vm.$t('components.ioiList.filterBy')))]):_vm._e(),_c('div',{staticClass:"sort-filter-bar__select-container"},[(_vm.activeFilters.company)?_c('input-select',{attrs:{"form-class":'sort-filter-bar__select',"input-param":_vm.companyParam,"close-on-select":true,"allow-empty":true,"limit-height":true,"translate-options":false,"translate-value":false},on:{"sendInput":_vm.setInputValue}}):_vm._e(),(_vm.activeFilters.industry)?_c('input-select',{attrs:{"form-class":'sort-filter-bar__select',"input-param":_vm.industryParam,"close-on-select":true,"allow-empty":true,"limit-height":true},on:{"sendInput":_vm.setInputValue}}):_vm._e(),(_vm.activeFilters.company)?_c('input-select',{attrs:{"form-class":'sort-filter-bar__select',"input-param":_vm.countryParam,"close-on-select":true,"allow-empty":true,"limit-height":true},on:{"sendInput":_vm.setInputValue}}):_vm._e()],1)]):_vm._e(),(
        (_vm.activeFilters.any && _vm.filterByIssuerCompany.value.length > 0) ||
          _vm.filterByIndustry.value.length > 0 ||
          _vm.filterByCountry.value.length > 0
      )?_c('button',{staticClass:"button-icon",on:{"click":_vm.clearFilters}},[_vm._v(" "+_vm._s(_vm.$t('components.ioiList.clearFilters'))+" ")]):_vm._e()]):_vm._e(),_vm._l((_vm.sortedList),function(ioi,index){return _c('div',{key:(_vm.randomNumber + "+" + index + "+1+" + (ioi.indicationOfInterestId))},[_c('div',{class:[
        _vm.ioiReference === ioi.referenceNumber ? 'expanded' : '',
        'ioi-and-detail',
        _vm.isIssuer ? 'issuer' : 'investor' ],attrs:{"id":_vm.ioiReference === ioi.referenceNumber ? 'open-ioi-id' : ''}},[_c('transition-group',{key:(_vm.randomNumber + "+" + index + "+" + (ioi.indicationOfInterestId)),attrs:{"name":"ioiSlide"}},[_c('div',{key:1 + ioi.referenceNumber,on:{"click":function($event){return _vm.slotClick(ioi.referenceNumber)}}},[_vm._t("default",null,{"ioi":ioi})],2),(
            _vm.ioiReference === ioi.referenceNumber && (_vm.isIssuer || _vm.isInvestor)
          )?_c('i-o-i-detail',{key:2 + ioi.referenceNumber,attrs:{"page-view":false,"i-o-i":ioi}}):_vm._e()],1)],1)])}),(_vm.sortedList.length === 0 && _vm.iois.length > 0)?_c('p',{staticClass:"no-data__message"},[_vm._v(" "+_vm._s(_vm.$t('components.noDataAvailable.filters2'))+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }