













































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CompanyItem } from '@/types/types';
const AuthenticationNameSpace = namespace('authentication/');
const CompanyStore = namespace('companies/');
@Component({
  name: 'EditCompany',
  components: {},
})
export default class EditCompany extends Vue {
  @CompanyStore.Getter('selectedCompanyData')
  selectedCompanyData: CompanyItem;
}
