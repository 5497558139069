import api from '@/api/index';

export function approveMultiEyeInstrument(
  instrumentId: string,
  approveMessage: string,
) {
  return api.post(`/multieye/${instrumentId}/approve`, {
    instrumentType: 'Offer',
    data: {
      message: approveMessage,
    },
  });
}

export function rejectMultiEyeInstrument(
  instrumentId: string,
  rejectMessage: string,
) {
  return api.post(`/multieye/${instrumentId}/reject`, {
    instrumentType: 'Offer',
    data: {
      message: rejectMessage,
    },
  });
}

export function getMultiEyeInstrumentData(instrumentId: string) {
  return api.get(`/multieye/${instrumentId}`);
}
