


























import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import { isEmpty, forEach } from 'lodash';
import vSelect from 'vue-select';
import { Validator } from 'vee-validate';
import { CustomInput, InputObject, ReturnInput } from './inputTypes';
import { EventBus } from '@/main';

@Component({
  name: 'InputSelectComponent',
  components: { vSelect },
})
export default class InputSelectComponent extends Vue {
  @Prop({}) inputParam!: InputObject;
  @Prop({}) dataTestId!: string;
  @Prop({}) formClass!: string;
  @Prop({ default: false }) noValidation!: boolean;
  @Inject('$validator') validator!: Validator;

  el = document.body;
  eventBus = EventBus;
  vmodelValue: any | null = {};
  selected: string = '';

  inputField: InputObject = {
    id: '',
    name: '',
    ref: '',
    type: 'text',
    placeHolder: '',
    class: '',
    vRule: '',
    vLabel: '',
    disabled: false,
    optionList: [],
    containerClass: '',
    value: '',
    hideVLabel: false,
    isValid: null,
    translate: true,
    showLabel: false,
  };

  get inputName() {
    return isEmpty(this.inputField.name) ? '' : this.inputField.name;
  }

  get inputVRule(): any {
    if (this.inputField.vRule === null) {
      return '';
    }
    return this.inputField.vRule;
  }

  get required() {
    if (typeof this.inputVRule === 'string') {
      return this.inputVRule.indexOf('required') !== -1;
    }
    return this.inputVRule.required;
  }

  get vLabel() {
    if (
      isEmpty(this.inputField.vLabel) &&
      isEmpty(this.inputField.placeHolder)
    ) {
      return '';
    }
    return isEmpty(this.inputField.vLabel)
      ? this.inputPlaceHolder
      : this.inputField.vLabel;
  }

  get inputPlaceHolder(): string {
    return isEmpty(this.inputField.placeHolder)
      ? ''
      : `${this.$t(this.inputField.placeHolder)}`;
  }

  get inputDisabled(): boolean {
    return this.inputField.disabled === undefined
      ? false
      : this.inputField.disabled;
  }

  get containerClass(): string[] {
    const classes: string[] = [];
    classes.push('form-select');
    if (this.isInputCorrect) {
      classes.push('select-correct');
    }
    if (this.inputHasError) {
      classes.push('select-has-error');
    }
    if (this.inputDisabled) {
      classes.push('select-disable-actions');
    }
    return classes;
  }

  get wrapperClass(): string[] {
    const classes: string[] = [];
    if (this.inputField.value === undefined) {
      classes.push('select-border-empty');
    }
    if (this.inputHasError) {
      classes.push('select-has-error-border');
    }
    if (this.isInputCorrect && !this.noValidation) {
      classes.push('select-correct-border');
    }
    if (
      this.inputField.containerClass !== undefined &&
      this.inputField.containerClass !== null
    ) {
      classes.push(this.inputField.containerClass);
    }
    return classes;
  }

  get showVLabel(): boolean {
    return !this.inputField.hideVLabel && this.inputHasError;
  }

  get optionList(): any {
    if (
      isEmpty(this.inputField.optionList) ||
      this.inputField.optionList === null ||
      this.inputField.optionList.length === 0
    ) {
      return [{ value: ' ', label: '' }];
    }
    if (this.inputField.translate === undefined || this.inputField.translate) {
      const selectOptions: any = [];
      forEach(this.inputField.optionList, (element: any) => {
        const label: string = `${this.$t(element.label)}`;
        selectOptions.push({ value: element.value, label: label.trim() });
      });
      return selectOptions;
    }

    return this.inputField.optionList;
  }

  get isInputCorrect(): boolean {
    return (
      this.vmodelValue !== null &&
      this.vmodelValue !== undefined &&
      this.vmodelValue.value !== '' &&
      !this.$validator.errors.has(this.inputField.name)
    );
  }

  get inputHasError(): boolean {
    const hasError: boolean = this.$validator.errors.has(this.inputField.name);
    if (hasError && this.inputField.hideVLabel) {
      this.$emit('validate');
    }
    return hasError;
  }

  get inputTooltip(): any {
    if (this.inputField.defaultTooltip) {
      return { placement: 'bottom-center', content: this.inputPlaceHolder };
    }
    if (
      this.inputField.tooltip === undefined ||
      this.inputField.tooltip === null
    ) {
      return null;
    }

    const tooltipContent: string | null = `${this.$t(this.inputField.tooltip)}`;
    return tooltipContent === null
      ? null
      : { placement: 'bottom-center', content: tooltipContent };
  }

  inputChange() {
    if (this.vmodelValue === null || this.vmodelValue === undefined) {
      const returnInput: ReturnInput = {
        name: this.inputField.name,
        value: '',
      };
      this.$emit('sendInput', returnInput);
    } else if (!isEmpty(this.vmodelValue.value)) {
      const returnInput: CustomInput = {
        name: this.inputField.name,
        value: this.vmodelValue.value === null ? '' : this.vmodelValue.value,
        label: this.vmodelValue.label === null ? '' : this.vmodelValue.label,
      };
      this.$emit('sendInput', returnInput);
    } else {
      const returnInput: CustomInput = {
        name: this.inputField.name,
        value: this.vmodelValue.value,
        label: this.vmodelValue.label,
      };
      this.$emit('sendInput', returnInput);
    }
  }

  toggleBodyClass(addRemoveClass: string, className: string) {
    if (addRemoveClass === 'addClass') {
      this.el.classList.add(className);
    } else {
      this.el.classList.remove(className);
    }
  }

  created() {
    this.inputField = Object.assign({}, this.inputParam);
    // vmodelvalue is set to input value or the first value in the optionlist
    // when the placeholder is empty
    this.vmodelValue = this.inputParam.value
      ? this.inputParam.value
      : undefined;
    if (!this.vmodelValue && !this.inputParam.placeHolder) {
      [this.vmodelValue] = this.inputParam.optionList;
    }
    this.eventBus.$on('reloadSelectInput', (inputInformation: InputObject) => {
      if (inputInformation.name.trim() === this.inputParam.name) {
        this.vmodelValue = inputInformation.value;
        this.inputField = {
          id: inputInformation.id,
          name: inputInformation.name,
          ref: inputInformation.ref,
          value: this.vmodelValue,
          type: inputInformation.type,
          placeHolder: inputInformation.placeHolder,
          class: inputInformation.class,
          vRule: inputInformation.vRule,
          vLabel: inputInformation.vLabel,
          disabled: inputInformation.disabled,
          optionList: inputInformation.optionList,
          containerClass: inputInformation.containerClass,
          hideVLabel: inputInformation.hideVLabel,
          isValid: inputInformation.isValid,
          defaultTooltip: inputInformation.defaultTooltip,
          tooltip: inputInformation.tooltip,
          translate: inputInformation.translate,
        };
      }
    });
  }
}
