export function setstatusLabel(status: number) {
  switch (status) {
    case 0:
      return 'Pending';
    case 1:
      return 'Accepted';
    case 2:
      return 'Denied';
    case 3:
      return 'Owner';
    default:
      return '';
  }
}
