'use strict';

import api from './index';
import {
  AccountPayload,
  AccountSignupConfirmationPayload,
  AccountEmailPayload,
  LoginPayload,
  ChangePasswordPayload,
  MarketViewerAccount,
  SignUpProfessionalPayload,
  PreferencesPayload,
  SignUpUpgradePayload,
  InviteUsersPayload,
} from '@/types/accountTypes';

// account signs up and login
export function login(payload: LoginPayload) {
  return api.post('/account/login', payload);
}

export function confirmSignup(payload: AccountSignupConfirmationPayload) {
  return api.post('/account/confirm', payload);
}

export function approveSignup(payload: AccountEmailPayload) {
  return api.post('/account/approve', payload);
}

export function denySignup(payload: AccountEmailPayload) {
  return api.post('/account/deny', payload);
}

export function approveUpgrade(payload: AccountEmailPayload) {
  return api.post('/account/upgrade/approve', payload);
}

export function denyUpgrade(payload: AccountEmailPayload) {
  return api.post('/account/upgrade/deny', payload);
}

export function requestPasswordReset(payload: LoginPayload) {
  return api.post('/user-account/password/request-reset', payload);
}

export function resetPassword(payload: ChangePasswordPayload) {
  return api.post('/user-account/password/reset', payload);
}

export function requestPasswordChange(payload: ChangePasswordPayload) {
  return api.post('/user-account/password/request-change', payload);
}

export function changePassword(payload: ChangePasswordPayload) {
  return api.post('/user-account/password/change', payload);
}

export function signUp(
  payload: MarketViewerAccount | SignUpProfessionalPayload,
) {
  return api.post('/account', payload);
}

export function signUpAdditional(payload: any) {
  return api.post('/account', payload);
}

export function signUpByInvite(payload: any) {
  return api.post('/invite/accept', payload);
}

export function signUpUpgrade(payload: SignUpUpgradePayload) {
  return api.post('/user-account/request-upgrade', payload);
}

export function signUpProfessional(payload: SignUpProfessionalPayload) {
  return api.post('/account/upgrade/request', payload);
}

export function inviteUsers(payload: InviteUsersPayload) {
  return api.post('/company/add-account-managers', payload);
}

export function prepopulateProfessional(payload: AccountPayload) {
  return api.get(`/user-account/prepopulate-upgrade/${payload}`);
}

export function prepopulateInviteSignup(payload: string) {
  return api.get(`/invite/prepopulate/${payload}`);
}

export function countries(code: string) {
  return api.get(`/account/country/${code}`);
}

export function resendConfirmationMail(email: object) {
  return api.post('/account/resend-confirmation', email);
}

// account preferences
export function preferencesPrepopulate(payload: PreferencesPayload) {
  return api.post('/account/preference/prepopulate', payload);
}

export function preferencesUpdate(payload: PreferencesPayload) {
  return api.post('/account/preference/update', payload);
}

export function getNotificationToken() {
  return api.get('/notification/token');
}

export function countryList(): any {
  return [
    { value: 'NL', label: 'Netherlands' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AT', label: 'Austria' },
    { value: 'BE', label: 'Belgium' },
    { value: 'DK', label: 'Denmark' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'DE', label: 'Germany' },
    { value: 'GR', label: 'Greece' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IT', label: 'Italy' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MT', label: 'Malta' },
    { value: 'MC', label: 'Monaco' },
    { value: 'NO', label: 'Norway' },
    { value: 'PT', label: 'Portugal' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ES', label: 'Spain' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'TR', label: 'Turkey' },
    { value: 'GB', label: 'United Kingdom' },
  ];
}

export function industrySectorList(): any {
  return [
    { value: 'GL', label: 'components.IOIForm.industryGL' },
    { value: 'HA', label: 'components.IOIForm.industryHA' },
    { value: 'DS', label: 'components.IOIForm.industryDS' },
    { value: 'GO', label: 'components.IOIForm.industryGO' },
    { value: 'FI', label: 'components.IOIForm.industryFI' },
    { value: 'RW', label: 'components.IOIForm.industryRW' },
  ];
}
