'use strict';

import 'babel-polyfill'; // for IE compatibility
import Vue from 'vue';
import { VueHammer } from 'vue2-hammer';
import { ClientTable, Event } from 'vue-tables-2';
import VueMq from 'vue-mq';
import VeeValidate from 'vee-validate';
import VTooltip from 'v-tooltip';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueSimpleSVG from 'vue-simple-svg';
import i18n from '@/translation';
import store from './store/index';
import './api/index';
import App from './App.vue';
import router from './router';
import dictionary from '@/validations/dictionary';

import initializeValidator from './validations/validators';
import './service-worker';
import sentry from '@/util/sentry';
import { getNotificationToken } from '@/api/authenticationApi';
import VueNativeSock from 'vue-native-websocket';
import PrismicVue from '@prismicio/vue';
import linkResolver from './util/prismicLinkResolver';
// @ts-ignore
window.store = store;

Vue.config.productionTip = false;

// eslint-disable-next-line import/prefer-default-export
export const EventBus = new Vue();

// Validations
initializeValidator();
Vue.use(VeeValidate, {
  i18n,
  dictionary,
});

Vue.use(VueSimpleSVG);
// Vue.use
Vue.use(VTooltip);
Vue.use(VueHammer);
Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    mobileLandscape: 750,
    tablet: 900,
    tabletLandscape: 1025,
    laptop: 1250,
    desktop: Infinity,
  },
});
Vue.use(ClientTable, {}, false, 'bulma');

Vue.use(VueNativeSock, process.env.VUE_APP_WS_NOTIFICATION_SERVER, {
  reconnection: false,
  connectManually: true,
});

Vue.use(PrismicVue, {
  endpoint: 'https://afsblue.cdn.prismic.io/api/v2',
  linkResolver,
});

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

export default vm;

if (process.env.VUE_APP_SENTRY_ENABLED === '1') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
    ],
    release: process.env.VUE_APP_VERSION,
    environment: process.env.VUE_APP_ENVIRONMENT,
  });
}
