







































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EventBus } from '@/main';
import { IOI, Offer, PayloadIOI } from '@/types/instrumentsTypes';
import confirm from '@/components/utils/Confirm.vue';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import { InputFormatObject } from '@/components/inputs/inputTypes';
import { CompanyItem, CompanySettings, Permission } from '@/types/types';
import { permAvailable } from '@/util/generalUtils';
import { Company } from '@/types/accountTypes';
import {
  approveMultiEyeInstrument,
  getMultiEyeInstrumentData,
  rejectMultiEyeInstrument,
} from '@/api/multiEyeApi';
import OfferApprove from '@/components/ioi/OfferApprove.vue';
import ModalWindow from '@/components/utils/Modal.vue';
import { getCompanySettings } from '@/api/companyApi';

const IOINameSpace = namespace('iois/');
const CompanyStore = namespace('companies/');
const ModalNameSpace = namespace('modals/');
@Component({
  name: 'MyOffers',
  components: {
    ModalWindow,
    OfferApprove,
    confirm,
    InputComponentFormat,
    ButtonComponent,
  },
})
export default class MyOffers extends Vue {
  @Prop({}) ioiParam!: IOI;
  @Prop({}) accountParam!: string;
  @Prop({}) multiEyeSettings!: CompanySettings;

  @IOINameSpace.Action('withdrawOffer') withdrawOffer: any;
  @IOINameSpace.Getter('prevIoiPayload') prevIoiPayload: PayloadIOI;
  @IOINameSpace.Action('getIOIs') getIOIs: any;
  @CompanyStore.Getter('selectedCompanyData') selectedCompanyData: CompanyItem;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  @CompanyStore.Getter('selectedCompanyIdGetter')
  selectedCompanyIdGetter: string;

  permAvailable = permAvailable;
  Permission = Permission;
  eventBus = EventBus;
  updateAnOffer: any = {
    myAccount: '',
    update: false,
    offerId: '',
    minSize: 0,
    maxSize: 0,
    couponPercentage: 0,
    spreadBasisPoints: 0,
    spreadOverWhat: null,
    instrumentType: null,
  };

  showConfirmModal: boolean = false;
  showConfirmApprovalModal: boolean = false;
  confirmData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  confirmApprovalData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  offerToBeRemoved: Offer = {} as Offer;
  offerToBeApproved: Offer = {} as Offer;

  columns = [
    'companyName',
    'couponPercentage',
    'spreadBasisPoints',
    'instrumentLabel',
    'minSize',
    'maxSize',
    'approvalStatus',
    'action',
  ];

  get headings() {
    return {
      companyName: this.$t('components.linkedCompanies.tableCompanyName'),
      couponPercentage: this.$t('components.IOIOffer.OfferGrid.titleCoupon'),
      spreadBasisPoints: this.$t('components.IOIOffer.OfferGrid.titleSpread'),
      instrumentLabel: this.$t('components.IOIOffer.OfferGrid.titleInstrument'),
      minSize: this.$t('components.IOIOffer.OfferGrid.titleMinSize'),
      maxSize: this.$t('components.IOIOffer.OfferGrid.titleMaxSize'),
      approvalStatus: this.$t('components.IOIOffer.OfferGrid.approvalStatus'),
      action: this.$t('components.IOIOffer.OfferGrid.action'),
    };
  }

  get offers() {
    return this.ioiParam.ioiOffers.filter(offer => {
      if (
        // permAvailable(Permission.can_approve_multieye) &&
        offer.madeForCompany === this.selectedCompanyData.companyId
      ) {
        return offer;
      }
      if (offer.state === 'outstanding') {
        return offer;
      }
    });
  }

  inputCoupon(offer: Offer): InputFormatObject {
    if (offer) {
      return {
        name: `coupon_${offer.offerId}`,
        placeHolder: '',
        class: 'form-tiny-inline-item',
        validationClasses: false,
        vRule: '',
        disabled: true,
        value:
          Number(offer.couponPercentage) === 0 ? '' : offer.couponPercentage,
        hideVLabel: true,
        precision: 3,
        showNegative: true,
        showCurrency: false,
      };
    } else {
      return {
        name: '',
        placeHolder: '',
        class: '',
        validationClasses: false,
        vRule: '',
        disabled: true,
        value: '',
        hideVLabel: true,
        showCurrency: false,
        precision: 0,
      };
    }
  }

  inputMinimumSize(offer: Offer): InputFormatObject {
    if (offer) {
      return {
        name: `minSize_${offer.offerId}`,
        placeHolder: '',
        class: 'form-tiny-inline-item',
        validationClasses: false,
        vRule: '',
        disabled: true,
        value: offer.minSize.toString(),
        hideVLabel: true,
        showCurrency: false,
        precision: 0,
      };
    } else {
      return {
        name: '',
        placeHolder: '',
        class: '',
        validationClasses: false,
        vRule: '',
        disabled: true,
        value: '',
        hideVLabel: true,
        showCurrency: false,
        precision: 0,
      };
    }
  }

  inputMaximumSize(offer: Offer): InputFormatObject {
    if (offer) {
      return {
        name: `maxSize_${offer.offerId}`,
        placeHolder: '',
        class: 'form-tiny-inline-item',
        validationClasses: false,
        vRule: '',
        disabled: true,
        value: offer.maxSize.toString(),
        hideVLabel: true,
        showCurrency: false,
        precision: 0,
      };
    } else {
      return {
        name: '',
        placeHolder: '',
        class: '',
        validationClasses: false,
        vRule: '',
        disabled: true,
        value: '',
        hideVLabel: true,
        showCurrency: false,
        precision: 0,
      };
    }
  }

  selectOffer(offer: Offer) {
    this.updateAnOffer = {
      ...offer,
      accountParam: this.accountParam,
      update: true,
    };
    this.$emit('updateOneOffer', this.updateAnOffer);
  }

  confirmRemoveOffer(offer: Offer): void {
    const ioiId: string = this.ioiParam.indicationOfInterestId;
    const { offerId } = offer;
    this.showConfirmModal = true;
    this.confirmData.genericId = { ioiId, offerId };
    this.confirmData.title = 'views.myInvestorAccount.withdrawOfferTitle';
    this.confirmData.body = 'views.myInvestorAccount.withdrawOfferBody';
    this.confirmData.confirmButton =
      'views.myInvestorAccount.withdrawOfferConfirmButton';
    this.confirmData.cancelButton =
      'views.myInvestorAccount.withdrawOfferCancelButton';
    this.offerToBeRemoved = { ...offer };
  }

  confirmApproveOffer(offer: Offer): void {
    const ioiId: string = this.ioiParam.indicationOfInterestId;
    const { offerId } = offer;
    this.toggleModal('approveWindow');

    this.confirmApprovalData.genericId = { ioiId, offerId };
    this.confirmApprovalData.title =
      'views.myInvestorAccount.approveOfferTitle';
    this.confirmApprovalData.body = 'views.myInvestorAccount.approveOfferBody';
    this.confirmApprovalData.confirmButton =
      'views.myInvestorAccount.approveOfferConfirmButton';
    this.confirmApprovalData.cancelButton =
      'views.myInvestorAccount.approveOfferCancelButton';
    this.offerToBeApproved = { ...offer };
  }

  withdrawIOICancellation(): void {
    this.confirmData.genericId = '';
    this.showConfirmModal = false;
    this.offerToBeRemoved = {} as Offer;
  }

  submitWithdrawOffer(): void {
    this.withdrawOffer({
      newOffer: this.offerToBeRemoved,
    }).then(() => {
      this.confirmData.genericId = '';
      this.showConfirmModal = false;
      this.offerToBeRemoved = {} as Offer;
      this.getIOIs(this.prevIoiPayload);
      EventBus.$emit('getIoi');
      this.$emit('clearOffer');
    });
  }
}
