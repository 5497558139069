import { ActionTree, GetterTree, MutationTree } from 'vuex';
import Vue from 'vue';

import { TabObject, TabState } from '@/types/types';
import { RootState } from '@/types/rootState';

export const state: TabState = {
  tabs: {},
};

// getters
export const getters: GetterTree<TabState, RootState> = {
  tabState: state => state.tabs,
};

// mutations
export const mutations: MutationTree<TabState> = {
  INIT_TAB(state, tabObject: TabObject): void {
    Vue.set(state.tabs, tabObject.key, 0);
  },
  SET_TAB(state, tabObject: TabObject): void {
    state.tabs[tabObject.key] = tabObject.value;
  },
};

// actions
export const actions: ActionTree<TabState, RootState> = {
  initTab({ commit }, tabObject: TabObject) {
    if (state.tabs[tabObject.key]) {
      return state.tabs[tabObject.key];
    } else {
      commit('INIT_TAB', tabObject);
      return 0;
    }
  },
  setTab({ commit }, tabObject: TabObject) {
    commit('SET_TAB', tabObject);
  },
};

export const tabState = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
