
























































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'SignUpInvestor',
})
export default class SignUpInvestor extends Vue {
  @Prop({}) fullSignUpParameter!: boolean;
}
