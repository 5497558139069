





























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ExpandElement from '@/components/utils/ExpandElement.vue';

@Component({
  name: 'TextSlider',
  components: {
    ExpandElement,
  },
})
export default class TextSlider extends Vue {
  @Prop({}) title!: string;
  @Prop({}) text!: string;

  expanded: boolean = false;
}
