












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IOI } from '@/types/instrumentsTypes';
import { getIOIState } from '@/util/ioiUtils';

@Component({
  name: 'IOIRowMaturityColumn',
})
export default class IOIRowMaturityColumn extends Vue {
  @Prop({}) ioiInput!: IOI;

  get maturity(): string {
    return `${this.$t('components.IOIRow.maturity')} ${
      this.ioiInput.issueMaturity
    } ${this.$t('components.IOIRow.years')}`;
  }

  get state(): string {
    const stateCode: string | undefined = this.ioiInput.state;
    if (stateCode === null) {
      return '';
    }
    return `${this.$t('components.IOIRow.state')} ${getIOIState(stateCode)}`;
  }
}
