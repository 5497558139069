




































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import { forEach } from 'lodash';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import InputRadioComponent from '@/components/inputs/InputRadioComponent.vue';
import InputSelectComponent from '@/components/inputs/InputSelectComponent.vue';
import CollapsibleComponent from '@/components/utils/CollapsibleComponent.vue';
import {
  InputFormatObject,
  CustomInput,
  RadioButtonOption,
} from '@/components/inputs/inputTypes';
import { EventBus } from '@/main';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import {
  FilterStateIOI,
  IOI,
  Offer,
  PayloadIOI,
} from '@/types/instrumentsTypes';
import { getApiError } from '@/api/util';
import { getInstrumentType, getSpread } from '@/util/ioiUtils';
import sentry from '@/util/sentry';
import InputSelectCompany from '@/components/inputs/InputSelectCompany.vue';
import InputSelect from '@/components/inputs/InputSelect.vue';
import moment from 'moment';

const InstrumentNameSpace = namespace('iois/');
const NotificationsNameSpace = namespace('notifications/');
const CompanyStore = namespace('companies/');
@Component({
  name: 'IOISpreadOrCoupon',
  components: {
    InputSelectCompany,
    InputSelect,
    InputComponentFormat,
    InputRadioComponent,
    InputSelectComponent,
    ButtonComponent,
    CollapsibleComponent,
  },
})
export default class IOISpreadOrCoupon extends Vue {
  @Prop({}) accountParam!: string;
  @Prop({}) ioiIdParam!: string;
  @Prop({}) ioiAdditionalInformation: string;
  @Prop({}) ioiInstrumentsParam!: any;
  @Prop({}) ioiCountryCodeParam!: string;
  @Prop({}) spreadMinValueParam!: number;
  @Prop({}) spreadMaxValueParam!: number;
  @Prop({}) spreadOverWhatParam!: string;
  @Prop({}) stateParam!: string;
  @Prop({}) independentLayoutParam!: boolean;
  @Prop({}) ioiInput!: IOI;
  @Prop({}) offerParam!: Offer;

  // Notification store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // Instrument store
  @InstrumentNameSpace.Action('spreadList') spreadList: any;
  @InstrumentNameSpace.Action('createOffer') createOffer: any;
  @InstrumentNameSpace.Action('editOffer') editOffer: any;
  @InstrumentNameSpace.Action('getIOIs') getIOIs: any;
  @InstrumentNameSpace.Getter('prevIoiPayload') prevIoiPayload: PayloadIOI;

  @CompanyStore.Getter('companyCantVote') companyCantVote: boolean;
  @CompanyStore.Getter('selectedCompanyData') selectedCompanyData: any;

  eventBus = EventBus;
  OfferInformationTitle: string = '';
  OfferInformationBody: string[] = [];
  offerId: string = this.offerParam.offerId;
  spreadListLocal: any = [];
  insertOffer: boolean =
    this.offerParam.offerId === undefined ||
    this.offerParam.offerId === null ||
    this.offerParam.offerId === '';

  validateSpread: string = 'regex:[1234567890]|min_value:1';
  offerType: string =
    this.offerParam.offerType === undefined ? '' : this.offerParam.offerType;

  showSpread: boolean = this.offerType === 'S';

  inputBehalfOff: any = {
    name: 'behalfOff',
    vRule: 'required',
    containerClass: '',
    optionList: [],
    value: {
      value: this.offerParam.madeForCompany,
      label: this.offerParam.companyName,
    },
  };

  inputSelectType: any = {
    id: 'type',
    name: 'selectType',
    optionList: [],
    containerClass: 'radio-container',
    verticalRadioButton: false,
    showImage: false,
    value: this.offerParam.offerType === 'S' ? 'S' : 'C',
  };

  setSelectType(type: string | undefined | null) {
    this.inputSelectType.value =
      type === undefined || type === null || type === '' ? 'C' : type;
    if (this.offerType === undefined || this.offerType === '') {
      this.offerType = this.inputSelectType.value;
    }
    this.inputSelectType.optionList = this.spreadTypeOptions;
    this.inputSelectType.id = `type_${type}`;
  }

  inputOfferSpread: InputFormatObject = {
    id: 'spread_',
    name: 'offerSpread',
    placeHolder: '',
    class: 'form-offer-input',
    validationClasses: true,
    vRule: `${!this.showSpread ? '' : 'required'}`,
    value:
      this.offerParam.spreadBasisPoints === undefined ||
      this.offerParam.spreadBasisPoints === null
        ? ''
        : this.offerParam.spreadBasisPoints.toString(),
    showCurrency: false,
    precision: 0,
  };

  inputSpreadOverWhat: any = {
    name: 'spreadOverWhat',
    placeHolder: 'components.IOIOffer.myOfferSpreadOverPlaceHolder',
    defaultTooltip: true,
    containerClass: 'ioi-offer-form-select',
    class: 'form-offer-input',
    optionList: [],
    translate: false,
    hideVLabel: true,
    value: !this.offerParam.spreadOverWhat
      ? ''
      : {
          value: this.offerParam.spreadOverWhat,
          label: this.$root.$t(
            `components.IOIOffer.spread${this.offerParam.spreadOverWhat}`,
          ),
        },
  };

  inputOfferCoupon: InputFormatObject = {
    id: 'coupon_',
    name: 'offerCoupon',
    placeHolder: '',
    class: 'form-offer-input',
    validationClasses: true,
    vRule: `${this.showSpread ? '' : 'required'}`,
    value:
      this.offerParam.offerType === 'S'
        ? ''
        : this.offerParam.couponPercentage || '',
    precision: 3,
    showCurrency: false,
    showNegative: true,
  };

  inputInstrument: any = {
    name: 'instrumentType',
    containerClass: 'ioi-offer-form-select',
    placeHolder: '',
    vRule: 'required',
    translate: false,
    optionList: this.ioiInstrumentsParam,
    value:
      this.offerParam.instrumentType === undefined
        ? ''
        : {
            value: this.offerParam.instrumentType,
            label: getInstrumentType(this.offerParam.instrumentType),
          },
  };

  validateMinimumSize: string = `required|isNumber|min_value:${this.ioiInput
    .minimumPiece || '100000'}`;

  inputMinOfferSize: InputFormatObject = {
    id: 'minOffer_',
    name: 'myMinOfferSize',
    placeHolder: '',
    class: 'form-offer-input',
    vRule: '',
    vLabel: 'components.IOIOffer.myOfferMinSizeLabel',
    value: !this.offerParam.minSize ? '' : this.offerParam.minSize.toString(),
    hideVLabel: true,
    precision: 0,
  };

  get validateMaximumSize(): string {
    const absoluteMinimum: number = Math.max(
      100000,
      Number(this.inputMinOfferSize.value),
    );
    return `required|isNumber|min_value:${absoluteMinimum}`;
  }

  inputMaxOfferSize: InputFormatObject = {
    id: 'maxOffer_',
    name: 'myMaxOfferSize',
    placeHolder: '',
    class: 'form-offer-input',
    vRule:
      this.offerParam.maxSize === undefined ? '' : this.validateMaximumSize,
    vLabel: 'components.IOIOffer.myOfferMaxSizeLabel',
    value: !this.offerParam.maxSize ? '' : this.offerParam.maxSize.toString(),
    hideVLabel: true,
    precision: 0,
  };

  get showIOISpread() {
    return this.spreadOverWhatParam;
  }

  get spreadText(): string {
    const title = this.$t('components.IOIOffer.IOISpreadTitle');
    const min = this.spreadMinValueParam;
    const max = this.spreadMaxValueParam;
    const fullSpreadText = `${this.$t(
      'components.IOIOffer.spreadOverWhat',
    )} ${getSpread(this.spreadOverWhatParam)}`;

    return this.showIOISpread
      ? `${title} {${min} bps - ${max} bps} ${fullSpreadText}`
      : '';
  }

  get spreadTypeOptions(): RadioButtonOption[] {
    const options: RadioButtonOption[] = [];
    options.push(
      {
        id: 'spread',
        value: 'S',
        labelCode: 'components.IOIOffer.spreadOption',
        static: false,
      },
      {
        id: 'coupon',
        value: 'C',
        labelCode: 'components.IOIOffer.couponOption',
        static: false,
      },
    );

    return options;
  }

  get tenMinutesBefore() {
    const transDate = moment(
      `${this.ioiInput.proposedTransactionDateStr}T${this.ioiInput.proposedPricingAndTransactionTime}`,
    );
    const diff = moment().diff(transDate, 'minutes');

    return diff > -10 && this.offerType === 'S';
  }

  setInputValue(inputValue: CustomInput) {
    switch (inputValue.name) {
      case 'selectType':
        this.setSelectType(inputValue.value);
        this.showSpread = inputValue.value === 'S';
        if (inputValue.value !== null) {
          this.offerType = inputValue.value;
        }
        break;
      case 'offerCoupon':
        if (inputValue.value !== null && inputValue.value !== '') {
          this.inputOfferCoupon.value = inputValue.value;
          this.inputOfferCoupon.id = `coupon_${inputValue.value}`;
        }
        break;
      case 'offerSpread':
        if (inputValue.value !== null && inputValue.value !== '') {
          this.inputOfferSpread.value = inputValue.value;
          this.inputOfferSpread.vRule = this.validateSpread;
          this.inputOfferSpread.id = `spread_${inputValue.value}`;
        }
        break;
      case 'myMinOfferSize':
        if (inputValue.value !== null && inputValue.value !== '') {
          this.inputMinOfferSize.value = inputValue.value;
          this.inputMinOfferSize.vRule = this.validateMinimumSize;
          this.inputMinOfferSize.id = `minOffer_${inputValue.value}`;
          if (
            this.inputMaxOfferSize.value === undefined ||
            this.inputMaxOfferSize.value === null ||
            Number(this.inputMaxOfferSize.value) === 0
          ) {
            this.inputMaxOfferSize.value = inputValue.value;
            this.inputMaxOfferSize.vRule = this.validateMaximumSize;
            this.inputMaxOfferSize.id = `maxOffer_${inputValue.value}`;
          }
        }
        break;
      case 'myMaxOfferSize':
        if (inputValue.value !== null && inputValue.value !== '') {
          this.inputMaxOfferSize.value = inputValue.value;
          this.inputMaxOfferSize.vRule = this.validateMaximumSize;
          this.inputMaxOfferSize.id = `maxOffer_${inputValue.value}`;
        }
        break;
      case 'spreadOverWhat':
        this.inputSpreadOverWhat.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      case 'instrumentType':
        this.inputInstrument.value = {
          value: inputValue.value,
          label: inputValue.label,
        };
        break;
      default:
        break;
    }
  }

  get makeOfferButton(): any {
    return {
      containerClass: 'button-center',
      buttonClass: 'button-icon button-icon-resize',
      description: this.insertOffer
        ? `${this.$t('components.IOIOffer.makeOfferButton')}`
        : `${this.$t('components.IOIOffer.updateOfferButton')}`,
      toolTip: `${this.$t('components.IOIOffer.makeOfferButton')}`,
      icon: 'edit-blue.png',
      showExtended: false,
      disabled:
        this.selectedCompanyData.permissions &&
        !this.selectedCompanyData.permissions.includes('can_make_offer'),
    };
  }

  get clearOfferButton(): any {
    return {
      containerClass: 'button-center',
      buttonClass: 'button-icon button-icon-centered',
      description: `${this.$t('components.IOIOffer.clearButton')}`,
      toolTip: `${this.$t('components.IOIOffer.clearButton')}`,
      icon: 'clear.png',
      showExtended: false,
    };
  }

  validateCouponOrSpread() {
    const validations: any[] = [];
    validations.push(
      this.$validator.validate('myMinOfferSize', this.inputMinOfferSize.value),
    );
    validations.push(
      this.$validator.validate('myMaxOfferSize', this.inputMaxOfferSize.value),
    );

    if (this.offerType === 'S') {
      validations.push(
        this.$validator.validate('offerSpread', this.inputOfferSpread.value),
      );
    } else {
      validations.push(
        this.$validator.validate('offerCoupon', this.inputOfferCoupon.value),
      );
    }
    return Promise.all(validations);
  }

  validateOffer(): void {
    const validationString: TranslateResult = this.$t(
      'views.myInvestorAccount.submittingError',
    );
    this.validateCouponOrSpread()
      .then((result: boolean[]) => {
        let allValidated: boolean = true;
        forEach(result, (itemValidated: boolean) => {
          if (!itemValidated) {
            allValidated = false;
          }
        });
        if (!allValidated) {
          this.addNotification({
            message: `${validationString}`,
            type: 'error',
          });
        } else {
          if (this.tenMinutesBefore) {
            this.addNotification({
              message: this.$t('views.myInvestorAccount.errorMinutesSpread'),
              type: 'error',
            });
          } else {
            this.submitOffer();
          }
        }
      })
      .catch((error: any) => {
        this.addNotification({
          message: `${validationString} ${error}`,
          type: 'error',
        });
        sentry(error);
      });
  }

  saveOffer(newOffer: Offer) {
    return this.insertOffer
      ? this.createOffer(newOffer)
      : this.editOffer({ newOffer });
  }

  submitOffer(): void {
    let couponPercentage: string | null = null;
    if (
      this.inputOfferCoupon.value !== undefined &&
      this.inputOfferCoupon.value !== '' &&
      this.inputOfferCoupon.value !== null
    ) {
      couponPercentage = this.inputOfferCoupon.value.toString();
    }
    let spreadBasisPoints: number | null = null;
    if (
      this.offerType === 'S' &&
      this.inputOfferSpread.value !== '' &&
      this.inputOfferSpread.value !== undefined
    ) {
      spreadBasisPoints = Number(this.inputOfferSpread.value);
    }
    // if the IOI is closing, the user can only enter a coupon
    if (this.stateParam === 'closing') {
      this.inputSelectType.value = 'C';
    }
    const instrumentType: number = Number(this.inputInstrument.value.value);
    const newOffer: Offer = {
      companyName: this.inputBehalfOff.value.label,
      madeForCompany: this.inputBehalfOff.value.value,
      offerId: this.insertOffer ? '' : this.offerParam.offerId,
      createdForIndicationOfInterest: this.ioiIdParam,
      madeByInvestor: this.accountParam,
      minSize:
        this.inputMinOfferSize.value === null
          ? 0
          : Number(this.inputMinOfferSize.value),
      maxSize:
        this.inputMaxOfferSize.value === null
          ? 0
          : Number(this.inputMaxOfferSize.value),
      spreadBasisPoints,
      spreadOverWhat: this.showSpread
        ? this.inputSpreadOverWhat.value.value
        : null,
      couponPercentage,
      offerType: this.offerType,
      instrumentType,
      acceptedBy: '',
      state: '',
    };

    const errorLabel: string = this.insertOffer
      ? 'views.myInvestorAccount.createOfferErrorMessage'
      : 'views.myInvestorAccount.editOfferErrorMessage';
    this.saveOffer(newOffer)
      .then(() => this.$emit('onSubmitMakeOffer', newOffer))
      .then(() => {
        if (this.prevIoiPayload) {
          this.getIOIs(this.prevIoiPayload);
        }
      })
      .catch((error: any) => {
        this.addNotification({
          labelToTranslate: errorLabel,
          message: getApiError(error),
          type: 'error',
        });
        sentry(error);
      });
  }

  clearOffer() {
    this.$emit('onCancelMakeOffer');
  }

  mounted() {
    this.OfferInformationTitle = 'components.IOIOffer.whatWillHappen';
    this.OfferInformationBody = ['components.IOIOffer.whatWillHappenLong'];
    if (
      this.ioiInstrumentsParam.length > 0 &&
      (this.inputInstrument.value === undefined ||
        this.inputInstrument.value === '')
    ) {
      [this.inputInstrument.value] = this.ioiInstrumentsParam;
    }
    this.spreadList({ countryCode: this.ioiCountryCodeParam }).then(
      (resultList: any) => {
        this.spreadListLocal = resultList;
        this.inputSpreadOverWhat.optionList = resultList;
        if (this.inputSpreadOverWhat.optionList.length === 1) {
          this.inputSpreadOverWhat.value = {
            value: resultList[0].value,
            label: resultList[0].label,
          };
        }
        this.eventBus.$emit('reloadSelectInput', this.inputSpreadOverWhat);
      },
    );
    this.setSelectType(this.offerParam.offerType);

    this.eventBus.$on('clearOffer', () => {
      this.clearOffer();
    });
  }
}
