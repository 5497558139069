'use strict';

import axios from 'axios';
import store from '../store';
import router from '../router';

const baseConfig = {
  baseURL: process.env.VUE_APP_AFSBLUE_API_ROOT || '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const api = axios.create(baseConfig);

api.interceptors.request.use(
  initialConfig => {
    const config = initialConfig;
    const token: string | null = localStorage.getItem('token');
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  (response: any) => {
    if ('NEW-JWT' in response.headers) {
      store.dispatch(
        'authentication/refreshToken',
        response.headers['NEW-JWT'],
      );
    } else if ('new-jwt' in response.headers) {
      store.dispatch(
        'authentication/refreshToken',
        response.headers['new-jwt'],
      );
    }
    if (!store.getters['authentication/serverDateDifference']) {
      store.dispatch(
        'authentication/setServerDatetime',
        response.headers['server-datetime'],
      );
    }
    return response;
  },
  (error: any) => {
    if (error && error.response && error.response.status === 401) {
      store.commit('authentication/LOGOUT', null, { root: true });
      router.push({ name: 'login' }).catch(err => {});
    }
    return Promise.reject(error);
  },
);

export default api;
