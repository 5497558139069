














































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IOI } from '@/types/instrumentsTypes';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import { InputFormatObject } from '@/components/inputs/inputTypes';
import { EventBus } from '@/main';
import {
  isDutchStateIndustry,
  getIndustry,
  getFrequency,
  getDayCount,
  getRankingType,
  getRankingGuarantee,
  getRedemptionForm,
  getBucketSize,
  getMaturityBucket,
  getSpread,
  getStructure,
  instrumentsLabel,
  createMaturityString,
} from '@/util/ioiUtils';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';

const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'IOISummary',
  components: {
    InputComponentFormat,
    ButtonComponent,
  },
})
export default class IOISummary extends Vue {
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @Prop({}) ioi!: IOI;
  @Prop({ default: false }) vertical!: boolean;

  eventBus = EventBus;
  showModal: boolean = false;
  dutchStateIndustry: boolean = false;
  showMinimumPiece: boolean = false;
  instrumentsLabel = instrumentsLabel;
  createMaturityString = createMaturityString;

  inputSize: InputFormatObject = {
    id: 'issueNominalSize_',
    name: 'issueNominalSize',
    value: '',
    placeHolder: '',
    containerClass: 'ioi-summary__formatted-input',
    vRule: '',
    validationClasses: false,
    precision: 0,
    disabled: true,
  };

  inputMinimumPiece: InputFormatObject = {
    id: 'minimumPiece_',
    name: 'minimumPiece',
    value: '',
    placeHolder: '',
    containerClass: 'ioi-summary__formatted-input',
    vRule: '',
    validationClasses: false,
    precision: 0,
    disabled: true,
  };

  inputCoupon: InputFormatObject = {
    id: 'coupon_',
    name: 'coupon',
    value: '',
    placeHolder: '',
    containerClass: 'ioi-summary__formatted-input',
    vRule: '',
    validationClasses: false,
    precision: 3,
    showNegative: true,
    disabled: true,
    showCurrency: false,
  };

  get formattedIOI() {
    return {
      indicationOfInterestId: this.ioi.indicationOfInterestId,
      referenceNumber: this.ioi.referenceNumber,
      state: this.ioi.state,
      issuerCompanyName: this.ioi.issuerCompanyName,
      industryOrSectorCode: this.ioi.industryOrSector,
      industryOrSector: getIndustry(this.ioi.industryOrSector),
      countryName: this.ioi.countryName,
      currencyType: this.ioi.currencyType,
      instrumentTypes: this.ioi.instrumentTypes,
      instrumentTypeLabel: this.ioi.instrumentTypeLabel,
      instrumentTypeFullDescription: this.ioi.instrumentTypeLabel,
      ecaInsuredPercentage: this.ioi.ecaInsuredPercentage,
      structure: getStructure(this.ioi.structure),
      issueNominalSize: this.ioi.issueNominalSize,
      bucketSize: getBucketSize(this.ioi.bucketSize),
      issueMaturity: this.ioi.issueMaturity,
      issueMaturityInDays: this.ioi.issueMaturityInDays,
      maturityBucket: getMaturityBucket(this.ioi.maturityBucket),
      proposedTransactionDateStr:
        this.ioi.proposedPricingAndTransactionDate === undefined
          ? this.ioi.proposedTransactionDateStr
          : this.ioi.proposedPricingAndTransactionDate,
      proposedTransactionTime: this.ioi.proposedPricingAndTransactionTime,
      minimumPiece: this.ioi.minimumPiece,
      proposedSettlementDate:
        this.ioi.proposedSettlementDate === undefined
          ? this.ioi.proposedSettlementDateStr
          : this.ioi.proposedSettlementDate,
      brokenCouponDate: this.ioi.brokenCouponDate,
      maturityDate:
        this.ioi.maturityDate === null ? '-' : this.ioi.maturityDate,
      couponFrequency: this.ioi.couponFrequency
        ? getFrequency(this.ioi.couponFrequency)
        : '',
      redemptionForm: getRedemptionForm(this.ioi.redemptionForm),
      repaymentFrequency:
        this.ioi.repaymentFrequency === undefined
          ? ''
          : getFrequency(this.ioi.repaymentFrequency),
      enterSpread: this.ioi.enterSpread,
      spreadMinValue: this.ioi.spreadMinValue,
      spreadMaxValue: this.ioi.spreadMaxValue,
      spreadOverWhat:
        this.ioi.spreadOverWhat === ''
          ? ''
          : getSpread(this.ioi.spreadOverWhat),
      enterCoupon: this.ioi.enterCoupon,
      indicativeCoupon: this.ioi.indicativeCoupon,
      rankingGuarantee: getRankingGuarantee(this.ioi.rankingGuarantee),
      rankingType: getRankingType(this.ioi.rankingType),
      indicativeType: this.ioi.indicativeType,
      documentationForm: this.ioi.documentationForm,
      dayCount: getDayCount(this.ioi.dayCount),
      additionalInformation: this.ioi.additionalInformation,
      creditRatingS:
        this.ioi.creditRatingS === '' ? '' : this.ioi.creditRatingS,
      creditRatingF:
        this.ioi.creditRatingF === '' ? '' : this.ioi.creditRatingF,
      creditRatingM:
        this.ioi.creditRatingM === '' ? '' : this.ioi.creditRatingM,
      contactName: this.ioi.contactName,
    };
  }

  created() {
    this.dutchStateIndustry = isDutchStateIndustry(
      this.formattedIOI.industryOrSectorCode,
    );
    this.inputSize.value = this.formattedIOI.issueNominalSize;
    this.inputSize.id = `issueNominalSize_${this.formattedIOI.issueNominalSize}`;
    if (this.formattedIOI.minimumPiece) {
      this.showMinimumPiece = true;
      this.inputMinimumPiece.value = this.formattedIOI.minimumPiece;
      this.inputMinimumPiece.id = `issueNominalSize_${this.formattedIOI.minimumPiece}`;
    } else {
      this.showMinimumPiece = false;
    }
    this.inputCoupon.value = this.formattedIOI.indicativeCoupon
      ? this.formattedIOI.indicativeCoupon
      : '';
    this.inputCoupon.id = `issueNominalSize_${this.formattedIOI.indicativeCoupon}`;

    EventBus.$on('getpdfdata', (ioiId: string) => {
      if (ioiId === this.formattedIOI.indicationOfInterestId) {
        EventBus.$emit('sendPdfData', this.formattedIOI);
      }
    });
  }
}
