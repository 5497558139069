































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import htmlNL from '@/translation/faqNL.md';
import htmlEN from '@/translation/faqEN.md';
import TextSlider from '@/components/utils/TextSlider.vue';
import ExpandElement from '@/components/utils/ExpandElement.vue';
import { namespace } from 'vuex-class';
import { faqItem } from '@/types/types';
import ContactLeadManager from '@/components/utils/ContactLeadManager.vue';

const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'FaqPage',
  components: { ContactLeadManager, ExpandElement, TextSlider },
})
export default class FaqPage extends Vue {
  @AuthenticationNameSpace.Getter('language') language: string;

  htmlNL = htmlNL;
  htmlEN = htmlEN;
  faqItems: faqItem[] = [];
  faqTitles: { id: string; text: string }[] = [];

  @Watch('language')
  formatPage() {
    const html = this.language === 'nl' ? this.htmlNL : this.htmlEN;
    this.faqTitles = [];
    this.faqItems = html
      .split('<h4>end section</h4>')
      .map((htmlItem: any, index: number) => {
        let faqItemMap: faqItem = {
          expanded: false,
          type: 'question',
          html: [],
          text: '',
        };
        if (htmlItem.indexOf('<h3>') > 0) {
          faqItemMap.html = htmlItem.split('</h3>');
          faqItemMap.html[0] = faqItemMap.html[0].replace('<h3>', '');
          // needed later
          // this.faqTitles.push({ id: `h3-${index}`, text: faqItem.html[0] });
        } else {
          faqItemMap.type = 'title';
          faqItemMap.text = htmlItem.replace('<h2>', '').replace('</h2>', '');
          this.faqTitles.push({ id: `h2-${index}`, text: faqItemMap.text });
        }
        return faqItemMap;
      });
  }

  mounted() {
    this.formatPage();
  }
}
