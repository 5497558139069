var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('v-client-table',{ref:"userTable",staticClass:"blue-table",attrs:{"data":_vm.tableData,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"accountManagerType",fn:function(ref){
var row = ref.row;
return (row.accountManagerType)?_c('div',{staticClass:"VueTables__button-container"},[_vm._v(" "+_vm._s(_vm.$t(("components.linkedCompanies." + (row.accountManagerType))))+" ")]):_vm._e()}},{key:"action",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"VueTables__button-container"},[(row.accountManagerStatus === 1)?_c('button',{staticClass:"button-icon",on:{"click":function($event){return _vm.openConfirmLeave(row)}}},[_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.$t('components.linkedCompanies.leaveButton')))])]):_vm._e(),(row.accountManagerStatus === 0)?_c('button',{staticClass:"button-icon",on:{"click":function($event){return _vm.acceptInvitation(row)}}},[_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.$t('components.linkedCompanies.acceptButton')))])]):_vm._e(),(row.accountManagerStatus === 0)?_c('button',{staticClass:"button-icon",on:{"click":function($event){return _vm.denyInvitation(row)}}},[_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.$t('components.linkedCompanies.denyButton')))])]):_vm._e()])}},{key:"updateStatus",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('status-indicator',{attrs:{"status":row.updateStatus,"size":"small"}})],1)}}],null,true)}),(_vm.gettingUsers)?_c('loading-indicator',{key:"rIndicatorUsers",attrs:{"no-space":true,"size":"small"}}):_vm._e(),_c('confirm',{attrs:{"show-modal-param":true,"alt-modal-key":"confirmLeave","content-param":_vm.tableData[_vm.changedRow].accountType
        ? {
            title: ("" + (_vm.$t('components.linkedCompanies.modalLeaving'))),
            body: ("" + (_vm.$t('components.linkedCompanies.modalBody', {
              company: _vm.tableData[_vm.changedRow].companyName,
              role: _vm.$t(
                ("components.linkedCompanies." + (_vm.tableData[_vm.changedRow].accountType))
              ),
            }))),
            confirmButton: ("" + (_vm.$t(
              'components.linkedCompanies.modalButtonLeave'
            ))),
            cancelButton: ("" + (_vm.$t(
              'components.linkedCompanies.modalButtonCancel'
            ))),
          }
        : {}},on:{"onConfirm":_vm.confirmLeave}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }