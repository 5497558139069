





















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DealExamples from '@/components/landing/DealExamples.vue';

@Component({
  name: 'LandingHeader',
  components: { DealExamples },
})
export default class LandingHeader extends Vue {}
