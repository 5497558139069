





















import Vue from 'vue';
import { isEmpty } from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import { SimpleSVG } from 'vue-simple-svg';
import { ButtonObject } from '../inputs/inputTypes';

@Component({
  name: 'ButtonComponent',
})
export default class ButtonComponent extends Vue {
  @Prop({}) buttonParam!: ButtonObject;
  @Prop({}) dataTestId!: string;

  get containerClass(): string | null {
    return isEmpty(this.buttonParam.containerClass)
      ? ''
      : this.buttonParam.containerClass;
  }

  get buttonClass(): any {
    return [
      isEmpty(this.buttonParam.buttonClass)
        ? 'button-icon'
        : this.buttonParam.buttonClass,
      this.buttonParam.disabled ? 'button-disabled' : '',
    ];
  }

  get showIcon(): boolean {
    return !isEmpty(this.buttonParam.icon);
  }

  get iconPath(): string | null {
    return isEmpty(this.buttonParam.icon)
      ? ''
      : `/images/${this.buttonParam.icon}`;
  }

  get buildTooltip(): any {
    let content: string | null = this.buttonParam.toolTip;

    if (
      isEmpty(this.buttonParam.toolTip) &&
      isEmpty(this.buttonParam.extendedDescription)
    ) {
      content = this.buttonParam.description;
    }

    if (isEmpty(this.buttonParam.toolTip)) {
      content = this.buttonParam.extendedDescription;
    }

    return { placement: 'bottom-center', content };
  }

  get buttonLabel(): string {
    if (
      this.buttonParam.showExtended &&
      this.buttonParam.extendedDescription !== undefined &&
      this.buttonParam.extendedDescription !== null
    ) {
      return this.buttonParam.extendedDescription;
    }
    return this.buttonParam.description;
  }

  onClickSubmit(): any {
    this.$emit('onClickSubmit');
  }
}
