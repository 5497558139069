import { isEmpty } from 'lodash';
import api from '@/api/index';

export function getApiError(error: any): string {
  // this method gets the error message from the structure returned by the backend
  // if there is no error at the response, then it returns the http error
  let errorMessage: string = '';
  if (
    !isEmpty(error) &&
    !isEmpty(error.response) &&
    !isEmpty(error.response.data)
  ) {
    errorMessage = error.response.data.message;
  } else {
    errorMessage = error;
  }

  return errorMessage;
}

export function urlBase64ToUint8Array(base64String: any) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Detect Internet Explorer
function detectIE() {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  return false;
}

export function sendContactForm(payload: {
  firstName: string;
  email: string;
  companyName: string;
  message: string;
}) {
  return api.post('contact', payload);
}
