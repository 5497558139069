






























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const SignupNameSpace = namespace('signup/');

@Component({
  name: 'FinalizeSignup',
  components: {},
})
export default class FinalizeSignup extends Vue {
  @SignupNameSpace.Getter('finalStep') finalStep: any;
  @SignupNameSpace.Getter('upgrading') upgrading: any;
  @SignupNameSpace.Getter('inviting') inviting: any;
}
