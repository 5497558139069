






import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';

const ModalNameSpace = namespace('modals/');

@Component({
  name: 'ShareEmail',
})
export default class ShareEmail extends Vue {
  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  @Prop({}) modalKey!: string;
}
