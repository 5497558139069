




import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BaseMailTo',
})
export default class BaseMailTo extends Vue {
  @Prop({}) email!: string;

  get mailTo(): string {
    return `mailto:${this.email}`;
  }
}
