const URL_TO_DEFAULT_ICON = '/images/logo.png';
const EventTypes = {
  IOI_CREATED: 'IndicationOfInterestWasCreated',
  IOI_WITHDRAWN: 'IndicationOfInterestWasWithdrawn',
  IOI_EXPIRED: 'IndicationOfInterestExpired',
  IOI_EDITED: 'IndicationOfInterestWasEdited',
  IOI_CLOSED: 'IndicationOfInterestWasClosed',
};
self.addEventListener('activate', () => {
  // This will be called only once when the service worker is activated.
});

self.addEventListener('push', (event) => {
  if (event.data) {
    const channel = new BroadcastChannel('sw-messages');
    const eventData = JSON.parse(event.data.text());
    channel.postMessage({
      data: eventData,
    });
    let notificationTitle = '';
    if (eventData.type === EventTypes.IOI_CREATED) notificationTitle = 'A new IOI was created';
    else if (eventData.type === EventTypes.IOI_WITHDRAWN) notificationTitle = 'An IOI was removed';
    else if (eventData.type === EventTypes.IOI_EDITED) notificationTitle = 'An IOI was updated';
    else if (eventData.type === EventTypes.IOI_EXPIRED) notificationTitle = 'An IOI has expired';
    else if (eventData.type === EventTypes.IOI_CLOSED) notificationTitle = 'An IOI was closed';
  }
});
