























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ClientTable } from 'vue-tables-2';
import InputSelectComponent from '@/components/inputs/InputSelectComponent.vue';
import Confirm from '@/components/utils/Confirm.vue';
import { EventBus } from '@/main';
import StatusIndicator from '@/components/utils/statusIndicator.vue';
import { CompanyItem } from '@/types/types';
import api from '@/api';
import { setstatusLabel } from '@/util/companyUtils';
import LoadingIndicator from '@/components/utils/LoadingIndicator.vue';
import {
  leaveCompany,
  refuseAccountManagerInvite,
  acceptAccountManagerInvite,
} from '@/api/companyApi';
const AuthenticationNameSpace = namespace('authentication/');
const CompanyStore = namespace('companies/');
const ModalNameSpace = namespace('modals/');
@Component({
  name: 'LinkedCompanies',
  components: {
    LoadingIndicator,
    StatusIndicator,
    Confirm,
    InputSelectComponent,
    ClientTable,
  },
})
export default class LinkedCompanies extends Vue {
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  @CompanyStore.Action('getCompanyList') getCompanyList: any;
  @CompanyStore.Getter('companyListGetter') companyListGetter: CompanyItem[];
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  columns = [
    'companyName',
    'accountManagerStatusString',
    'accountManagerType',
    'action',
    'updateStatus',
  ];

  changedRow: number = 0;
  eventBus = EventBus;
  gettingUsers: boolean = false;

  tableData: CompanyItem[] = [
    {
      companyName: '',
      accountManagerStatusString: '',
      accountManagerType: '',
      updateStatus: '',
      accountType: '',
      status: '',
      companyId: '',
      countryCode: '',
    },
  ];

  get options() {
    return {
      filterable: false,
      headings: {
        companyName: this.$t('components.linkedCompanies.tableCompanyName'),
        accountManagerStatusString: this.$t(
          'components.linkedCompanies.tableStatus',
        ),
        action: this.$t('components.linkedCompanies.tableAction'),
        accountManagerType: this.$t('components.linkedCompanies.tableType'),
        updateStatus: this.$t('components.linkedCompanies.tableSync'),
      },
      uniqueId: 'companyId',
      sortable: ['companyName', 'status'],
    };
  }

  created() {
    this.gettingUsers = true;
    this.getCompanyList('linked').then(() => {
      if (this.companyListGetter.length > 0) {
        this.tableData = this.companyListGetter.map((company: CompanyItem) => {
          if (company.accountManagerStatus !== undefined) {
            company.accountManagerStatusString = setstatusLabel(
              company.accountManagerStatus,
            );
          }
          return company as CompanyItem;
        });
        this.gettingUsers = false;
      }
    });
  }

  acceptInvitation(row: any) {
    this.changedRow = this.tableData.findIndex(
      tRow => tRow.companyId === row.companyId,
    );
    this.tableData[this.changedRow].updateStatus = 'loading';
    acceptAccountManagerInvite({
      companyId: this.tableData[this.changedRow].companyId,
      userAccountId: this.getUserAccountId,
    }).then(() => {
      Vue.set(this.tableData, this.changedRow, {
        ...this.tableData[this.changedRow],
        accountManagerStatus: 1,
        accountManagerStatusString: setstatusLabel(1),
        updateStatus: 'completed',
      });
    });
  }

  denyInvitation(row: any) {
    this.changedRow = this.tableData.findIndex(
      tRow => tRow.companyId === row.companyId,
    );
    this.tableData[this.changedRow].updateStatus = 'loading';
    refuseAccountManagerInvite({
      companyId: this.tableData[this.changedRow].companyId,
      userAccountId: this.getUserAccountId,
    }).then(() => {
      Vue.set(this.tableData, this.changedRow, {
        ...this.tableData[this.changedRow],
        accountManagerStatus: 2,
        accountManagerStatusString: setstatusLabel(2),
        updateStatus: 'completed',
      });
    });
  }

  confirmLeave() {
    leaveCompany({
      companyId: this.tableData[this.changedRow].companyId,
      userAccountId: this.getUserAccountId,
    }).then(() => {
      this.tableData = this.tableData.filter(
        data => data.companyId !== this.tableData[this.changedRow].companyId,
      );
      this.changedRow = 0;
    });
  }

  openConfirmLeave(row: any) {
    this.changedRow = this.tableData.findIndex(
      tRow => tRow.companyId === row.companyId,
    );
    this.toggleModal('confirmLeave');
  }
}
