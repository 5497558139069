import { EncryptedStorage } from '@/security/crypto';

// ****************************************
// Notification Module
// ****************************************
// Notification state
export interface NotificationState {
  notifications: Notification[];
  nextNotification: number;
}

// Notification model
export interface Notification {
  id?: number;
  message: string;
  type: string;
  labelToTranslate?: string;
  label?: string;
}

// ****************************************
//  ProgressBar Module
// ****************************************
// ProgressBar state
export interface ProgressBarState {
  progressBar: ProgressBar;
}

// Progress Bar model
export interface ProgressBar {
  type: string;
  totalSteps: number;
  currentStep: number;
  percentage: number;
  title: string;
}

// ****************************************
// Authentication Module
// ****************************************
// Authentication state
export interface AuthenticationState {
  token: Token;
  redirectToPage: {
    redirect: boolean;
    name: string;
    query: any;
    params: any;
  };
  loginStatus: {
    notApproved: boolean;
    emailConfirmationSent: boolean;
    emailConfirmationNeeded: boolean;
  };
  encryptedLocalStorage: EncryptedStorage;
  encryptedSessionStorage: EncryptedStorage;
  timeout: number;
  tokenExpired: boolean;
  language: string;
  windowWidth: number;
  serverDatetime: number;
  computerDatetime: number;
}

// authentication model
export interface Token {
  userAccountId: string; // user's account id
  accountStatus: number; // user's account status
  clientKey: string; // user's security key
  exp: number; // UNIX timestamp for the token expiration date
  username: string;
  roles: string[];
  email: '';
  isLoggedIn: boolean;
  name: string;
}

// ****************************************
// Modal Module
// ****************************************
// Modal state
export interface ModalState {
  modals: ModalList;
}

export interface ModalList {
  [key: string]: ModalObject;
}

export interface ModalObject {
  active: boolean;
  key: string;
}

// ***************************************
// Sort and Filter Module
// ****************************************
// Filter state
export interface FilterState {
  ioiFilters: Filter[];
}

// Filter Model
export interface Filter {
  key: string;
  values: string[];
}

// Order Model
export interface Order {
  key: string;
  direction: Direction;
}

enum Direction {
  asc = 'asc',
  desc = 'desc',
}

/** **************************************
 Signup Module
 **************************************** */
// Modal state
export interface SignupState {
  step: number;
  accountType: string;
  flowType: string;
  accountTypeFormatted: string;
  finalStep: boolean;
  lastFormStep: boolean;
  uid: string;
  formAnswersOne: {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    companyEmailRepeat: string;
    passwordRepeat: string;
  };
  upgradeAnswersOne: {
    street: string;
    houseNumber: string;
    houseNumberSuffix: string;
    postalCode: string;
    city: string;
    countryCode: string;
  };
  formAnswersOneMV: {
    companyName: string;
    industryOrSector: string;
    companyType: number | null;
    terms: string;
  };
  formAnswersTwo: {
    street: string;
    houseNumber: string;
    houseNumberSuffix: string;
    postalCode: string;
    city: string;
    countryCode: string;
    companyName: string;
    industryOrSector: string;
    kvkFile: string;
    leiNumber: string;
    identificationFile: string;
    terms: string;
  };
  upgradeAnswersTwo: {
    kvkFile: string;
    leiNumber: string;
    identificationFile: string;
    terms: string;
  };
  formAnswersOneInvitation: {
    email: string;
    companyEmailRepeat: string;
  };
  formAnswersTwoInvitation: {
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    password: string;
    passwordRepeat: string;
    terms: string;
  };
}

// ****************************************
// Event Module
// ****************************************
// Event state
export interface EventState {
  myEvents: EventAc[];
  unreadEvents: number;
  eventManagement: boolean;
}

// Event Model
export interface EventAc {
  eventTitle: string;
  notificationId: string;
  type: string;
  read: boolean;
  payload: {
    IoiReferenceNumber: string;
    couponPercentage: number;
    offerAuthorUserName: string;
    offerForCompany: string;
    offerFromCompanyName: string;
    offerFromUser: string;
    offerId: string;
    offerMaxSize: number;
    offerMinSize: number;
    offerTo: string;
    offerToCompanyName: string;
    referenceNumber: string;
    spreadBasisPoints: string;
    spreadOverWhat: string;
    bucketSize: number;
    currencyType: string;
    indicationOfInterestId: string;
    industryOrSector: string;
    instrumentTypes: string[];
    maturityBucket: string;
    rankingGuarantee: string;
    rankingType: string;
  };
  occurredAt: number;
  occurredAtDay?: string;
  occuredAtTime?: string;
  description?: string;
}

export interface EventPayload {
  notificationIds: string[];
}
// ****************************************
// actionCenter Module
// ****************************************
// actionCenter state
export interface actionCenterState {
  events: EventAc[];
  wsActive: boolean;
  wsEvents: any;
}

// ****************************************
// Companies Module
// ****************************************
// Event state
export interface CompaniesState {
  companyList: CompanyItem[];
  roleList: string[];
  cache: {
    [key: string]: object;
    behalfOff: {
      value: string;
      label: string;
    };
    companySettings: {
      value: string;
      label: string;
    };
    companyName: {
      value: string;
      label: string;
    };
  };
  selectedCompanyId: string;
  noVotingCompanies: string[];
}

export interface CompanyItem {
  accountType: string;
  status: string;
  companyId: string;
  companyName: string;
  countryCode: string;
  updateStatus?: string;
  city?: string;
  country?: {
    value: string;
    label: string;
  };
  houseNumber?: number;
  houseNumberSuffix?: string;
  industryOrSector?: {
    value: string;
    label: string;
  };
  leiNumber?: string;
  name?: string;
  postalCode?: string;
  street?: string;
  type?: number;
  accountManagerStatus?: number;
  accountManagerStatusString?: string;
  accountManagerType?: string;
  inviteAccepted?: boolean;
}

export interface UserItem {
  userAccountId: string;
  accountStatusString: string;
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  locale: string;
  accountStatus: number;
  accountConfirmation: string;
  email: string;
  accountManagerStatus: number;
  accountType: { value: string; label: string };
  updateStatus?: string;
  role?: string;
  roleId?: string;
  permissions: string[];
}

// ****************************************
// TabState Module
// ****************************************
// TabState state
export interface TabState {
  tabs: TabList;
}

export interface TabList {
  [key: string]: number;
}

export interface TabObject {
  value: number;
  key: string;
}

// faq

export interface faqItem {
  expanded: boolean;
  type: string;
  html: string[];
  text: string;
}

// ****************************************
// Companies Module
// ****************************************
export interface roleObject {
  updateStatus?: string;
  companyId: string;
  id: string;
  name: string;
  permissions: {
    can_read_deals?: boolean;
    can_make_offer?: boolean;
    can_edit_offer?: boolean;
    can_accept_offer?: boolean;
    can_withdraw_offer?: boolean;
    can_invite_account_manager?: boolean;
    can_cancel_invite_for_account_manager?: boolean;
    can_remove_account_manager?: boolean;
    read_company_details?: boolean;
    read_company_account_managers?: boolean;
    update_company_details?: boolean;
    manage_company_permissions?: boolean;
    can_create_ioi?: boolean;
    can_update_ioi?: boolean;
    can_withdraw_ioi?: boolean;
    can_remove_ioi?: boolean;
    can_send_ioi_to_market?: boolean;
    can_approve_multieye?: boolean;
  };
}

export enum Permission {
  can_read_deals = 'can_read_deals',
  can_make_offer = 'can_make_offer',
  can_edit_offer = 'can_edit_offer',
  can_accept_offer = 'can_accept_offer',
  can_withdraw_offer = 'can_withdraw_offer',
  can_invite_account_manager = 'can_invite_account_manager',
  can_cancel_invite_for_account_manager = 'can_cancel_invite_for_account_manager',
  can_remove_account_manager = 'can_remove_account_manager',
  read_company_details = 'read_company_details',
  read_company_account_managers = 'read_company_account_managers',
  update_company_details = 'update_company_details',
  manage_company_permissions = 'manage_company_permissions',
  can_create_ioi = 'can_create_ioi',
  can_update_ioi = 'can_update_ioi',
  can_withdraw_ioi = 'can_withdraw_ioi',
  can_remove_ioi = 'can_remove_ioi',
  can_send_ioi_to_market = 'can_send_ioi_to_market',
  can_approve_multieye = 'can_approve_multieye',
}

export enum PermissionInvestor {
  can_read_deals = 'can_read_deals',
  can_make_offer = 'can_make_offer',
  can_edit_offer = 'can_edit_offer',
  can_withdraw_offer = 'can_withdraw_offer',
  read_company_account_managers = 'read_company_account_managers',
  can_approve_multieye = 'can_approve_multieye',
}

export enum PermissionIssuer {
  can_read_deals = 'can_read_deals',
  read_company_account_managers = 'read_company_account_managers',
  can_create_ioi = 'can_create_ioi',
  can_update_ioi = 'can_update_ioi',
  can_withdraw_ioi = 'can_withdraw_ioi',
  can_remove_ioi = 'can_remove_ioi',
  can_send_ioi_to_market = 'can_send_ioi_to_market',
  can_accept_offer = 'can_accept_offer',
}

export interface CompanySettingsBody {
  settingKey: string;
  value: {
    rule: string;
    params: {
      create: number;
      update: number;
      withdraw: number;
    };
  };
}

export interface ApprovalEvent {
  author: string;
  authorName: string;
  createdAt: {
    date: string;
    timezone: string;
    timezone_type: number;
  };

  data: string;
  eventType: string;
  flowId: string;
  id: string;
}

export interface ApprovalData {
  events: ApprovalEvent[];
  offerId: string;
  action: string;
  createdAt: {
    date: string;
    timezone: string;
    timezone_type: number;
  };

  id: string;
  instrumentId: string;
  instrumentType: string;
  status: string;
  updatedAt: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
}

export interface CompanySettings {
  id: string;
  companyId: string;
  settingKey: string;
  value: {
    rule: 'simple-rule';
    params: {
      create: number;
      update: number;
      withdraw: number;
    };
  };
  createdAt: string;
  updatedAt: string;
}
