



































































































































































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IOI, FilterStateIOI } from '@/types/instrumentsTypes';
import InputRadioComponent from '../../components/inputs/InputRadioComponent.vue';
import { CustomInput } from '@/components/inputs/inputTypes';
import IoiRow from '../../components/ioi/IOIRow.vue';
import {
  noOffersExpiring,
  offersExpiring,
  smallColumnClass,
} from '@/views/myAccounts/myAccounts';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import confirm from '../../components/utils/Confirm.vue';
import IoiConfirm from '../../components/ioi/IOIConfirm.vue';
import CountDown from '../../components/utils/CountDown.vue';
import desktopSizes from '@/assets/screen-sizes';
import { EventBus } from '@/main';
import AcceptOffers from '../../components/ioi/AcceptOffers.vue';
import ViewDeals from '../../components/ioi/ViewDeals.vue';
import IoiRowDayColumn from '../../components/ioi/IOIRowDayColumn.vue';
import IoiRowMaturityColumn from '../../components/ioi/IOIRowMaturityColumn.vue';
import EmailInputs from '@/components/ioi/EmailInputs.vue';
import ModalWindow from '@/components/utils/Modal.vue';
import IoiSort from '../../components/ioi/IOISort.vue';
import NoDataAvailable from '@/components/utils/NoDataAvailable.vue';
import IRPForm from '@/views/irp/IRPForm.vue';

const ModalNameSpace = namespace('modals/');
const IOINameSpace = namespace('iois/');
const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');

@Component({
  name: 'MyAccountLeadManager',
  components: {
    IRPForm,
    AcceptOffers,
    ViewDeals,
    InputRadioComponent,
    IoiRow,
    ButtonComponent,
    confirm,
    IoiConfirm,
    CountDown,
    IoiRowDayColumn,
    IoiRowMaturityColumn,
    ModalWindow,
    EmailInputs,
    IoiSort,
    NoDataAvailable,
  },
})
export default class MyAccountLeadManager extends Vue {
  // Indication of Interest store
  @IOINameSpace.Getter('filteredIOIs') filteredIOIs: IOI[];
  @IOINameSpace.Getter('allIOIs') iois: IOI[];
  @IOINameSpace.Getter('ioiWithOffers') ioiWithOffers: IOI[];
  @IOINameSpace.Getter('matchedIOIs') matchedIOIs: IOI[];
  @IOINameSpace.Getter('offTheMarketIOIs') offTheMarketIOIs: IOI[];
  @IOINameSpace.Getter('draftIOIs') draftIOIs: IOI[];
  @IOINameSpace.Action('getIOIs') getIOIs: any;
  @IOINameSpace.Action('withdrawIOI') withdrawIOI: any;
  @IOINameSpace.Action('copyIOI') copyIOI: any;
  @IOINameSpace.Action('deleteIOI') deleteIOI: any;
  @IOINameSpace.Action('sendToMarketIOI') sendToMarketIOI: any;

  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @AuthenticationNameSpace.Action('signUpFullUser') signUpFullUser: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // modal
  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  ioiSmallColumnClass = smallColumnClass;
  ioiNoOffersExpiring = noOffersExpiring;
  ioiOffersExpiring = offersExpiring;

  eventBus = EventBus;
  inputSelectOffer: string | null = '';
  myAccount = this.getUserAccountId;
  currentTab: string = '1';
  windowSize: number = 0;
  showExtended: boolean = true;
  screenMediumBig: number = desktopSizes.mediumBig;
  showDaysColumn: boolean = true;
  showConfirmWithdrawModal: boolean = false;
  activeIoI: string = '';
  confirmWithdrawData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  showConfirmUpdateModal: boolean = false;
  confirmUpdateData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  showConfirmDeleteDraftModal: boolean = false;
  confirmDeleteDraftData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  showConfirmSendToMarketModal: boolean = false;
  confirmSendToMarketData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  displayModal: any = { showModal: false, ioi: {} };
  showAcceptOfferModal: any = {
    showModal: false,
    ioi: {},
  };

  showViewDealsModal: any = {
    showModal: false,
    ioiId: '',
    ioi: {},
  };

  showFilterFormModal: any = {
    showModal: false,
    tab: '',
    filters: {},
    myAccount: '',
  };

  get createIOIButton(): any {
    return {
      containerClass: '',
      buttonClass: 'button-icon',
      description: this.$t('views.myLMAccount.createIOIButton'),
      extendedDescription: this.$t('views.myLMAccount.createIOIButton'),
      icon: 'create-blue.png',
      showExtended: this.showExtended,
    };
  }

  createIOI(): void {
    this.$router.push({ name: 'ioi', params: { ioiId: '0' } });
  }

  // Edit IOI events
  get editIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.myIssuerAccount.update'),
      extendedDescription: this.$t('views.myIssuerAccount.updateIOI'),
      icon: 'edit-blue.png',
      showExtended: this.showExtended,
    };
  }

  editIOICancellation(): void {
    this.confirmUpdateData.genericId = '';
    this.showConfirmUpdateModal = false;
  }

  editIOIConfirmation(ioiId: string, cntOffers: number): void {
    this.confirmUpdateData.genericId = ioiId;
    if (cntOffers === 0) {
      this.editIOISubmit();
    } else {
      this.showConfirmUpdateModal = true;
      this.confirmUpdateData.genericId = ioiId;
      this.confirmUpdateData.title = 'views.myIssuerAccount.updateIOITitle';
      this.confirmUpdateData.body = 'views.myIssuerAccount.updateIOIBody';
      this.confirmUpdateData.confirmButton =
        'views.myIssuerAccount.updateIOIConfirmButton';
      this.confirmUpdateData.cancelButton =
        'views.myIssuerAccount.updateIOICancelButton';
    }
  }

  editIOISubmit(): void {
    const ioiId: string = this.confirmUpdateData.genericId;
    this.$router.push({ name: 'ioi', params: { ioiId } });
  }

  // Withdraw IOI events
  get withdrawIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.myIssuerAccount.withdraw'),
      extendedDescription: this.$t('views.myIssuerAccount.withdrawIOI'),
      icon: 'remove-blue.png',
      showExtended: this.showExtended,
    };
  }

  withdrawIOIConfirmation(ioiId: string): void {
    this.showConfirmWithdrawModal = true;
    this.confirmWithdrawData.genericId = ioiId;
    this.confirmWithdrawData.title = 'views.myLMAccount.withdrawIOITitle';
    this.confirmWithdrawData.body = 'views.myLMAccount.withdrawIOIBody';
    this.confirmWithdrawData.confirmButton =
      'views.myLMAccount.withdrawIOIConfirmButton';
    this.confirmWithdrawData.cancelButton =
      'views.myLMAccount.withdrawIOICancelButton';
  }

  withdrawIOICancellation(): void {
    this.confirmWithdrawData.genericId = '';
    this.showConfirmWithdrawModal = false;
  }

  withdrawIOISubmit(): void {
    this.withdrawIOI(this.confirmWithdrawData.genericId).then(() => {
      this.confirmWithdrawData.genericId = '';
      this.showConfirmWithdrawModal = false;
      // get open IOIs
      this.changeTab('1');
    });
  }

  // Offers events
  setInputRadioValue(inputValue: CustomInput) {
    if (inputValue.name === 'selectOffer') {
      this.inputSelectOffer = inputValue.value;
    }
  }

  get acceptOfferButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.myLMAccount.accept'),
      extendedDescription: this.$t('views.myLMAccount.acceptOffer'),
      icon: 'currency-euro-blue.png',
      showExtended: this.showExtended,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  showAcceptOffer(ioi: IOI): boolean {
    if (ioi.showOffersToLeadManagers === undefined) {
      return ioi.state === 'closed';
    }
    return ioi.state === 'closed' || ioi.showOffersToLeadManagers;
  }

  acceptOfferSubmit(ioi: IOI): void {
    if (this.showAcceptOffer(ioi)) {
      this.showAcceptOfferModal.showModal = true;
      this.showAcceptOfferModal.ioi = ioi;
      this.showAcceptOfferModal.accountParam = this.myAccount;
      this.eventBus.$emit('reloadCreateAnOffer', this.showAcceptOfferModal);
    }
  }

  onSubmitOffer(): void {
    this.showAcceptOfferModal.showModal = false;
    this.showAcceptOfferModal.ioi = {};
    this.changeTab('2');
  }

  onCloseMakeOffer(): void {
    this.showAcceptOfferModal.showModal = false;
    this.showAcceptOfferModal.ioi = {};
    this.eventBus.$emit('reloadCreateAnOffer', this.showAcceptOfferModal);
  }

  displayIOI(ioi: any): void {
    this.displayModal.showModal = true;
    this.displayModal.ioi = ioi;
    this.eventBus.$emit('reloadConfirmIOI', this.displayModal);
  }

  onCloseIOI(): void {
    this.displayModal.showModal = false;
    this.displayModal.ioi = {};
    this.eventBus.$emit('reloadConfirmIOI', this.displayModal);
  }

  // Copy IOI events
  get copyIOIButton() {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass:
        'button-icon button-icon-grid button-icon-resize full-width-button',
      description: `${this.$t('views.myLMAccount.copyIOIButton')}`,
      extendedDescription: `${this.$t('views.myLMAccount.copyIOIButton')}`,
      icon: 'content-copy.svg',
      showExtended: this.showExtended,
    };
  }

  copyIOIData(ioiId: string): void {
    return this.copyIOI({
      indicationOfInterestId: ioiId,
      userAccountId: this.myAccount,
    });
  }

  // View Deals
  get viewIOIDealsButton() {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass:
        'button-icon button-icon-grid button-icon-resize full-width-button',
      description: `${this.$t('views.myLMAccount.viewIOIDealsButton')}`,
      extendedDescription: `${this.$t('views.myLMAccount.viewIOIDealsButton')}`,
      icon: 'deal.png',
      showExtended: this.showExtended,
    };
  }

  viewIOIDeals(ioi: any) {
    this.toggleModal('showDealsLm');
    this.showViewDealsModal.showModal = true;
    this.showViewDealsModal.ioiId = ioi.indicationOfInterestId;
    this.showViewDealsModal.ioi = ioi;
    this.eventBus.$emit('reloadViewDeals', this.showViewDealsModal);
  }

  onCloseViewDeals(): void {
    this.showViewDealsModal.showModal = false;
    this.showViewDealsModal.ioiId = '';
    this.showViewDealsModal.ioi = {};
    this.eventBus.$emit('reloadViewDeals', this.showViewDealsModal);
  }

  // Delete Draft IOI events
  get deleteIOIButton() {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: `${this.$t('views.myLMAccount.deleteIOIButton')}`,
      extendedDescription: `${this.$t('views.myLMAccount.deleteIOIButton')}`,
      icon: 'remove-blue.png',
      showExtended: this.showExtended,
    };
  }

  deleteIOIConfirmation(ioiId: string): void {
    this.showConfirmDeleteDraftModal = true;
    this.confirmDeleteDraftData.genericId = ioiId;
    this.confirmDeleteDraftData.title = 'views.myLMAccount.deleteIOITitle';
    this.confirmDeleteDraftData.body = 'views.myLMAccount.deleteIOIBody';
    this.confirmDeleteDraftData.confirmButton =
      'views.myLMAccount.deleteIOIConfirmButton';
    this.confirmDeleteDraftData.cancelButton =
      'views.myLMAccount.deleteIOICancelButton';
  }

  deleteIOICancellation(): void {
    this.confirmDeleteDraftData.genericId = '';
    this.showConfirmDeleteDraftModal = false;
  }

  deleteIOISubmit(): void {
    this.deleteIOI(this.confirmDeleteDraftData.genericId).then(() => {
      this.confirmDeleteDraftData.genericId = '';
      this.showConfirmDeleteDraftModal = false;
    });
  }

  // Send to market events
  get sendToMarketIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass:
        'button-icon button-icon-grid button-icon-resize full-width-button',
      description: this.$t('views.myIssuerAccount.sendToMarketButton'),
      extendedDescription: this.$t('views.myIssuerAccount.sendToMarketButton'),
      icon: 'send-to-market-blue.png',
      showExtended: this.showExtended,
    };
  }

  sendToMarketIOIConfirmation(ioiId: string): void {
    const stringStart: string = 'views.myIssuerAccount';
    this.showConfirmSendToMarketModal = true;
    this.confirmSendToMarketData.genericId = ioiId;
    this.confirmSendToMarketData.title = `${stringStart}.sendToMarketIOITitle`;
    this.confirmSendToMarketData.body = `${stringStart}.sendToMarketIOIBody`;
    this.confirmSendToMarketData.confirmButton = `${stringStart}.sendToMarketIOIConfirmButton`;
    this.confirmSendToMarketData.cancelButton = `${stringStart}.sendToMarketIOICancelButton`;
  }

  sendToMarketIOICancellation(): void {
    this.confirmSendToMarketData.genericId = '';
    this.showConfirmSendToMarketModal = false;
  }

  sendToMarketIOISubmit(): void {
    this.sendToMarketIOI(this.confirmSendToMarketData.genericId).then(() => {
      this.confirmSendToMarketData.genericId = '';
      this.showConfirmSendToMarketModal = false;
      this.changeTab('1');
    });
  }

  changeTab(tabId: string): void {
    this.currentTab = tabId;
    const ioiFilters: FilterStateIOI = {};
    if (this.isUserLoggedIn && this.isLeadManager) {
      if (this.currentTab === '1') {
        // get open IOIs
        ioiFilters.state = ['open', 'closing', 'closed'];
        this.getIOIs({
          userAccountId: this.myAccount,
          filters: ioiFilters,
          withOffers: 'true',
          state: 'outstanding',
        });
      } else if (this.currentTab === '2') {
        // get matched iois (iois with deals)
        ioiFilters.state = ['matched'];
        this.getIOIs({
          userAccountId: this.myAccount,
          filters: ioiFilters,
          withOffers: 'true',
          state: 'closed',
        });
      } else if (this.currentTab === '3') {
        // get expired iois
        ioiFilters.state = ['expired', 'withdrawn'];
        this.getIOIs({
          userAccountId: this.myAccount,
          filters: ioiFilters,
          withOffers: 'true',
          state: 'off-the-market',
        });
      } else if (this.currentTab === '4') {
        // get draft iois
        ioiFilters.state = ['draft'];
        this.getIOIs({
          userAccountId: this.myAccount,
          filters: ioiFilters,
          withOffers: 'true',
          state: 'draft',
        });
      }
    }
  }

  created() {
    // control if the user is logged in; if not, then the user will be sent to login page
    this.currentTab = this.$route.params.tabId;
    const accountErrorMessage: string = `${this.$t(
      'views.myLMAccount.accountErrorMessage',
    )}`;
    this.authenticateLogin(accountErrorMessage).then(() => {
      this.myAccount = this.getUserAccountId;
      if (this.currentTab === undefined || this.currentTab === null) {
        this.currentTab = '1';
      }
      this.changeTab(this.currentTab);
      this.windowSize = window.innerWidth;
      this.showExtended = this.windowSize >= desktopSizes.large;
      this.showDaysColumn = this.windowSize >= this.screenMediumBig;
    });
  }

  mounted() {
    // Window size
    window.addEventListener('resize', () => {
      this.windowSize = window.innerWidth;
      this.showExtended = this.windowSize >= desktopSizes.large;
      this.showDaysColumn = this.windowSize >= this.screenMediumBig;
    });
  }

  @Watch('windowSize')
  onWindowSizeChange(newSize: number) {
    this.showExtended = newSize >= desktopSizes.large;
    this.showDaysColumn = this.windowSize >= this.screenMediumBig;
  }

  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.windowSize = window.innerWidth;
    });
  }
}
