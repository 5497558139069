




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AfsExplained from '@/components/landing/AfsExplained.vue';
import LandingHeader from '@/components/landing/LandingHeader.vue';
import LandingInfo from '@/components/landing/LandingInfo.vue';
import DealExamples from '@/components/landing/DealExamples.vue';
import RoleColumns from '@/components/landing/RoleColumns.vue';
import DealExampleGraph from '@/components/landing/DealExampleGraph.vue';

@Component({
  name: 'LandingPage',
  components: {
    DealExampleGraph,
    AfsExplained,
    LandingHeader,
    LandingInfo,
    DealExamples,
    RoleColumns,
  },
})
export default class LandingPage extends Vue {
  @Prop({}) accountType!: string;
}
