









































import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import VueTouchKeyboard from 'vue-touch-keyboard';
import { Validator } from 'vee-validate';
import { CustomInput, InputObject, ReturnInput } from './inputTypes';
import {
  enterNumberLocale,
  formatNumberLocale,
  formatNumberPHP,
} from '@/util/digits';
import { EventBus } from '@/main';

@Component({
  name: 'InputComponent',
  components: { 'vue-touch-keyboard': VueTouchKeyboard.component },
})
export default class InputComponent extends Vue {
  @Prop({}) inputParam!: InputObject;
  @Prop({}) dataTestId!: string;
  @Prop({}) formClass?: string;
  @Inject('$validator') $validator!: Validator;

  vmodelValue: string | null = '';
  inputField: InputObject = {
    id: '',
    name: '',
    ref: '',
    type: 'text',
    placeHolder: '',
    class: '',
    vRule: '',
    vLabel: '',
    disabled: false,
    optionList: [],
    containerClass: '',
    value: '',
    hideVLabel: false,
    isValid: null,
    format: false,
    valueWithoutFormat: '',
    showLabel: false,
  };

  get inputName() {
    return isEmpty(this.inputField.name) ? ' ' : this.inputField.name;
  }

  get inputVRule(): any {
    if (this.inputField.vRule === null) {
      return ' ';
    }
    return this.inputField.vRule;
  }

  get required() {
    if (typeof this.inputVRule === 'string') {
      return this.inputVRule.indexOf('required') !== -1;
    }
    return this.inputVRule.required;
  }

  get inputType() {
    return isEmpty(this.inputField.type) ? 'text' : this.inputField.type;
  }

  get errorLabel() {
    if (
      isEmpty(this.inputField.vLabel) &&
      isEmpty(this.inputField.placeHolder)
    ) {
      return '';
    }
    if (isEmpty(this.inputField.vLabel) || this.inputField.vLabel === null) {
      return this.inputPlaceHolder;
    }
    return `${this.$t(this.inputField.vLabel)}`;
  }

  get inputPlaceHolder(): string {
    return isEmpty(this.inputField.placeHolder)
      ? ''
      : `${this.$t(this.inputField.placeHolder)}`;
  }

  get inputHasError(): boolean {
    return (
      this.$validator.errors.has(this.inputField.name) ||
      this.inputParam.isValid === false
    );
  }

  get isInputCorrect(): boolean {
    return (
      !!this.vmodelValue &&
      !this.$validator.errors.has(this.inputField.name) &&
      !(this.inputParam.isValid === false)
    );
  }

  get inputContainerClass(): string | null {
    return isEmpty(this.inputField.containerClass)
      ? 'input-noclass-container'
      : this.inputField.containerClass;
  }

  get customClass(): string[] {
    const classes: string[] = [];
    if (this.inputHasError) {
      classes.push('has-error');
    }
    if (this.isInputCorrect) {
      classes.push('correct');
    }
    if (this.inputField.class !== undefined && this.inputField.class !== null) {
      classes.push(this.inputField.class);
    }
    return classes;
  }

  get showVLabel(): boolean {
    return (
      !this.inputField.hideVLabel &&
      this.$validator.errors.has(this.inputField.name)
    );
  }

  get inputRef(): string {
    return !this.inputField.ref
      ? this.inputField.name
      : `${this.inputField.ref}`;
  }

  get inputDisabled(): boolean {
    return this.inputField.disabled !== undefined && this.inputField.disabled;
  }

  get inputTooltip(): any {
    if (this.inputField.defaultTooltip) {
      return { placement: 'bottom-center', content: this.inputPlaceHolder };
    }
    if (
      this.inputField.tooltip === undefined ||
      this.inputField.tooltip === null
    ) {
      return null;
    }
    const tooltipContent: string | null = `${this.$t(this.inputField.tooltip)}`;
    return tooltipContent === null
      ? null
      : { placement: 'bottom-center', content: tooltipContent };
  }

  inputChange() {
    if (this.inputField.format && !isEmpty(this.vmodelValue)) {
      this.vmodelValue = enterNumberLocale(this.vmodelValue, 3);
    }
    const returnInput: CustomInput = {
      name: this.inputField.name,
      label: this.inputField.name,
      value: this.vmodelValue ? this.vmodelValue : '',
      valueWithoutFormat: this.inputField.format
        ? formatNumberPHP(this.vmodelValue, 3)
        : '',
    };
    this.inputField.value = this.vmodelValue;
    this.inputField.valueWithoutFormat = returnInput.valueWithoutFormat;
    this.$emit('sendInput', returnInput);
  }

  inputUpdate() {
    const returnInput: ReturnInput = {
      name: this.inputField.name,
      value: this.vmodelValue ? this.vmodelValue : '',
    };
    this.$emit('input', returnInput);
  }

  created() {
    this.inputField = Object.assign({}, this.inputParam);
    this.vmodelValue = this.inputParam.value;
    EventBus.$on('reloadInput', (inputInformation: any) => {
      if (inputInformation.name.trim() === this.inputParam.name.trim()) {
        this.inputField = {
          id: inputInformation.id,
          name: inputInformation.name,
          ref: inputInformation.ref,
          value: this.vmodelValue,
          type: inputInformation.type,
          placeHolder: inputInformation.placeHolder,
          class: inputInformation.class,
          vRule: inputInformation.vRule,
          vLabel: inputInformation.vLabel,
          disabled: inputInformation.disabled,
          optionList: inputInformation.optionList,
          containerClass: inputInformation.containerClass,
          hideVLabel: inputInformation.hideVLabel,
          isValid: inputInformation.isValid,
          defaultTooltip: inputInformation.defaultTooltip,
          tooltip: inputInformation.tooltip,
          format: inputInformation.format === true,
        };
        if (this.inputField.format && !isEmpty(this.inputField.value)) {
          this.vmodelValue = formatNumberLocale(inputInformation.value, 3);
          this.inputField.value = this.vmodelValue;
        } else {
          this.vmodelValue = inputInformation.value;
        }
      }
    });
  }
}
