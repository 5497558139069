





















import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import router from '@/router';

const SignupNameSpace = namespace('signup/');
@Component({
  name: 'StepTracker',
  components: {},
})
export default class StepTracker extends Vue {
  // @Prop({}) steps:any;
  @SignupNameSpace.Getter('step') currentStep: number;
  @SignupNameSpace.Action('setStep') setStep: any;
  @SignupNameSpace.Getter('flowType') flowType: string;
  @SignupNameSpace.Getter('accountType') accountType: any;

  get steps() {
    const steps = [
      { step: 1, title: this.$t('components.stepTracker.step1') },
      { step: 2, title: this.$t('components.stepTracker.step2') },
      { step: 3, title: this.$t('components.stepTracker.stepFinal') },
    ];
    const stepsMarketviewer = [
      { step: 1, title: this.$t('components.stepTracker.step1') },
      { step: 2, title: this.$t('components.stepTracker.stepFinal') },
    ];
    const stepsUpgrade = [
      { step: 1, title: this.$t('components.stepTracker.step2') },
      { step: 2, title: this.$t('components.stepTracker.stepId') },
      { step: 3, title: this.$t('components.stepTracker.stepFinal') },
    ];
    const stepsInvitation = [
      { step: 1, title: this.$t('components.stepTracker.step2') },
      { step: 2, title: this.$t('components.stepTracker.step1') },
      { step: 3, title: this.$t('components.stepTracker.stepFinal') },
    ];
    if (this.flowType === 'upgrade') {
      return stepsUpgrade;
    }
    if (this.flowType === 'invitation') {
      return stepsInvitation;
    }
    return this.accountType === 'marketviewer' ? stepsMarketviewer : steps;
  }

  stepCondition(step: any) {
    let condition = '';
    if (step.step === this.currentStep) {
      condition = 'current';
    }
    if (step.step > this.currentStep) {
      condition = 'next';
    }
    if (step.step < this.currentStep) {
      condition = 'done';
    }
    return condition;
  }

  // eslint-disable-next-line class-methods-use-this
  route(step: any) {
    router.push({ name: 'signupnew', params: { step: step.step } });
  }
}
