










































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { PrismicAPIPromise } from '@/types/prismic';
import moment from 'moment';
import { getIOIByReference } from '@/api/instrumentApi';
import { buildIOI } from '@/store/iois';
import { IOI } from '@/types/instrumentsTypes';
import { namespace } from 'vuex-class';
import IOIRow from '@/components/ioi/IOIRow.vue';
import LoadingIndicator from '@/components/utils/LoadingIndicator.vue';

const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'BlogDetail',
  components: { LoadingIndicator, IOIRow },
})
export default class BlogDetail extends Vue {
  @Prop({}) documentId!: string;

  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  page: Array<any> = [];
  upcomingIois: IOI[] = [];
  recentIois: IOI[] = [];
  authors = [];
  contactPersons = [];

  get newsItems() {
    return this.page[0].data.body.filter(
      (slice: any) => slice.slice_type === 'news_',
    );
  }

  mounted() {
    this.getContent();
  }

  getContent() {
    this.$prismic.client
      .query(this.$prismic.Predicates.at('document.id', this.documentId))
      .then((response: PrismicAPIPromise) => {
        // response is the response object, response.results holds the documents
        this.page = response.results;
        this.getAuthors();
        this.getContacts();
        this.getUpcomingIois();
        this.getRecentIois();
      });
  }

  getAuthors() {
    let authorids = this.page[0].data.body.filter(
      (item: any) => item.slice_type === 'author',
    );
    if (authorids.length > 0) {
      authorids = authorids[0].items.map((item: any) => item.author.id);
    }

    this.$prismic.client
      .query(this.$prismic.Predicates.in('document.id', authorids))
      .then((response: any) => {
        // response is the response object, response.results holds the documents
        this.authors = response.results;
      });
  }

  getContacts() {
    let authorids = this.page[0].data.body.filter(
      (item: any) => item.slice_type === 'contact_persons',
    );
    if (authorids.length > 0) {
      authorids = authorids[0].items.map((item: any) => item.contact_person.id);
    }
    this.$prismic.client
      .query(this.$prismic.Predicates.in('document.id', authorids))
      .then((response: any) => {
        // response is the response object, response.results holds the documents
        this.contactPersons = response.results;
      });
  }

  getUpcomingIois() {
    const nowDate: string = moment()
      .utc()
      .format('YYYY-MM-DD');
    const todayUnix: number = moment(nowDate).unix();
    const ioiReferences: any[] = [];
    const ioiSlcies = this.page[0].data.body.filter(
      (item: any) => item.slice_type === 'ioi',
    );
    if (ioiSlcies.length > 0) {
      ioiSlcies[0].items.forEach((item: any) =>
        ioiReferences.push(item.ioi_reference),
      );
    }

    ioiReferences.forEach(reference =>
      getIOIByReference(reference).then(data => {
        this.upcomingIois.push(
          buildIOI(data.data, true, todayUnix, this.getUserAccountId),
        );
      }),
    );
  }

  getRecentIois() {
    const nowDate: string = moment()
      .utc()
      .format('YYYY-MM-DD');
    const todayUnix: number = moment(nowDate).unix();
    const ioiReferences: any[] = [];
    this.page[0].data.body
      .filter((item: any) => item.slice_type === 'recently_priced_ioi')[0]
      .items.forEach((item: any) => ioiReferences.push(item.ioi_reference));
    ioiReferences.forEach(reference =>
      getIOIByReference(reference).then(data => {
        this.recentIois.push(
          buildIOI(data.data, true, todayUnix, this.getUserAccountId),
        );
      }),
    );
  }

  printArticle() {
    window.print();
  }

  newTabSerializer(
    type: any,
    element: { data: { url: any } },
    content: any,
    children: any[],
  ) {
    switch (type) {
      case 'hyperlink':
        return `<a target="_blank" href="${element.data.url}">${children.join(
          '',
        )}</a>`;
      default:
        return null;
    }
  }
}
