









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Notification } from '@/types/types';

const NotificationsNameSpace = namespace('notifications/');

@Component({
  name: 'NotificationMessage',
})
export default class NotificationMessage extends Vue {
  @Prop(Notification) notification!: Notification;
  timeout = 0;

  @NotificationsNameSpace.Action('remove') removeNotification: any;

  mounted() {
    const timeOut = this.notification.type === 'success' ? 5000 : 10000;
    this.timeout = setTimeout(
      () => this.removeNotification(this.notification),
      timeOut,
    );
  }

  get notificationTypeClass() {
    return `notification-${this.notification.type}`;
  }

  removeCurrentNotification() {
    this.removeNotification(this.notification);
  }

  beforeDestroy() {
    clearTimeout(this.timeout);
  }
}
