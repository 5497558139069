
























import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import { isEmpty, forEach } from 'lodash';
import vSelect from 'vue-select';
import { Validator } from 'vee-validate';
import { CustomInput, InputMultiSelectObject, ReturnInput } from './inputTypes';
import { EventBus } from '@/main';
import { formatNumberLocale } from '@/util/digits';

@Component({
  name: 'InputSelectMultiComponent',
  components: { vSelect },
})
export default class InputSelectMultiComponent extends Vue {
  @Prop({}) inputParam!: InputMultiSelectObject;
  @Prop({ default: true }) validate?: boolean;
  @Prop({}) dataTestId!: string;
  @Inject('$validator') validator!: Validator;

  eventBus = EventBus;
  vmodelValue: any | null = {};
  selected: string = '';
  multiple: boolean = true;

  get vLabel() {
    if (
      isEmpty(this.inputParam.vLabel) &&
      isEmpty(this.inputParam.placeHolder)
    ) {
      return '';
    }
    return isEmpty(this.inputParam.vLabel)
      ? this.inputPlaceHolder
      : this.inputParam.vLabel;
  }

  get inputPlaceHolder(): string {
    return isEmpty(this.inputParam.placeHolder)
      ? ' '
      : `${this.$t(this.inputParam.placeHolder)}`;
  }

  get containerClass(): string[] {
    const classes: string[] = [];
    classes.push('form-select');
    if (this.isInputCorrect) {
      classes.push('select-correct');
    }
    if (this.inputHasError) {
      classes.push('select-has-error');
    }
    if (this.inputParam.disabled) {
      classes.push('select-disable-actions');
    }
    return classes;
  }

  get wrapperClass(): string[] {
    const classes: string[] = [];
    if (this.inputParam.value === undefined) {
      classes.push('select-border-empty');
    }
    if (this.inputHasError && this.validate) {
      classes.push('select-has-error-border');
    }
    if (this.isInputCorrect && this.validate) {
      classes.splice(classes.indexOf('dropdown-toggle'), 1);
      classes.push('select-correct-border');
    }
    if (
      this.inputParam.containerClass !== undefined &&
      this.inputParam.containerClass !== null
    ) {
      classes.push(this.inputParam.containerClass);
    }
    return classes;
  }

  get showVLabel(): boolean {
    return !this.inputParam.hideVLabel && this.inputHasError;
  }

  get optionList(): any {
    if (
      isEmpty(this.inputParam.optionList) ||
      this.inputParam.optionList === null ||
      this.inputParam.optionList.length === 0
    ) {
      return [{ value: ' ', label: '' }];
    }
    if (this.inputParam.translate === undefined || this.inputParam.translate) {
      const selectOptions: any = [];
      forEach(this.inputParam.optionList, (element: any) => {
        const label: string = `${this.$t(element.label)}`;
        selectOptions.push({ value: element.value, label: label.trim() });
      });
      return selectOptions;
    }

    return this.inputParam.optionList;
  }

  get isInputCorrect(): boolean {
    return (
      this.vmodelValue !== null &&
      this.vmodelValue !== undefined &&
      this.vmodelValue.value !== '' &&
      this.vmodelValue.length > 0 &&
      !this.$validator.errors.has(this.inputParam.name)
    );
  }

  get inputHasError(): boolean {
    return this.inputParam.value === undefined
      ? false
      : this.$validator.errors.has(this.inputParam.name);
  }

  get inputTooltip(): any {
    if (this.inputParam.defaultTooltip) {
      return { placement: 'bottom-center', content: this.inputPlaceHolder };
    }
    if (
      this.inputParam.tooltip === undefined ||
      this.inputParam.tooltip === null
    ) {
      return null;
    }

    const tooltipContent: string | null = `${this.$t(this.inputParam.tooltip)}`;
    return tooltipContent === null
      ? null
      : { placement: 'bottom-center', content: tooltipContent };
  }

  inputChange() {
    if (this.vmodelValue === null || this.vmodelValue === undefined) {
      const returnInput: ReturnInput = {
        name: this.inputParam.name,
        value: '',
      };
      this.$emit('sendInput', returnInput);
    } else {
      const returnInput: ReturnInput = {
        name: this.inputParam.name,
        value: this.vmodelValue,
      };
      this.$emit('sendInput', returnInput);
    }
  }

  created() {
    this.vmodelValue = this.inputParam.value ? this.inputParam.value : '';
    EventBus.$on('selectAll', (selectAllParameter: boolean) => {
      if (!selectAllParameter) {
        this.vmodelValue = this.optionList;
      }
      if (selectAllParameter) {
        this.vmodelValue = [];
      }
      this.inputChange();
    });
  }
}
