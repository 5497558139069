'use strict';

import VeeValidate from 'vee-validate';
import { isEmpty, replace } from 'lodash';
import { formatNumberPHP } from '@/util/digits';
import dictionary from '@/validations/dictionary';
import { calculateDays } from '@/util/ioiUtils';

// create new rule
const ecaInsuredPercentageRule = {
  // NOT BEING USED. Left as an example
  getMessage: (field: string) =>
    `${field} cant not be empty and has to be a number`,
  validate(value: string, showECA: any) {
    let localShowECA: boolean = false;
    if (typeof showECA === 'object') {
      [localShowECA] = showECA;
    } else if (typeof showECA === 'boolean') {
      localShowECA = showECA;
    }
    return Promise.resolve({
      valid:
        localShowECA === true && !isEmpty(value) && typeof value === 'number',
    });
  },
};

const phoneNumber = (value: {
  fullNumber: string | null;
  intlTelInput: any;
}) => {
  const { intlTelInput } = value;

  if (!intlTelInput) {
    return true;
  }

  return (
    intlTelInput.getNumber() === undefined ||
    intlTelInput.getNumber() === null ||
    String(intlTelInput.getNumber()).trim().length === 0 ||
    intlTelInput.isValidNumber()
  );
};

const phoneNumberRequired = (value: {
  fullNumber: string | null;
  intlTelInput: any;
}) => {
  const { fullNumber } = value;

  return (
    fullNumber !== undefined &&
    fullNumber !== null &&
    String(fullNumber).trim().length !== 0
  );
};

interface Range {
  min: string;
  max: string;
}

const isBetween = {
  validate(spread: any, limits: Range) {
    const spreadMax: number =
      typeof spread === 'string' ? spread : spread.value;
    return Number(limits.min) <= spreadMax && Number(limits.max) >= spreadMax;
  },
};

const isNumber = (value: string) => {
  const plainNumber = parseInt(value, 10);
  return !Number.isNaN(plainNumber);
};

const minNumberWithFormat = (numberWithFormat: number, newValues: any) => {
  const newNumber: string = formatNumberPHP(numberWithFormat, 3);
  return Number(newNumber) >= Number(newValues[0]);
};

const maxNumberWithFormat = (numberWithFormat: number, newValues: any) => {
  const newNumber: string = formatNumberPHP(numberWithFormat, 3);
  return Number(newNumber) <= Number(newValues[0]);
};

const minimumPieceValidation = (minimumPiece: string) => {
  const newMinimumPiece: string = formatNumberPHP(minimumPiece, 3);
  return (
    isNumber(newMinimumPiece) &&
    (Number(newMinimumPiece) === 0 || Number(newMinimumPiece) >= 100000)
  );
};

const minimumMaturity = (
  value: string,
  { settlementDate }: { settlementDate: string },
) => {
  const daysBetween = calculateDays(value, settlementDate);
  return daysBetween > 29;
};

const paramNames = ['min', 'max'];

export default function initializeValidator() {
  VeeValidate.Validator.extend(
    'checkEcaInsuredPercentage',
    ecaInsuredPercentageRule,
  );
  VeeValidate.Validator.extend('phone_number', phoneNumber);
  VeeValidate.Validator.extend('phone_number_required', phoneNumberRequired);
  VeeValidate.Validator.extend('isNumber', isNumber);
  // @ts-ignore
  VeeValidate.Validator.extend('min_number_with_format', minNumberWithFormat);
  // @ts-ignore
  VeeValidate.Validator.extend('max_number_with_format', maxNumberWithFormat);
  // @ts-ignore
  VeeValidate.Validator.extend('between', isBetween, {
    paramNames,
  });
  // @ts-ignore not sure, seems external type that is complaining
  VeeValidate.Validator.extend('minimumMaturity', minimumMaturity, {
    paramNames: ['settlementDate'],
  });
  VeeValidate.Validator.extend(
    'minimum_piece_validation',
    minimumPieceValidation,
  );
}
