
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import IrpTable from '@/components/irp/IRPTable.vue';
import { IRP } from '@/types/irpTypes';

const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'IRPAllTables',
  components: {
    IrpTable,
  },
})
export default class IRPAllTables extends Vue {
  @Prop({}) irsCurve!: IRP;
  @Prop({}) apdCurve!: IRP;
  @Prop({}) netherCurve!: IRP;
  @Prop({}) spreadCurve!: IRP;
  @Prop({}) loadTables!: boolean;
}
