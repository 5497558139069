





















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { forEach } from 'lodash';
import InputComponent from '../inputs/InputComponent.vue';
import { CustomInput } from '../inputs/inputTypes';
import ButtonComponent from '../utils/ButtonComponent.vue';
import { getApiError } from '@/api/util';
import { EventBus } from '@/main';
import sentry from '@/util/sentry';
import InputPasswordComponent from '@/components/inputs/InputPasswordComponent.vue';

const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'ChangePasswordForm',
  components: { InputComponent, ButtonComponent, InputPasswordComponent },
})
export default class ChangePasswordForm extends Vue {
  // notification store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @AuthenticationNameSpace.Action('resetUserPassword') resetUserPassword: any;
  @AuthenticationNameSpace.Action('requestUserPasswordChange')
  requestUserPasswordChange: any;

  @Prop({ default: false }) inSettingsMenu: boolean;
  @Prop({}) userAccountId!: string;
  @Prop({}) token!: string;

  eventBus = EventBus;
  password: string | null = '';
  repeatPwdVRule: string | null = '';
  passwordChangeRequested: boolean = false;

  currentPassword: any = {
    name: 'currentPassword',
    type: 'password',
    placeHolder: 'components.changePasswordForm.currentPassword',
    containerClass: 'input-row',
    vRule: 'required|min:8|max:35',
  };

  repeatPassword: any = {
    name: 'repeatPassword',
    placeHolder: 'components.changePasswordForm.passwordRepeat',
    containerClass: 'input-row',
    vRule: this.repeatPwdVRule,
    type: 'password',
  };

  get showChangePassword(): boolean {
    // if the route (url) is "change-password" then the screen should show that
    return this.inSettingsMenu || this.$route.name === 'change-password';
  }

  get title(): string {
    return this.showChangePassword
      ? `${this.$t('components.changePasswordForm.titleRequestChange')}`
      : `${this.$t('components.changePasswordForm.titleReset')}`;
  }

  setInputValue(inputValue: CustomInput) {
    switch (inputValue.name) {
      case 'currentPassword':
        this.currentPassword.value = inputValue.value;
        break;
      case 'password':
        this.password = inputValue.value;
        this.repeatPwdVRule = `required|confirmed:${this.password}`;
        this.repeatPassword.vRule = this.repeatPwdVRule;
        this.repeatPassword.value = '';
        this.eventBus.$emit('reloadInput', this.repeatPassword);
        break;
      case 'repeatPassword':
        this.repeatPassword.value = inputValue.value;
        break;
      default:
        break;
    }
  }

  get passwordButton(): any {
    const description: string = this.showChangePassword
      ? `${this.$t('components.changePasswordForm.changePasswordButton')}`
      : `${this.$t('components.changePasswordForm.resetPasswordButton')}`;

    return {
      containerClass: 'button-center',
      buttonClass: 'button-icon button-icon-large',
      description,
      toolTip: description,
      icon: 'password-change.png',
      showExtended: false,
    };
  }

  validateForm() {
    const validations: any[] = [];
    if (this.showChangePassword) {
      // change password validations
      validations.push(
        this.$validator.validate('currentPassword', this.currentPassword),
      );
    }
    validations.push(this.$validator.validate('password', this.password));
    validations.push(
      this.$validator.validate('repeatPassword', this.repeatPassword.value),
    );
    Promise.all(validations)
      .then((result: boolean[]) => {
        let allValidated: boolean = true;
        forEach(result, (itemValidated: boolean) => {
          if (!itemValidated) {
            allValidated = false;
          }
        });
        if (allValidated) {
          if (this.showChangePassword) {
            this.submitRequestChangePassword();
          } else {
            this.submitPasswordReset();
          }
        }
      })
      .catch((error: any) => {
        const errorString: string = `${this.$t(
          'components.changePasswordForm.validationInputError',
        )}`;
        this.addNotification({
          id: 0,
          message: `${errorString} ${error}`,
          type: 'error',
        });
        sentry(error);
      });
  }

  submitRequestChangePassword() {
    this.requestUserPasswordChange({
      userAccountId: this.getUserAccountId,
      currentPassword: this.currentPassword.value,
      newPassword: this.password,
    })
      .then(() => {
        const successChangePasswordRequestMessage: string = `
          ${this.$t(
            'components.changePasswordForm.successChangePasswordRequestMessage',
          )}
        `;
        this.addNotification({
          id: 0,
          message: `${successChangePasswordRequestMessage}`,
          type: 'success',
        });
        this.passwordChangeRequested = true;
      })
      .catch((error: any) => {
        const errorString: string = `${this.$t(
          'components.changePasswordForm.submittingPasswordChangeError',
        )}`;
        this.addNotification({
          id: 0,
          message: `${errorString} ${getApiError(error)}`,
          type: 'error',
        });
        sentry(error);
      });
  }

  submitPasswordReset() {
    this.resetUserPassword({
      userAccountId: this.userAccountId,
      token: this.token,
      newPassword: this.password,
    })
      .then(() => {
        const errorString: string = `${this.$t(
          'components.changePasswordForm.successResetPassword',
        )}`;
        this.addNotification({
          id: 0,
          message: `${errorString}`,
          type: 'success',
        });
        this.$router.push({ name: 'login' });
      })
      .catch((error: any) => {
        const errorString: string = `${this.$t(
          'components.changePasswordForm.submittingError',
        )}`;
        this.addNotification({
          id: 0,
          message: `${errorString} ${getApiError(error)}`,
          type: 'error',
        });
        sentry(error);
      });
  }
}
