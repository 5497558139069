















































import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import { InputFormatObject } from '@/components/inputs/inputTypes';
import { EventBus } from '@/main';

const DealNameSpace = namespace('deals/');
const ModalNameSpace = namespace('modals/');

@Component({
  name: 'TcRow',
  components: {
    InputComponentFormat,
    ButtonComponent,
  },
})
export default class AccountRow extends Vue {
  @DealNameSpace.Action('getDeal') getDeal: any;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  @Prop({}) tcInput: any;
  @Prop({}) type: string;

  eventBus = EventBus;
  inputSize: InputFormatObject = {
    id: 'size_',
    name: 'size',
    value: this.tcInput.acceptedIssueSize,
    placeHolder: '',
    containerClass: '',
    vRule: '',
    precision: 0,
    showNegative: true,
    disabled: true,
    validationClasses: false,
  };

  inputCoupon: InputFormatObject = {
    id: 'coupon_',
    name: 'coupon',
    value: this.tcInput.coupon,
    placeHolder: '',
    containerClass: '',
    vRule: '',
    precision: 3,
    showNegative: true,
    disabled: true,
    validationClasses: false,
    showCurrency: false,
  };

  // View Account events
  get viewButtonObject(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-resize',
      description: this.type === 'generate' ? "Generate TC's" : 'View Deal',
      extendedDescription: 'Display Account Full Information',
      icon: 'edit-blue.png',
      showExtended: false,
    };
  }

  viewDeal(): void {
    this.getDeal(this.tcInput.dealId).then(() =>
      this.toggleModal('detailDeal'),
    );
  }
}
