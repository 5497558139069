import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { forEach } from 'lodash';
import { Account, AccountPayload, BackOfficeState } from '@/types/accountTypes';
import { RootState } from '@/types/rootState';
import backOfficeApi from '@/api/backOfficeApi';
import { getApiError } from '@/api/util';
import sentry from '@/util/sentry';

const ADD_NOTIFICATION = 'notifications/add';

function filterAccounts(
  filteredCompany: string | null | undefined,
  accountSet: any,
): Account[] {
  const filteredAccounts: Account[] = [];
  if (
    filteredCompany === undefined ||
    filteredCompany === null ||
    filteredCompany === ''
  ) {
    return accountSet;
  }
  forEach(accountSet, (company: any) => {
    if (company.companyName.trim() === filteredCompany.trim()) {
      filteredAccounts.push(company);
    }
  });
  return filteredAccounts;
}

export const state: BackOfficeState = {
  pendingAccounts: [],
  approvedAccounts: [],
  pendingUpgradeAccounts: [],
  approvedUpgradedAccounts: [],
  blockedAccounts: [],
  companies: [],
  filteredCompany: '',
};

// getters
export const getters: GetterTree<BackOfficeState, RootState> = {
  companies: state => state.companies,
  filteredCompany: state => state.filteredCompany,
  pendingAccounts: state => state.pendingAccounts,
  approvedAccounts: state => state.approvedAccounts,
  pendingUpgradeAccounts: state => state.pendingUpgradeAccounts,
  blockedAccounts: state => state.blockedAccounts,
  approvedUpgradedAccounts: state => state.approvedUpgradedAccounts,
};

// mutations
export const mutations: MutationTree<BackOfficeState> = {
  SET_PENDING_ACCOUNTS(state, accountSet): void {
    state.pendingAccounts = accountSet;
  },
  SET_APPROVED_ACCOUNTS(state, accountSet): void {
    state.approvedAccounts = accountSet;
  },
  SET_PENDING_UPGRADE_ACCOUNTS(state, accountSet): void {
    state.pendingUpgradeAccounts = accountSet;
  },
  SET_APPROVED_UPGRADE_ACCOUNTS(state, accountSet): void {
    state.approvedUpgradedAccounts = accountSet;
  },
  SET_BLOCKED_ACCOUNTS(state, accountSet): void {
    state.blockedAccounts = accountSet;
  },
  SET_COMPANIES(state, companySet): void {
    state.companies = companySet;
  },
  SET_FILTERED_COMPANY(state, filteredCompany: string): void {
    state.filteredCompany = filteredCompany;
  },
};

// actions
export const actions: ActionTree<BackOfficeState, RootState> = {
  setFilteredCompany({ commit }, filteredCompany: string) {
    commit('SET_FILTERED_COMPANY', filteredCompany);
  },

  getPendingAccounts({ commit, dispatch, rootState }) {
    return backOfficeApi
      .getPendingAccounts()
      .then((response: any) =>
        commit(
          'SET_PENDING_ACCOUNTS',
          filterAccounts(state.filteredCompany, response.data),
        ),
      )
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'Error fetching the Pending Accounts: ',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  getApprovedAccounts({ commit, dispatch, rootState }) {
    // fetch actual indications of interest from the API
    backOfficeApi
      .getApprovedAccounts()
      .then((response: any) =>
        commit(
          'SET_APPROVED_ACCOUNTS',
          filterAccounts(state.filteredCompany, response.data),
        ),
      )
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'Error fetching the Approved Accounts: ',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  getPendingUpgradeAccounts({ commit, dispatch, rootState }) {
    // fetch actual indications of interest from the API
    return backOfficeApi
      .getPendingUpgradeAccounts()
      .then((response: any) =>
        commit(
          'SET_PENDING_UPGRADE_ACCOUNTS',
          filterAccounts(state.filteredCompany, response.data),
        ),
      )
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'Error fetching the Pending Upgrade Accounts: ',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  getApprovedUpgradedAccounts({ commit, dispatch, rootState }) {
    // fetch actual indications of interest from the API
    return backOfficeApi
      .getApprovedUpgradedAccounts()
      .then((response: any) =>
        commit(
          'SET_APPROVED_UPGRADE_ACCOUNTS',
          filterAccounts(state.filteredCompany, response.data),
        ),
      )
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'Error fetching the Approved Upgraded Accounts: ',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  getBlockedAccounts({ commit, dispatch, rootState }) {
    // fetch actual indications of interest from the API
    return backOfficeApi
      .getBlockedAccounts()
      .then((response: any) =>
        commit(
          'SET_BLOCKED_ACCOUNTS',
          filterAccounts(state.filteredCompany, response.data),
        ),
      )
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'Error fetching the Blocked Accounts: ',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  getCompanies({ commit, dispatch, rootState }) {
    // fetch actual indications of interest from the API
    return backOfficeApi
      .getCompanies()
      .then((response: any) => commit('SET_COMPANIES', response.data))
      .catch((error: any) => commit('SET_COMPANIES', []));
  },

  approveAccount({ commit, dispatch, rootState }, payload: AccountPayload) {
    return backOfficeApi.approveAccount(payload).catch((error: any) => {
      dispatch(
        ADD_NOTIFICATION,
        {
          labelToTranslate: 'Error approving the account: ',
          message: getApiError(error),
          type: 'error',
        },
        { root: true },
      );
      sentry(error);
    });
  },

  approveUpgradeAccount(
    { commit, dispatch, rootState },
    payload: AccountPayload,
  ) {
    return backOfficeApi.approveUpgradeAccount(payload).catch((error: any) => {
      dispatch(
        ADD_NOTIFICATION,
        {
          labelToTranslate: 'Error approving the account upgrade: ',
          message: getApiError(error),
          type: 'error',
        },
        { root: true },
      );
      sentry(error);
    });
  },

  denyAccount({ commit, dispatch, rootState }, payload: AccountPayload) {
    return backOfficeApi.denyAccount(payload).catch((error: any) => {
      dispatch(
        ADD_NOTIFICATION,
        {
          labelToTranslate: 'Error denying the account: ',
          message: getApiError(error),
          type: 'error',
        },
        { root: true },
      );
      sentry(error);
    });
  },

  denyUpgradeAccount({ commit, dispatch, rootState }, payload: AccountPayload) {
    return backOfficeApi.denyUpgradeAccount(payload).catch((error: any) => {
      dispatch(
        ADD_NOTIFICATION,
        {
          labelToTranslate: 'Error denying the account upgrade: ',
          message: getApiError(error),
          type: 'error',
        },
        { root: true },
      );
      sentry(error);
    });
  },

  blockAccount({ commit, dispatch, rootState }, payload: AccountPayload) {
    return backOfficeApi.blockAccount(payload).catch((error: any) => {
      dispatch(
        ADD_NOTIFICATION,
        {
          labelToTranslate: 'Error blocking the account: ',
          message: getApiError(error),
          type: 'error',
        },
        { root: true },
      );
      sentry(error);
    });
  },

  unblockAccount({ commit, dispatch, rootState }, payload: AccountPayload) {
    return backOfficeApi.unblockAccount(payload).catch((error: any) => {
      dispatch(
        ADD_NOTIFICATION,
        {
          labelToTranslate: 'Error denying the account: ',
          message: getApiError(error),
          type: 'error',
        },
        { root: true },
      );
      sentry(error);
    });
  },

  getAccountDocuments(
    { commit, dispatch, rootState },
    payload: AccountPayload,
  ) {
    return backOfficeApi.getAccountDocuments(payload).catch((error: any) => {
      dispatch(
        ADD_NOTIFICATION,
        {
          labelToTranslate: 'Error fetching the account documents: ',
          message: getApiError(error),
          type: 'error',
        },
        { root: true },
      );
      sentry(error);
    });
  },

  getAccount({ commit, dispatch, rootState }, userAccountId: string) {
    // fetch actual indications of interest from the API
    return backOfficeApi.getAccount(userAccountId).catch((error: any) => {
      dispatch(
        ADD_NOTIFICATION,
        {
          labelToTranslate: 'Error fetching the Account: ',
          message: getApiError(error),
          type: 'error',
        },
        { root: true },
      );
      sentry(error);
    });
  },
};

export const backOffice = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
