


















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Navigation from '@/components/navigation/Navigation.vue';
import NavigationMobile from '@/components/navigation/NavigationMobile.vue';
import CookieConsentBar from '@/components/utils/CookieConsentBar.vue';
import Footer from '@/components/Footer.vue';
import NotificationContainer from '@/components/notifications/NotificationContainer.vue';
import TokenExpired from '@/views/authentication/TokenExpired.vue';
import { getNotificationToken } from './api/authenticationApi';
import VueNativeSock from 'vue-native-websocket';
const AuthenticationNameSpace = namespace('authentication/');

Vue.directive('focus', {
  inserted(el) {
    el.focus();
  },
  update(el) {
    Vue.nextTick(() => {
      el.focus();
    });
  },
});

@Component({
  components: {
    Navigation,
    NavigationMobile,
    NotificationContainer,
    Footer,
    CookieConsentBar,
    TokenExpired,
  },
})
export default class App extends Vue {
  el: '#afs-blue';
  @AuthenticationNameSpace.Action('loadToken') loadToken: any;
  @AuthenticationNameSpace.Action('widthAction') widthAction: any;
  @AuthenticationNameSpace.Getter('tokenExpired') tokenExpired: boolean;

  cookieAccepted: boolean = true;

  busy: boolean = false;
  // eslint-disable-next-line class-methods-use-this
  beforeCreate() {
    if (process.env.VUE_APP_ENVIRONMENT === 'alpha') {
      window.document.title += ' (ALPHA)';
    } else if (process.env.VUE_APP_ENVIRONMENT === 'beta') {
      window.document.title += ' (BETA)';
    }
  }

  mounted() {
    this.loadToken();
    this.cookieAccepted = !!localStorage.getItem('afsCookieStatus');
  }

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    // resize handler with timout, sets window width in state
    if (!this.busy) {
      this.busy = true;
      this.widthAction(window.innerWidth);
      setTimeout(() => {
        this.busy = false;
      }, 100);
    }
  }
}
