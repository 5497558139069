












import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SignUpForm from '../authentication/SignUpForm.vue';
import StepTracker from '../navigation/StepTracker.vue';
import FinalizeSignup from '../authentication/FinalizeSignup.vue';
import RoleSelector from '../authentication/RoleSelector.vue';
import SignUpButtonRow from '../authentication/SignUpButtonRow.vue';
import VisualSignupContent from '../authentication/VisualSignupContent.vue';

const SignupNameSpace = namespace('signup/');

@Component({
  name: 'ExpandElement',
  components: {
    SignUpForm,
    StepTracker,
    FinalizeSignup,
    RoleSelector,
    SignUpButtonRow,
    VisualSignupContent,
  },
})
export default class ExpandElement extends Vue {
  @Prop({}) expanded!: boolean;
  // amount in pixels: '100px';
  @Prop({}) baseHeight!: string;
  // time as string; '1s', '0.2s'
  @Prop({}) animationSpeed!: string;
  // active or not
  @Prop({}) expandActive!: boolean;

  element: any = '';
  height: any = '';

  @Watch('expanded')
  onChangeExpanded(expanded: boolean) {
    // trigger based on prop
    if (expanded === true && this.element !== '') {
      this.element.style.transition = `height ${this.animationSpeed} ease-in-out`;
      this.element.style.height = this.height;
    } else if (this.element !== '') {
      this.element.style.transition = `height ${this.animationSpeed} ease-in-out`;
      this.element.style.height = this.baseHeight;
    }
  }

  @Watch('expandActive')
  expandActivated(expandActive: boolean) {
    // trigger based on prop
    if (expandActive === true && this.element !== '') {
      this.enter(this.element);
      this.afterEnter(this.element);
    } else if (this.element !== '') {
      this.afterEnter(this.element);
    }
  }

  enter(element: any) {
    this.element = element;
    const elementL = element;
    if (this.expandActive) {
      const { width } = getComputedStyle(elementL);
      elementL.style.overflow = 'hidden';
      elementL.style.width = width;
      elementL.style.position = 'absolute';
      elementL.style.visibility = 'hidden';
      elementL.style.height = 'auto';

      const { height } = getComputedStyle(elementL);
      this.height = height;
      elementL.style.width = '';
      elementL.style.position = '';
      elementL.style.visibility = '';
      elementL.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(elementL);

      // set base height asap so user sees nothing funky
      elementL.style.height = this.baseHeight;
    }
  }

  afterEnter(element: any) {
    if (this.expandActive) {
      if (this.expanded) {
        this.element.style.height = 'auto';
      } else {
        this.element.style.transition = `height ${this.animationSpeed} ease-in-out`;
        this.element.style.height = this.baseHeight;
      }
    } else {
      this.element.style.height = 'auto';
    }
  }
}
