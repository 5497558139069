










































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Deal } from '@/types/dealTypes';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import InputComponent from '@/components/inputs/InputComponent.vue';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import { InputFormatObject } from '@/components/inputs/inputTypes';
import InputDateComponent from '@/components/inputs/InputDateComponent.vue';
import {
  getRedemptionForm,
  getInstrumentType,
  getDayCount,
} from '@/util/ioiUtils';
import PdfViewer from '@/components/utils/PdfViewer.vue';
import { EventBus } from '@/main';
import sentry from '@/util/sentry';

const DealNameSpace = namespace('deals/');
const NotificationsNameSpace = namespace('notifications/');
const ModalNameSpace = namespace('modals/');

@Component({
  name: 'DealForm',
  components: {
    ButtonComponent,
    InputComponent,
    InputComponentFormat,
    InputDateComponent,
    PdfViewer,
  },
})
export default class DealForm extends Vue {
  // Deal store
  @DealNameSpace.Getter('detailDeal') detailDeal: Deal;
  @DealNameSpace.Getter('detailDealNumber') detailDealNumber: string;
  @DealNameSpace.Action('setDealOption') setDealOption: any;
  @DealNameSpace.Action('generateTradeConfirmations')
  generateTradeConfirmations: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;
  // Modal store
  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  @Prop({ required: true }) type!: String;

  eventBus = EventBus;
  showModal: boolean = false;
  disclaimer: string =
    'The period from an Interest Payment Date ' +
    '(or Issue Date for the first period) next to but excluding interest Payment the Date ' +
    '(or the Maturity Date for the last period)';

  inputMaturityDate: any = {
    id: 'maturityDate_',
    name: 'maturityDate',
    vRule: 'required',
    optionList: ['limitDates'],
    value: undefined,
    defaultTooltip: true,
    ref: 'maturityDate',
    placeHolder: 'components.dealForm.maturityDate',
    containerClass: '',
    class: ['small'],
    vLabel: 'components.dealForm.maturityDate',
  };

  inputIssueSize: InputFormatObject = {
    id: 'issueSize_',
    name: 'issueSize',
    value: '',
    placeHolder: '',
    containerClass: '',
    vRule: '',
    precision: 0,
    showNegative: true,
    disabled: true,
    validationClasses: false,
  };

  inputInterestPaymentDates: InputFormatObject = {
    id: 'interestPaymentDates_',
    name: 'interestPaymentDates',
    value: '',
    placeHolder: '',
    containerClass: '',
    vRule: 'required',
    precision: 0,
  };

  inputCoupon: InputFormatObject = {
    id: 'coupon_',
    name: 'coupon',
    value: '',
    placeHolder: '',
    containerClass: '',
    vRule: '',
    precision: 3,
    showNegative: true,
    disabled: true,
    validationClasses: false,
    showCurrency: false,
  };

  inputEffectiveAllInYield: InputFormatObject = {
    id: 'effectiveAllInYield_',
    name: 'effectiveAllInYield',
    value: '',
    placeHolder: 'components.dealForm.yield',
    defaultTooltip: true,
    containerClass: '',
    vRule: 'required|isNumber',
    disabled: this.type !== 'pending',
    validationClasses: this.type === 'pending',
    vLabel: 'components.IOIForm.issueNominalSizeVLabel',
    precision: 3,
    showCurrency: false,
  };

  inputBrokerage: InputFormatObject = {
    id: 'brokerageAFS_',
    name: 'brokerageAFS',
    value: '',
    placeHolder: 'components.dealForm.brokerageAFS',
    defaultTooltip: true,
    containerClass: '',
    vRule: 'required|isNumber',
    disabled: this.type !== 'pending',
    validationClasses: this.type === 'pending',
    vLabel: 'components.IOIForm.issueNominalSizeVLabel',
    precision: 3,
  };

  inputDocumentation: any = {
    name: 'documentation',
    placeHolder: 'components.dealForm.documentation',
    vRule: '',
    defaultTooltip: true,
  };

  approveButton: any = {
    containerClass: 'ioi-row-inline-button',
    buttonClass: 'button-icon button-icon-resize',
    description: 'Generate Trade Confirmations',
    extendedDescription: 'Generate this Trade Confirmation',
    icon: 'document.png',
    showExtended: false,
  };

  // sends updates made in input fields to the additional deals object in the store
  setInputValue(inputValue: any) {
    switch (inputValue.name) {
      case 'effectiveAllInYield':
        if (inputValue.value !== null && inputValue.value !== '') {
          this.inputEffectiveAllInYield.value = inputValue.value;
          this.inputEffectiveAllInYield.id = `effectiveAllInYield_${inputValue.value}`;
          this.setDealOption({
            property: inputValue.name,
            value: parseFloat(inputValue.value),
          });
        }
        break;
      case 'documentation':
        this.setDealOption({
          property: inputValue.name,
          value: inputValue.value,
        });
        break;
      case 'brokerageAFS':
        if (inputValue.value !== null && inputValue.value !== '') {
          this.inputBrokerage.value = inputValue.value;
          this.inputBrokerage.id = `brokerageAFS_${inputValue.value}`;
          this.setDealOption({
            property: inputValue.name,
            value: parseFloat(inputValue.value),
          });
        }
        break;
      case 'maturityDate':
        this.setDealOption({
          property: inputValue.name,
          value: inputValue.value,
        });
        break;
      case 'interestPaymentDates':
        this.setDealOption({
          property: inputValue.name,
          value: inputValue.value,
        });
        break;
      default:
        break;
    }
  }

  validate(): void {
    this.$validator.validateAll().then(result => {
      if (result) {
        this.sendDeal();
      } else {
        this.addNotification({
          label: 'Please fill in all fields',
          type: 'error',
        });
      }
    });
  }

  sendDeal() {
    this.generateTradeConfirmations()
      .then(() => {
        // show the deal with all the fields disabled
        this.inputEffectiveAllInYield.disabled = true;
        this.inputEffectiveAllInYield.validationClasses = false;
        this.inputEffectiveAllInYield.id = 'effectiveAllInYield_';
        this.inputBrokerage.disabled = true;
        this.inputBrokerage.validationClasses = false;
        this.inputBrokerage.id = 'brokerageAFS_';

        this.eventBus.$emit('dealGenerated');
      })
      .catch((error: any) => {
        sentry(error);
      });
  }

  get redemptionForm(): string {
    return getRedemptionForm(this.detailDeal.redemptionForm);
  }

  get instrumentType(): string {
    return getInstrumentType(parseFloat(this.detailDeal.instrumentType));
  }

  get dayCount(): string {
    return getDayCount(this.detailDeal.dayCount);
  }

  mounted() {
    // coupon
    this.inputCoupon.value = this.detailDeal.coupon;
    this.inputCoupon.id = `coupon_${this.detailDeal.coupon}`;

    // accepted size
    this.inputIssueSize.value = this.detailDeal.acceptedIssueSize;
    this.inputIssueSize.id = `issueSize_${this.detailDeal.acceptedIssueSize}`;

    this.inputMaturityDate.value = this.detailDeal.maturityDate;
    this.eventBus.$emit('reloadDateInput', this.inputMaturityDate);
    if (this.detailDeal.maturityDate) {
      this.setDealOption({
        property: 'maturityDate',
        value: this.detailDeal.maturityDate,
      });
    }

    if (this.type !== 'pending') {
      // brokerage
      this.inputBrokerage.value = this.detailDeal.brokerageAFS;
      this.inputBrokerage.disabled = true;
      this.inputBrokerage.validationClasses = false;
      this.inputBrokerage.id = `brokerageAFS_${this.detailDeal.brokerageAFS}`;

      // effective all in yield
      this.inputEffectiveAllInYield.value = this.detailDeal.effectiveAllInYield.toString();
      this.inputEffectiveAllInYield.disabled = true;
      this.inputEffectiveAllInYield.validationClasses = false;
      const yieldString: string = this.detailDeal.effectiveAllInYield.toString();
      this.inputEffectiveAllInYield.id = `effectiveAllInYield_${yieldString}`;
    }
  }
}
