




































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LineChart from '@/components/charts/LineChart.vue';
import { IRP } from '@/types/irpTypes';
import IrpAllTables from '@/components/irp/IRPAllTables.vue';

const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');
const IRPNameSpace = namespace('irps/');

@Component({
  name: 'IRPChart',
  components: {
    LineChart,
    IrpAllTables,
  },
})
export default class IRPChart extends Vue {
  // Authentication store
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // Interest Rate Pages store
  @IRPNameSpace.Getter('irsIRP') irsIRP: IRP;
  @IRPNameSpace.Getter('apdIRP') apdIRP: IRP;
  @IRPNameSpace.Getter('netherIRP') netherIRP: IRP;
  @IRPNameSpace.Getter('spreadIRP') spreadIRP: IRP;
  @IRPNameSpace.Getter('irpsReady') irpsReady: boolean;
  @IRPNameSpace.Getter('formattedCurve') formattedCurve: any;
  @IRPNameSpace.Action('getIRPCurves') getIRPCurves: any;

  tenorTooltip: string = '';
  yearsTooltip: string = '';
  showDataTable: boolean = false;

  get chartDateTime(): string {
    return this.irpsReady ? this.irsIRP.snapshotTime : '';
  }

  get chartOptions(): any {
    return {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: `${this.$t('views.IRPChart.graphTitle')} ${this.chartDateTime}`,
        fontSize: '20',
        fontStyle: 'bold',
        padding: 10,
      },
      lineTension: 1,
      tooltips: {
        backgroundColor: 'rgb(190, 190, 190)',
        titleFontColor: 'rgb(10, 10, 10)',
        bodyFontColor: 'rgb(10, 10, 10)',
        callbacks: {
          // eslint-disable-next-line max-len
          title: (tooltipItem: any) =>
            `${this.tenorTooltip}: ${tooltipItem[0].xLabel} ${this.yearsTooltip}`,
          label: (tooltipItem: any, data: any) => {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.yLabel;
            return label;
          },
        },
      },
      legend: {
        display: true,
        align: 'center',
        labels: {
          fontColor: 'rgb(0, 0, 0)',
          boxWidth: 20,
          padding: 5,
        },
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: `${this.$t('views.IRPChart.axisYears')}`,
              fontStyle: 'bold',
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: `${this.$t('views.IRPChart.axisPercentages')}`,
              fontStyle: 'bold',
            },
          },
        ],
      },
      onClick: (event: any) => {
        this.toggleDataTable();
      },
    };
  }

  toggleDataTable() {
    this.showDataTable = !this.showDataTable;
  }

  get formatData(): any {
    return this.irpsReady ? this.formattedCurve : {};
  }

  get irsData(): IRP {
    return this.irsIRP;
  }

  get apdData(): IRP {
    return this.apdIRP;
  }

  get netherData(): IRP {
    return this.netherIRP;
  }

  get spreadData(): IRP {
    return this.spreadIRP;
  }

  created() {
    const accountErrorMessage: string = `${this.$t(
      'views.IRPForm.accountErrorMessage',
    )}`;
    this.authenticateLogin(accountErrorMessage).then(() => {
      this.getIRPCurves();
    });
  }

  mounted() {
    this.tenorTooltip = `${this.$t('views.IRPChart.tooltipTenor')}`;
    this.yearsTooltip = `${this.$t('views.IRPChart.tooltipYears')}`;
  }
}
