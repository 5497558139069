























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { isEmpty } from 'lodash';

@Component({
  name: 'CollapsibleComponent',
})
export default class CollapsibleComponent extends Vue {
  @Prop({}) title!: string;
  @Prop({}) content!: string[];
  @Prop({}) icon!: string;
  @Prop({}) alwaysOpen!: boolean;

  showContent: boolean = false;

  get showIcon(): boolean {
    return !isEmpty(this.icon);
  }

  get iconPath(): string | null {
    return isEmpty(this.icon) ? '' : `/images/${this.icon}`;
  }

  openContent() {
    this.showContent = this.alwaysOpen || !this.showContent;
  }

  created() {
    if (this.alwaysOpen) {
      this.showContent = true;
    }
  }
}
