























































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { EventBus } from '@/main';

@Component({
  name: 'SignUpMarketViewer',
})
export default class SignUpMarketViewer extends Vue {}
