

































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TcRow from '@/components/backoffice/TcRow.vue';
import ModalWindow from '@/components/utils/Modal.vue';
import DealForm from '@/components/backoffice/DealForm.vue';
import { EventBus } from '@/main';

const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');
const DealNameSpace = namespace('deals/');

@Component({
  name: 'TcDashboard',
  components: {
    TcRow,
    DealForm,
    ModalWindow,
  },
})
export default class AccountDashboard extends Vue {
  // Authentication store
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isBackOffice') isBackOffice: boolean;

  // deals
  @DealNameSpace.Action('getPendingDeals') getPendingDeals: any;
  @DealNameSpace.Action('getAccountDeals') getAccountDeals: any;
  @DealNameSpace.Action('getClosedDeals') getClosedDeals: any;
  @DealNameSpace.Getter('pendingDeals') pendingDeals: any;
  @DealNameSpace.Getter('accountDeals') accountDeals: any;
  @DealNameSpace.Getter('closedDeals') closedDeals: any;
  @DealNameSpace.Getter('detailDealNumber') detailDealNumber: any;
  @DealNameSpace.Getter('detailDeal') detailDeal: any;
  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  eventBus = EventBus;
  myAccount: string = this.getUserAccountId;
  currentTab: string = '';
  showDealFormModal: any = {
    showModal: false,
    account: {},
    title: '',
    type: '',
  };

  // Deal events
  changeTab(tabId: string): void {
    this.currentTab = tabId;
    if (this.isUserLoggedIn && this.isBackOffice) {
      if (this.currentTab === '1') {
        this.getPendingDeals();
        this.showDealFormModal.title = 'Generate Trade Confirmations for deal';
        this.showDealFormModal.type = 'pending';
      } else if (this.currentTab === '2') {
        this.showDealFormModal.title = 'My Trade Confirmation';
        this.showDealFormModal.type = 'my';
      } else if (this.currentTab === '3') {
        this.getClosedDeals();
        this.showDealFormModal.title = 'Trade Confirmation';
        this.showDealFormModal.type = 'all';
      }
    }
  }

  created() {
    this.currentTab = this.$route.params.tabId;
    const accountErrorMessage: string =
      'You need to login as a Backoffice user to access' +
      ' the BackOffice Dashboard.';
    this.authenticateLogin(accountErrorMessage).then(() => {
      this.myAccount = this.getUserAccountId;
      if (this.currentTab === undefined || this.currentTab === null) {
        this.currentTab = '1';
      }
      this.changeTab(this.currentTab);
    });

    this.eventBus.$on('dealGenerated', () => {
      this.showDealFormModal.showModal = !this.showDealFormModal.showModal;
      this.changeTab('3');
    });
  }
}
