





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SocialIcons from './utils/SocialIcons.vue';

@Component({
  name: 'Footer',
  components: {
    SocialIcons,
  },
})
export default class Footer extends Vue {
  date: Date = new Date();
}
