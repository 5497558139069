












































import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import { Validator } from 'vee-validate';
import { CustomInput, InputRadioObject, ReturnInput } from './inputTypes';
import { EventBus } from '@/main';

@Component({
  name: 'InputRadioComponent',
})
export default class InputRadioComponent extends Vue {
  @Prop({}) inputParam!: InputRadioObject;
  @Inject('$validator') $validator!: Validator;

  eventBus = EventBus;
  vmodelValue: string | null = '';
  previouslySelected: string | null = '';
  inputField: InputRadioObject = {
    id: '',
    name: '',
    class: '',
    vRule: '',
    vLabel: '',
    disabled: false,
    optionList: [],
    containerClass: '',
    containerTitle: '',
    titleClass: '',
    alwaysOneOptionActive: false,
    value: '',
    verticalRadioButton: false,
    showImage: true,
    wrapperClass: '',
  };

  get inputName() {
    return isEmpty(this.inputField.name) ? '' : this.inputField.name;
  }

  get inputVRule() {
    return isEmpty(this.inputField.vRule) ? '' : this.inputField.vRule;
  }

  get showVLabel(): boolean {
    return this.$validator.errors.has(this.inputField.name);
  }

  get inputHasError(): boolean {
    return this.$validator.errors.has(this.inputField.name);
  }

  get isInputCorrect(): boolean {
    return (
      !isEmpty(this.vmodelValue) &&
      !this.$validator.errors.has(this.inputField.name)
    );
  }

  get customClass(): string | null {
    return isEmpty(this.inputField.class)
      ? 'radio-item'
      : this.inputField.class;
  }

  get containerClass(): string | null {
    return isEmpty(this.inputField.containerClass)
      ? ''
      : this.inputField.containerClass;
  }

  isChecked(value: string): boolean {
    return value === this.vmodelValue;
  }

  onOptionClick(currentValue: string) {
    this.vmodelValue = currentValue;
    if (
      this.previouslySelected === currentValue &&
      !this.inputParam.alwaysOneOptionActive
    ) {
      this.vmodelValue = '';
    }
    this.previouslySelected = this.vmodelValue;

    const returnInput: ReturnInput = {
      name: this.inputField.name,
      value: this.vmodelValue,
    };
    this.$emit('sendRadioInput', returnInput);
  }

  get showTitle(): boolean {
    return !isEmpty(this.inputField.containerTitle);
  }

  get titleClass(): string | null {
    return isEmpty(this.inputField.titleClass)
      ? ''
      : this.inputField.titleClass;
  }

  get wrapperClass(): any {
    if (this.inputField.verticalRadioButton) {
      // vertical radio button
      if (this.inputField.showImage) {
        return {
          'radio-wrapper': true,
          'radio-button-vertical-has-error': this.inputHasError,
          'radio-button-vertical-correct': this.isInputCorrect,
        };
      }
      return {
        'radio-wrapper': true,
        'radio-button-vertical-has-error': this.inputHasError,
        'radio-button-vertical-correct-no-image': this.isInputCorrect,
      };
    }
    // horizontal radio button
    if (this.inputField.showImage) {
      return {
        'radio-horizontal-wrapper': true,
        'radio-button-horizontal-has-error': this.inputHasError,
        'radio-button-horizontal-correct': this.isInputCorrect,
      };
    }
    return {
      'radio-horizontal-wrapper': true,
    };
  }

  get inputTooltip(): any {
    if (this.inputField.defaultTooltip) {
      if (this.inputField.containerTitle !== null) {
        return {
          placement: 'bottom-center',
          content: this.labelFromCode(this.inputField.containerTitle, false),
        };
      }
      return '';
    }
    if (
      this.inputField.tooltip === undefined ||
      this.inputField.tooltip === null
    ) {
      return null;
    }
    const tooltipContent: string | null = `${this.$t(this.inputField.tooltip)}`;
    return tooltipContent === null
      ? null
      : { placement: 'bottom-center', content: tooltipContent };
  }

  labelFromCode(code: string, isStatic: boolean): string {
    return isStatic ? code : `${this.$t(code)}`;
  }

  inputOptionTooltip(tooltipCode: string): string {
    return tooltipCode === undefined
      ? this.inputTooltip
      : `${this.$t(tooltipCode)}`;
  }

  created() {
    this.inputField = Object.assign({}, this.inputParam);
    if (this.inputField.optionList !== null) {
      this.inputField.optionList = this.inputParam.optionList;
    }
    this.vmodelValue = this.inputParam.value;
    this.eventBus.$on(
      'reloadRadioInput',
      (inputInformation: InputRadioObject) => {
        if (inputInformation.name === this.inputParam.name) {
          this.inputField = {
            id: inputInformation.id,
            name: inputInformation.name,
            class: inputInformation.class,
            vRule: inputInformation.vRule,
            vLabel: inputInformation.vLabel,
            disabled: inputInformation.disabled,
            optionList: inputInformation.optionList,
            containerClass: inputInformation.containerClass,
            containerTitle: inputInformation.containerTitle,
            titleClass: inputInformation.titleClass,
            value: inputInformation.value,
            verticalRadioButton: inputInformation.verticalRadioButton,
            showImage:
              inputInformation.showImage === undefined
                ? true
                : inputInformation.showImage,
            defaultTooltip: inputInformation.defaultTooltip,
            tooltip: inputInformation.tooltip,
          };
          this.vmodelValue = this.inputField.value;
          this.previouslySelected = this.vmodelValue;
        }
      },
    );
  }
}
