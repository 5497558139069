import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { forEach, isEmpty, remove, findIndex, union, orderBy } from 'lodash';
import moment from 'moment';
import {
  IOIState,
  IOI,
  Offer,
  PayloadIOI,
  PayloadInterested,
  DtoIOI,
  PayloadIOIId,
  PayloadAcceptOffers,
  FilteredIOISet,
} from '@/types/instrumentsTypes';
import { RootState } from '@/types/rootState';
import instrumentApi from '@/api/instrumentApi';
import { getIOIList } from '@/api/instrumentApi';
import router from '@/router';
import { AccountPayload } from '@/types/accountTypes';
import {
  getIssueType,
  getFrequency,
  getSpread,
  getInstrumentType,
  calculateDays,
} from '@/util/ioiUtils';
import { daysBetween } from '@/util/date';
import { getApiError } from '@/api/util';
import { formatNumberLocale } from '@/util/digits';
import sentry from '@/util/sentry';

// ****************************************
// IOI Module
// ****************************************
function instrumentsFullLabel(instruments: number[]): string {
  let fullLabel: string = '';
  forEach(instruments, (item: number) => {
    fullLabel =
      fullLabel === ''
        ? `${getInstrumentType(item)}`
        : `${getInstrumentType(item)} ${router.app.$t(
            'components.IOIRow.instrumentSeparation',
          )} ${fullLabel}`;
  });
  return fullLabel;
}

function buildCouponOrSpread(rawData: any): string {
  if (rawData.spreadOverWhat) {
    const spread: string = getSpread(rawData.spreadOverWhat);
    return `Spread: {${rawData.spreadMinValue} bps, ${rawData.spreadMaxValue} bps} ${spread} `;
  }
  if (
    rawData.indicativeCoupon !== undefined &&
    rawData.indicativeCoupon !== 0 &&
    rawData.indicativeCoupon !== null
  ) {
    return `${router.app.$t('views.myIssuerAccount.gridIndicativeCoupon')}: ${
      rawData.indicativeCoupon
    }`;
  }
  return `${router.app.$t('views.myIssuerAccount.gridNoCouponNoSpread')}`;
}

export function buildIOI(
  rawData: any,
  withOffers: boolean,
  todayUnix: number,
  userAccountId: string,
): IOI {
  const expirationDate: number = Number(
    moment(rawData.proposedPricingAndTransactionDate).unix(),
  );
  let expiringTodayDescription: string = 'Expiring Today';
  if (expirationDate >= todayUnix && rawData.state === 'closing') {
    expiringTodayDescription = `${expiringTodayDescription} & Closing Now`;
  } else if (rawData.state === 'closing') {
    expiringTodayDescription = 'Closing Now';
  } else {
    expiringTodayDescription = '';
  }
  let instrumentTypeLabel: string = '';
  if (rawData.instrumentTypes !== undefined) {
    instrumentTypeLabel = instrumentsFullLabel(rawData.instrumentTypes);
  }
  let isFavorite: boolean = false;
  if (
    userAccountId !== null &&
    rawData.interestedUsers !== undefined &&
    rawData.interestedUsers.length > 0
  ) {
    const index = findIndex(
      rawData.interestedUsers,
      (interestedAccountId: string) => interestedAccountId === userAccountId,
    );
    isFavorite = index !== -1;
  }
  return {
    indicationOfInterestId: rawData.indicationOfInterestId,
    userAccountId: rawData.userAccountId,
    companyId: rawData.createdForCompany,
    issuerCompanyName: rawData.companyName,
    issueType: getIssueType(rawData.issueType),
    issuerCountry: rawData.countryCode,
    countryName: rawData.countryName,
    industryOrSector: rawData.industryOrSector,
    rankingType: rawData.rankingType,
    rankingGuarantee: rawData.rankingGuarantee,
    instrumentType: rawData.instrumentType,
    instrumentTypes: rawData.instrumentTypes,
    instrumentTypeLabel,
    bucketSize: rawData.bucketSize,
    indicativeType: rawData.indicativeType,
    issueNominalSize: rawData.issueNominalSize,
    issueNominalSizeWithFormat:
      rawData.issueNominalSize > 0
        ? formatNumberLocale(rawData.issueNominalSize, 3)
        : '0',
    currencyType: rawData.currencyType,
    maturityBucket: rawData.maturityBucket,
    issueMaturity: rawData.issueMaturity,
    issueMaturityInDays: rawData.issueMaturityInDays
      ? rawData.issueMaturityInDays
      : rawData.maturityDate && rawData.proposedSettlementDate
      ? calculateDays(rawData.maturityDate, rawData.proposedSettlementDate)
      : rawData.issueMaturity * 365,
    redemptionForm: rawData.redemptionForm,
    minimumPiece: rawData.minimumPiece,
    couponFrequency: rawData.couponFrequency,
    repaymentFrequencyString: `Repay ${getFrequency(
      rawData.repaymentFrequency,
    )}`,
    repaymentFrequency: rawData.repaymentFrequency,
    ecaInsuredPercentage: rawData.ecaInsuredPercentage,
    proposedSettlementDateStr: rawData.proposedSettlementDate,
    brokenCouponDate: rawData.brokenCouponDate,
    maturityDate: rawData.maturityDate,
    proposedTransactionDateStr: rawData.proposedPricingAndTransactionDate,
    proposedPricingAndTransactionTime: rawData.proposedPricingAndTransactionTime.slice(
      0,
      5,
    ),
    minimumPieceWithFormat:
      rawData.minimumPiece === 0
        ? '0'
        : formatNumberLocale(rawData.minimumPiece, 3),
    enterCoupon:
      rawData.indicativeCoupon !== undefined && rawData.indicativeCoupon !== '',
    indicativeCoupon: rawData.indicativeCoupon,
    indicativeCouponText: buildCouponOrSpread(rawData),
    enterSpread: rawData.spreadOverWhat,
    spreadMinValue: rawData.spreadMinValue,
    spreadMaxValue: rawData.spreadMaxValue,
    spreadOverWhat: rawData.spreadOverWhat,
    documentationForm: rawData.documentationForm,
    structure: rawData.structure,
    creditRatingM: rawData.mRating,
    creditRatingS: rawData.spRating,
    creditRatingF: rawData.fRating,
    dayCount: rawData.dayCount,
    additionalInformation: rawData.additionalInformation,
    daysLeft:
      expirationDate >= todayUnix
        ? `${daysBetween(rawData.proposedPricingAndTransactionDate)} `
        : expiringTodayDescription,
    isPreference: rawData.isPreference,
    isFavorite: isFavorite,
    // offers
    ioiOffers: rawData.offers === undefined ? [] : rawData.offers,
    // expired
    state: rawData.state,
    alertExpired: rawData.state === 'matched' ? false : rawData.expiring,
    interestedUsers: rawData.interestedUsers,
    offerCount: rawData.offerCount,
    showOffersToLeadManagers: rawData.showOffersToLeadManagers,
    referenceNumber: rawData.referenceNumber,
    highlight: false,
    contactName: rawData.contactName,
  };
}

export const state: IOIState = {
  iois: [],
  unfilteredOpenSet: [],
  myWishList: [],
  myOffers: [],
  myMatchedIOIs: [],
  unfilteredOffTheMarket: [],
  unfilteredMatchedSet: [],
  myOffTheMarketIOIs: [],
  myDraftIOIs: [],
  unfilteredDraftSet: [],
  activeIOISet: '',
  unfilteredIOIState: '',
  newOfferId: '',
  prevIoiPayload: false,
};

const ADD_NOTIFICATION = 'notifications/add';
const APPLY_FILTERS = 'filters/applyFilters';

// getters
export const getters: GetterTree<IOIState, RootState> = {
  allIOIs: state => state.iois,
  matchedIOIs: state => state.myMatchedIOIs,
  ioisLength: state => state.iois.length,
  ioiWithOffers: state => state.myOffers,
  wishList: state => state.myWishList,
  wishListComputed: state =>
    state.iois.filter((ioi: IOI) => ioi.interestedUsers.length > 0),
  outStandingIois: state =>
    state.iois.filter((ioi: IOI) =>
      ['open', 'closing', 'closed'].includes(ioi.state),
    ),
  offTheMarketIOIs: state => state.myOffTheMarketIOIs,
  draftIOIs: state => state.myDraftIOIs,
  newOfferId: state => state.newOfferId,
  prevIoiPayload: state => state.prevIoiPayload,
};

// mutations
export const mutations: MutationTree<IOIState> = {
  ADD_IOI(state, ioi: IOI): void {
    state.iois.push(ioi);
  },

  EDIT_IOI(state, ioi: any): void {
    remove(
      state.iois,
      (element: IOI) =>
        element.indicationOfInterestId === ioi.indicationOfInterestId,
    );
    const nowDate: string = moment()
      .utc()
      .format('YYYY-MM-DD');
    const todayUnix: number = moment(nowDate).unix();
    state.iois.push(buildIOI(ioi, true, todayUnix, ''));
  },

  SAVE_PUSHED_IOI(state, ioi: IOI): void {
    state.iois = [
      ...state.iois.filter(
        (stateIoi: IOI) =>
          stateIoi.indicationOfInterestId !== ioi.indicationOfInterestId,
      ),
      ioi,
    ];
  },

  SET_HIGHLIGHT_IOI(state, ioiId: string): void {
    const index: number = findIndex(
      state.iois,
      (iterIOI: IOI) => iterIOI.indicationOfInterestId === ioiId,
    );
    if (index !== -1) {
      state.iois[index].highlight = !state.iois[index].highlight;
    }
  },

  SET_UNFILTERED_IOIS(state, unFilteredSet: FilteredIOISet): void {
    state.unfilteredIOIState = unFilteredSet.activeSet;
  },

  SET_FILTERED_IOIS(state, filteredPayload: FilteredIOISet): void {
    state.activeIOISet = filteredPayload.activeSet;
    if (state.activeIOISet === 'open') {
      state.iois = filteredPayload.filteredIOIs;
    } else if (state.activeIOISet === 'off-the-market') {
      state.myOffTheMarketIOIs = filteredPayload.filteredIOIs;
    } else if (state.activeIOISet === 'draft') {
      state.myDraftIOIs = filteredPayload.filteredIOIs;
    } else if (state.activeIOISet === 'matched') {
      state.myMatchedIOIs = filteredPayload.filteredIOIs;
    }
  },

  SET_IOIS(state, ioiSet): void {
    state.iois = ioiSet;
    state.unfilteredOpenSet = ioiSet;
    state.activeIOISet = 'open';
  },

  SET_MATCHED_IOIS(state, ioiSet): void {
    state.myMatchedIOIs = ioiSet;
    state.activeIOISet = 'matched';
    state.unfilteredMatchedSet = ioiSet;
  },

  SET_OFFERS(state, ioiSet): void {
    state.myOffers = ioiSet;
  },

  SET_WISHLIST(state, ioiSet): void {
    state.myWishList = ioiSet;
    state.activeIOISet = 'wishlist';
  },

  SET_OFF_THE_MARKET_IOIS(state, ioiSet): void {
    state.myOffTheMarketIOIs = ioiSet;
    state.activeIOISet = 'off-the-market';
    state.unfilteredOffTheMarket = ioiSet;
  },

  DELETE_OPEN_IOI(state, ioiId: string): void {
    state.iois = [
      ...state.iois.filter(
        (stateIoi: IOI) => stateIoi.indicationOfInterestId !== ioiId,
      ),
    ];
  },

  DELETE_IOI(state, ioiId: string): void {
    state.myOffTheMarketIOIs = remove(
      state.myOffTheMarketIOIs,
      (element: IOI) => element.indicationOfInterestId !== ioiId,
    );
    state.myDraftIOIs = remove(
      state.myDraftIOIs,
      (element: IOI) => element.indicationOfInterestId !== ioiId,
    );
  },

  SET_DRAFT_IOIS(state, ioiSet): void {
    state.myDraftIOIs = ioiSet;
    state.activeIOISet = 'draft';
    state.unfilteredDraftSet = ioiSet;
  },

  ADD_OFFER(state, newOfferId: string): void {
    state.newOfferId = newOfferId;
  },

  RESET_OFFER_ID(): void {
    state.newOfferId = '';
  },

  DELETE_OFFER(state, editOfferPayload: any): void {
    const { ioi, newOffer } = editOfferPayload;
    // // remove the offer on the ioiOffers set
    // remove(ioi.ioiOffers, (offer: Offer) => offer.offerId === newOffer.offerId);
    ioi.ioiOffers = ioi.ioiOffers.filter(
      (offer: Offer) => offer.offerId !== newOffer.offerId,
    );
    state.newOfferId = '';
  },

  ACCEPT_OFFER(state, newOffer: Offer): void {
    const ioiId: string = newOffer.createdForIndicationOfInterest;
    // remove the ioi from the open ioi set
    remove(
      state.iois,
      (element: IOI) => element.indicationOfInterestId === ioiId,
    );
  },

  ADD_TO_WISHLIST(state, ioi: IOI): void {
    const ioiId: string = ioi.indicationOfInterestId;
    if (!isEmpty(ioiId)) {
      let index: number = findIndex(
        state.myWishList,
        (iterIOI: IOI) => iterIOI.indicationOfInterestId === ioiId,
      );
      if (index !== -1) {
        // add the new offer to the ioi, if it was found
        state.myWishList = [...state.myWishList, ioi];
      }
      index = findIndex(
        state.iois,
        (iterIOI: IOI) => iterIOI.indicationOfInterestId === ioiId,
      );
      if (index !== -1) {
        state.iois[index].isFavorite = true;
      }
    }
  },

  DELETE_WISHLIST(state, ioiId: string): void {
    state.myWishList = state.myWishList.filter(
      (ioi: IOI) => ioi.indicationOfInterestId !== ioiId,
    );
  },

  SAVE_PAYLOAD(state, payload: PayloadIOI) {
    state.prevIoiPayload = payload;
  },
};

// @ts-ignore
export const actions: ActionTree<IOIState, RootState> = {
  prepopulateIOI(_: {}, payload: AccountPayload) {
    // this method gets the information for the IOI form
    return instrumentApi.prepopulateIOI(payload);
  },

  submitIOI({ commit, dispatch, rootState }, payloadIOI: DtoIOI) {
    // submit the ioi creation to the API
    return instrumentApi
      .createIOI(payloadIOI)
      .then(() => {
        dispatch('getIOIs', state.prevIoiPayload); // used to call add ioi
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'components.IOIForm.successNotification',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        const errorMessage: string = getApiError(error);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'components.IOIForm.errorIOISubmitNotification',
            message: errorMessage,
            type: 'error',
          },
          { root: true },
        );
        throw new Error(errorMessage);
      });
  },

  prepopulateEditIOI(_: {}, payload: PayloadInterested) {
    // this method gets the information for the IOI form
    return instrumentApi.prepopulateEditIOI(payload);
  },

  editIOI({ commit, dispatch, rootState }, payloadIOI: DtoIOI) {
    // submit the ioi creation to the API
    return instrumentApi
      .editIOI(payloadIOI)
      .then(() => {
        dispatch('getIOIs', state.prevIoiPayload); // used to call edit ioi
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'components.IOIForm.successUpdateNotification',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        const errorMessage: string = getApiError(error);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'components.IOIForm.errorIOIUpdateNotification',
            message: errorMessage,
            type: 'error',
          },
          { root: true },
        );
        throw new Error(errorMessage);
      });
  },

  saveFilteredIOI(
    { commit, dispatch, rootState },
    filterPayload: FilteredIOISet,
  ) {
    commit('SET_UNFILTERED_IOIS', filterPayload);
    commit('SET_FILTERED_IOIS', filterPayload);
  },

  getIOIs({ commit, dispatch, rootState }, payload: PayloadIOI) {
    // commit('SET_IOIS', []);
    // fetch actual indications of interest from the API
    return getIOIList(payload)
      .then((response: any) => {
        commit('SAVE_PAYLOAD', payload);
        const userAccountId = rootState.authentication.token.userAccountId;
        let ioiSet: IOI[] = [];
        const ioiOffTheMarketSet: IOI[] = [];
        const ioiDraftSet: IOI[] = [];
        const ioiClosingSet: IOI[] = [];
        const ioiClosedSet: IOI[] = [];
        const ioiFavoriteSet: IOI[] = [];
        const ioiWithOffersSet: IOI[] = [];
        const ioiMatchedSet: IOI[] = [];
        // stores datetime const nowUnix = Number(moment().format('X'));
        const nowDate: string = moment()
          .utc()
          .format('YYYY-MM-DD');
        const todayUnix: number = moment(nowDate).unix();
        const withOffers: boolean = payload.filters.offers === 'true';
        const openState: boolean = payload.filters.state.includes('open');
        const closingState: boolean = payload.filters.state.includes('closing');
        const expiredState: boolean = payload.filters.state.includes('expired');
        const withdrawnState: boolean = payload.filters.state.includes(
          'withdrawn',
        );
        const draftState: boolean = payload.filters.state.includes('draft');
        const matchedState: boolean = payload.filters.state.includes('matched');
        const showInterested: string | string[] = payload.filters.interested;
        const showPreferred: boolean = payload.preferred;
        forEach(response.data, (rawIOI: any) => {
          const iterIOI: IOI = buildIOI(
            rawIOI,
            withOffers,
            todayUnix,
            userAccountId,
          );
          if (showPreferred) {
            if (iterIOI.isPreference) {
              if (expiredState || withdrawnState) {
                ioiOffTheMarketSet.push(iterIOI);
              } else if (iterIOI.state === 'draft') {
                ioiDraftSet.push(iterIOI);
              } else if (iterIOI.state === 'closing') {
                ioiClosingSet.push(iterIOI);
              } else if (iterIOI.state === 'closed') {
                ioiClosedSet.push(iterIOI);
              } else {
                ioiSet.push(iterIOI);
              }
            }
          } else if (expiredState || withdrawnState) {
            ioiOffTheMarketSet.push(iterIOI);
          } else if (iterIOI.state === 'draft') {
            ioiDraftSet.push(iterIOI);
          } else if (iterIOI.state === 'closing') {
            ioiClosingSet.push(iterIOI);
          } else if (iterIOI.state === 'closed') {
            ioiClosedSet.push(iterIOI);
          } else if (iterIOI.isFavorite) {
            ioiSet.push(iterIOI);
            ioiFavoriteSet.push(iterIOI);
          } else {
            ioiSet.push(iterIOI);
          }
          if (iterIOI.state === 'matched') {
            ioiMatchedSet.push(iterIOI);
          }
          if (iterIOI.ioiOffers.length !== 0) {
            ioiWithOffersSet.push(iterIOI);
          }
        });

        if (expiredState || withdrawnState) {
          commit('SET_OFF_THE_MARKET_IOIS', ioiOffTheMarketSet);
        } else if (matchedState) {
          commit('SET_MATCHED_IOIS', ioiMatchedSet);
        } else if (draftState) {
          commit('SET_DRAFT_IOIS', ioiDraftSet);
        } else {
          ioiSet = union(ioiClosedSet, ioiClosingSet, ioiSet);
          if ((openState || closingState) && withOffers) {
            commit('SET_OFFERS', ioiWithOffersSet);
          } else if ((openState || closingState) && showInterested === 'true') {
            commit('SET_WISHLIST', ioiFavoriteSet);
          }
        }
        commit('SET_IOIS', ioiSet);
        dispatch(APPLY_FILTERS, {}, { root: true });
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'components.IOIForm.fetchingIOIs',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  ioiRequiredLists(): any {
    return instrumentApi.ioiRequiredLists();
  },

  spreadList({ commit }, countryPayload: any): any {
    return instrumentApi.spreadList(countryPayload);
  },

  withdrawIOI({ commit, dispatch, rootState }, ioiId: string) {
    // this method deletes an IOI
    return instrumentApi
      .withdrawIOI({ indicationOfInterestId: ioiId })
      .then(() => {
        // remove the IOI from the open ioi store
        commit('DELETE_OPEN_IOI', ioiId);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.myIssuerAccount.ioiWithdrawSuccess',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.myIssuerAccount.errorIOIWithdrawMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  deleteIOI({ commit, dispatch, rootState }, ioiId: string) {
    // this method deletes an IOI
    return instrumentApi
      .deleteIOI(ioiId)
      .then(() => {
        // remove the IOI from the myOffers store
        commit('DELETE_IOI', ioiId);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.myIssuerAccount.deleteIOISuccessMessage',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.myInvestorAccount.deleteIOIErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  sendToMarketIOI({ commit, dispatch, rootState }, ioiId: string) {
    return instrumentApi
      .sendToMarketIOI({ indicationOfInterestId: ioiId })
      .then(() =>
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myIssuerAccount.sendIOIToMarketSuccessMessage',
            type: 'success',
          },
          { root: true },
        ),
      )
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myIssuerAccount.sendIOIToMarketErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  copyIOI(
    { commit, dispatch, rootState },
    payload: {
      indicationOfInterestId: string;
      state: string;
      userAccountId: string;
    },
  ) {
    const sendToMarket: boolean = payload.state === 'open';
    // submit the ioi copy to the API
    return instrumentApi
      .copyIOI({
        indicationOfInterestId: payload.indicationOfInterestId,
        sendToMarket: sendToMarket,
        userAccountId: payload.userAccountId,
      })
      .then((copiedIOI: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.myIssuerAccount.copyIOISuccessMessage',
            type: 'success',
          },
          { root: true },
        );
        // obtain the new id from the api result and redirect the user to edit the ioi
        router.push({ name: 'ioi', params: { ioiId: copiedIOI.data.data } });
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.myIssuerAccount.copyIOIErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  // service worker's ioi methods
  savePushedIOI({ commit }, ioi: any): void {
    const nowUnix = Number(moment().format('X'));
    const newIOI: IOI = buildIOI(ioi, false, nowUnix, '');
    newIOI.highlight = true;
    commit('SAVE_PUSHED_IOI', newIOI);
  },

  removeIOINotification({ commit }, ioiPayload: any): void {
    commit('DELETE_OPEN_IOI', ioiPayload.indicationOfInterestId);
  },

  toggleHighlightIOI({ commit }, ioiId: string): void {
    commit('SET_HIGHLIGHT_IOI', ioiId);
  },

  // Offers
  createOffer({ commit, dispatch, rootState }, newOffer: Offer) {
    // submit the offer creation to the API
    const offer: any = { ...newOffer };
    // need this field
    return instrumentApi
      .createOffer(offer)
      .then((fullNewOffer: any) => {
        const offerId: string = fullNewOffer.data.data;
        commit('ADD_OFFER', offerId);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myInvestorAccount.createOfferSuccessMessage',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        commit('RESET_OFFER_ID');
        throw new Error(getApiError(error));
      });
  },

  editOffer({ commit, dispatch, rootState }, editOfferPayload: any) {
    // submit the offer update to the API
    return instrumentApi.editOffer(editOfferPayload.newOffer).then(() => {
      dispatch(
        ADD_NOTIFICATION,
        {
          labelToTranslate: 'views.myInvestorAccount.editOfferSuccessMessage',
          type: 'success',
        },
        { root: true },
      );
    });
  },

  withdrawOffer({ commit, dispatch, rootState }, editOfferPayload: any) {
    // this method deletes an offer
    return instrumentApi
      .withdrawOffer(editOfferPayload.newOffer.offerId)
      .then(() => {
        // remove the IOI from the store
        // commit('DELETE_OFFER', editOfferPayload);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myInvestorAccount.removeOfferSuccessMessage',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.myInvestorAccount.removeOfferErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  requestIndication({ commit, dispatch, rootState }, payload: any) {
    instrumentApi
      .requestIndication(payload)
      .then(() => {
        // remove the IOI from the store
        // commit('DELETE_OFFER', editOfferPayload);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myInvestorAccount.requestIndicationSuccessMessage',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myInvestorAccount.requestIndicationErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  acceptOffer(
    { commit, dispatch, rootState },
    offerAccepted: PayloadAcceptOffers,
  ) {
    // submit the offer update to the API
    return instrumentApi
      .acceptOffer(offerAccepted)
      .then((results: any) => {
        commit('ACCEPT_OFFER', offerAccepted);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.myIssuerAccount.acceptOfferSuccessMessage',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        const errorFromBE: string = getApiError(error);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.myIssuerAccount.acceptOfferErrorMessage',
            message: errorFromBE,
            type: 'error',
          },
          { root: true },
        );
        throw new Error(`${errorFromBE}`);
      });
  },

  // Wishlist
  addToWishList({ commit, dispatch, rootState }, payload: PayloadInterested) {
    // submit the offer creation to the API
    return instrumentApi
      .addTowishList(payload)
      .then(() => {
        commit('ADD_TO_WISHLIST', payload.indicationOfInterestId);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myInvestorAccount.addToWishListSuccessMessage',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myInvestorAccount.addToWishListErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  removeFromWishList(
    { commit, dispatch, rootState },
    payload: PayloadInterested,
  ) {
    // this method deletes an IOI from the wishlist
    return instrumentApi
      .removeFromWishList(payload)
      .then(() => {
        // remove the IOI from the open ioi store
        commit('DELETE_WISHLIST', payload.indicationOfInterestId);
        dispatch('getIOIs', state.prevIoiPayload);
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myInvestorAccount.removeFromWishlistSuccessMessage',
            type: 'success',
          },
          { root: true },
        );
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate:
              'views.myInvestorAccount.removeInterestedIOIErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  // Deals
  getIOIDeals({ dispatch, rootState }, ioiId: string) {
    // this method returns all the deals from a matched IOI
    return instrumentApi.getIOIDeals({ indicationOfInterestId: ioiId });
  },

  sendIOIEmails({ dispatch, rootState }, payload: object) {
    return new Promise((resolve, reject) => {
      instrumentApi
        .sendIOIEmails(payload)
        .then(response => {
          if (response.status === 202) {
            dispatch(
              ADD_NOTIFICATION,
              {
                labelToTranslate: 'components.emailInputs.succesEmails',
                type: 'success',
              },
              { root: true },
            );
            resolve();
          }
        })
        .catch((error: any) => {
          dispatch(
            ADD_NOTIFICATION,
            {
              labelToTranslate: 'components.emailInputs.errorEmails',
              message: getApiError(error),
              type: 'error',
            },
            { root: true },
          );
          reject(error);
          sentry(error);
        });
    });
  },
};

export const iois = {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
