



















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'UnderConstruction',
})
export default class UnderConstruction extends Vue {}
