import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { ProgressBarState } from '@/types/types';
import { RootState } from '@/types/rootState';

export const state: ProgressBarState = {
  progressBar: {
    type: '',
    title: '',
    totalSteps: 1,
    currentStep: 0,
    percentage: 0,
  },
};

// getters
export const getters: GetterTree<ProgressBarState, RootState> = {
  type: state => state.progressBar.type,
  title: state => state.progressBar.title,
  currentStep: state => state.progressBar.currentStep,
  totalSteps: state => state.progressBar.totalSteps,
  percentage: state => state.progressBar.percentage,
};

// mutations
export const mutations: MutationTree<ProgressBarState> = {
  CREATE(state, payload) {
    state.progressBar.type = payload.type;
    state.progressBar.title = payload.title;
    state.progressBar.totalSteps = payload.steps > 0 ? payload.steps : 1;
    state.progressBar.percentage = payload.percentage;
    state.progressBar.currentStep = payload.currentStep;
  },
  INC_STEP(state) {
    state.progressBar.currentStep += 1;
  },
  DEC_STEP(state) {
    state.progressBar.currentStep =
      state.progressBar.totalSteps - 1 >= 0
        ? state.progressBar.totalSteps - 1
        : 0;
  },
  SET_PERCENTAGE(state, value: number) {
    if (value > 0 && value <= 100) {
      state.progressBar.percentage = value;
    } else {
      state.progressBar.percentage = 0;
    }
  },
  SET_TITLE(state, title: string) {
    if (title) {
      state.progressBar.title = title;
    }
  },
};

// actions
export const actions: ActionTree<ProgressBarState, RootState> = {
  create({ commit, rootState }, payload): void {
    commit('CREATE', payload);
  },
  incStep({ commit, rootState }): void {
    commit('INC_STEP');
  },
  decStep({ commit, rootState }): void {
    commit('DEC_STEP');
  },
  setPercentage({ commit, rootState }, payload): void {
    commit('SET_PERCENTAGE', payload.percentage);
    commit('SET_TITLE', payload.title);
  },
};

export const progressBar = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
