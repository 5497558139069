



































































import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Provide } from 'vue-property-decorator';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import router from '@/router';
import { getApiError } from '@/api/util';

const NotificationsNameSpace = namespace('notifications/');
const SignupNameSpace = namespace('signup/');
@Component({
  name: 'SignUpButtonRow',
  components: { ButtonComponent },
})
export default class SignUpButtonRow extends Vue {
  @Provide() $validator: any = this.$validator;
  @SignupNameSpace.Getter('step') currentStep: number;
  @SignupNameSpace.Action('sendSignupForm') sendSignupForm: any;
  @SignupNameSpace.Action('sendSignupFormFull') sendSignupFormFull: any;
  @SignupNameSpace.Action('sendSignupUpgradeForm') sendSignupUpgradeForm: any;
  @SignupNameSpace.Action('sendSignupFormInvitation')
  sendSignupFormInvitation: any;

  @SignupNameSpace.Action('setStep') setStep: any;
  @SignupNameSpace.Getter('accountType') accountType: string;
  @SignupNameSpace.Getter('upgrading') upgrading: boolean;
  @SignupNameSpace.Getter('inviting') inviting: boolean;
  @SignupNameSpace.Getter('finalStep') finalStep: any;
  @SignupNameSpace.Getter('lastFormStep') lastFormStep: any;
  @SignupNameSpace.Getter('formQuestionsOne') formQuestionsOne: any;
  @SignupNameSpace.Getter('formQuestionsTwo') formQuestionsTwo: any;
  @NotificationsNameSpace.Action('add') addNotification: any;

  apiErrorMessage: {} = '';
  errors: any;

  validateStepOne() {
    const promises = [];
    for (let i = 0; i < Object.keys(this.formQuestionsOne).length; i += 1) {
      promises.push(
        this.$validator.validate(Object.keys(this.formQuestionsOne)[i]),
      );
    }
    return Promise.all(promises);
  }

  validateStepTwo() {
    const promises = [];
    for (let i = 0; i < Object.keys(this.formQuestionsTwo).length; i += 1) {
      promises.push(
        this.$validator.validate(Object.keys(this.formQuestionsTwo)[i]),
      );
    }
    return Promise.all(promises);
  }

  completeSignup() {
    // validates all MV fields then sends form or scrolls to error
    this.validateStepOne().then(result => {
      if (!result.includes(false)) {
        this.sendForm();
      } else {
        this.scrollToError();
      }
    });
  }

  completeSignupFull() {
    this.validateStepOne().then(result => {
      if (!result.includes(false)) {
        this.validateStepTwo().then(resultTwo => {
          if (!resultTwo.includes(false)) {
            if (this.upgrading) {
              this.sendUpgradeForm();
            } else if (this.inviting) {
              this.sendInvitationForm();
            } else {
              this.sendFormFull();
            }
          } else {
            this.scrollToError();
          }
        });
      } else {
        router.push({ name: 'signupnew', params: { step: '1' } }).then(() => {
          this.scrollToError();
        });
      }
    });
  }

  scrollToError() {
    const ref: any = this.errors.items[0].field;
    // using queryselector and DOM because else we would have to send all inputrefs to the state
    // when we know for a fact the inputelement with this name currently exists due
    // to the $validator
    const firstInputFieldWithError = document.querySelector(
      `[name=${ref}`,
    ) as HTMLInputElement;
    firstInputFieldWithError.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  stepBack() {
    router.push({
      name: 'signupnew',
      params: { step: (this.currentStep - 1).toString() },
    });
  }

  nextStep() {
    // validates all fields of step 1 of the signupfull process then goes to next step or
    // scrolls to error
    this.validateStepOne().then(result => {
      if (!result.includes(false)) {
        router.push({
          name: 'signupnew',
          params: { step: (this.currentStep + 1).toString() },
        });
      } else {
        this.scrollToError();
      }
    });
  }

  sendForm() {
    this.apiErrorMessage = '';
    this.sendSignupForm()
      .then(() => {
        router.push({
          name: 'signupnew',
          params: { step: (this.currentStep + 1).toString() },
        });
      })
      .catch((error: any) => {
        this.handleApiError(error);
      });
  }

  sendFormFull() {
    this.apiErrorMessage = '';
    this.sendSignupFormFull()
      .then(() => {
        router.push({
          name: 'signupnew',
          params: { step: (this.currentStep + 1).toString() },
        });
      })
      .catch((error: any) => {
        this.handleApiError(error);
      });
  }

  sendUpgradeForm() {
    this.apiErrorMessage = '';
    this.sendSignupUpgradeForm()
      .then(() => {
        router.push({
          name: 'signupnew',
          params: { step: (this.currentStep + 1).toString() },
        });
      })
      .catch((error: any) => {
        this.handleApiError(error);
      });
  }

  sendInvitationForm() {
    this.apiErrorMessage = '';
    this.sendSignupFormInvitation()
      .then(() => {
        router.push({
          name: 'signupnew',
          params: { step: (this.currentStep + 1).toString() },
        });
      })
      .catch((error: any) => {
        this.handleApiError(error);
      });
  }

  handleApiError(error: any) {
    this.apiErrorMessage = getApiError(error);
    this.addNotification({
      message: `${this.$t('components.signUpForm.submittingError')} ${
        this.apiErrorMessage
      }`,
      type: 'error',
    });
  }
}
