




































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TextSlider from '@/components/utils/TextSlider.vue';

@Component({
  name: 'LandingInfo',
  components: {
    TextSlider,
  },
})
export default class LandingInfo extends Vue {}
