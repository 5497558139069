




























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import InputComponent from '@/components/inputs/InputComponent.vue';
import { CustomInput, CustomFileInput } from '@/components/inputs/inputTypes';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import InputDateComponent from '@/components/inputs/InputDateComponent.vue';
import InputFileComponent from '@/components/inputs/InputFileComponent.vue';
import InputTimePickerComponent from '@/components/inputs/InputTimePickerComponent.vue';
import CollapsibleComponent from '@/components/utils/CollapsibleComponent.vue';

const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');
const IRPNameSpace = namespace('irps/');

@Component({
  name: 'IRPForm',
  components: {
    ButtonComponent,
    InputComponent,
    InputDateComponent,
    InputFileComponent,
    InputTimePickerComponent,
    CollapsibleComponent,
  },
})
export default class IRPForm extends Vue {
  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // Interest Rate Pages store
  @IRPNameSpace.Action('submitIRPData') submitIRPData: any;

  myAccount = this.getUserAccountId;
  irpFormTitle: string = '';
  irpFormBody: string[] = [];

  inputIRPDate: any = {
    name: 'irpDate',
    vRule: {
      required: true,
    },
    optionList: [],
    value: undefined,
    defaultTooltip: true,
    placeHolder: 'views.IRPForm.irpDateTooltip',
    vLabel: 'views.IRPForm.irpDate',
    class: ['small'],
  };

  inputIRPTime: any = {
    name: 'irpTime',
    placeHolder: 'views.IRPForm.irpTimePlaceHolder',
    vRule: 'required',
    defaultTooltip: true,
    containerClass: 'form-select__time-picker',
    hideVLabel: true,
    translate: false,
    value: undefined,
  };

  inputIRPFile: any = {
    name: 'irpFile',
    placeHolder: 'views.IRPForm.irpFilePlaceHolder',
    vRule: {
      ext: 'csv',
      required: true,
      size: 2000,
    },
    hideVLabel: false,
  };

  csvIRPFile: string | null = '';
  csvIRPFileName: string | null = '';

  setInputValue(inputValue: CustomInput) {
    switch (inputValue.name) {
      case 'irpDate':
        this.inputIRPDate.value = inputValue.value;
        break;
      case 'irpTime':
        this.inputIRPTime.value = inputValue.value;
        break;
      default:
        break;
    }
  }

  // handle file input components
  setInputFileValue(inputValue: CustomFileInput) {
    if (inputValue.inputName === 'irpFile') {
      this.csvIRPFileName = inputValue.fileName;
      this.csvIRPFile = inputValue.fileValue;
    }
  }

  get uploadIRPDataButton(): any {
    return {
      buttonClass: 'button-icon',
      description: this.$t('views.IRPForm.uploadButton'),
      extendedDescription: this.$t('views.IRPForm.uploadButton'),
      icon: 'cloud-upload.png',
    };
  }

  validateForm() {
    const errorString: string = `${this.$t(
      'views.IRPForm.irpSubmittingError',
    )}`;
    this.$validator
      .validateAll()
      .then(result => {
        if (!result) {
          this.addNotification({
            message: `${this.$t('components.signUpFullForm.validationError')}`,
            type: 'error',
          });
        } else {
          this.uploadIRPSubmit();
        }
      })
      .catch(error =>
        this.addNotification({
          message: `${errorString} ${error}`,
          type: 'error',
        }),
      );
  }

  uploadIRPSubmit() {
    this.submitIRPData({
      userAccountId: this.myAccount,
      snapshotDate: this.inputIRPDate.value,
      snapshotTime: this.inputIRPTime.value,
      irpDataFile: this.csvIRPFile,
    });
  }

  created() {
    const accountErrorMessage: string = `${this.$t(
      'views.IRPForm.accountErrorMessage',
    )}`;
    this.authenticateLogin(accountErrorMessage).then(() => {
      this.myAccount = this.getUserAccountId;
      this.irpFormTitle = 'views.IRPForm.irpExplanationTitle';
      this.irpFormBody = ['views.IRPForm.irpExplanationBody1'];
    });
  }
}
