

































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'TermsOfUse',
})
export default class TermsOfUse extends Vue {}
