import store from '../store';
import { EventAc } from '@/types/types';
import {
  getBucketSize,
  getIndustry,
  getMaturityBucket,
  getRankingGuarantee,
  getRankingType,
  instrumentsLabel,
} from '@/util/ioiUtils';
import router from '@/router';
import moment from 'moment';
import { EventTypes } from '@/store/actionCenter';

export function redirectUserPage() {
  let response: string = 'landing-page';
  if (store.getters['authentication/checkUserLoggedIn']) {
    if (store.getters['authentication/isIssuer']) {
      response = 'myaccount-issuer';
    } else if (store.getters['authentication/isMarketViewer']) {
      response = 'ioi-list-viewer';
    } else if (store.getters['authentication/isLeadManager']) {
      response = 'myaccount-lm';
    } else if (store.getters['authentication/isBackOffice']) {
      response = 'backoffice-page';
    } else if (store.getters['authentication/isInvestor']) {
      response = 'ioi-list-investor';
    }
  }

  return response;
}

export function neatifyString(string: any) {
  let neatString = string;
  if (typeof string === 'string') {
    neatString = neatString.trim();
  }
  return neatString;
}

// build events in the action centre
export function buildEvent(event: EventAc): EventAc {
  let description: string = '';
  const read: boolean = event.read !== undefined ? event.read : false;
  if (
    event.type &&
    (event.type === EventTypes.IOI_CREATED ||
      event.type === EventTypes.IOI_EDITED ||
      event.type === EventTypes.IOI_EXPIRED ||
      event.type === EventTypes.IOI_CLOSED ||
      event.type === EventTypes.IOI_WITHDRAWN)
  ) {
    description = `${getIndustry(event.payload.industryOrSector)} `;
    description += `| ${getRankingGuarantee(event.payload.rankingGuarantee)}`;
    description += `| ${getRankingType(event.payload.rankingType)} `;
    description += `| ${instrumentsLabel(event.payload.instrumentTypes)}`;
    description += `| ${getBucketSize(event.payload.bucketSize)} `;
    description += `| ${event.payload.currencyType} - ${getMaturityBucket(
      event.payload.maturityBucket,
    )}`;
  }
  let eventTitle: string = '';
  switch (event.type) {
    case EventTypes.IOI_CREATED:
      eventTitle = `${router.app.$t('views.events.eventTitles.ioiCreated')}`;
      break;
    case EventTypes.IOI_EDITED:
      eventTitle = `${router.app.$t('views.events.eventTitles.ioiUpdated')}`;
      break;
    case EventTypes.IOI_WITHDRAWN:
      eventTitle = `${router.app.$t('views.events.eventTitles.ioiWithdrawn')}`;
      break;
    case EventTypes.IOI_CLOSED:
      eventTitle = `${router.app.$t('views.events.eventTitles.ioiClosed')}`;
      break;
    case EventTypes.IOI_EXPIRED:
      eventTitle = `${router.app.$t('views.events.eventTitles.ioiExpired')}`;
      break;
    case EventTypes.MULTI_EYE_REQUEST_OFFER:
      eventTitle = `${router.app.$t(
        'views.events.eventTitles.MultiEyeRequestForOffer',
        {
          offerAuthorUserName: event.payload.offerAuthorUserName,
          offerToCompanyName: event.payload.offerToCompanyName,
          offerFromCompanyName: event.payload.offerFromCompanyName,
        },
      )}`;
      description = `${router.app.$t(
        'components.IOIOffer.myOfferMinSizeLabel',
      )}: ${event.payload.offerMinSize} |
      ${router.app.$t('components.IOIOffer.myOfferMaxSizeLabel')}:
      ${event.payload.offerMaxSize}
       ${
         event.payload.spreadBasisPoints
           ? `| ${router.app.$t('components.IOIOffer.spreadOption')}: ${
               event.payload.spreadBasisPoints
             }`
           : ''
       }
       ${
         event.payload.spreadOverWhat
           ? `| ${router.app.$t('components.IOIForm.spreadOverWhat')}: ${
               event.payload.spreadOverWhat
             }`
           : ''
       }`;
      break;
    default:
      eventTitle = event.type;
      break;
  }
  const time = new Date(event.occurredAt * 1000);
  return {
    ...event,
    // occuredAtTime: `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`,
    occuredAtTime: moment(new Date(event.occurredAt * 1000)).format('HH:mm:ss'),
    occurredAtDay: moment(new Date(event.occurredAt * 1000)).format(
      'DD-MM-YYYY',
    ),
    eventTitle,
    description,
    read,
  };
}

export function makeWsUrl(response: any) {
  let token: string = response.data.notificationsToken;
  const wssUri = new URL(process.env.VUE_APP_WS_NOTIFICATION_SERVER);
  wssUri.searchParams.append('token', token);
  return wssUri;
}

export function checkIfPageResponsive(pageName: string | null | undefined) {
  return (
    pageName !== 'home' &&
    pageName !== 'login' &&
    pageName !== 'landing-page' &&
    pageName !== 'contact-page' &&
    pageName !== 'faq-page' &&
    pageName !== 'signupnew' &&
    pageName !== 'blog-overview' &&
    pageName !== 'blog-detail' &&
    pageName !== 'logout'
  );
}

export function compareValues(key: string, order: 'asc' | 'desc') {
  return (a: any, b: any) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

export function permAvailable(permissionToCheck: string) {
  if (!store.getters['companies/selectedCompanyData'].permissions) {
    return false;
  }
  if (
    store.getters['companies/selectedCompanyData'].permissions.includes(
      permissionToCheck,
    )
  ) {
    return true;
  }
}
