


























































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'SignUpIssuer',
})
export default class SignUpIssuer extends Vue {
  @Prop({}) fullSignUpParameter!: boolean;
}
