'use strict';

import api from './index';
import { EventPayload } from '@/types/types';

export default {
  getMyUnreadEvents(accountId: string) {
    return api.get('/notification/unread');
  },

  getMyEvents() {
    return api.get('/notification/');
  },

  markEventAsRead(payload: EventPayload) {
    return api.post('/notification/mark-as-read', payload);
  },

  deleteEvent(payload: EventPayload) {
    // TODO: when the backend has the actual delete-notification endpoint,
    // this should be checked and tested
    return api.post('/notification/delete', payload);
  },
};
