




























































import Vue from 'vue';
import { Component, Provide, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import InputComponent from '@/components/inputs/InputComponent.vue';
import InputFileComponent from '@/components/inputs/InputFileComponent.vue';
import InputRadioComponent from '@/components/inputs/InputRadioComponent.vue';
import InputSelect from '@/components/inputs/InputSelect.vue';
import { CustomInput, CustomFileInput } from '@/components/inputs/inputTypes';
import { EventBus } from '@/main';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import countriesJson from '@/assets/data/countries';
import { getIndustry } from '@/util/ioiUtils';
import { neatifyString } from '@/util/generalUtils';

const SignupNameSpace = namespace('signup/');
const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'UpgradeForm',
  components: {
    InputComponent,
    InputRadioComponent,
    ButtonComponent,
    InputFileComponent,
    InputSelect,
  },
})
export default class UpgradeForm extends Vue {
  @SignupNameSpace.Action('setFormOption') setFormOption: any;
  @SignupNameSpace.Getter('step') currentStep: number;
  @SignupNameSpace.Getter('accountType') accountType: any;
  @SignupNameSpace.Action('setStep') setStep: any;
  @SignupNameSpace.Action('setAccountType') setAccountType: any;
  @AuthenticationNameSpace.Action('prepopulateProfessionalSignUp')
  prepopulateProfessionalSignUp: any;

  @Provide() $validator: any;
  getIndustry = getIndustry;

  eventBus = EventBus;
  password: string | null = '';
  prepopData: {
    companyName: string;
    firstName: string;
    industryOrSector: string;
    lastName: string;
    middleName: string;
  } = {
    companyName: '',
    firstName: '',
    industryOrSector: '',
    lastName: '',
    middleName: '',
  };

  formOne: any = {
    street: {
      compType: 'InputComponent',
      name: 'street',
      placeHolder: 'components.signUpFullForm.street',
      vRule: 'required|alpha_spaces',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'three-fifth',
    },
    houseNumber: {
      compType: 'InputComponent',
      name: 'houseNumber',
      placeHolder: 'components.signUpFullForm.houseNumber',
      vRule: 'required|numeric',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'one-fifth',
    },
    houseNumberSuffix: {
      compType: 'InputComponent',
      name: 'houseNumberSuffix',
      placeHolder: 'components.signUpFullForm.numberSuffix',
      vRule: 'alpha_num',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'one-fifth',
    },
    postalCode: {
      compType: 'InputComponent',
      name: 'postalCode',
      placeHolder: 'components.signUpFullForm.postalCode',
      vRule: { required: true, regex: /^[\w\-\s]+$/ }, // regex => alpha_num + spaces
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'two-fifth',
    },
    city: {
      compType: 'InputComponent',
      name: 'city',
      placeHolder: 'components.signUpFullForm.city',
      vRule: 'required|alpha_spaces',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'three-fifth',
    },
    country: {
      compType: 'InputSelect',
      name: 'countryCode',
      placeHolder: 'components.signUpFullForm.country',
      vRule: 'required',
      containerClass: 'input-inner',
      class: 'form-input',
      optionList: countriesJson.countries,
      value: undefined,
      showLabel: true,
      formClass: 'input-container',
    },
  };

  formExtracts: any = {
    kvkFile: {
      compType: 'InputFileComponent',
      name: 'kvkFile',
      label: 'components.signUpFullForm.kvkExtract',
      placeHolder: 'components.signUpFullForm.kvkPDF',
      vRule: { ext: 'pdf', required: true, size: 2000 },
      showLabel: true,
      hideVLabel: false,
      containerClass: 'input-inner',
      class: 'form-input',
      value: null,
    },
    leiNumber: {
      compType: 'InputComponent',
      name: 'leiNumber',
      placeHolder: 'components.signUpFullForm.leiNumber',
      vRule: 'alpha_num|length:20',
      showLabel: true,
      containerClass: 'input-inner',
      class: 'form-input',
      value: null,
    },
    identificationFile: {
      compType: 'InputFileComponent',
      name: 'identificationFile',
      label: 'components.signUpFullForm.proofOfIdentity',
      placeHolder: 'components.signUpFullForm.identification',
      vRule: { ext: 'pdf', required: true, size: 5000 },
      showLabel: true,
      hideVLabel: false,
      value: null,
      containerClass: 'input-inner',
      class: 'form-input',
    },
    terms: {
      name: 'terms',
      compType: 'InputRadioComponent',
      optionList: [
        {
          id: 'terms',
          value: '1',
          labelCode: 'components.signUpFullForm.termsOfUse',
          static: false,
        },
      ],
      vRule: 'required',
      wrapperClass: 'input-container radio-no-stuff',
      verticalRadioButton: false,
      showImage: true,
    },
  };

  get firstForm(): any {
    let form = Object.values(this.formOne);
    if (this.accountType !== 'marketviewer') {
      form = form.filter((input: any) => !input.marketviewerOnly);
    } else {
      form = this.formOne;
    }

    return form;
  }

  mounted() {
    this.prepopulateProfessionalSignUp().then((data: any) => {
      this.prepopData = data.data;
    });
  }

  setInputValue(inputValue: CustomInput) {
    // check if validation available
    if (this.$validator.errors) {
      // check if field has been validated else set empty
      if (!this.$validator.errors.has(inputValue.name)) {
        this.setFormOption({
          name: inputValue.name,
          value: neatifyString(inputValue.value),
        });
      } else {
        this.setFormOption({
          name: inputValue.name,
          value: '',
        });
      }
    }
  }

  // handle file input components
  setInputFileValue(inputValue: CustomFileInput) {
    // check if validation available
    if (this.$validator.errors) {
      // check if field has been validated else set empty
      if (!this.$validator.errors.has(inputValue.inputName)) {
        this.setFormOption({
          name: inputValue.inputName,
          value: inputValue.fileValue,
        });
      } else {
        this.setFormOption({
          name: inputValue.inputName,
          value: '',
        });
      }
    }
  }
}
