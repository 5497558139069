





































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ClientTable } from 'vue-tables-2';
import InputSelectComponent from '@/components/inputs/InputSelectComponent.vue';
import Confirm from '@/components/utils/Confirm.vue';
import { EventBus } from '@/main';
import InputSelect from '@/components/inputs/InputSelect.vue';
import { CustomInput, InputObject } from '@/components/inputs/inputTypes';
import StatusIndicator from '@/components/utils/statusIndicator.vue';
import { CompanyItem, roleObject, TabList, UserItem } from '@/types/types';
import api from '@/api';
import { setstatusLabel } from '@/util/companyUtils';
import LoadingIndicator from '@/components/utils/LoadingIndicator.vue';
import {
  assignRole,
  getAccountManagers,
  removeAccountManager,
  transferHamRights,
} from '@/api/companyApi';

const ModalNameSpace = namespace('modals/');
const CompanyStore = namespace('companies/');
const tabState = namespace('tabState/');
@Component({
  name: 'CompanyUsers',
  components: {
    LoadingIndicator,
    StatusIndicator,
    InputSelect,
    Confirm,
    InputSelectComponent,
    ClientTable,
  },
})
export default class CompanyUsers extends Vue {
  @Prop({}) roles!: roleObject[];
  @Prop({}) accountManagers!: UserItem[];

  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  @CompanyStore.Getter('cacheCompanySettings')
  cacheCompanySettings: any;

  @CompanyStore.Getter('selectedCompanyIdGetter')
  selectedCompanyIdGetter: string;

  @CompanyStore.Action('getRoleList') getRoleList: any;

  @tabState.Action('setTab') setTab: any;
  columns = [
    'name',
    'accountStatusString',
    'email',
    'accountType',
    'action',
    'updateStatus',
  ];

  randomNumber = Math.random();
  changedRow: number = 0;
  eventBus = EventBus;
  gettingUsers: boolean = false;

  @Watch('accountManagers')
  setCompany() {
    if (this.cacheCompanySettings.value !== 'placeholder') {
      this.gettingUsers = true;
      this.tableData = this.accountManagers.map((user: any) => {
        let userL: UserItem = user;
        userL.name = user.firstName
          ? `${user.firstName} ${user.middleName} ${user.lastName}`
          : '';
        userL.accountType = {
          value: user.accountManagerStatus,
          label: this.setAccTypeLabel(user.accountManagerStatus),
        };
        userL.updateStatus = '';
        userL.accountStatusString = setstatusLabel(user.accountManagerStatus);

        return userL;
      });
      this.changedRow = 0;
      this.gettingUsers = false;
    }
  }

  tableData: UserItem[] = [
    {
      userAccountId: '',
      accountStatus: 0,
      accountStatusString: '',
      email: '',
      accountType: { value: '0', label: '' },
      updateStatus: '',
      name: '',
      firstName: '',
      middleName: '',
      lastName: '',
      phoneNumber: '',
      locale: '',
      accountConfirmation: '',
      accountManagerStatus: 0,
      permissions: [],
    },
  ];

  get optionListAccountType() {
    const mappedRoles = this.roles.map((role: roleObject) => {
      return {
        value: role.id,
        label: role.name,
      };
    });
    return [
      ...mappedRoles,
      {
        value: 'default',
        label: 'default',
      },
      {
        value: 'ham',
        label: 'Head account manager',
      },
    ];
  }

  get options() {
    return {
      filterable: false,
      headings: {
        name: this.$t('components.companyUsers.tableName'),
        accountStatusString: this.$t('components.linkedCompanies.tableStatus'),
        email: this.$t('components.companyUsers.tableEmail'),
        accountType: this.$t('components.linkedCompanies.tableType'),
        action: this.$t('components.linkedCompanies.tableAction'),
        updateStatus: this.$t('components.linkedCompanies.tableSync'),
      },
      uniqueId: 'id',
      sortable: ['name', 'accountStatusString', 'email'],
    };
  }

  created() {
    this.setCompany();
    this.eventBus.$on('reloadCompanyUsers', () => this.setCompany());
  }

  setAccTypeLabel(status: number) {
    switch (status) {
      case 0:
        return 'Pending';
      case 1:
        return 'Account manager';
      case 2:
        return 'Denied';
      case 3:
        return 'Head account manager';
      default:
        return '';
    }
  }

  deleteRow() {
    this.tableData.splice(this.changedRow, 1);
    this.changedRow = 0;
  }

  openConfirmRemoveUser(row: any) {
    this.toggleModal('confirmRemoveUser');
    this.changedRow = this.tableData.findIndex(
      tRow => tRow.userAccountId === row.userAccountId,
    );
  }

  setAccType(inputValue: any) {
    this.changedRow = this.tableData.findIndex(
      row => row.userAccountId === inputValue.name,
    );

    // check if there is only 1 ham
    if (inputValue.value === 'ham') {
      this.toggleModal('confirmHamChange');
    }
  }

  setRole(event: any, accountManager: any) {
    if (event.value === 'ham') {
      this.setAccType(event);
    } else {
      this.changedRow = this.tableData.findIndex(
        row => row.userAccountId === accountManager.userAccountId,
      );
      Vue.set(this.tableData, this.changedRow, {
        ...this.tableData[this.changedRow],
        updateStatus: 'loading',
      });
      assignRole(
        {
          roleId: event.value,
          accountManagerId: accountManager.userAccountId,
        },
        this.selectedCompanyIdGetter,
      )
        .then(() => {
          Vue.set(this.tableData, this.changedRow, {
            ...this.tableData[this.changedRow],
            updateStatus: 'completed',
            roleId: event.value,
            role: event.label,
          });
        })
        .catch(() => {
          Vue.set(this.tableData, this.changedRow, {
            ...this.tableData[this.changedRow],
            updateStatus: 'failed',
          });
        });
    }
  }

  cancelHamChange() {
    // re renders the select fields with the data from their props
    this.randomNumber += 1;
  }

  confirmHamChange() {
    Vue.set(this.roles, this.changedRow, {
      ...this.roles[this.changedRow],
      updateStatus: 'loading',
    });
    transferHamRights({
      companyId: this.selectedCompanyIdGetter,
      userAccountId: this.tableData[this.changedRow].userAccountId,
    })
      .then(() => {
        this.setCompany();
        this.setTab({ key: 'settingsTabs', value: 0 });
      })
      .catch(() => {
        Vue.set(this.roles, this.changedRow, {
          ...this.roles[this.changedRow],
          updateStatus: 'failed',
        });
      });
  }

  confirmRemoveUser() {
    removeAccountManager({
      companyId: this.selectedCompanyIdGetter,
      userAccountId: this.tableData[this.changedRow].userAccountId,
    })
      .then(() => {
        this.deleteRow();
      })
      .catch(() => {
        Vue.set(this.roles, this.changedRow, {
          ...this.roles[this.changedRow],
          updateStatus: 'failed',
        });
      });
  }
}
