

























import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.min.css';
import { Validator } from 'vee-validate';
import { isEmpty } from 'lodash';
import { CustomInput, InputObject, ReturnInput } from './inputTypes';
import { countries, countryList } from '@/api/authenticationApi';

@Component({
  name: 'InputPhoneNumberComponent',
})
export default class InputPhoneNumberComponent extends Vue {
  @Prop({}) inputParam!: InputObject;
  @Prop({}) formClass?: string;
  @Inject('$validator') $validator!: Validator;

  intlTelInput: any = false;
  initialising: boolean = false;

  vModelValue: string | null = null;

  get fullNumber(): { fullNumber: string | null; intlTelInput: any } {
    return {
      fullNumber: this.vModelValue,
      intlTelInput: this.intlTelInput,
    };
  }

  get inputName() {
    return isEmpty(this.inputParam.name) ? ' ' : this.inputParam.name;
  }

  get inputVRule() {
    if (this.inputParam.vRule === null) {
      return ' ';
    }
    return this.inputParam.vRule;
  }

  get errorLabel() {
    if (
      isEmpty(this.inputParam.vLabel) &&
      isEmpty(this.inputParam.placeHolder)
    ) {
      return '';
    }
    if (isEmpty(this.inputParam.vLabel) || this.inputParam.vLabel === null) {
      return this.inputPlaceHolder;
    }
    return `${this.$t(this.inputParam.vLabel)}`;
  }

  get inputPlaceHolder(): string {
    return isEmpty(this.inputParam.placeHolder)
      ? ''
      : `${this.$t(this.inputParam.placeHolder)}`;
  }

  get inputHasError(): boolean {
    return (
      this.$validator.errors.has(this.inputParam.name) ||
      this.inputParam.isValid === false
    );
  }

  get isInputCorrect(): boolean {
    return (
      this.vModelValue !== null &&
      !this.$validator.errors.has(this.inputParam.name) &&
      !(this.inputParam.isValid === false)
    );
  }

  get inputContainerClass(): string | null {
    return isEmpty(this.inputParam.containerClass)
      ? 'input-noclass-container'
      : this.inputParam.containerClass;
  }

  get customClass(): string[] {
    const classes: string[] = [];
    if (this.inputHasError) {
      classes.push('has-error');
    }
    if (this.isInputCorrect) {
      classes.push('correct');
    }
    if (this.inputParam.class !== null) {
      classes.push(this.inputParam.class);
    }
    return classes;
  }

  get showVLabel(): boolean {
    return (
      !this.inputParam.hideVLabel &&
      this.$validator.errors.has(this.inputParam.name)
    );
  }

  get inputDisabled(): boolean {
    return this.inputParam.disabled !== undefined && this.inputParam.disabled;
  }

  get inputTooltip(): any {
    if (this.inputParam.defaultTooltip) {
      return { placement: 'bottom-center', content: this.inputPlaceHolder };
    }
    if (
      this.inputParam.tooltip === undefined ||
      this.inputParam.tooltip === null
    ) {
      return null;
    }
    const tooltipContent: string | null = `${this.$t(this.inputParam.tooltip)}`;
    return { placement: 'bottom-center', content: tooltipContent };
  }

  mounted() {
    const input: Element = this.$refs.inputRef as Element;
    const countryListCodes = countryList().map((country: any) => country.value);
    this.$validator.pause();
    this.intlTelInput = intlTelInput(input, {
      utilsScript: '/intl-tel-input-utils.js',
      onlyCountries: countryListCodes,
      initialCountry: 'NL',
    });
    if (this.inputParam.value) {
      this.intlTelInput.setNumber(this.inputParam.value);
    }
    this.$nextTick(() => {
      this.$validator.resume();
    });

    input.addEventListener('countrychange', () => {
      this.inputChange();
    });
  }

  inputChange() {
    const returnInput: ReturnInput = {
      name: this.inputParam.name,
      value: this.intlTelInput.getNumber(),
    };
    if (!this.initialising) {
      this.$emit('sendInput', returnInput);
    }
  }
}
