























































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CompanyUsers from '@/components/settings/CompanyUsers.vue';
import EmailInputs from '@/components/ioi/EmailInputs.vue';
import EditCompany from '@/components/settings/EditCompany.vue';
import AddEditCompany from '@/components/settings/AddEditCompany.vue';
import ModalWindow from '@/components/utils/Modal.vue';
import SideMenu from '@/components/utils/SideMenu.vue';
import InputSelectCompany from '@/components/inputs/InputSelectCompany.vue';
import HistoryLog from '@/components/utils/HistoryLog.vue';
import { HistoryLogType } from '@/types/enums.ts';
import InputCheckboxComponent from '@/components/inputs/InputCheckboxComponent.vue';
import { getRoles, getAccountManagers } from '@/api/companyApi';
import { Permission, roleObject, UserItem } from '@/types/types';
import { EventBus } from '@/main';
import InputComponent from '@/components/inputs/InputComponent.vue';
import StatusIndicator from '@/components/utils/statusIndicator.vue';
import { permAvailable } from '@/util/generalUtils';
import PermissionManager from '@/components/settings/PermissionManager.vue';
import CompanySettingsSwitcher from '@/components/settings/CompanySettingsSwitcher.vue';
const AuthenticationNameSpace = namespace('authentication/');
const ModalNameSpace = namespace('modals/');
const CompanyStore = namespace('companies/');
@Component({
  name: 'CompanySettings',
  components: {
    CompanySettingsSwitcher,
    PermissionManager,
    StatusIndicator,
    InputComponent,
    InputCheckboxComponent,
    InputSelectCompany,
    SideMenu,
    ModalWindow,
    AddEditCompany,
    EditCompany,
    CompanyUsers,
    EmailInputs,
    HistoryLog,
  },
})
export default class CompanySettings extends Vue {
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  @AuthenticationNameSpace.Getter('isHAM') isHAM: boolean;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @CompanyStore.Getter('companyListGetter') companyListGetter: [];
  @CompanyStore.Getter('roleListGetter') roleListGetter: [];
  @CompanyStore.Getter('selectedCompanyIdGetter')
  selectedCompanyIdGetter: string;

  @CompanyStore.Getter('selectedCompanyData')
  selectedCompanyData: string;

  @CompanyStore.Getter('cacheCompanySettings')
  cacheCompanySettings: any;

  menuTitles: { title: string; ref: HTMLHeadingElement }[] = [];
  addEditModalTitle: string = '';
  addEditModalMode: string = '';
  eventBus = EventBus;
  inputSettingsCompany: any = {
    name: 'companySettings',
    containerClass: 'side-menu__select',
    optionList: [],
    value: undefined,
  };

  permAvailable = permAvailable;
  Permission = Permission;
  roles: roleObject[] = [];
  accountManagers: UserItem[] = [];

  @Watch('selectedCompanyData')
  getRoles() {
    if (permAvailable(Permission.manage_company_permissions)) {
      getRoles(this.selectedCompanyIdGetter).then(
        roles =>
          (this.roles = roles.data.roles.map(
            (role: any): roleObject => {
              return {
                companyId: role.companyId,
                id: role.id,
                name: role.name,
                permissions: this.isInvestor
                  ? this.investorPermissions(role.permissions)
                  : this.issuerPermissions(role.permissions),
              };
            },
          )),
      );
    }
    if (this.cacheCompanySettings.value !== 'placeholder') {
      getAccountManagers(this.cacheCompanySettings.value).then(data => {
        this.accountManagers = data.data;
      });
    }
  }

  investorPermissions(permissions: string[]) {
    return {
      can_read_deals: permissions.includes(Permission.can_read_deals),
      can_make_offer: permissions.includes(Permission.can_make_offer),
      can_edit_offer: permissions.includes(Permission.can_edit_offer),
      can_accept_offer: permissions.includes(Permission.can_accept_offer),
      can_withdraw_offer: permissions.includes(Permission.can_withdraw_offer),
      can_invite_account_manager: permissions.includes(
        Permission.can_invite_account_manager,
      ),
      can_cancel_invite_for_account_manager: permissions.includes(
        Permission.can_cancel_invite_for_account_manager,
      ),
      can_remove_account_manager: permissions.includes(
        Permission.can_remove_account_manager,
      ),
      read_company_details: permissions.includes(
        Permission.read_company_details,
      ),
      read_company_account_managers: permissions.includes(
        Permission.read_company_account_managers,
      ),
      update_company_details: permissions.includes(
        Permission.update_company_details,
      ),
      manage_company_permissions: permissions.includes(
        Permission.manage_company_permissions,
      ),
      can_approve_multieye: permissions.includes(
        Permission.can_approve_multieye,
      ),
    };
  }

  issuerPermissions(permissions: string[]) {
    return {
      can_read_deals: permissions.includes(Permission.can_read_deals),
      can_invite_account_manager: permissions.includes(
        Permission.can_invite_account_manager,
      ),
      can_cancel_invite_for_account_manager: permissions.includes(
        Permission.can_cancel_invite_for_account_manager,
      ),
      can_remove_account_manager: permissions.includes(
        Permission.can_remove_account_manager,
      ),
      read_company_details: permissions.includes(
        Permission.read_company_details,
      ),
      read_company_account_managers: permissions.includes(
        Permission.read_company_account_managers,
      ),
      update_company_details: permissions.includes(
        Permission.update_company_details,
      ),
      manage_company_permissions: permissions.includes(
        Permission.manage_company_permissions,
      ),
      can_create_ioi: permissions.includes(Permission.can_create_ioi),
      can_update_ioi: permissions.includes(Permission.can_update_ioi),
      can_withdraw_ioi: permissions.includes(Permission.can_withdraw_ioi),
      can_remove_ioi: permissions.includes(Permission.can_remove_ioi),
      can_send_ioi_to_market: permissions.includes(
        Permission.can_send_ioi_to_market,
      ),
      can_accept_offer: permissions.includes(Permission.can_accept_offer),
    };
  }

  get logType(): HistoryLogType {
    return HistoryLogType.COMPANY;
  }

  get visibleTitles() {
    if (this.menuTitles.length > 0) {
      if (this.companyListGetter.length > 0) {
        return this.menuTitles;
      } else {
        return [this.menuTitles[3]];
      }
    } else {
      return [];
    }
  }

  mounted() {
    Vue.nextTick(() => {
      Object.keys(this.$refs).forEach(key => {
        if (this.$refs[key]) {
          this.menuTitles.push({
            title: (this.$refs[key] as HTMLHeadingElement).innerHTML,
            ref: this.$refs[key] as HTMLHeadingElement,
          });
        }
      });
      this.getRoles();
    });
  }

  openAddEditCompany(type: string) {
    this.toggleModal('addEditCompany');
    this.addEditModalMode = type;
    if (type === 'add') {
      this.addEditModalTitle = 'components.companySettings.addModalTitle';
    }
    if (type === 'edit') {
      this.addEditModalTitle = 'components.companySettings.editModalTitle';
    }
  }
}
