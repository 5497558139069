export enum ButtonTypes {
  withdraw = 'withdraw',
  withdrawAllOffers = 'withdrawAllOffers',
  edit = 'edit',
  delete = 'delete',
  sendToMarket = 'sendToMarket',
  requestIndication = 'requestIndication',
}

export enum HistoryLogType {
  USER,
  COMPANY,
  IOI,
  OFFER,
  DEAL,
}
