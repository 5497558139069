import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { findIndex } from 'lodash';
import en from './en.json';
import nl from './nl.json';

Vue.use(VueI18n);

const languages: string[] = ['en', 'nl'];

const messages = {
  en,
  nl,
};

export function setLanguage(selectedLanguage: string) {
  if (
    findIndex(
      languages,
      (element: string) => element === selectedLanguage.toLowerCase(),
    ) !== -1
  ) {
    localStorage.setItem('language', selectedLanguage);
  }
}

export function getCurrentLanguage() {
  const language: string | null = localStorage.getItem('language');
  if (language === undefined || language === null) {
    return 'en';
  }
  if (language.toLowerCase() === 'nl') {
    return 'nl';
  }
  return 'en';
}

export default new VueI18n({
  locale: getCurrentLanguage(),
  messages,
  silentTranslationWarn: true,
});
