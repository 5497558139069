
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const ModalNameSpace = namespace('modals/');

@Component({
  name: 'ModalWindow',
})
export default class ModalWindow extends Vue {
  @ModalNameSpace.Action('initializeModal') initializeModal: any;
  @ModalNameSpace.Action('initializeModalOpen') initializeModalOpen: any;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  @ModalNameSpace.Getter('modals') modals: any;

  @Prop({ required: true }) modalKey!: string; // unique id
  @Prop({ required: true }) modalTitle!: string; // title
  @Prop({ required: false }) modalStyle!: string; // additional styling
  @Prop({ required: false, default: false }) noClose!: boolean; // component can't close itself
  @Prop({ required: false }) initOpen: boolean; // open on initialization
  @Prop({ required: false }) icon: string; // icon in top bar

  switchModal(): void {
    if (!this.noClose) {
      this.toggleModal(this.modalKey);
    }
  }

  created() {
    if (this.initOpen) {
      this.initializeModalOpen(this.modalKey);
    } else {
      this.initializeModal(this.modalKey);
    }
  }
}
