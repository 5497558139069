
























































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { isEmpty, forEach } from 'lodash';
import InputComponent from '@/components/inputs/InputComponent.vue';
import InputSelectMultiComponent from '@/components/inputs/InputSelectMultiComponent.vue';
import {
  CustomMultiInput,
  InputMultiSelectList,
} from '@/components/inputs/inputTypes';
import CollapsibleComponent from '@/components/utils/CollapsibleComponent.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import { PreferencesPayload } from '@/types/accountTypes';
import { EventBus } from '@/main';
import {
  getIndustry,
  getFrequency,
  getInstrumentType,
  getIndicativeType,
  getBucketSize,
  getMaturityBucket,
} from '@/util/ioiUtils';
import sentry from '@/util/sentry';
import InputSelect from '@/components/inputs/InputSelect.vue';

const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');
const InstrumentNameSpace = namespace('iois/');

@Component({
  name: 'PreferencesMail',
  components: {
    InputSelect,
    CollapsibleComponent,
    ButtonComponent,
    InputSelectMultiComponent,
  },
})
export default class PreferencesMail extends Vue {
  // Notification store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @AuthenticationNameSpace.Action('preferencesPrepopulate')
  preferencesPrepopulate: any;

  @AuthenticationNameSpace.Action('preferencesUpdate') preferencesUpdate: any;

  // Instrument store
  @InstrumentNameSpace.Action('ioiRequiredLists') ioiRequiredLists: any;

  eventBus = EventBus;
  myAccount: string = '';
  informationTitle: string = '';
  informationBody: string[] = [];
  preferenceType: string = 'INVESTOR_IOI';
  ioiLists: any = {};
  preferencesPreloaded: boolean = false;
  selectAllParameter: boolean = true;

  inputs: InputMultiSelectList = {
    inputIndustry: {
      name: 'industry',
      placeHolder: 'components.preferenceInvestor.industryPlaceHolder',
      containerClass: 'select-dropdown-ioi',
      optionList: [],
      value: [],
      key: 'industry_or_sector',
      type: 'InputComponent',
      multi: true,
    },

    inputBucketSize: {
      name: 'bucketSize',
      placeHolder: 'components.preferenceInvestor.bucketSizePlaceHolder',
      containerClass: 'select-dropdown-ioi',
      optionList: [],
      value: [],
      key: 'bucket_size',
      translate: false,
      type: 'InputSelectMultiComponent',
      multi: true,
    },

    inputInstrumentType: {
      name: 'instrumentTypes',
      placeHolder: 'components.preferenceInvestor.instrumentTypePlaceHolder',
      containerClass: 'select-dropdown-ioi',
      optionList: [],
      value: [],
      key: 'instrument_types',
      type: 'InputSelectMultiComponent',
      multi: true,
    },

    inputMaturityBucket: {
      name: 'maturityBucket',
      placeHolder: 'components.preferenceInvestor.maturityBucketPlaceHolder',
      containerClass: 'select-dropdown-ioi',
      optionList: [],
      value: [],
      key: 'maturity_bucket',
      translate: false,
      type: 'InputSelectMultiComponent',
      multi: true,
    },

    inputIndicativeType: {
      name: 'indicativeType',
      placeHolder: 'components.preferenceInvestor.indicativeTypePlaceHolder',
      containerClass: 'select-dropdown-ioi',
      optionList: [],
      value: [],
      key: 'indicative_type',
      translate: true,
      multi: true,
      type: 'InputSelectMultiComponent',
    },

    inputRepaymentFrequency: {
      name: 'repaymentFrequency',
      placeHolder:
        'components.preferenceInvestor.repaymentFrequencyPlaceHolder',
      containerClass: 'select-dropdown-ioi',
      optionList: [],
      value: [],
      key: 'repayment_frequency',
      type: 'InputSelectMultiComponent',
      multi: true,
    },

    inputCountry: {
      name: 'country',
      placeHolder: 'components.preferenceInvestor.countryPlaceHolder',
      containerClass: 'select-dropdown-ioi',
      optionList: [],
      value: [],
      key: 'country_code',
      type: 'InputSelectMultiComponent',
      multi: true,
    },
  };

  get selectAllButton(): any {
    return {
      containerClass: 'button-without-margin',
      buttonClass: 'button-icon button-icon-large button-icon',
      description: !this.allOptionsSelected
        ? `${this.$t('components.preferenceInvestor.selectAll')}`
        : `${this.$t('components.preferenceInvestor.deselectAll')}`,
      extendedDescription: !this.allOptionsSelected
        ? `${this.$t('components.preferenceInvestor.selectAll')}`
        : `${this.$t('components.preferenceInvestor.deselectAll')}`,
      toolTip: !this.allOptionsSelected
        ? `${this.$t('components.preferenceInvestor.selectAll')}`
        : `${this.$t('components.preferenceInvestor.deselectAll')}`,
      showExtended: true,
    };
  }

  get submitPreferencesButton(): any {
    return {
      containerClass: 'button-without-margin',
      buttonClass: 'button-icon button-icon-large',
      description: `${this.$t(
        'components.preferenceInvestor.submitPreferences',
      )}`,
      extendedDescription: `${this.$t(
        'components.preferenceInvestor.submitPreferences',
      )}`,
      toolTip: `${this.$t('components.preferenceInvestor.submitPreferences')}`,
      showExtended: true,
    };
  }

  get allOptionsSelected() {
    let selected = true;

    Object.keys(this.inputs).forEach((input: any) => {
      if (this.inputs[input].optionList && this.inputs[input].value) {
        if (
          this.inputs[input].optionList.length !==
          this.inputs[input].value.length
        ) {
          selected = false;
        }
      } else {
        selected = false;
      }
    });
    return selected;
  }

  selectAll() {
    EventBus.$emit('selectAll', this.allOptionsSelected);
    this.selectAllParameter = !this.selectAllParameter;
  }

  setInputValue(inputValue: CustomMultiInput) {
    Object.keys(this.inputs).forEach((input: any) => {
      if (this.inputs[input].name === inputValue.name) {
        this.inputs[input].value = inputValue.value;
      }
    });
  }

  submitPreferences(): void {
    const successSubmittingPreferences: string = `${this.$t(
      'components.preferenceInvestor.submittingPreferencesSuccess',
    )}`;
    const errorSubmittingPreferences: string = `${this.$t(
      'components.preferenceInvestor.submittingPreferencesError',
    )}`;
    const preferencePayload: PreferencesPayload = {
      userAccountId: this.myAccount,
      preferences: [],
    };

    Object.keys(this.inputs).forEach((input: any) => {
      preferencePayload.preferences.push({
        type: this.preferenceType,
        key: this.inputs[input].key,
        values: this.inputs[input].value
          ? this.inputs[input].value.map((value: any) => value.value)
          : [],
      });
    });

    this.preferencesUpdate(preferencePayload)
      .then(() => {
        this.addNotification({
          message: `${successSubmittingPreferences}`,
          type: 'success',
        });
      })
      .catch((error: any) => {
        this.addNotification({
          message: `${errorSubmittingPreferences} ${error}`,
          type: 'error',
        });
        sentry(error);
      });
  }

  mounted() {
    this.informationTitle = 'components.preferenceInvestor.informationTitle';
    this.informationBody = [
      'components.preferenceInvestor.informationBody1',
      'components.preferenceInvestor.informationBody2',
    ];
    const loginError: string = `${this.$t(
      'components.preferenceInvestor.loginError',
    )}`;
    const errorRequiredInfoString: string = `${this.$t(
      'components.preferenceInvestor.requiredInformationError',
    )}`;
    // control if the user is logged in; if not, then the user will be sent to login page
    this.authenticateLogin(loginError)
      .then(() => {
        this.myAccount = this.getUserAccountId;
        if (!this.isUserLoggedIn || !(this.isInvestor || this.isMarketViewer)) {
          this.addNotification({
            message: `${loginError}`,
            type: 'error',
          });
          this.$router.push({ path: '/' });
        }
      })
      .then(() => this.ioiRequiredLists())
      .then((ioiLists: any) => {
        this.ioiLists = ioiLists;
      })
      .then(() =>
        this.preferencesPrepopulate({ userAccountId: this.myAccount }),
      )
      .then((result: any) => {
        this.inputs.inputIndustry.optionList = this.ioiLists.industryList;
        this.inputs.inputIndicativeType.optionList = this.ioiLists.indicativeTypeList;
        this.inputs.inputInstrumentType.optionList = this.ioiLists.instrumentTypeList;
        this.inputs.inputBucketSize.optionList = this.ioiLists.bucketSizeList;
        this.inputs.inputMaturityBucket.optionList = this.ioiLists.maturityBucketList;
        this.inputs.inputRepaymentFrequency.optionList = this.ioiLists.frequencyList;
        const countryCode: string | null = null;
        let countryName: string | null = null;
        if (result.data !== undefined) {
          forEach(result.data.preferences, (item: any) => {
            const preference = item.values;
            if (
              !isEmpty(preference) &&
              item.type === this.preferenceType &&
              item.key === 'industry_or_sector'
            ) {
              this.inputs.inputIndustry.value = preference.map((pref: any) => ({
                value: pref,
                label: getIndustry(pref),
              }));
            } else if (
              !isEmpty(preference[0]) &&
              item.type === this.preferenceType &&
              item.key === 'indicative_type'
            ) {
              this.inputs.inputIndicativeType.value = preference.map(
                (pref: any) => ({
                  value: pref,
                  label: getIndicativeType(pref),
                }),
              );
            } else if (
              !isEmpty(preference[0]) &&
              item.type === this.preferenceType &&
              item.key === 'instrument_types'
            ) {
              this.inputs.inputInstrumentType.value = preference.map(
                (pref: any) => ({
                  value: pref,
                  label: getInstrumentType(parseInt(pref, 10)),
                }),
              );
            } else if (
              item.type === this.preferenceType &&
              item.key === 'bucket_size'
            ) {
              this.inputs.inputBucketSize.value = preference.map(
                (pref: any) => ({
                  value: pref,
                  label: getBucketSize(pref),
                }),
              );
            } else if (
              !isEmpty(preference) &&
              item.type === this.preferenceType &&
              item.key === 'maturity_bucket'
            ) {
              this.inputs.inputMaturityBucket.value = preference.map(
                (pref: any) => ({
                  value: pref,
                  label: getMaturityBucket(pref),
                }),
              );
            } else if (
              !isEmpty(preference) &&
              item.type === this.preferenceType &&
              item.key === 'repayment_frequency'
            ) {
              this.inputs.inputRepaymentFrequency.value = preference.map(
                (pref: any) => ({
                  value: pref,
                  label: getFrequency(pref),
                }),
              );
            } else if (
              !isEmpty(preference) &&
              item.type === this.preferenceType &&
              item.key === 'country_code'
            ) {
              const countries: any = [];
              preference.forEach((countryInfo: any) => {
                const countryNameLabel: string = `countries.${countryInfo.toLowerCase()}`;
                countries.push({
                  value: countryInfo,
                  label: `${this.$t(countryNameLabel)}`,
                });
              });

              this.inputs.inputCountry.value = countries;
            }
          });
        }
        const countries: any = [{ value: 'NL', label: 'countries.nl' }];
        // countries
        forEach(result.data.countries, (countryInfo: any) => {
          const countryNameLabel: string = `countries.${countryInfo.code.toLowerCase()}`;
          if (countryCode !== null && countryCode === countryInfo.code) {
            countryName = countryInfo.name;
          }
          if (countryInfo.code !== 'NL') {
            countries.push({
              value: countryInfo.code,
              label: countryNameLabel,
            });
          }
        });
        this.inputs.inputCountry.optionList = countries;
        // Ready to load in select inputs:
        this.preferencesPreloaded = true;
      })
      .catch((error: any) => {
        this.addNotification({
          message: `${errorRequiredInfoString} ${error}`,
          type: 'error',
        });
        sentry(error);
      });
  }
}
