



import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'Logout',
})
export default class Logout extends Vue {
  @AuthenticationNameSpace.Action('logoutUser') logoutUser: any;

  created() {
    this.logoutUser();
    this.$router.push({ name: 'login' });
    window.location.reload();
  }
}
