
























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';

const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'ContactLeadManager',
  components: { ButtonComponent },
})
export default class ContactLeadManager extends Vue {
  @Prop({}) showModalParam!: boolean;
  @Prop({}) activeTabPref: string;

  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;

  activeTab: string = 'general';

  created() {
    if (this.activeTabPref) {
      this.activeTab = this.activeTabPref;
    }
  }
}
