'use strict';

export function daysColumnClass(alert: boolean): any {
  if (alert) {
    return {
      'myaccount-days-col': true,
      'myaccount-days-col-expiring': true,
    };
  }
  return {
    'myaccount-days-col': true,
  };
}

export function daysBigColumnClass(alert: boolean): any {
  if (alert) {
    return {
      'ioi-col-button': true,
      'myaccount-days-col-expiring': true,
    };
  }
  return {
    'ioi-col-button': true,
  };
}

export function daysColumnText(alert: boolean): string {
  return alert ? 'myaccount-days-col-text-expiring' : 'myaccount-days-col-text';
}

export function daysColumnBadgeClass(alert: boolean): any {
  if (alert) {
    return {
      'myaccount-days-col-badge': true,
    };
  }
  return 'myaccount-days-col-hide-badge';
}

export function smallColumnClass(alert: boolean): any {
  if (alert) {
    return {
      'myaccount-small-col': true,
      'myaccount-col-expiring-information': true,
    };
  }
  return 'myaccount-small-col';
}

export function mediumColumnClass(alert: boolean): any {
  if (alert) {
    return {
      'myaccount-medium-col': true,
      'myaccount-col-expiring-information': true,
    };
  }
  return 'myaccount-medium-col';
}

export function rowOfferColumnClass(alert: boolean): any {
  if (alert) {
    return {
      'myaccount-my-offers-col': true,
      'myaccount-col-expiring-information': true,
    };
  }
  return 'myaccount-my-offers-col';
}

export function interestedColumnClass(alert: boolean): any {
  if (alert) {
    return {
      'ioi-col-button': true,
      'myaccount-col-expiring-information': true,
    };
  }
  return 'ioi-col-button';
}

export function interestedTitleClass(alert: boolean): string {
  return alert ? 'ioi-col-interested-title' : 'ioi-green-subtitle';
}

export function noOffersExpiring(alert: boolean): any {
  if (alert) {
    return {
      'myaccount-no-offers': true,
      'myaccount-no-offers-expiring': true,
    };
  }
  return 'myaccount-no-offers';
}

export function offersExpiring(alert: boolean): any {
  if (alert) {
    return {
      'myaccount-with-offers': true,
      'myaccount-no-offers-expiring': true,
    };
  }
  return 'myaccount-with-offers';
}
