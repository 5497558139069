import * as Sentry from '@sentry/browser';
import { get } from 'lodash';

export default function sentry(error: any) {
  if (get(error, 'response.data.code')) {
    const errorCode = error.response.data.code;
    if (
      errorCode !== 400 &&
      errorCode !== 401 &&
      errorCode !== 403 &&
      errorCode !== 500
    ) {
      Sentry.captureException(error);
    }
  } else {
    Sentry.captureException(error);
  }
}

export function sentryAdditional(error: any, custom: any) {
  Sentry.captureException(error, custom);
}
