












import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';

const filterNameSpace = namespace('filters/');

@Component({
  name: 'NoDataAvailable',
})
export default class NoDataAvailable extends Vue {
  @filterNameSpace.Getter('anyFilters') anyFilters: boolean;

  // # text options:
  // IOI, IOIFavorites, IOIOutstanding, offers, positions, closedDeals, offTheMarket, IOIDraft
  @Prop({}) text: string;
  loaded: boolean = false;

  mounted() {
    this.loaded = true;
  }
}
