





















































import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import { Validator } from 'vee-validate';
import { CustomFileInput, InputObject } from './inputTypes';
import { EventBus } from '@/main';

@Component({
  name: 'InputFileComponent',
})
export default class InputFileComponent extends Vue {
  @Prop({}) inputParam!: InputObject;
  @Inject('$validator') $validator!: Validator;

  fileValue: string | null = '';
  fileName: string | null = '';
  inputField: InputObject = {
    id: '',
    name: '',
    ref: '',
    type: 'text',
    placeHolder: '',
    class: '',
    vRule: {
      required: false,
    },
    vLabel: '',
    disabled: false,
    optionList: [],
    containerClass: '',
    value: '',
    hideVLabel: false,
    isValid: null,
  };

  get inputName() {
    return isEmpty(this.inputField.name) ? '' : this.inputField.name;
  }

  get inputFileName() {
    return `${this.inputName}_file`;
  }

  get vLabel() {
    if (
      isEmpty(this.inputField.vLabel) &&
      isEmpty(this.inputField.placeHolder)
    ) {
      return '';
    }
    return isEmpty(this.inputField.vLabel)
      ? this.inputPlaceHolder
      : this.inputField.vLabel;
  }

  get inputPlaceHolder(): string | null {
    if (isEmpty(this.fileName)) {
      return isEmpty(this.inputField.placeHolder)
        ? ' '
        : `${this.$t(this.inputField.placeHolder)}`;
    }
    return this.fileName;
  }

  get showVLabel(): boolean {
    return (
      !this.inputField.hideVLabel &&
      this.$validator.errors.has(this.inputField.name)
    );
  }

  get inputRef(): string | null {
    return isEmpty(this.inputField.ref)
      ? this.inputField.name
      : `"${this.inputField.ref}"`;
  }

  get inputDisabled(): boolean {
    return this.inputField.disabled;
  }

  handleFile(event: any) {
    const input = event.target;
    // Ensure that you have a file before attempting to read it
    if (input.files && input.files[0]) {
      this.fileName = input.files[0].name;
      const reader = new FileReader();
      reader.onload = e => {
        // once the file is loaded, then the variables are stored and the
        // information is sent to the parent component
        this.fileValue = (e.target as any).result;
        const returnInput: CustomFileInput = {
          inputName: this.inputField.name,
          fileName: isEmpty(this.fileName) ? '' : this.fileName,
          fileValue: isEmpty(this.fileValue) ? '' : this.fileValue,
        };
        this.$emit('sendInput', returnInput);
        this.$emit('sendFileInput', returnInput);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  created() {
    this.inputField = Object.assign({}, this.inputParam);
    EventBus.$on('reloadInput', (inputInformation: any) => {
      if (inputInformation.name === this.inputParam.name) {
        this.inputField = {
          id: inputInformation.id,
          name: inputInformation.name,
          ref: '',
          value: inputInformation.value,
          type: '',
          placeHolder: inputInformation.placeHolder,
          class: inputInformation.class,
          vRule: inputInformation.vRule,
          vLabel: inputInformation.vLabel,
          disabled: inputInformation.disabled,
          optionList: [],
          containerClass: inputInformation.containerClass,
          hideVLabel: false,
          isValid: inputInformation.isValid,
        };
        this.fileValue = this.inputField.value;
      }
    });
  }
}
