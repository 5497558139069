











































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Account } from '@/types/accountTypes';
import AccountRow from '@/components/backoffice/AccountRow.vue';
import AccountForm from '@/components/backoffice/AccountForm.vue';
import FilterForm from '@/components/utils/FilterForm.vue';
import { EventBus } from '@/main';

const AuthenticationNameSpace = namespace('authentication/');
const BackOfficeNameSpace = namespace('backOffice/');
const NotificationsNameSpace = namespace('notifications/');

@Component({
  name: 'AccountDashboard',
  components: { AccountRow, AccountForm, FilterForm },
})
export default class AccountDashboard extends Vue {
  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isBackOffice') isBackOffice: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @BackOfficeNameSpace.Getter('filteredCompany') filteredCompany: any;
  @BackOfficeNameSpace.Getter('pendingAccounts') pendingAccounts: any;
  @BackOfficeNameSpace.Getter('approvedAccounts') approvedAccounts: any;
  @BackOfficeNameSpace.Getter('pendingUpgradeAccounts')
  pendingUpgradeAccounts: any;

  @BackOfficeNameSpace.Getter('approvedUpgradedAccounts')
  approvedUpgradedAccounts: any;

  @BackOfficeNameSpace.Getter('blockedAccounts') blockedAccounts: any;
  @BackOfficeNameSpace.Action('getPendingAccounts') getPendingAccounts: any;
  @BackOfficeNameSpace.Action('getApprovedAccounts') getApprovedAccounts: any;
  @BackOfficeNameSpace.Action('getPendingUpgradeAccounts')
  getPendingUpgradeAccounts: any;

  @BackOfficeNameSpace.Action('getApprovedUpgradedAccounts')
  getApprovedUpgradedAccounts: any;

  @BackOfficeNameSpace.Action('getBlockedAccounts') getBlockedAccounts: any;
  @BackOfficeNameSpace.Action('approveAccount') approveAccount: any;
  @BackOfficeNameSpace.Action('approveUpgradeAccount')
  approveUpgradeAccount: any;

  @BackOfficeNameSpace.Action('denyAccount') denyAccount: any;
  @BackOfficeNameSpace.Action('denyUpgradeAccount') denyUpgradeAccount: any;
  @BackOfficeNameSpace.Action('blockAccount') blockAccount: any;
  @BackOfficeNameSpace.Action('unblockAccount') unblockAccount: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  eventBus = EventBus;
  myAccount: string = this.getUserAccountId;
  currentTab: string = '';
  showAccountFormModal: any = { showModal: false, account: {} };
  showFilterFormModal: any = {
    showModal: false,
    tab: '',
    filters: {},
    myAccount: '',
  };

  // Filter Events
  get showRemoveFilter(): boolean {
    return !(
      this.filteredCompany === undefined ||
      this.filteredCompany === null ||
      this.filteredCompany === ''
    );
  }

  get filterTooltip(): string {
    return this.showRemoveFilter ? 'Remove or change filters' : 'Apply Filters';
  }

  openFilterForm() {
    this.showFilterFormModal.showModal = true;
    this.showFilterFormModal.tab = this.currentTab;
    if (this.showRemoveFilter) {
      this.showFilterFormModal.filters.company = this.filteredCompany;
    } else {
      this.showFilterFormModal.filters.company = '';
    }
    this.eventBus.$emit('reloadFilterForm', this.showFilterFormModal);
  }

  onCloseFilterForm() {
    this.showFilterFormModal.showModal = false;
    this.showFilterFormModal.filters = {};
    this.eventBus.$emit('reloadFilterForm', this.showFilterFormModal);
  }

  // Account events
  onViewAccount(account: Account) {
    this.showAccountFormModal.showModal = true;
    this.showAccountFormModal.account = account;
    this.eventBus.$emit('reloadAccountForm', this.showAccountFormModal);
  }

  onApproveAccount() {
    this.approveAccount({
      userAccountId: this.showAccountFormModal.account.userAccountId,
    }).then(() => {
      const { email } = this.showAccountFormModal.account;
      this.addNotification({
        message: `The account ${email} has been successfully approved.`,
        type: 'success',
      });
      this.showAccountFormModal.showModal = false;
      this.eventBus.$emit('resetAccountForm', this.showAccountFormModal);
      this.changeTab('2');
    });
  }

  onRejectAccount() {
    this.denyAccount({
      userAccountId: this.showAccountFormModal.account.userAccountId,
    }).then(() => {
      const { email } = this.showAccountFormModal.account.email;
      this.addNotification({
        message: `The account ${email} has been successfully rejected.`,
        type: 'success',
      });
      this.showAccountFormModal.showModal = false;
      this.eventBus.$emit('resetAccountForm', this.showAccountFormModal);
      this.changeTab('2');
    });
  }

  onApproveUpgradeAccount() {
    this.approveUpgradeAccount({
      userAccountId: this.showAccountFormModal.account.userAccountId,
    }).then(() => {
      const { email } = this.showAccountFormModal.account.email;
      this.addNotification({
        message: `The account ${email} has been successfully upgraded.`,
        type: 'success',
      });
      this.showAccountFormModal.showModal = false;
      this.eventBus.$emit('resetAccountForm', this.showAccountFormModal);
      this.changeTab('4');
    });
  }

  onRejectUpgradeAccount() {
    this.denyUpgradeAccount({
      userAccountId: this.showAccountFormModal.account.userAccountId,
    }).then(() => {
      const { email } = this.showAccountFormModal.account;
      this.addNotification({
        message: `The account ${email} upgrade request has been successfully rejected.`,
        type: 'success',
      });
      this.showAccountFormModal.showModal = false;
      this.eventBus.$emit('resetAccountForm', this.showAccountFormModal);
      this.changeTab('2');
    });
  }

  onBlockAccount() {
    this.blockAccount({
      userAccountId: this.showAccountFormModal.account.userAccountId,
    }).then(() => {
      const { email } = this.showAccountFormModal.account.email;
      this.addNotification({
        message: `The account ${email} has been successfully blocked.`,
        type: 'success',
      });
      this.showAccountFormModal.showModal = false;
      this.showAccountFormModal.account = {};
      this.eventBus.$emit('reloadAccountForm', this.showAccountFormModal);
      this.changeTab('2');
    });
  }

  onUnblockAccount() {
    this.unblockAccount({
      userAccountId: this.showAccountFormModal.account.userAccountId,
    }).then(() => {
      const { email } = this.showAccountFormModal.account.email;
      this.addNotification({
        message: `The account ${email} has been successfully unblocked.`,
        type: 'success',
      });
      this.showAccountFormModal.showModal = false;
      this.showAccountFormModal.account = {};
      this.eventBus.$emit('reloadAccountForm', this.showAccountFormModal);
      this.changeTab('2');
    });
  }

  onCloseAccountForm() {
    this.showAccountFormModal.showModal = false;
    this.eventBus.$emit('resetAccountForm', this.showAccountFormModal);
  }

  changeTab(tabId: string): void {
    this.showAccountFormModal.showModal = false;
    this.currentTab = tabId;
    if (this.isUserLoggedIn && this.isBackOffice) {
      if (this.currentTab === '1') {
        this.getPendingAccounts();
      } else if (this.currentTab === '2') {
        this.getApprovedAccounts();
      } else if (this.currentTab === '3') {
        this.getPendingUpgradeAccounts();
      } else if (this.currentTab === '4') {
        this.getApprovedUpgradedAccounts();
      } else if (this.currentTab === '5') {
        this.getBlockedAccounts();
      }
    }
  }

  created() {
    this.currentTab = this.$route.params.tabId;
    const accountErrorMessage: string =
      'You need to login as a Backoffice user to access' +
      ' the BackOffice Dashboard.';
    this.authenticateLogin(accountErrorMessage).then(() => {
      this.myAccount = this.getUserAccountId;
      if (this.currentTab === undefined || this.currentTab === null) {
        this.currentTab = '1';
      }
      this.changeTab(this.currentTab);
    });

    this.eventBus.$on('reloadViewAccountForm', (inputInformation: any) => {
      if (inputInformation.showModal) {
        this.onViewAccount(inputInformation.account);
      }
    });

    this.eventBus.$on('closeFilters', (inputInformation: any) => {
      this.showFilterFormModal.showModal = false;
      this.showFilterFormModal.filters = {};
      this.changeTab(inputInformation.tab);
    });
  }
}
