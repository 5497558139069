
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { openPDF } from '@/api/pdf';

@Component({
  name: 'PdfViewer',
})

// Class depends on context, when using in:
//  account-rows: account-form-pdf in the prop
//  accounts => view account:  account-file-grid-pdf
export default class PdfViewer extends Vue {
  @Prop({}) title: string;
  @Prop({}) hiddenTitle: string;
  @Prop({}) pdfString: string;
  @Prop({}) propClass: string;

  openPdfFile(type: string) {
    const title = this.title ? this.title : this.hiddenTitle;
    if (this.pdfString) {
      openPDF(this.pdfString, title);
    }
  }
}
