



































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  AWAITING_APPROVAL,
  AWAITING_UPGRADE_APPROVAL,
  LIGHT_ACTIVE,
  FULL_ACTIVE,
  BLOCKED,
} from '@/types/accountTypes';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import BaseMailTo from '@/components/utils/BaseMailTo.vue';
import PdfViewer from '@/components/utils/PdfViewer.vue';
import HistoryViewer from '@/components/backoffice/HistoryViewer.vue';
import { getIndustry } from '@/util/ioiUtils';
import { openPDF } from '@/api/pdf';
import { EventBus } from '@/main';

const BackOfficeNameSpace = namespace('backOffice/');

@Component({
  name: 'AccountForm',
  components: {
    ButtonComponent,
    BaseMailTo,
    PdfViewer,
    HistoryViewer,
  },
})
export default class AccountForm extends Vue {
  @BackOfficeNameSpace.Action('getAccount') getAccount: any;

  eventBus = EventBus;
  closeTooltip: string = 'Close the Account Form';
  showModal: boolean = false;
  userAccountId: string = '';
  firstName: string = '';
  middleName: string = '';
  lastName: string = '';
  email: string = '';
  upgradedTo: string = '';
  accountStatus: number = 0;
  phoneNumber: string = '';
  industry: string = '';
  companyName: string = '';
  companyAddress: string = '';
  companyCity: string = '';
  companyPostCode: string = '';
  country: string = '';
  leiNumber: string = '';
  kvkFile: string = '';
  hasKvk: boolean = false;
  identificationFile: string = '';
  hasIdentification: boolean = false;
  uboFile: string = '';
  hasUbo: boolean = false;
  approvedDate: string = '';
  timestamps: object = {};
  loadingData: boolean = false;
  approveButton = AccountForm.approveButton;
  rejectButton = AccountForm.rejectButton;
  approveUpgradeButton = AccountForm.approveUpgradeButton;
  rejectUpgradeButton = AccountForm.rejectUpgradeButton;
  blockButton = AccountForm.blockButton;
  unblockButton = AccountForm.unblockButton;

  get fullName(): string {
    let name: string = this.firstName;
    if (this.middleName) {
      name = `${name} ${this.middleName}`;
    }
    name = `${name} ${this.lastName} `;
    return name;
  }

  get closeTooltipText(): string {
    return this.closeTooltip;
  }

  // Approve Account events
  get showApproveRejectButton(): boolean {
    return this.accountStatus === AWAITING_APPROVAL;
  }

  static get approveButton(): any {
    return {
      containerClass: '',
      buttonClass: 'button-icon button-icon-resize',
      description: 'Approve Account',
      extendedDescription: 'Approve this Account',
      icon: 'approve-blue.png',
      showExtended: false,
    };
  }

  onApproveAccount() {
    this.$emit('onApproveAccount');
  }

  // Reject Account events
  static get rejectButton(): any {
    return {
      containerClass: '',
      buttonClass: 'button-icon button-icon-resize',
      description: 'Reject Account',
      extendedDescription: 'Reject this Account',
      icon: 'block-blue.png',
      showExtended: false,
    };
  }

  onRejectAccount() {
    this.$emit('onRejectAccount');
  }

  // Approve Upgrade Account events
  get showApproveRejectUpgradeButton(): boolean {
    return this.accountStatus === AWAITING_UPGRADE_APPROVAL;
  }

  static get approveUpgradeButton(): any {
    return {
      containerClass: '',
      buttonClass: 'button-icon button-icon-resize',
      description: 'Approve Upgrade Account',
      extendedDescription: 'Approve this Account Upgrade',
      icon: 'approve-blue.png',
      showExtended: false,
    };
  }

  onApproveUpgradeAccount() {
    this.$emit('onApproveUpgradeAccount');
  }

  static get rejectUpgradeButton(): any {
    return {
      containerClass: '',
      buttonClass: 'button-icon button-icon-resize',
      description: 'Reject Upgrade Account',
      extendedDescription: 'Reject this Account Upgrade',
      icon: 'block-blue.png',
      showExtended: false,
    };
  }

  onRejectUpgradeAccount() {
    this.$emit('onRejectUpgradeAccount');
  }

  // Block Account events
  get showBlockButton(): boolean {
    return (
      this.accountStatus === LIGHT_ACTIVE || this.accountStatus === FULL_ACTIVE
    );
  }

  static get blockButton(): any {
    return {
      containerClass: '',
      buttonClass: 'button-icon button-icon-resize',
      description: 'Block Account',
      extendedDescription: 'Block this Account',
      icon: 'lock-blue.png',
      showExtended: false,
    };
  }

  onBlockAccount(): void {
    this.$emit('onBlockAccount');
  }

  // Unblock Account events
  get showUnblockButton(): boolean {
    return this.accountStatus === BLOCKED;
  }

  static get unblockButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-resize',
      description: 'Unblock Account',
      extendedDescription: 'Unblock this Account',
      icon: 'lock-open-blue.png',
      showExtended: false,
    };
  }

  onUnblockAccount(): void {
    this.$emit('onUnblockAccount');
  }

  onCloseAccountForm(): void {
    this.$emit('onCloseAccountForm');
  }

  openPdfFile(type: string) {
    if (type === 'kvk') {
      openPDF(this.kvkFile, 'Kvk Extract');
    }
    if (type === 'identification') {
      openPDF(this.identificationFile, 'Identification file');
    }
    if (type === 'ubo') {
      openPDF(this.uboFile, 'Ubo Declaration file');
    }
  }

  created() {
    this.eventBus.$on('reloadAccountForm', (inputInformation: any) => {
      this.showModal = inputInformation.showModal;
      this.loadingData = true;
      if (inputInformation.showModal) {
        this.getAccount(inputInformation.account.userAccountId).then(
          (response: any) => {
            this.userAccountId = inputInformation.account.userAccountId;
            this.firstName = response.data.firstName;
            this.middleName = response.data.middleName;
            this.lastName = response.data.lastName;
            this.email = response.data.email;
            this.phoneNumber = response.data.phoneNumber;
            this.industry = getIndustry(response.data.industryOrSector);
            this.companyName = response.data.companyName;
            const street: string = !response.data.street
              ? ''
              : response.data.street;
            const houseNumber = !response.data.houseNumber
              ? ''
              : response.data.houseNumber;
            const houseNumberSuffix = !response.data.houseNumberSuffix
              ? ''
              : response.data.houseNumberSuffix;
            this.companyAddress = `${street} ${houseNumber} ${houseNumberSuffix}`;
            this.companyPostCode = response.data.postalCode;
            this.companyCity = response.data.city;
            this.country = !response.data.countryCode
              ? ''
              : `${this.$root.$t(
                  `countries.${response.data.countryCode.toLowerCase()}`,
                )}`;
            this.accountStatus = response.data.accountStatus;
            this.upgradedTo = response.data.upgradedTo;
            this.leiNumber = response.data.leiNumber;
            if (response.data.kvkFile) {
              this.kvkFile = response.data.kvkFile;
              this.hasKvk = true;
            }
            if (response.data.identificationFile) {
              this.identificationFile = response.data.identificationFile;
              this.hasIdentification = true;
            }
            if (response.data.uboDeclarationFile) {
              this.uboFile = response.data.uboDeclarationFile;
              this.hasUbo = true;
            }
            if (
              response.data.timestamps &&
              response.data.timestamps.length !== 0
            ) {
              this.timestamps = response.data.timestamps;
            } else {
              this.timestamps = {};
            }
            this.loadingData = false;
          },
        );
      }
    });
    this.eventBus.$on('resetAccountForm', () => {
      this.showModal = false;
      this.userAccountId = '';
      this.firstName = '';
      this.middleName = '';
      this.lastName = '';
      this.email = '';
      this.phoneNumber = '';
      this.industry = '';
      this.companyName = '';
      this.companyAddress = '';
      this.companyPostCode = '';
      this.companyCity = '';
      this.country = '';
      this.accountStatus = 0;
      this.upgradedTo = '';
      this.leiNumber = '';
      this.hasKvk = false;
      this.kvkFile = '';
      this.hasIdentification = false;
      this.identificationFile = '';
      this.uboFile = '';
      this.hasUbo = false;
    });
  }
}
