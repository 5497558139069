



import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import Error404 from '@/views/errors/Error404.vue';

const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'PasswordChangeSubmit',
  components: { Error404 },
})
export default class PasswordChangeSubmit extends Vue {
  // This view is just a re route to call a password confirmation endpoint and
  // redirect the user to the login
  @AuthenticationNameSpace.Action('changeUserPassword') changeUserPassword: any;
  // notification store
  @NotificationsNameSpace.Action('add') addNotification: any;

  @Prop({}) userAccountId!: string;
  @Prop({}) token!: string;

  created() {
    this.changeUserPassword({
      userAccountId: this.userAccountId,
      token: this.token,
    })
      .then(() => {
        const successChangePasswordMessage: string = `
          ${this.$t(
            'components.changePasswordForm.successChangePasswordMessage',
          )}
        `;
        this.addNotification({
          id: 0,
          message: `${successChangePasswordMessage}`,
          type: 'success',
        });
      })
      .then(() => this.$router.push({ name: 'login' }));
  }
}
