





















import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import router from '@/router';

const SignupNameSpace = namespace('signup/');
@Component({
  name: 'RoleSelector',
  components: {},
})
export default class RoleSelector extends Vue {
  @SignupNameSpace.Getter('step') currentStep: number;
  @SignupNameSpace.Getter('finalStep') finalStep: number;
  @SignupNameSpace.Action('setStep') setStep: any;
  @SignupNameSpace.Getter('accountType') accountType: any;
  @SignupNameSpace.Getter('flowType') flowType: string;
  @SignupNameSpace.Action('setAccountType') setAccountType: any;

  get roles() {
    const rolesArray = [
      {
        role: 'investor',
        title: 'Investor',
        active: this.accountType === 'investor',
      },
      {
        role: 'issuer',
        title: 'Issuer',
        active: this.accountType === 'issuer',
      },
    ];
    if (this.flowType === 'sign-up') {
      rolesArray.push({
        role: 'marketviewer',
        title: 'Market Viewer',
        active: this.accountType === 'marketviewer',
      });
    }
    return rolesArray;
  }

  // eslint-disable-next-line class-methods-use-this
  setAccount(role: string) {
    router.push({
      name: 'signupnew',
      params: { accountType: role, step: '1' },
    });
  }
}
