




































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'HomeSmallDevices',
})
export default class HomeSmallDevices extends Vue {}
