










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import IOIForm from '../../components/ioi/IOIForm.vue';

@Component({
  components: { IOIForm },
})
export default class IOI extends Vue {}
