





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EventAc } from '@/types/types';
import EventItem from '@/components/actionCenter/event-item.vue';
const AcNs = namespace('actionCenter/');
const NotificationsNameSpace = namespace('notifications/');

@Component({
  name: 'EventListAc',
  components: { EventItem },
})
export default class EventListAc extends Vue {
  @Prop({ required: true }) events!: EventAc[];
  @Prop({ required: true }) title!: string;

  @AcNs.Action('markEventAsRead') markEventAsRead: any;
  @AcNs.Action('getMyEvents') getMyEvents: any;

  hideGetMoreButton: boolean = false;

  // group events by day, sort days: by new
  get groupedList() {
    return Object.entries(
      this.groupArrayByKeyValue('occurredAtDay', this.events),
    ).sort((a: any, b: any) => {
      return b[1][0].occurredAt - a[1][0].occurredAt;
    });
  }

  beforeDestroy() {
    this.readAllEvents();
  }

  readAllEvents() {
    this.markEventAsRead({
      notificationIds: this.events
        .filter(event => !event.read)
        .map(event => event.notificationId),
    });
  }

  getMoreEvents() {
    this.hideGetMoreButton = true;
    this.getMyEvents();
  }

  groupArrayByKeyValue(key: string, array: any) {
    return array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  }
}
