
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import ModalWindow from '@/components/utils/Modal.vue';

const ModalNameSpace = namespace('modals/');

@Component({
  name: 'Confirm',
  components: { ButtonComponent, ModalWindow },
})
export default class Confirm extends Vue {
  @Prop({}) showModalParam!: boolean;
  @Prop({ default: true }) translate!: boolean;
  @Prop({}) contentParam!: any;
  @Prop({ default: false }) altModalKey!: any;

  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  get title(): string {
    const titleText: string = this.translate
      ? `${this.$t(this.contentParam.title)}`
      : this.contentParam.title;
    return titleText === undefined ? '' : titleText;
  }

  get body(): string {
    const bodyText: string = this.translate
      ? `${this.$t(this.contentParam.body)}`
      : this.contentParam.body;
    return bodyText === undefined ? '' : bodyText;
  }

  get confirmButton(): any {
    const confirmText: string = this.translate
      ? `${this.$t(this.contentParam.confirmButton)}`
      : this.contentParam.confirmButton;
    return {
      containerClass: '',
      buttonClass: 'button-icon button-icon-medium button-icon-green',
      description: confirmText,
      toolTip: confirmText,
      icon: 'check.png',
      showExtended: false,
    };
  }

  get cancelButton(): any {
    const cancelText: string = this.translate
      ? `${this.$t(this.contentParam.cancelButton)}`
      : this.contentParam.cancelButton;
    return {
      containerClass: '',
      buttonClass: 'button-icon button-icon-medium button-icon-big-alert',
      description: cancelText,
      toolTip: cancelText,
      icon: 'close-error.png',
      showExtended: false,
    };
  }

  onCancel(): void {
    this.$emit('onCancel', this.contentParam.genericId);
    this.toggleModal(this.altModalKey ? this.altModalKey : 'confirm');
  }

  onConfirm(): void {
    this.$emit('onConfirm', this.contentParam.genericId);
    this.toggleModal(this.altModalKey ? this.altModalKey : 'confirm');
  }
}
