// ****************************************
// Authentication Module
// ****************************************
export interface AccountPayload {
  userAccountId: string;
}

export interface AccountSignupConfirmationPayload {
  userAccountId: string;
  userAccountConfirmation: string;
}

export interface AccountEmailPayload {
  email: string;
}

export interface LoginPayload {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface ChangePasswordPayload {
  userAccountId: string;
  token: string | null;
  currentPassword: string | null;
  newPassword: string;
}

export interface MarketViewerAccount {
  email: string;
  password: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
}

export interface SignUpProfessionalPayload {
  email: string;
  street: string;
  houseNumber: number | null;
  houseNumberSuffix: string | null;
  postalCode: string;
  city: string;
  countryCode: string;
  kvkFile: string;
  companyName: string | null;
  leiNumber: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  identificationFile: string;
  password: string;
  companyType: number | null;
  industryOrSector: string;
}

export interface InviteUsersPayload {
  userAccountId: string;
  companyId: string;
  emailAddresses: string[];
}

export interface SignUpUpgradePayload {
  userAccountId: string;
  street: string;
  houseNumber: number | null;
  houseNumberSuffix: string | null;
  postalCode: string;
  city: string;
  countryCode: string;
  kvkFile: string;
  leiNumber: string | null;
  identificationFile: string;
  companyType: number;
}

export interface Preferences {
  type: string;
  key: string;
  values: string[];
}

export interface PreferencesPayload {
  userAccountId: string;
  preferences: Preferences[];
}

// ****************************************
// BackOffice Module
// ****************************************
// BackOffice state
export interface BackOfficeState {
  pendingAccounts: Account[];
  approvedAccounts: Account[];
  pendingUpgradeAccounts: Account[];
  approvedUpgradedAccounts: Account[];
  blockedAccounts: Account[];
  companies: Company[];
  filteredCompany?: string;
}

// Account model
export interface Account {
  id: string;
  roles: string[];
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  industry?: string;
  companyName: string;
  companyAddress?: string;
  companyPostCode?: string;
  companyCity?: string;
  country?: string;
  phoneNumber?: string;
  leiNumber?: string;
  status: number;
  kvkFile?: string;
  uboDeclarationFile?: string;
  identificationFile?: string;
  approvedDate?: string;
  upgradedTo?: string;
}

// Company model
export interface Company {
  id: string;
  name: string;
  address?: string;
  industry?: string;
  phoneNumber?: string;
  status: number;
}

export const NEED_CONFIRMATION: number = 1;
export const AWAITING_APPROVAL: number = 2;
export const LIGHT_ACTIVE: number = 3;
export const DENIED: number = 4;
export const AWAITING_UPGRADE_APPROVAL: number = 5;
export const FULL_ACTIVE: number = 6;
export const BLOCKED: number = 7;
