


























import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import SignUpMarketViewer from './SignUpMarketViewer.vue';
import SignUpInvestor from './SignUpInvestor.vue';
import SignUpIssuer from './SignUpIssuer.vue';
import ExpandElement from '../utils/ExpandElement.vue';

const SignupNameSpace = namespace('signup/');
const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'VisualSignupContent',
  components: {
    SignUpMarketViewer,
    SignUpInvestor,
    SignUpIssuer,
    ExpandElement,
  },
})
export default class VisualSignupContent extends Vue {
  @SignupNameSpace.Getter('accountType') accountType: any;
  @SignupNameSpace.Getter('finalStep') finalStep: any;
  @AuthenticationNameSpace.Getter('windowWidth') windowWidth: any;

  expanded: boolean = false;
  $mq: string;

  get mobile() {
    return this.windowWidth <= 1023;
  }

  get activeComponent() {
    let component;
    if (this.accountType === 'marketviewer') {
      component = SignUpMarketViewer;
    }
    if (this.accountType === 'investor') {
      component = SignUpInvestor;
    }
    if (this.accountType === 'issuer') {
      component = SignUpIssuer;
    }
    return component;
  }
}
