
























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PersonalSettings from '@/components/settings/PersonalSettings.vue';
import CompanySettings from '@/components/settings/CompanySettings.vue';
import Tabs from '@/components/utils/Tabs.vue';
const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'Settings',
  components: { Tabs, CompanySettings, PersonalSettings },
})
export default class Settings extends Vue {
  @AuthenticationNameSpace.Getter('isHAM') isHAM: boolean;
}
