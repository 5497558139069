'use strict';

/* eslint-disable */

const dictionary = {
  en: {
    messages: {
      _default: (_: string) => 'Incorrect field',
      email: () => 'Invalid email.',
      required: (field: string) => `The ${field} is required.`,
      between: (field: string, args: any) =>
        `The ${field} value must be between -100 and ${args[1]}.`,
      min_value: (field: string, args: any) =>
        `The ${field} value must be ${args[0]} or more.`,
      max_value: (field: string, args: any) =>
        `The ${field} value can not be greater than ${args[0]}.`,
      min: (field: string, args: any) =>
        `The ${field} value must have at least ${args[0]} characters.`,
      max: (field: string, args: any) =>
        `The ${field} may not be longer than ${args[0]} characters.`,
      decimal: (field: string) =>
        `The ${field} must be numeric and may contain decimal points.`,
      alpha_spaces: (field: string) =>
        `The ${field} field may only contain alphabetic characters and spaces.`,
      alpha: (field: string) =>
        `The ${field} field may only contain alphabetic characters.`,
      isNumber: (field: string) => `The ${field} must be a number.`,
      confirmed: (field: string) => `The ${field} confirmation does not match.`,
      after: (field: string, args: any) =>
        `The ${field} value must be after ${args[0]}.`,
      date_format: (field: string, args: any) =>
        `The ${field} must be in the format ${args[0]}.`,
      min_number_with_format: (field: string, args: any) =>
        `The ${field} must be ${args[0]} or more.`,
      max_number_with_format: (field: string, args: any) =>
        `The ${field} can not be greater than ${args[0]}.`,
      minimum_piece_validation: (field: string) =>
        `The ${field} must be 100.000 or more.`,
      phone_number: () => 'The telephone number provided is not valid.',
      phone_number_required: () => 'The telephone number is required.',
      minimumMaturity: () => 'Not long enough, minimum maturity is 30 days'
    },
  },
  nl: {
    messages: {
      _default: (_: string) => 'Incorrect veld',
      email: () => 'Ongeldig email.',
      required: (field: string) => `De ${field} is verplicht.`,
      between: (field: string, args: any) =>
        `De ${field} waarde moet tussen -100 en ${args[1]} zijn.`,
      min_value: (field: string, args: any) =>
        `De ${field} waarde moet groter zijn dan ${args[0]} zijn.`,
      max_value: (field: string, args: any) =>
        `De ${field} waarde moet kleiner dan ${args[0]} zijn.`,
      min: (field: string, args: any) =>
        `De ${field} moet minimaal ${args[0]} tekens bevatten.`,
      max: (field: string, args: any) =>
        `De ${field} mag niet langer zijn dan ${args[0]} tekens.`,
      decimal: (field: string) =>
        `De ${field} moet numeriek zijn en mag decimale getallen hebben.`,
      alpha_spaces: (field: string) =>
        `De ${field} mag alleen alfabetische tekens en open ruimten bevatten.`,
      alpha: (field: string) =>
        `De ${field} mag alleen alfabetische tekens bevatten.`,
      isNumber: (field: string) => `De ${field} moet numeriek zijn.`,
      confirmed: (field: string) =>
        `De bevestiging ${field} komt niet overeen.`,
      after: (field: string, args: any) =>
        `De waarde van de ${field} moet na ${args[0]} zijn.`,
      date_format: (field: string, args: any) =>
        `De ${field} must be in the format ${args[0]}.`,
      min_number_with_format: (field: string, args: any) =>
        `De waarde van ${field} moet groter dan ${args[0]} zijn.`,
      max_number_with_format: (field: string, args: any) =>
        `De waarde van ${field} moet kleiner dan ${args[0]} zijn.`,
      minimum_piece_validation: (field: string) =>
        `De waarde van ${field} moet groter dan 100.000 zijn.`,
      phone_number: () => 'Het telefoonnummer is niet geldig.',
      phone_number_required: () => 'Een telefoonnummer is verplicht.',
      minimumMaturity: () => 'Niet lang genoeg, minimale looptijd is 30 dagen'
    },
  },
};

export default dictionary;
