import Vue from 'vue';
import Vuex from 'vuex';
import { notifications } from '@/store/notifications';
import { progressBar } from '@/store/progressBar';
import { authentication } from '@/store/authentication';
import { iois } from '@/store/iois';
import { backOffice } from '@/store/backOffice';
import { deals } from '@/store/deals';
import { modals } from '@/store/modals';
import { filters } from '@/store/filters';
import { irps } from '@/store/irps';
import { tabState } from '@/store/tabState';
import { companies } from '@/store/companies';
import { actionCenter } from '@/store/actionCenter';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notifications,
    progressBar,
    authentication,
    iois,
    backOffice,
    deals,
    modals,
    filters,
    irps,
    companies,
    tabState,
    actionCenter,
  },
});
