





















































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { namespace } from 'vuex-class';
import { EventBus } from '@/main';
import InputCheckboxComponent from '@/components/inputs/InputCheckboxComponent.vue';
import InputComponent from '@/components/inputs/InputComponent.vue';
import {
  CustomInput,
  InputCheckboxObject,
} from '@/components/inputs/inputTypes';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import sentry from '@/util/sentry';

const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'LoginForm',
  components: { InputComponent, ButtonComponent, InputCheckboxComponent },
})
export default class LoginForm extends Vue {
  @NotificationsNameSpace.Action('add') addNotification: any;
  @AuthenticationNameSpace.Action('resendConfirmationMailAction')
  resendConfirmationMail: any;

  @AuthenticationNameSpace.Action('setLoginStatus') setLoginStatus: any;
  @AuthenticationNameSpace.Getter('rememberedAccount')
  rememberedAccount: string;

  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isBackOffice') isBackOffice: boolean;
  @AuthenticationNameSpace.Getter('loginStatus') loginStatus: any;
  @AuthenticationNameSpace.Action('loginUser') loginUser: any;

  eventBus = EventBus;
  email: string | null = '';
  password: string | null = '';
  rememberMe: boolean = false;

  rememberMeInput: InputCheckboxObject = {
    id: 'rememberMe',
    name: 'rememberMe',
    labelCode: 'components.login.rememberMe',
    containerClass: 'checkbox-item',
    static: false,
    disabled: false,
    value: false,
  };

  get emailInput(): any {
    return {
      name: 'email',
      placeHolder: 'components.login.email',
      vRule: 'required|email',
      class: this.loginStatus.emailConfirmationNeeded ? 'has-custom-error' : '',
    };
  }

  get passwordInput(): any {
    return {
      name: 'password',
      type: 'password',
      placeHolder: 'components.login.password',
      vRule: 'required',
      class: this.loginStatus.emailConfirmationNeeded ? 'has-custom-error' : '',
    };
  }

  get loginButton(): any {
    return {
      containerClass: 'button-end',
      buttonClass: 'button-icon login-button',
      description: `${this.$t('components.login.submit')}`,
      toolTip: `${this.$t('components.login.submit')}`,
      icon: 'login.png',
      showExtended: false,
    };
  }

  get resendConfirmationButton(): any {
    return {
      containerClass: 'button-center',
      buttonClass: 'button-icon login-status__button',
      description: `${this.$t('components.login.resendConfirmationButton')}`,
      toolTip: `${this.$t('components.login.resendConfirmationButton')}`,
      icon: 'content-copy.svg',
      svg: true,
      showExtended: false,
    };
  }

  setInputValue(inputValue: CustomInput) {
    switch (inputValue.name) {
      case 'email':
        this.email = inputValue.value;
        this.emailInput.value = inputValue.value;
        break;
      case 'password':
        this.password = inputValue.value;
        this.passwordInput.value = inputValue.value;
        break;
      default:
        break;
    }
  }

  setInputCheckbox(inputValue: InputCheckboxObject) {
    if (inputValue.name === 'rememberMe') {
      this.rememberMe = inputValue.value;
      this.rememberMeInput.value = inputValue.value;
    }
  }

  resendConfirmationNow() {
    this.resendConfirmationMail(this.email)
      .then(() => {
        this.setLoginStatus({
          emailConfirmationNeeded: false,
          emailConfirmationSent: true,
        });
      })
      .catch((error: any) => {
        this.addNotification({
          message: error,
          type: 'error',
        });
        sentry(error);
      });
  }

  validateForm() {
    this.$validator
      .validateAll()
      .then(result => {
        if (!result) {
          const validationError: TranslateResult = this.$t(
            'components.login.validationError',
          );
          this.addNotification({
            message: `${validationError}`,
            type: 'error',
          });
        } else {
          this.submitLogin();
          this.setLoginStatus({
            notApproved: false,
            emailConfirmationNeeded: false,
            emailConfirmationSent: false,
          });
        }
      })
      .catch((error: any) => {
        const loginError: TranslateResult = this.$t(
          'components.login.loginError',
        );
        this.addNotification({
          message: `${loginError} ${error}`,
          type: 'error',
        });
        sentry(error);
      });
  }

  submitLogin() {
    this.loginUser({
      email: this.email,
      password: this.password,
      rememberMe: this.rememberMeInput.value,
    });
  }

  forgotPasswordReload() {
    this.eventBus.$emit('reloadHome', 'forgotPassword');
  }

  created() {
    if (this.rememberedAccount !== '') {
      this.email = this.rememberedAccount;
      this.emailInput.value = this.email;
      this.rememberMeInput.value = true;
      this.eventBus.$emit('reloadCheckboxInput', this.rememberMeInput);
      this.eventBus.$emit('reloadInput', this.emailInput);
    }
  }
}
