import jwtDecode from 'jwt-decode';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { isEmpty, forEach } from 'lodash';
import moment from 'moment';
import { TranslateResult } from 'vue-i18n';
import router from '@/router';
import store from '@/store';
import { AuthenticationState, Token, Notification } from '@/types/types';
import { RootState } from '@/types/rootState';
import { EncryptedStorage } from '@/security/crypto';
import {
  login,
  requestPasswordReset,
  resetPassword,
  requestPasswordChange,
  changePassword,
  signUp,
  signUpProfessional,
  inviteUsers,
  prepopulateProfessional,
  industrySectorList,
  preferencesPrepopulate,
  preferencesUpdate,
  approveSignup,
  denySignup,
  approveUpgrade,
  denyUpgrade,
  resendConfirmationMail,
} from '@/api/authenticationApi';
import {
  AccountEmailPayload,
  LoginPayload,
  ChangePasswordPayload,
  MarketViewerAccount,
  SignUpProfessionalPayload,
  PreferencesPayload,
  InviteUsersPayload,
} from '@/types/accountTypes';
import { redirectUserPage } from '@/util/generalUtils';
import sentry from '@/util/sentry';
import { getCurrentLanguage, setLanguage } from '@/translation';

function decodedAccessToken(tokenString: string): Token {
  const tokenInfo: any = jwtDecode(tokenString);
  const token: Token = {
    userAccountId: tokenInfo.userAccountId,
    accountStatus: tokenInfo.userAccountStatus,
    clientKey: tokenInfo.clientKey,
    exp: tokenInfo.exp,
    username: tokenInfo.username,
    roles: [],
    email: tokenInfo.email,
    isLoggedIn: false,
    name: tokenInfo.name,
  };
  if (!isEmpty(tokenInfo.roles)) {
    forEach(tokenInfo.roles, role => {
      token.roles.push(role);
    });
  }
  return token;
}

function checkCredentials(localToken: Token) {
  let expiration: number = 0;
  if (isEmpty(localToken) || localToken.exp === 0) {
    // @ts-ignore
    const tokenFromStorage: string = !isEmpty(localStorage.getItem('token'))
      ? localStorage.getItem('token')
      : '';
    if (!isEmpty(tokenFromStorage)) {
      const token: Token = decodedAccessToken(tokenFromStorage);
      expiration = token.exp;
    } else {
      return false;
    }
  } else {
    expiration = localToken.exp;
  }
  const nowUnix: number = Number(moment().format('X'));
  return !isEmpty(localToken.userAccountId) && expiration > nowUnix;
}

function getRememberedAccount(): string {
  const email: string | null = localStorage.getItem('rememberedAccount');
  return email === null ? '' : email;
}

export const state: AuthenticationState = {
  token: {
    userAccountId: '',
    accountStatus: 0,
    clientKey: '',
    exp: 0,
    username: '',
    roles: [],
    email: '',
    isLoggedIn: false,
    name: '',
  },
  redirectToPage: {
    redirect: false,
    name: '',
    query: Object,
    params: Object,
  },
  loginStatus: {
    notApproved: false,
    emailConfirmationSent: false,
    emailConfirmationNeeded: false,
  },
  timeout: 0,
  tokenExpired: false,
  encryptedLocalStorage: {} as EncryptedStorage,
  encryptedSessionStorage: {} as EncryptedStorage,
  language: getCurrentLanguage(),
  windowWidth: 0,
  serverDatetime: 0,
  computerDatetime: 0,
};

// getters
export const getters: GetterTree<AuthenticationState, RootState> = {
  language: (state): string => state.language,
  userAccountId: (state): string => state.token.userAccountId,
  rememberedAccount: (): string => getRememberedAccount(),
  isUserLoggedIn: (state): boolean => state.token.isLoggedIn,
  tokenExpired: (state): boolean => state.tokenExpired,
  loginStatus: (state): Object => state.loginStatus,
  checkUserLoggedIn: (state): boolean => checkCredentials(state.token),
  userLoggedName: (state): string => state.token.name,
  isMarketViewer(state): boolean {
    return (
      !isEmpty(state.token) &&
      !isEmpty(state.token.roles) &&
      state.token.roles.indexOf('ROLE_MARKET_VIEWER') > -1
    );
  },
  isInvestor(state): boolean {
    return (
      !isEmpty(state.token) &&
      !isEmpty(state.token.roles) &&
      state.token.roles.indexOf('ROLE_INVESTOR') > -1
    );
  },
  isIssuer(state): boolean {
    return (
      !isEmpty(state.token) &&
      !isEmpty(state.token.roles) &&
      state.token.roles.indexOf('ROLE_ISSUER') > -1
    );
  },
  isLeadManager(state): boolean {
    return (
      !isEmpty(state.token) &&
      !isEmpty(state.token.roles) &&
      state.token.roles.indexOf('ROLE_LEAD_MANAGER') > -1
    );
  },
  isHAM(state): boolean {
    return (
      !isEmpty(state.token) &&
      !isEmpty(state.token.roles) &&
      state.token.roles.indexOf('ROLE_HEAD_ACCOUNT_MANAGER') > -1
    );
  },
  isBackOffice(state): boolean {
    return (
      !isEmpty(state.token) &&
      !isEmpty(state.token.roles) &&
      state.token.roles.indexOf('ROLE_BACKOFFICE') > -1
    );
  },
  isAccountStatus(state): {} {
    return state.token.accountStatus;
  },
  windowWidth: (state): number => state.windowWidth,
  serverDateDifference: (state): number =>
    state.serverDatetime - state.computerDatetime,
};

// mutations
export const mutations: MutationTree<AuthenticationState> = {
  LOGOUT(state): void {
    state.token.userAccountId = '';
    state.token.clientKey = '';
    state.encryptedLocalStorage = {} as EncryptedStorage;
    state.encryptedSessionStorage = {} as EncryptedStorage;
    clearTimeout(state.timeout);
    localStorage.setItem('token', '');
  },
  LOGIN(state, token: string): void {
    state.token = decodedAccessToken(token);
    state.token.isLoggedIn = true;
    state.encryptedLocalStorage = new EncryptedStorage(
      localStorage,
      state.token.clientKey,
    );
    state.encryptedSessionStorage = new EncryptedStorage(
      sessionStorage,
      state.token.clientKey,
    );
    localStorage.setItem('token', token);
    if (
      state.token.roles.indexOf('ROLE_INVESTOR') > -1 ||
      state.token.roles.indexOf('ROLE_ISSUER') > -1
    ) {
      localStorage.removeItem('upgradeAccount');
    }

    if (state.timeout !== 0) {
      clearTimeout(state.timeout);
    }
    state.tokenExpired = false;
    // set timeout id in state so it can be cleared

    state.timeout = setTimeout(() => {
      state.tokenExpired = true;
      localStorage.setItem('token', '');
    }, 1200000);
  },
  SET_LOGIN_STATUS(state, statusChanged: object) {
    state.loginStatus = { ...state.loginStatus, ...statusChanged };
  },
  SET_TOKEN(state, token: string): void {
    state.token = decodedAccessToken(token);
    state.token.isLoggedIn = true;
  },
  SET_LOGGEDIN(state): void {
    state.token.isLoggedIn = true;
  },
  SET_TOKENEXPIRED(state): void {
    state.tokenExpired = true;
  },
  SET_LANGUAGE(state, selectedLanguage: string): void {
    state.language = selectedLanguage;
    setLanguage(selectedLanguage);
  },
  SET_WINDOW(state, width): void {
    state.windowWidth = width;
  },
  ACTIVATE_REDIRECT(
    state,
    redirection: { name: string; query: {}; params: {} },
  ): void {
    const redirect = state.redirectToPage;
    redirect.name = redirection.name;
    redirect.query = redirection.query;
    redirect.params = redirection.params;
    redirect.redirect = true;
    state.redirectToPage = redirect;
  },
  DEACTIVATE_REDIRECT(state): void {
    const redirect = state.redirectToPage;
    redirect.redirect = false;
    state.redirectToPage = redirect;
  },
  SET_SERVER_DATETIME(state, serverDatetime): void {
    state.serverDatetime = parseInt(serverDatetime) * 1000;
    state.computerDatetime = Date.now();
  },
};

export const actions: ActionTree<AuthenticationState, RootState> = {
  loginUser({ commit, dispatch, getters }, payload: LoginPayload) {
    let tokenInformation: any = '';
    return login(payload)
      .then(({ data }) => {
        commit('LOGIN', data.token);
        tokenInformation = data.token;
        if (payload.rememberMe) {
          localStorage.setItem('rememberedAccount', payload.email);
        } else {
          localStorage.setItem('rememberedAccount', '');
        }
        if (localStorage.getItem('redirect')) {
          // if redirect active get the saved params from storage
          const redirectTo = JSON.parse(
            <string>localStorage.getItem('redirect'),
          );
          const toParam = {
            name: redirectTo.name,
            query: redirectTo.query,
            params: redirectTo.params,
          };

          localStorage.removeItem('redirect');
          router.push(toParam).catch(err => {});
        } else {
          router.push({ name: redirectUserPage() }).catch(err => {});
        }

        store.dispatch('actionCenter/activateCenter', {
          root: true,
        });
      })
      .catch((error: any) => {
        const errorLoginMessage: TranslateResult = router.app.$t(
          'components.login.loginError',
        );

        if (error.response) {
          dispatch(
            'notifications/add',
            {
              message: `${errorLoginMessage} ${error.response.data.message}`,
              type: 'error',
            },
            { root: true },
          );

          if (error.response.data.code === 400) {
            const { explanationCode } = error.response.data;
            if (explanationCode === 19999) {
              commit('SET_LOGIN_STATUS', { notApproved: true });
            } else if (explanationCode === 19998) {
              commit('SET_LOGIN_STATUS', {
                emailConfirmationSent: false,
                emailConfirmationNeeded: true,
              });
            }
          }
        } else {
          dispatch(
            'notifications/add',
            {
              message: `${errorLoginMessage} ${error}`,
              type: 'error',
            },
            { root: true },
          );
        }
        router.push({ name: 'login' }).catch(err => {});
        sentry(error);
      });
  },

  loadToken({ commit }): void {
    const token: string | null = localStorage.getItem('token');
    if (!isEmpty(token)) {
      commit('SET_TOKEN', token);
    }
  },

  setLoginStatus({ commit }, status): void {
    commit('SET_LOGIN_STATUS', status);
  },

  logoutUser({ commit }): void {
    commit('LOGOUT');
  },

  approveSignup(_: {}, payload: AccountEmailPayload) {
    return approveSignup(payload);
  },

  denySignup(_: {}, payload: AccountEmailPayload) {
    return denySignup(payload);
  },

  approveUpgrade(_: {}, payload: AccountEmailPayload) {
    return approveUpgrade(payload);
  },

  denyUpgrade(_: {}, payload: AccountEmailPayload) {
    return denyUpgrade(payload);
  },

  requestUserPasswordReset(_: {}, payload: LoginPayload) {
    return requestPasswordReset(payload);
  },

  resetUserPassword(_: {}, payload: ChangePasswordPayload) {
    return resetPassword(payload);
  },

  requestUserPasswordChange(_: {}, payload: ChangePasswordPayload) {
    return requestPasswordChange(payload);
  },

  changeUserPassword(_: {}, payload: ChangePasswordPayload) {
    return changePassword(payload);
  },

  signUpUser({ commit, dispatch, getters }, payload: MarketViewerAccount) {
    return new Promise((resolve, reject) => {
      signUp(payload)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  },

  signUpProfessionallUser({ state }, payload: SignUpProfessionalPayload) {
    return signUpProfessional(payload);
  },

  inviteUsers({ state }, payload: InviteUsersPayload) {
    return inviteUsers(payload);
  },

  refreshToken({ commit, rootState }, token: string): void {
    localStorage.setItem('token', token);
    store.dispatch('actionCenter/setNotificationEventListener', {
      root: true,
    });
    commit('LOGIN', token);
  },

  authenticateLogin(
    { commit, dispatch, rootState },
    errorMessage: TranslateResult,
  ) {
    // this method controls if the user is logged in and if the token hasn't expired
    // if it has, then the user is sent back to login
    if (isEmpty(state.token) || state.token.exp === 0) {
      // @ts-ignore
      const tokenFromStorage: string = !isEmpty(localStorage.getItem('token'))
        ? localStorage.getItem('token')
        : ' ';
      if (!isEmpty(tokenFromStorage) && tokenFromStorage !== ' ') {
        // DO NOT remove the (tokenFromStorage !== ' ') condition
        commit('SET_TOKEN', tokenFromStorage);
      }
    }
    const loggedIn: boolean = checkCredentials(state.token);
    if (loggedIn) {
      commit('SET_LOGGEDIN');
      store.dispatch('actionCenter/activateCenter', {
        root: true,
      });
    } else {
      const errorNotification: Notification = {
        id: 0,
        message: `${errorMessage}`,
        type: 'error',
      };
      dispatch('notifications/add', errorNotification, { root: true });
      router.push({ name: 'login' }).catch(err => {});
    }
  },

  authenticateLoginRoute({ commit, dispatch, rootState }) {
    if (isEmpty(state.token) || state.token.exp === 0) {
      // @ts-ignore
      const tokenFromStorage: string = !isEmpty(localStorage.getItem('token'))
        ? localStorage.getItem('token')
        : ' ';
      if (!isEmpty(tokenFromStorage) && tokenFromStorage !== ' ') {
        // DO NOT remove the (tokenFromStorage !== ' ') condition. question: why?
        commit('SET_TOKEN', tokenFromStorage);
      }
    }
    const loggedIn: boolean = checkCredentials(state.token);
    if (loggedIn) {
      commit('SET_LOGGEDIN');
    }
  },

  resendConfirmationMailAction(_: {}, email: object) {
    return resendConfirmationMail({ email });
  },

  async prepopulateProfessionalSignUp({ dispatch, getters }) {
    if (!getters.userAccountId) {
      await dispatch('loadToken');
      return prepopulateProfessional(getters.userAccountId);
    }

    return prepopulateProfessional(getters.userAccountId);
  },

  industryListProfessionalSignUp(_: {}) {
    // this method gets a list of possible industries or sector for the professional account
    return industrySectorList();
  },

  setUpgradeAccountType(_: {}, upgradeType: string) {
    localStorage.setItem('upgradeAccount', upgradeType);
  },

  getUpgradeAccountType(_: {}) {
    return localStorage.getItem('upgradeAccount');
  },

  removeUpgradeAccount(_: {}) {
    return localStorage.removeItem('upgradeAccount');
  },

  preferencesPrepopulate(_: {}, payload: PreferencesPayload) {
    return preferencesPrepopulate(payload);
  },

  preferencesUpdate(_: {}, payload: PreferencesPayload) {
    return preferencesUpdate(payload);
  },

  widthAction({ commit }, width: number): void {
    commit('SET_WINDOW', width);
  },

  setLanguageStore({ commit }, language: string): void {
    commit('SET_LANGUAGE', language);
  },

  setServerDatetime({ commit }, serverDatetime: string): void {
    commit('SET_SERVER_DATETIME', serverDatetime);
  },
};

export const authentication = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
