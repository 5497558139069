





















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { findIndex } from 'lodash';
import { EventBus } from '@/main';
import IoiRow from '@/components/ioi/IOIRow.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import IoiSpreadOrCoupon from '@/components/ioi/IOISpreadOrCoupon.vue';
import MyOffers from '@/components/ioi/MyOffers.vue';
import { multipleInstruments, getInstrumentType } from '@/util/ioiUtils';
import { Offer } from '@/types/instrumentsTypes';
import { getCompanySettings } from '@/api/companyApi';
import { ApprovalData, CompanySettings } from '@/types/types';
import { getMultiEyeInstrumentData } from '@/api/multiEyeApi';

const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');
const IOINameSpace = namespace('iois/');
const CompanyStore = namespace('companies/');
@Component({
  name: 'MakeOffers',
  components: {
    ButtonComponent,
    IoiSpreadOrCoupon,
    IoiRow,
    MyOffers,
  },
})
export default class MakeOffers extends Vue {
  @Prop({}) showModalParam!: boolean;
  @Prop({ default: true }) showIoiRow!: boolean;
  @Prop({}) accountParam!: string;
  @Prop({}) showKeepInformedParam!: boolean;
  @Prop({}) offerInputInformation!: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;
  // IOI store
  @IOINameSpace.Getter('newOfferId') newOfferId: string;
  @IOINameSpace.Action('addToWishList') addToWishList: any;
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @CompanyStore.Getter('selectedCompanyIdGetter')
  selectedCompanyIdGetter: string;

  eventBus = EventBus;
  showModal: boolean = false;
  offer: any = {}; // current offer
  lastKey: number = 1;
  myOffersKey: string = `myOffers_${this.lastKey}`;
  newOfferKey: string = 'currentOffer';
  multiEyeSettings: CompanySettings | null = null;
  approvalData: ApprovalData[] = [];

  get oneOptionClass(): string {
    return this.showKeepInformedParam ? '' : 'ioi-offer-one-selection';
  }

  get inputIOI() {
    return {
      ...this.offerInputInformation.ioi,
      instrumentsList: multipleInstruments(
        this.offerInputInformation.ioi.instrumentTypes,
      ),
      ioiOffers: this.inputIOIFullOffers,
    };
  }

  get inputIOIFullOffers() {
    if (this.multiEyeSettings) {
      return this.offerInputInformation.ioi.ioiOffers.map((offer: Offer) => ({
        ...offer,
        instrumentLabel: getInstrumentType(Number(offer.instrumentType)),
        approvalData:
          this.approvalData.filter(
            approvalData => offer.offerId === approvalData.offerId,
          )[0] || null,
      }));
    }
    return this.offerInputInformation.ioi.ioiOffers.map((offer: Offer) => ({
      ...offer,
      instrumentLabel: getInstrumentType(Number(offer.instrumentType)),
    }));
  }

  getApprovalData() {
    this.offerInputInformation.ioi.ioiOffers.forEach((offer: any) => {
      getMultiEyeInstrumentData(offer.offerId).then(response => {
        this.approvalData.push({
          ...response.data['multieye-flows'][0],
          offerId: offer.offerId,
        });
      });
    });
  }

  onSubmitOffer(offerSaved: Offer): void {
    // when an offer has been submitted (created or updated) from the IOISpreadCoupon,
    // said offer needs to be refreshed on the sibling component MyOffers
    // this event synchronizes both sibling components
    this.clearOffer();
    const index: number = findIndex(
      this.inputIOI.ioiOffers,
      (offer: Offer) => offer.offerId === offerSaved.offerId,
    );
    if (index !== -1) {
      this.inputIOI.ioiOffers[index] = {
        ...offerSaved,
        offerId:
          offerSaved.offerId === '' ? this.newOfferId : offerSaved.offerId,
        instrumentLabel: getInstrumentType(Number(offerSaved.instrumentType)),
      };
    } else {
      this.inputIOI.ioiOffers.push({
        ...offerSaved,
        offerId: this.newOfferId,
        instrumentLabel: getInstrumentType(Number(offerSaved.instrumentType)),
      });
    }
    this.lastKey += 1;
    this.myOffersKey = `myOffers_${this.lastKey}`;

    getMultiEyeInstrumentData(this.newOfferId).then(response => {
      this.approvalData.push({
        ...response.data['multieye-flows'][0],
        offerId: this.newOfferId,
      });
    });
    this.$emit('onSubmitOffer');
  }

  onCancelOffer(): void {
    this.clearOffer();
  }

  editCurrentOffer(newOffer: Offer) {
    // when an offer has been selected to be updated on the MyOffers component,
    // said offer it is sent to the sibling component IOISpreadOrCoupon, so the information is
    // copied
    this.offer = { ...newOffer };
    this.newOfferKey = `currentOffer_${newOffer.offerId}`;
  }

  clearOffer() {
    // to clear the offer, the current offer is reset
    // and the component key is changed to re-render the child component
    this.offer = {} as Offer;
    this.newOfferKey = this.newOfferKey + 1;
  }

  onCloseOffer() {
    // this event closes the whole modal window
    this.$emit('onCloseMakeOffers');
  }

  getIOI(inputInformation: any) {
    if (inputInformation.offerId !== '') {
      // offer to edit information
      this.offer.offerId = inputInformation.offerId;
      this.offer.minSize = inputInformation.minSize;
      this.offer.maxSize = inputInformation.maxSize;
      this.offer.couponPercentage = inputInformation.couponPercentage;
      this.offer.spreadBasisPoints = inputInformation.spreadBasisPoints;
      this.offer.spreadOverWhat = inputInformation.spreadOverWhat;
      this.offer.instrumentType = inputInformation.instrumentType;
      this.offer.offerType = inputInformation.offerType;
      this.newOfferKey = `currentOffer_${this.offer.offerId}`;
    } else {
      this.offer = {};
    }
  }

  mounted() {
    this.getIOI(this.offerInputInformation);
    this.getData();
    this.getApprovalData();
  }

  getData() {
    getCompanySettings(this.selectedCompanyIdGetter)
      .then(({ data: { settings } }) => {
        this.multiEyeSettings = settings;
      })
      .catch(() => {
        this.multiEyeSettings = null;
      });
  }
}
