var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"base-container contact"},[_c('div',{staticClass:"contact__form-container"},[_c('h2',{staticClass:"contact__title"},[_vm._v(_vm._s(_vm.$t('views.contact.title')))]),_c('input-component',{attrs:{"input-param":{
          name: 'name',
          vLabel: 'components.signUpForm.firstName',
          placeHolder: 'components.signUpForm.firstName',
          vRule: 'required',
          containerClass: 'input-inner',
          class: 'form-input',
          showLabel: true,
          value: _vm.firstName,
        }},on:{"sendInput":function($event){_vm.firstName = arguments[0].value}}}),_c('input-component',{attrs:{"input-param":{
          name: 'email',
          placeHolder: 'components.signUpForm.email',
          vRule: 'required|email',
          containerClass: 'input-inner',
          class: 'form-input',
          showLabel: true,
          value: _vm.email,
        }},on:{"sendInput":function($event){_vm.email = arguments[0].value}}}),_c('input-component',{attrs:{"input-param":{
          name: 'company',
          placeHolder: 'components.signUpForm.companyName',
          vRule: '',
          containerClass: 'input-inner',
          class: 'form-input',
          showLabel: true,
          value: _vm.companyName,
        }},on:{"sendInput":function($event){_vm.companyName = arguments[0].value}}}),_c('div',{staticClass:"input-container"},[_c('label',{attrs:{"for":"contact-message"}},[_vm._v(" "+_vm._s(_vm.$t('views.contact.yourMessage'))+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"contact__textarea",attrs:{"id":"contact-message","name":"contact-message"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}})]),_c('canvas',{ref:"sumCanvas",staticClass:"sum-canvas"}),_c('input-component',{attrs:{"input-param":{
          name: 'captcha',
          placeHolder: 'components.signUpForm.captcha',
          vRule: { is: _vm.sumAnswer.stringSum, required: true },
          containerClass: 'input-inner',
          class: 'form-input',
          showLabel: true,
          value: _vm.captcha,
        }},on:{"sendInput":function($event){_vm.captcha = arguments[0].value}}}),_c('input-radio-component',{attrs:{"input-param":{
          name: 'terms',
          optionList: [
            {
              id: 'terms',
              value: '1',
              labelCode: 'views.contact.policy',
              static: false,
            } ],
          vRule: 'required',
          wrapperClass: 'input-container radio-no-stuff',
          verticalRadioButton: false,
          showImage: true,
          containerClass: 'radio-container',
          link: '/privacy',
        }}}),(!_vm.succesMessage)?_c('button',{staticClass:"button-icon huge",on:{"click":_vm.sendForm}},[_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.$t('views.contact.sendMail')))]),_c('svg',{class:'button-icon__img',attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","svg-inline":'',"data-src":'../../assets/images/email-send-outline.svg',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M13 17h4v-3l5 4.5-5 4.5v-3h-4v-3m7-13H4a2 2 0 00-2 2v12a2 2 0 002 2h7v-2H4V8l8 5 8-5v6h2V6a2 2 0 00-2-2m-8 7L4 6h16z"}})])]):_vm._e(),(_vm.succesMessage)?_c('p',{staticClass:"succes-message"},[_vm._v(" "+_vm._s(_vm.succesMessage)+" ")]):_vm._e(),(_vm.errorMessage)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact__directions-container"},[_c('div',{staticClass:"contact__adress"},[_c('address',{staticClass:"contact__adress-el"},[_vm._v(" Beursplein 5"),_c('br'),_vm._v(" 1012 JW Amsterdam"),_c('br'),_vm._v(" The Netherlands"),_c('br'),_vm._v(" T: "),_c('a',{attrs:{"href":"tel:0031205707200"}},[_vm._v("+ 31 20 5707200")]),_c('br'),_vm._v(" F: "),_c('a',{attrs:{"href":"tel:0031205707299"}},[_vm._v("+31 20 5707299")]),_c('br'),_c('a',{attrs:{"href":"mailto:info@afsgroup.nl"}},[_vm._v("info@afsgroup.nl")])])]),_c('div',{staticClass:"contact__map"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2435.7925501036634!2d4.893734!3d52.3741815!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609b8818e05e5%3A0x4e8435326a24d079!2sBeursplein%205%2C%201012%20JW%20Amsterdam!5e0!3m2!1sen!2snl!4v1583142772140!5m2!1sen!2snl","width":"100%","height":"600px","frameborder":"0","allowfullscreen":""}})])])}]

export { render, staticRenderFns }