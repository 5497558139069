






























































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import InputPhoneNumberComponent from '@/components/inputs/InputPhoneNumberComponent.vue';
import InputComponent from '@/components/inputs/InputComponent.vue';
import InputFileComponent from '@/components/inputs/InputFileComponent.vue';
import InputSelectComponent from '@/components/inputs/InputSelectComponent.vue';
import InputRadioComponent from '@/components/inputs/InputRadioComponent.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import { sendContactForm } from '@/api/util';
import sentry from '@/util/sentry';
import { EventBus } from '@/main';

const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'ContactPage',
  components: {
    InputPhoneNumberComponent,
    InputComponent,
    InputSelectComponent,
    InputRadioComponent,
    ButtonComponent,
    InputFileComponent,
  },
})
export default class ContactPage extends Vue {
  numberOne: number = Math.floor(Math.random() * (6 - 1) + 1);
  numberTwo: number = Math.floor(Math.random() * (6 - 1) + 1);

  message: string = '';
  firstName: string = '';
  email: string = '';
  companyName: string = '';
  captcha: string = '';
  succesMessage: string = '';
  errorMessage: string = '';
  eventBus = EventBus;

  get sumAnswer(): { sum: number; stringSum: string } {
    const sum = this.numberOne + this.numberTwo;
    return { sum: sum, stringSum: sum.toString() };
  }

  mounted() {
    this.drawSum();
  }

  drawSum() {
    // @ts-ignore, vue does not understand the canvas element
    const ctx = this.$refs.sumCanvas.getContext('2d');
    ctx.font = '90px serif';
    ctx.textAlign = 'center';
    ctx.lineHeight = '1';
    ctx.fillText(`${this.numberOne} + ${this.numberTwo} = ?`, 180, 105);
  }

  validateContactForm() {
    const promises: any = [];
    ['name', 'email', 'captcha', 'terms'].forEach(element => {
      promises.push(this.$validator.validate(element));
    });
    return Promise.all(promises);
  }

  sendForm(): void {
    this.validateContactForm().then(result => {
      if (!result.includes(false)) {
        sendContactForm({
          firstName: this.firstName,
          email: this.email,
          companyName: this.companyName,
          message: this.message,
        })
          .then(() => {
            this.errorMessage = '';
            this.succesMessage = this.$t('views.contact.succes').toString();
          })
          .catch((error: any) => {
            this.succesMessage = '';
            this.errorMessage = this.$t('views.contact.error').toString();
            sentry(error);
          });
      }
    });
  }
}
