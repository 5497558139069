



























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { namespace } from 'vuex-class';
import { confirmSignup } from '@/api/authenticationApi';

const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'ConfirmationPage',
  props: {
    userAccountId: String,
    accountConfirmation: String,
  },
})
export default class ConfirmationPage extends Vue {
  @NotificationsNameSpace.Action('add') addNotification: any;
  @AuthenticationNameSpace.Action('confirmSignup') confirmSignup: boolean;

  processed: boolean = false;
  success: boolean = false;
  greeting: TranslateResult = '';
  successTitle: TranslateResult = '';
  successDescription: TranslateResult = '';
  failedTitle: TranslateResult = '';
  failedDescription: TranslateResult = '';

  created() {
    this.greeting = `${this.$t('views.confirmationPage.userGreeting')}`;
    this.successTitle = `${this.$t(
      'views.confirmationPage.confirmedYourAccount.titleSuccess',
    )}`;
    this.successDescription = `${this.$t(
      'views.confirmationPage.confirmedYourAccount.descriptionSuccess',
    )}`;
    this.failedTitle = `${this.$t(
      'views.confirmationPage.confirmedYourAccount.titleFailed',
    )}`;
    this.failedDescription = `${this.$t(
      'views.confirmationPage.confirmedYourAccount.descriptionFailed',
    )}`;
    confirmSignup({
      userAccountId: this.$props.userAccountId,
      userAccountConfirmation: this.$props.accountConfirmation,
    })
      .then(() => {
        this.processed = true;
        this.success = true;
      })
      .catch(() => {
        this.processed = true;
        this.success = false;
      });
  }
}
