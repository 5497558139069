
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Pie } from 'vue-chartjs-typescript';
import 'chartjs-plugin-labels';
@Component({
  name: 'PieChart',
})
export default class PieChart extends Mixins(Pie) {
  @Prop({ required: true }) chartData!: any;
  @Prop({ required: true }) chartOptions!: any;

  startRenderChart() {
    if (this.$data.chart) {
      this.$data.chart.destroy();
    }
    this.renderChart(this.chartData, this.chartOptions);
  }

  mounted() {
    // double render to make sure it is responsive
    this.startRenderChart();
    setTimeout(this.startRenderChart, 300);
  }
}
