













import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import { Validator } from 'vee-validate';
import { InputCheckboxObject } from './inputTypes';
import { EventBus } from '@/main';

@Component({
  name: 'InputCheckboxComponent',
})
export default class InputCheckboxComponent extends Vue {
  @Prop({}) inputParam!: any;
  @Inject('$validator') $validator!: Validator;

  eventBus = EventBus;
  vmodelValue: boolean = false;
  inputField: InputCheckboxObject = {
    id: '',
    name: '',
    containerClass: '',
    labelCode: '',
    disabled: false,
    value: false,
    static: true,
  };

  get inputId() {
    return this.inputParam.id;
  }

  get inputName() {
    return isEmpty(this.inputParam.name) ? '' : this.inputParam.name;
  }

  get containerClass(): string | null {
    return isEmpty(this.inputParam.containerClass)
      ? 'checkbox-item'
      : this.inputParam.containerClass;
  }

  get labelText(): string {
    return this.inputParam.static === true
      ? this.inputParam.labelCode
      : `${this.$t(this.inputParam.labelCode)}`;
  }

  inputChange(event: any) {
    this.$emit('sendInput', {
      id: this.inputId,
      name: this.inputName,
      value: event.target.checked,
    });
  }

  created() {
    this.inputField = Object.assign({}, this.inputParam);
    this.vmodelValue = this.inputParam.value;
    this.eventBus.$on(
      'reloadCheckboxInput',
      (inputInformation: InputCheckboxObject) => {
        if (inputInformation.name === this.inputParam.name) {
          this.vmodelValue = inputInformation.value;
          this.inputField = {
            id: inputInformation.id,
            name: inputInformation.name,
            containerClass: inputInformation.containerClass,
            static: inputInformation.static,
            disabled: inputInformation.disabled,
            labelCode: inputInformation.labelCode,
            value: inputInformation.value,
          };
        }
      },
    );
  }
}
