'use strict';

import api from './index';

export function createIOIOverviewPDF(payload: any) {
  const config = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  };

  // @ts-ignore
  api.post('/pdf/ioi-overview', payload, config).then((response: any) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ioi-overview.pdf');
    document.body.appendChild(link);
    link.click();
  });
}

export function openPDF(base64URL: string, title: string) {
  const pdfWindow: any = window.open('');
  const style = 'border:0 0 0 0;width:100%;height:100%;';
  // @ts-ignore for isIE check
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  // @ts-ignore for isEdge check
  const isEdge = !isIE && !!window.StyleMedia;
  if (pdfWindow !== null) {
    setTimeout(() => {
      if (isIE || isEdge) {
        // IE check
        const fileName = `${title}.pdf`;
        const byteCharacters = atob(base64URL.split('base64,')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: 'application/pdf,charset=utf-8',
        });
        // ask to save or open file + open new window
        pdfWindow.document.write(
          `<iframe src="${window.navigator.msSaveOrOpenBlob(
            blob,
            fileName,
          )}" style="${style}"></iframe>`,
        );
        // close window immediately
        pdfWindow.document.write('<script>self.close();</script>');
      } else {
        pdfWindow.document.write(
          `<iframe src="${encodeURI(base64URL)}" style="${style}"></iframe>`,
        );
        pdfWindow.document.title = title;
      }
    }, 0);
  }
}
