










































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import InputCompanyComponent from '@/components/inputs/InputCompanyComponent.vue';
import { EventBus } from '@/main';

const BackOfficeNameSpace = namespace('backOffice/');

@Component({
  name: 'FilterForm',
  components: { ButtonComponent, InputCompanyComponent },
})
export default class FilterForm extends Vue {
  @BackOfficeNameSpace.Getter('companies') companies: any;
  @BackOfficeNameSpace.Action('getCompanies') getCompanies: any;
  @BackOfficeNameSpace.Action('setFilteredCompany') setFilteredCompany: any;

  eventBus = EventBus;
  showModal: boolean = false;
  filteredCompany: string = '';
  returnToTab: string = '';
  filterTooltipText: string = 'Close filters';
  inputCompanySelect: any = {
    name: 'companyName',
    placeHolder: 'components.IOIForm.issuerCompanyName',
    vRule: '',
    defaultTooltip: true,
    optionList: [],
    value: undefined,
  };

  applyFiltersButtonObject: any = {
    containerClass: 'ioi-row-inline-button',
    buttonClass: 'button-icon button-icon-resize',
    description: 'Apply Filters',
    extendedDescription: 'Apply Filters',
    icon: 'edit-blue.png',
    showExtended: false,
  };

  get filterTooltip(): string {
    return this.filterTooltipText;
  }

  // Approve Account events
  get applyFiltersButton(): any {
    return this.applyFiltersButtonObject;
  }

  onCloseFilterForm() {
    this.showModal = false;
    this.$emit('onCloseFilterForm');
  }

  setCompany(company: any) {
    if (company.value !== undefined) {
      this.inputCompanySelect.value = company.value;
      this.filteredCompany = company.label;
    }
  }

  onCloseAccountForm(): void {
    this.$emit('onCloseAccountForm');
  }

  applyFilters() {
    this.setFilteredCompany(this.filteredCompany);
    this.eventBus.$emit('closeFilters', { tab: this.returnToTab });
  }

  created() {
    this.eventBus.$on('reloadFilterForm', (inputInformation: any) => {
      this.showModal = inputInformation.showModal;
      this.returnToTab =
        inputInformation.tab === undefined ? '' : inputInformation.tab;
      if (inputInformation.showModal) {
        if (this.companies === undefined || this.companies.length === 0) {
          this.getCompanies().then(() => {
            this.inputCompanySelect.optionList = this.companies;
            if (inputInformation.filters.company !== undefined) {
              this.inputCompanySelect.value = {
                value: inputInformation.filters.company,
                label: inputInformation.filters.company,
              };
            }
            this.eventBus.$emit('reloadCompanyInput', this.inputCompanySelect);
          });
        } else {
          this.inputCompanySelect.optionList = this.companies;
        }
      }
    });
  }
}
