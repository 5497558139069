

























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EventList from '@/views/events/EventList.vue';
import EventListAc from '@/components/actionCenter/event-list-ac.vue';
import { EventAc } from '@/types/types';
import LoadingIndicator from '@/components/utils/LoadingIndicator.vue';

const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');
const AcNs = namespace('actionCenter/');

@Component({
  name: 'ActionCenter',
  components: { LoadingIndicator, EventListAc, EventList },
})
export default class ActionCenter extends Vue {
  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // Events store
  @AcNs.Getter('unreadEvents') unreadEvents: any;
  @AcNs.Action('getMyUnreadEvents') getMyUnreadEvents: any;
  @AcNs.Action('deleteEvent') deleteEvent: any;
  @AcNs.Action('activateCenter') activateCenter: any;
  @AcNs.Getter('events') events: EventAc[];

  myAccount: string = this.getUserAccountId;

  get sortedEvents() {
    return {
      meEvents: this.events.filter(event =>
        ['MultiEyeRequestForOffer'].includes(event.type),
      ),
      otherEvents: this.events.filter(
        event => !['MultiEyeRequestForOffer'].includes(event.type),
      ),
    };
  }

  mounted() {
    this.activateCenter();
  }
}
