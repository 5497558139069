












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ChangePasswordForm from '@/components/authentication/ChangePasswordForm.vue';

const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'Password',
  components: { ChangePasswordForm },
})
export default class Password extends Vue {
  /*
   * This view is a component to manage the user password changes
   * */
  @Prop({}) userAccountId!: string;
  @Prop({}) token!: string;

  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
}
