

























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');
const EventsNameSpace = namespace('events/');

@Component({
  name: 'EventList',
})
export default class EventList extends Vue {
  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  // Events store
  @EventsNameSpace.Getter('allMyEvents') allMyEvents: any;
  @EventsNameSpace.Getter('unreadEvents') unreadEvents: any;
  @EventsNameSpace.Action('getMyUnreadEvents') getMyUnreadEvents: any;
  @EventsNameSpace.Action('markEventAsRead') markEventAsRead: any;
  @EventsNameSpace.Action('deleteEvent') deleteEvent: any;

  myAccount: string = this.getUserAccountId;

  markAsReadEvent(eventId: string): void {
    this.markEventAsRead({
      notificationIds: [eventId],
    });
  }

  deleteCurrentEvent(eventId: string): void {
    this.deleteEvent({
      notificationId: eventId,
      accountId: this.myAccount,
    });
  }

  created() {
    // control if the user is logged in; if not, then the user will be sent to login page
    const accountErrorMessage: string = `${this.$t(
      'views.ioiList.errorMessageMV',
    )}`;
    this.authenticateLogin(accountErrorMessage)
      .then(() => {
        this.myAccount = this.getUserAccountId;
        if (this.isUserLoggedIn) {
          this.getMyUnreadEvents(this.myAccount);
        }
      })
      .catch(() =>
        this.addNotification({
          message: 'Error fetching the events',
          type: 'error',
        }),
      );
  }
}
