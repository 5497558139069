














































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IOI } from '@/types/instrumentsTypes';
import {
  daysColumnClass,
  daysColumnText,
  daysColumnBadgeClass,
} from '@/views/myAccounts/myAccounts';
import CountDown from '../utils/CountDown.vue';
import { addMinutes, DateDto } from '@/util/date';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import { EventBus } from '@/main';
import sentry from '@/util/sentry';
import { getIOIState } from '@/util/ioiUtils';

const AuthenticationNameSpace = namespace('authentication/');
const IOINameSpace = namespace('iois/');
const ModalNameSpace = namespace('modals/');
@Component({
  name: 'IOIRowDayColumn.vue',
  components: { CountDown, ButtonComponent },
})
export default class IOIRowDayColumn extends Vue {
  @Prop({}) ioiInput!: IOI;
  @Prop({}) showExtended!: boolean;
  @Prop({}) showButton!: boolean;

  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  // IOI store
  @IOINameSpace.Action('addToWishList') addToWishList: any;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  alert: boolean = this.ioiInput.alertExpired;
  ioiDaysColumnClass: string = daysColumnClass(this.alert);
  ioiDaysColumnBadgeClass = daysColumnBadgeClass(this.alert);
  ioiDaysColumnText = daysColumnText(this.alert);
  timerOver: boolean = false;
  getIOIState = getIOIState;
  eventBus = EventBus;
  myAccount = this.getUserAccountId;
  makeAnOffer: any = {
    showModal: false,
    ioi: {},
    myAccount: '',
    showKeepMeInformed: true,
  };

  get alertBadgeText(): string {
    return this.ioiInput.state === 'closed'
      ? `${this.$t('views.myLMAccount.closingBadge')}`
      : `${this.$t('views.myLMAccount.expiringBadge')}`;
  }

  get countDownDateTime(): DateDto {
    if (this.ioiInput.alertExpired) {
      if (this.ioiInput.state === 'closed') {
        return addMinutes(
          this.ioiInput.proposedTransactionDateStr,
          this.ioiInput.proposedPricingAndTransactionTime,
          15,
        );
      }
      return {
        date: this.ioiInput.proposedTransactionDateStr,
        time: this.ioiInput.proposedPricingAndTransactionTime,
      };
    }
    return { date: '', time: '' };
  }

  get hasOffers(): boolean {
    return this.showButton && this.ioiInput.ioiOffers
      ? this.ioiInput.ioiOffers.length > 0
      : false;
  }

  get showOfferedIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.ioiList.showOffersButton'),
      extendedDescription: this.$t('views.ioiList.showOffersButton'),
      icon: 'currency-euro-blue.png',
      showExtended: this.showExtended,
    };
  }

  get showInterestedIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.ioiList.saveToFavorites'),
      extendedDescription: this.$t('views.ioiList.saveToFavorites'),
      icon: 'heart.png',
      showExtended: this.showExtended,
    };
  }

  get showReactButton(): boolean {
    return this.showButton && !this.timerOver;
  }

  get reactButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.ioiList.reactButton'),
      extendedDescription: this.$t('views.ioiList.reactButton'),
      icon: 'react-blue.png',
      showExtended: this.showExtended,
    };
  }

  showInterests(tabId: string): void {
    if (tabId === '2') {
      this.makeAnOffer.showModal = true;
      this.makeAnOffer.accountParam = this.myAccount;
      this.makeAnOffer.ioi = this.ioiInput;
      this.toggleModal('makeOffer');
      this.eventBus.$emit('reloadMakeAnOffer', this.makeAnOffer);
    }
  }

  saveToFavorites(): void {
    this.addToWishList({
      userAccountId: this.myAccount,
      indicationOfInterestId: this.ioiInput.indicationOfInterestId,
      ioi: this.ioiInput,
    })
      .then(() =>
        this.$router.push({
          name: 'myaccount-investor',
          params: { tabId: '1' },
        }),
      )
      .catch((error: any) => {
        sentry(error);
        // Do not remove
        // Why? - Bart
      });
  }

  interested(): void {
    this.makeAnOffer.showModal = true;
    this.makeAnOffer.accountParam = this.myAccount;
    this.makeAnOffer.ioi = this.ioiInput;
    this.toggleModal('makeOffer');
    this.eventBus.$emit('reloadMakeAnOffer', this.makeAnOffer);
  }

  created() {
    this.myAccount = this.getUserAccountId;
  }
}
