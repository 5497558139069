












































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { namespace } from 'vuex-class';
import ContactLeadManager from '../utils/ContactLeadManager.vue';
import ModalWindow from '@/components/utils/Modal.vue';
import { setLanguage, getCurrentLanguage } from '@/translation/index';

const AuthenticationNameSpace = namespace('authentication/');
const ModalNameSpace = namespace('modals/');
interface navSubmenu {
  submenuName: string;
  submenuLink: string;
  submenuAction: any;
}

interface navItem {
  id: number;
  name: TranslateResult;
  path: string;
  show: boolean;
  submenus: navSubmenu[];
  class?: string;
  image?: string;
  event?: string;
  tooltip?: string;
}

@Component({
  name: 'NavigationMobile',
  components: { ContactLeadManager, ModalWindow },
})
export default class NavigationMobile extends Vue {
  // Authentication store
  @AuthenticationNameSpace.Getter('checkUserLoggedIn')
  checkUserLoggedIn: boolean;

  @AuthenticationNameSpace.Getter('userLoggedName') userLoggedName: string;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  language: string = getCurrentLanguage();
  showContactLeadManager: boolean = false;
  showMenu: boolean = false;
  showSubMenu: boolean = false;
  selectedMenu: number = 0;

  $refs!: {
    dropdownMenu: HTMLFormElement;
  };

  /* **************************************************************************
   *  ********************* First level menus *********************************
   *  ************************************************************************** */
  get firstLevelMenus(): navItem[] {
    return [
      {
        id: 1,
        name: `${this.$t('components.navigation.currentInvestments')}`,
        path: this.ioiPathPerProfile,
        show: this.showIOIOverview,
        submenus: [],
        class: '',
        image: '',
        event: '',
      },
      {
        id: 2,
        name: `${this.$t('components.navigation.myAccount')}`,
        path: this.myAccountPathPerProfile,
        show: this.showMyAccountPage,
        submenus: [],
        class: '',
        image: '',
        event: '',
      },
      {
        id: 4,
        name: ` ${this.$t('components.navigation.rates')}`,
        path: '/construction',
        show: this.showRates,
        submenus: [],
        class: '',
        image: '',
        event: '',
      },
      {
        id: 5,
        name: this.$t('components.navigation.login'),
        path: '/',
        show: this.showLogin,
        submenus: [],
        class: '',
        image: '',
        event: '',
      },
      {
        id: 6,
        name: this.$t('components.navigation.contactLink'),
        path: '',
        show: true,
        submenus: [],
        class: '',
        image: '',
        event: 'showSupport',
      },
      {
        id: 8,
        name: `${this.userName}`,
        path: '',
        show: this.showUserInformation,
        submenus: this.userSubMenus,
        class: 'user-thumb',
        image: '',
        event: '',
      },
      {
        id: 9,
        name: this.$t('components.navigation.changeLanguage'),
        path: '',
        show: true,
        submenus: NavigationMobile.languageSubMenus,
      },
      {
        id: 10,
        name: '',
        path: '/logout',
        show: this.showLogout,
        submenus: [],
        class: 'logout',
        image: '/images/lock.png',
        event: 'close',
      },
    ];
  }

  get showLogin(): boolean {
    return (
      !this.userLoggedIn &&
      this.$route.path !== '/' &&
      this.$route.path !== '/home'
    );
  }

  get showRates(): boolean {
    return (
      this.userLoggedIn &&
      this.$route.path !== '/' &&
      (this.isInvestor || this.isIssuer) &&
      this.$route.path !== '/home'
    );
  }

  get userLoggedIn(): boolean {
    return this.checkUserLoggedIn;
  }

  get showUserInformation(): boolean {
    return this.userLoggedIn && this.$route.path !== '/';
  }

  get showIOIOverview(): boolean {
    return (
      this.userLoggedIn &&
      this.$route.path !== '/' &&
      this.isInvestor &&
      this.$route.path !== '/home'
    );
  }

  get showLogout(): boolean {
    return this.userLoggedIn && this.$route.path !== '/';
  }

  get logOutLabel(): string {
    return `${this.$t('components.navigation.logout')}`;
  }

  get userName(): string {
    return this.userLoggedName;
  }

  get ioiPathPerProfile(): string {
    return this.isMarketViewer ? '/ioi-list-viewer' : '/ioi-list-investor';
  }

  // my account
  get showMyAccountPage(): boolean {
    return (
      this.userLoggedIn &&
      this.$route.path !== '/' &&
      !this.isMarketViewer &&
      this.$route.path !== '/home'
    );
  }

  get myAccountPathPerProfile(): string {
    let response: string = ' ';

    if (this.isIssuer) {
      response = '/myaccount-issuer';
    } else if (this.isInvestor) {
      response = '/myaccount-investor';
    } else if (this.isLeadManager) {
      response = '/myaccount-lm';
    }

    return response;
  }

  get userRole(): string {
    let response: string = ' ';

    if (this.isIssuer) {
      response = 'Issuer';
    } else if (this.isInvestor) {
      response = 'Investor';
    } else if (this.isLeadManager) {
      response = 'Lead Manager';
    }

    return response;
  }

  /* **************************************************************************
   *  ********************* Second level menus *********************************
   *  ************************************************************************** */
  get userSubMenus(): navSubmenu[] {
    const subMenus: navSubmenu[] = [
      {
        submenuName: `${this.$t('components.navigation.changePassword')}`,
        submenuLink: '/change-password',
        submenuAction: '',
      },
    ];
    return subMenus;
  }

  static get languageSubMenus(): navSubmenu[] {
    return [
      {
        submenuName: 'EN',
        submenuLink: '',
        submenuAction: { actionName: 'changeLanguage', actionParams: ['EN'] },
      },
      {
        submenuName: 'NL',
        submenuLink: '',
        submenuAction: { actionName: 'changeLanguage', actionParams: ['NL'] },
      },
    ];
  }

  // Menu handling
  toggleMenu(): void {
    this.showMenu = !this.showMenu;
    if (!this.showMenu) {
      this.showSubMenu = false;
    }
  }

  setShowSubMenu(): void {
    if (!this.showSubMenu) {
      this.showSubMenu = true;
    }
  }

  toggleSubMenuShow(): void {
    this.showSubMenu = !this.showSubMenu;
    if (!this.showSubMenu) {
      this.showMenu = false;
    }
  }

  menuEvent(page: navItem): void {
    if (page.event === 'showSupport') {
      this.showSupport();
    } else if (page.event !== undefined) {
      this.toggleMenu();
      this.$router.push({ path: page.path });
    }
  }

  menuClicked(page: navItem) {
    if (page.submenus.length > 0) {
      this.setShowSubMenu();
      if (this.showSubMenu) {
        this.selectedMenu = page.id;
      } else {
        this.selectedMenu = 0;
      }
    } else {
      this.toggleMenu();
    }
  }

  showSelectedSubMenu(pageId: number): boolean {
    return this.showSubMenu && pageId === this.selectedMenu;
  }

  redirectUserPage(): string {
    let response: string = 'ioi-list-investor';

    if (this.isIssuer) {
      response = 'myaccount-issuer';
    } else if (this.isMarketViewer) {
      response = 'ioi-list-viewer';
    } else if (this.isLeadManager) {
      response = 'myaccount-lm';
    }

    return response;
  }

  showSupport(): void {
    this.toggleModal('supportMobile');
    this.showContactLeadManager = true;
  }

  hideSupport(): void {
    this.toggleModal('supportMobile');
    this.showContactLeadManager = false;
  }

  submenuClick(item: any): void {
    const path: string = item.submenuLink;
    const actions: any = item.submenuAction;
    if (path === '') {
      // take action
      const { actionName } = actions;
      const param: string = actions.actionParams[0];
      // @ts-ignore
      this[actionName](param);
    } else {
      this.$router.push({ path });
    }
    this.toggleSubMenuShow();
  }

  documentClick(event: any) {
    if (!this.$el.contains(event.target)) {
      this.showSubMenu = false;
    }
  }

  changeLanguage(newLanguage: string): void {
    this.$i18n.locale = newLanguage.toLowerCase();
    this.language = newLanguage;
    setLanguage(this.language);
  }

  created() {
    this.showMenu = false;
    this.showSubMenu = false;
  }
}
