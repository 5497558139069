



















import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import VueTimePicker from 'vue2-timepicker/src/vue-timepicker.vue';
import { isEmpty } from 'lodash';
import { Validator } from 'vee-validate';
import { InputObject, ReturnInput } from './inputTypes';
import { EventBus } from '@/main';

@Component({
  name: 'InputTimePickerComponent',
  components: { VueTimePicker },
})
export default class InputDateComponent extends Vue {
  @Prop({}) inputParam!: InputObject;
  @Inject('$validator') $validator: Validator;

  timeValue: any = '';
  inputField: InputObject = {
    id: '',
    name: '',
    ref: '',
    type: 'text',
    placeHolder: '',
    class: '',
    vRule: '',
    vLabel: '',
    disabled: false,
    optionList: [],
    containerClass: '',
    value: '',
    hideVLabel: false,
    isValid: null,
  };

  get inputName() {
    return isEmpty(this.inputField.name) ? ' ' : this.inputField.name;
  }

  get inputVRule() {
    if (this.inputField.vRule === null) {
      return ' ';
    }
    return this.inputField.vRule;
  }

  get errorLabel() {
    if (
      isEmpty(this.inputField.vLabel) &&
      isEmpty(this.inputField.placeHolder)
    ) {
      return '';
    }
    if (isEmpty(this.inputField.vLabel) || this.inputField.vLabel === null) {
      return this.inputPlaceHolder;
    }
    return `${this.$t(this.inputField.vLabel)}`;
  }

  get inputPlaceHolder(): string {
    return isEmpty(this.inputField.placeHolder)
      ? ''
      : `${this.$t(this.inputField.placeHolder)}`;
  }

  get inputHasError(): boolean {
    return (
      this.$validator.errors.has(this.inputField.name) ||
      this.inputParam.isValid === false
    );
  }

  get isInputCorrect(): boolean {
    return (
      this.timeValue !== null &&
      this.timeValue !== undefined &&
      this.timeValue !== '' &&
      !this.$validator.errors.has(this.inputField.name) &&
      this.inputParam.isValid !== false
    );
  }

  get wrapperClass(): string[] {
    const classes: string[] = [];
    if (this.inputHasError) {
      classes.push('select-has-error-border');
    }
    if (this.isInputCorrect) {
      classes.push('select-correct-border');
    }
    if (
      this.inputField.containerClass !== undefined &&
      this.inputField.containerClass !== null
    ) {
      classes.push(this.inputField.containerClass);
    }
    return classes;
  }

  get showVLabel(): boolean {
    return (
      !this.inputField.hideVLabel &&
      this.$validator.errors.has(this.inputField.name)
    );
  }

  get inputTooltip(): any {
    if (this.inputField.defaultTooltip) {
      return {
        placement: 'left-center',
        content: this.inputPlaceHolder,
      };
    }
    if (
      this.inputField.tooltip === undefined ||
      this.inputField.tooltip === null
    ) {
      return null;
    }

    const tooltipContent: string | null = `${this.$t(this.inputField.tooltip)}`;
    return tooltipContent === null
      ? null
      : {
          placement: 'left-center',
          content: tooltipContent,
        };
  }

  inputChange() {
    if (this.timeValue === null || this.timeValue === undefined) {
      const returnInput: ReturnInput = {
        name: this.inputField.name,
        value: '',
      };
      this.$emit('sendInput', returnInput);
    } else if (!isEmpty(this.timeValue)) {
      const returnInput: ReturnInput = {
        name: this.inputField.name,
        value: this.timeValue.value === null ? '' : this.timeValue,
      };
      this.$emit('sendInput', returnInput);
    } else {
      const returnInput: ReturnInput = {
        name: this.inputField.name,
        value: this.timeValue,
      };
      this.$emit('sendInput', returnInput);
    }
  }

  created() {
    this.inputField = Object.assign({}, this.inputParam);
    EventBus.$on('reloadDateInput', (inputInformation: any) => {
      if (inputInformation.name === this.inputParam.name) {
        this.timeValue = inputInformation.value;
        this.inputField = {
          id: inputInformation.id,
          name: inputInformation.name,
          ref: inputInformation.ref,
          value: this.timeValue,
          type: inputInformation.type,
          placeHolder: inputInformation.placeHolder,
          class: inputInformation.class,
          vRule: inputInformation.vRule,
          vLabel: inputInformation.vLabel,
          disabled: inputInformation.disabled,
          optionList: inputInformation.optionList,
          containerClass: inputInformation.containerClass,
          hideVLabel: inputInformation.hideVLabel,
          isValid: inputInformation.isValid,
          defaultTooltip: inputInformation.defaultTooltip,
          tooltip: inputInformation.tooltip,
        };
      }
    });
  }
}
