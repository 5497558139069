












































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { namespace } from 'vuex-class';
import { FilterStateIOI, IOI } from '@/types/instrumentsTypes';
import { interestedColumnClass } from '@/views/myAccounts/myAccounts';
import IoiRow from '@/components/ioi/IOIRow.vue';
import IoiRowDayColumn from '@/components/ioi/IOIRowDayColumn.vue';
import { EventBus } from '@/main';
import MakeOffers from '@/components/ioi/MakeOffers.vue';
import { getApiError } from '@/api/util';
import desktopSizes from '@/assets/screen-sizes';
import ContactLeadManager from '../../components/utils/ContactLeadManager.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import IoiConfirm from '@/components/ioi/IOIConfirm.vue';
import IoiSort from '../../components/ioi/IOISort.vue';
import ModalWindow from '@/components/utils/Modal.vue';
import NoDataAvailable from '@/components/utils/NoDataAvailable.vue';
import sentry from '@/util/sentry';
import IoiList from '@/components/ioi/IoiList.vue';

const IOINameSpace = namespace('iois/');
const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');
const ModalNameSpace = namespace('modals/');

@Component({
  name: 'IOIListInvestor',
  components: {
    IoiList,
    ContactLeadManager,
    MakeOffers,
    IoiRow,
    IoiRowDayColumn,
    ButtonComponent,
    IoiConfirm,
    IoiSort,
    ModalWindow,
    NoDataAvailable,
  },
})
export default class IOIListInvestor extends Vue {
  // Indication of Interest store
  @IOINameSpace.Getter('allIOIs') iois: IOI[];
  @IOINameSpace.Getter('ioisLength') ioisLength: number;
  @IOINameSpace.Action('getIOIs') getIOIs: any;
  @IOINameSpace.Action('toggleHighlightIOI') toggleHighlightIOI: any;

  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @AuthenticationNameSpace.Action('signUpFullUser') signUpFullUser: any;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  eventBus = EventBus;
  myAccount = '';
  windowSize: number = 0;
  showExtended: boolean = true;
  screenMediumBig: number = desktopSizes.mediumBig;
  showDaysColumn: boolean = true;
  showPreferred: boolean = false;
  displayModal: any = { showModal: false, ioi: {} };
  highlight: any = '';
  directLinkWatcher: any;
  offerInputInformation: any = '';
  makeAnOffer: any = {
    showModal: false,
    ioi: {},
    myAccount: '',
    showKeepMeInformed: true,
  };

  displayIOI(ioi: any): void {
    this.displayModal.showModal = true;
    this.displayModal.ioi = ioi;
    this.eventBus.$emit('reloadConfirmIOI', this.displayModal);
  }

  onCloseIOI(): void {
    this.displayModal.showModal = false;
    this.displayModal.ioi = {};
    this.eventBus.$emit('reloadConfirmIOI', this.displayModal);
  }

  onSubmitOffer(action: any): void {
    this.makeAnOffer.showModal = false;
    this.makeAnOffer.ioi = {};
    this.eventBus.$emit('reloadMakeAnOffer', this.makeAnOffer);
    this.$router.push({
      name: 'myaccount-investor',
      params: { tabId: action.tabId },
    });
  }

  closeMakeOffers(): void {
    this.makeAnOffer.showModal = false;
    this.makeAnOffer.ioi = {};
    this.eventBus.$emit('reloadMakeAnOffer', this.makeAnOffer);
  }

  populateIOIList(): void {
    const ioiFilters: FilterStateIOI = {};
    ioiFilters.state = ['open', 'closing'];
    this.getIOIs({
      userAccountId: this.myAccount,
      state: 'outstanding',
      withOffers: '1',
      filters: ioiFilters,
      preferred: this.showPreferred,
    });
  }

  created() {
    // control if the user is logged in; if not, then the user will be sent to login page
    const accountErrorMessage: TranslateResult = this.$t(
      'views.ioiList.errorMessageMP',
    );
    const ioiErrorMessage: TranslateResult = this.$t(
      'views.ioiList.ioiErrorMessage',
    );
    this.authenticateLogin(accountErrorMessage)
      .then(() => {
        this.myAccount = this.getUserAccountId;
        this.windowSize = window.innerWidth;
        this.showExtended = this.windowSize >= desktopSizes.large;
        this.showDaysColumn = this.windowSize >= this.screenMediumBig;
        if (this.isUserLoggedIn && this.isInvestor) {
          // get open IOIs
          this.populateIOIList();
        } else {
          this.addNotification({
            id: 0,
            message: `${accountErrorMessage}`,
            type: 'error',
          });
          this.$router.push({ name: 'login' });
        }
      })
      .catch((error: any) => {
        this.addNotification({
          message: `${ioiErrorMessage} ${getApiError(error)}`,
          type: 'error',
        });
        sentry(error);
      });
    // wait for all data to load
    Vue.nextTick(() => {
      // start a watcher on the iois to see if they have loaded correctly
      this.directLinkWatcher = this.$watch('iois', () => {
        if (this.$route.query.mode === 'view' && this.iois.length > 0) {
          // filter iois for the provided #
          const chosenIoI = this.iois.filter(
            ioiItem => ioiItem.referenceNumber === this.$route.query.ref,
          );
          // open the selected ioi
          if (chosenIoI.length > 0) {
            this.displayIOI(chosenIoI[0]);
          } else {
            this.addNotification({
              message: this.$t('views.myInvestorAccount.ioiRefViewError', {
                ref: this.$route.query.ref,
              }),
              type: 'error',
            });
          }
          this.endReferIoI();
        }
        // check if query.mode = highlight and if all ioi's are loaded
        if (
          this.$route.query.mode === 'highlight' &&
          Object.keys(this.$refs).length === this.iois.length
        ) {
          // find the ioi
          const highlightedIOI = this.iois.filter(
            ioiItem => ioiItem.referenceNumber === this.$route.query.ref,
          );
          if (highlightedIOI.length > 0) {
            this.toggleHighlightIOI(highlightedIOI[0].indicationOfInterestId);
            // select ref and scroll highlight into view
            const ref: any = this.$refs[`${this.$route.query.ref}`];
            ref[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
            this.endReferIoI();
          } else {
            this.addNotification({
              message: this.$t('views.myInvestorAccount.ioiRefHighlightError', {
                ref: this.$route.query.ref,
              }),
              type: 'error',
            });
            this.endReferIoI();
          }
        }
      });
    });
  }

  endReferIoI() {
    // remove watcher
    this.directLinkWatcher();
    // clean url
    this.$router.replace({ path: this.$route.path });
  }

  mounted() {
    // Window size
    window.addEventListener('resize', () => {
      this.windowSize = window.innerWidth;
      this.showExtended = this.windowSize >= desktopSizes.large;
      this.showDaysColumn = this.windowSize >= this.screenMediumBig;
    });

    this.eventBus.$on('reloadMakeAnOffer', (inputInformation: any) => {
      this.offerInputInformation = inputInformation;
    });
  }

  @Watch('windowSize')
  onWindowSizeChange(newSize: number) {
    this.showExtended = newSize >= desktopSizes.large;
    this.showDaysColumn = this.windowSize >= this.screenMediumBig;
  }

  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.windowSize = window.innerWidth;
    });
  }
}
