












import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NotificationState } from '@/types/types';
import NotificationMessage from '@/components/notifications/NotificationMessage.vue';

const NotificationsNameSpace = namespace('notifications/');

@Component({
  components: { NotificationMessage },
})
export default class NotificationContainer extends Vue {
  @NotificationsNameSpace.Getter('allNotifications')
  notifications: NotificationState;
}
