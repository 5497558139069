








































































import Vue from 'vue';
import { Component, Provide, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import router from '@/router';
import InputPhoneNumberComponent from '@/components/inputs/InputPhoneNumberComponent.vue';
import InputComponent from '@/components/inputs/InputComponent.vue';
import InputFileComponent from '@/components/inputs/InputFileComponent.vue';
import InputSelectComponent from '@/components/inputs/InputSelectComponent.vue';
import InputRadioComponent from '@/components/inputs/InputRadioComponent.vue';
import {
  CustomInput,
  CustomFileInput,
  RadioButtonOption,
} from '@/components/inputs/inputTypes';
import { EventBus } from '@/main';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import countriesJson from '@/assets/data/countries';
import { industrySectorList } from '@/api/authenticationApi';
import { neatifyString } from '@/util/generalUtils';
import InputSelect from '@/components/inputs/InputSelect.vue';
import InputPasswordComponent from '@/components/inputs/InputPasswordComponent.vue';

const SignupNameSpace = namespace('signup/');
const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'SignUpForm',
  components: {
    InputPhoneNumberComponent,
    InputComponent,
    InputSelectComponent,
    InputRadioComponent,
    ButtonComponent,
    InputFileComponent,
    InputSelect,
    InputPasswordComponent,
  },
})
export default class SignUpForm extends Vue {
  @SignupNameSpace.Action('setFormOption') setFormOption: any;
  @SignupNameSpace.Getter('step') currentStep: number;
  @SignupNameSpace.Getter('accountType') accountType: any;
  @SignupNameSpace.Action('setStep') setStep: any;
  @SignupNameSpace.Action('setAccountType') setAccountType: any;

  @Provide() $validator: any;

  eventBus = EventBus;
  companyEmail: string | null | undefined = undefined;
  companyEmailRepeatValue: string | null = '';
  password: string | null = '';
  passwordRepeatValue: string | null = '';
  accountTypeOptions: object[] = [
    {
      id: 'issuer',
      value: 1,
      labelCode: 'components.signUpForm.issuer',
      static: false,
    },
    {
      id: 'investor',
      value: 2,
      labelCode: 'components.signUpForm.investor',
      static: false,
    },
  ];

  formOne: any = {
    firstName: {
      compType: 'InputComponent',
      name: 'firstName',
      vLabel: 'components.signUpForm.firstName',
      placeHolder: 'components.signUpForm.firstName',
      vRule: 'required|alpha',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      value: undefined,
      formClass: 'three-fifth',
    },
    middleName: {
      compType: 'InputComponent',
      name: 'middleName',
      placeHolder: 'components.signUpForm.middleName',
      vRule: 'alpha',
      containerClass: 'input-inner',
      class: 'form-input',
      hideVLabel: true,
      showLabel: true,
      value: undefined,
      formClass: 'two-fifth',
    },
    lastName: {
      compType: 'InputComponent',
      name: 'lastName',
      placeHolder: 'components.signUpForm.lastName',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      vRule: 'required|alpha_spaces',
    },
    phoneNumber: {
      compType: 'InputPhoneNumberComponent',
      name: 'phoneNumber',
      placeHolder: 'components.signUpForm.telephone',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      vRule: 'phone_number|phone_number_required',
      value: undefined,
    },
    email: {
      compType: 'InputComponent',
      name: 'email',
      ref: 'email',
      placeHolder: 'components.signUpForm.email',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      vRule: 'required|email',
      value: this.companyEmail,
    },
    companyEmailRepeat: {
      compType: 'InputComponent',
      name: 'companyEmailRepeat',
      placeHolder: 'components.signUpForm.emailRepeat',
      vRule: 'required|confirmed:',
      vLabel: 'components.signUpForm.emailRepeatVLabel',
      containerClass: 'input-inner',
      class: 'form-input',
      value: undefined,
      showLabel: true,
    },
    password: {
      compType: 'InputPasswordComponent',
      name: 'password',
      ref: 'password',
      type: 'password',
      placeHolder: 'components.signUpForm.password',
      vRule: 'required|min:8|max:35',
      containerClass: 'input-inner',
      showLabel: true,
      class: 'form-input',
      value: undefined,
      marketviewerOnly: false,
    },
    passwordRepeat: {
      name: 'passwordRepeat',
      compType: 'InputComponent',
      placeHolder: 'components.signUpForm.passwordRepeat',
      vRule: 'required|confirmed:',
      vLabel: 'components.signUpForm.passwordRepeatVLabel',
      type: 'password',
      showLabel: true,
      containerClass: 'input-inner',
      class: 'form-input',
      value: undefined,
      marketviewerOnly: false,
    },
  };

  formAnswersOneMV: any = {
    companyName: {
      name: 'companyName',
      compType: 'InputComponent',
      placeHolder: 'components.signUpFullForm.companyName',
      vRule: 'required',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      marketviewerOnly: true,
    },
    industryOrSector: {
      compType: 'InputSelect',
      name: 'industryOrSector',
      placeHolder: 'components.signUpFullForm.industry',
      vRule: 'required',
      optionList: industrySectorList(),
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      value: undefined,
      formClass: 'input-container',
      marketviewerOnly: true,
    },
    companyType: {
      name: 'companyType',
      alwaysOneOptionActive: true,
      containerTitle: 'components.signUpFullForm.role',
      titleClass: 'label-no-margin',
      compType: 'InputRadioComponent',
      value: 1,
      optionList: [
        {
          id: 'issuer',
          value: 1,
          labelCode: 'components.signUpForm.issuer',
          static: false,
        },
        {
          id: 'investor',
          value: 2,
          labelCode: 'components.signUpForm.investor',
          static: false,
        },
      ],
      vRule: 'required',
      containerClass: 'radio-container',
      wrapperClass: 'input-container radio-no-stuff',
      verticalRadioButton: false,
      showImage: true,
      marketviewerOnly: true,
    },
    terms: {
      name: 'terms',
      compType: 'InputRadioComponent',
      optionList: [
        {
          id: 'terms',
          value: '1',
          labelCode: 'components.signUpFullForm.termsOfUse',
          static: false,
        },
      ],
      vRule: 'required',
      containerClass: 'radio-container',
      wrapperClass: 'input-container radio-no-stuff',
      verticalRadioButton: false,
      showImage: true,
      marketviewerOnly: true,
      link: '/terms-of-use',
    },
  };

  formTwo: any = {
    companyName: {
      name: 'companyName',
      compType: 'InputComponent',
      placeHolder: 'components.signUpFullForm.companyName',
      vRule: 'required',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
    },
    industryOrSector: {
      compType: 'InputSelect',
      name: 'industryOrSector',
      placeHolder: 'components.signUpFullForm.industry',
      vRule: 'required',
      optionList: industrySectorList(),
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      value: undefined,
      formClass: 'input-container',
    },
    countryCode: {
      compType: 'InputSelect',
      name: 'countryCode',
      placeHolder: 'components.signUpFullForm.country',
      vRule: 'required',
      containerClass: 'input-inner',
      class: 'form-input',
      optionList: countriesJson.countries,
      value: undefined,
      showLabel: true,
      formClass: 'input-container',
    },
    street: {
      compType: 'InputComponent',
      name: 'street',
      placeHolder: 'components.signUpFullForm.street',
      vRule: 'required|alpha_spaces',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'three-fifth',
    },
    houseNumber: {
      compType: 'InputComponent',
      name: 'houseNumber',
      placeHolder: 'components.signUpFullForm.houseNumber',
      vRule: 'required|numeric',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'one-fifth',
    },
    houseNumberSuffix: {
      compType: 'InputComponent',
      name: 'houseNumberSuffix',
      placeHolder: 'components.signUpFullForm.numberSuffix',
      vRule: 'alpha_num',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'one-fifth',
    },
    postalCode: {
      compType: 'InputComponent',
      name: 'postalCode',
      placeHolder: 'components.signUpFullForm.postalCode',
      vRule: { required: true, regex: /^[\w\-\s]+$/ }, // regex => alpha_num + spaces
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'two-fifth',
    },
    city: {
      compType: 'InputComponent',
      name: 'city',
      placeHolder: 'components.signUpFullForm.city',
      vRule: 'required|alpha_spaces',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: null,
      formClass: 'three-fifth',
    },
  };

  formExtracts: any = {
    kvkFile: {
      compType: 'InputFileComponent',
      name: 'kvkFile',
      label: 'components.signUpFullForm.kvkExtract',
      placeHolder: 'components.signUpFullForm.kvkPDF',
      vRule: { ext: 'pdf', required: true, size: 2000 },
      showLabel: true,
      hideVLabel: false,
      containerClass: 'input-inner',
      class: 'form-input',
      value: null,
    },
    leiNumber: {
      compType: 'InputComponent',
      name: 'leiNumber',
      placeHolder: 'components.signUpFullForm.leiNumber',
      vRule: 'alpha_num|length:20',
      showLabel: true,
      containerClass: 'input-inner',
      class: 'form-input',
      value: null,
    },
    identificationFile: {
      compType: 'InputFileComponent',
      name: 'identificationFile',
      label: 'components.signUpFullForm.proofOfIdentity',
      placeHolder: 'components.signUpFullForm.identification',
      vRule: { ext: 'pdf', required: true, size: 5000 },
      showLabel: true,
      hideVLabel: false,
      value: null,
      containerClass: 'input-inner',
      class: 'form-input',
    },
    terms: {
      name: 'terms',
      compType: 'InputRadioComponent',
      optionList: [
        {
          id: 'terms',
          value: '1',
          labelCode: 'components.signUpFullForm.termsOfUse',
          static: false,
        },
      ],
      vRule: 'required',
      wrapperClass: 'input-container radio-no-stuff',
      verticalRadioButton: false,
      showImage: true,
    },
  };

  get firstForm(): any {
    let form = Object.values(this.formOne);
    if (this.accountType !== 'marketviewer') {
      form = form.filter((input: any) => !input.marketviewerOnly);
    } else {
      form = this.formOne;
    }

    return form;
  }

  get businessMvForm(): any {
    let form = {};
    if (this.accountType === 'marketviewer') {
      form = this.formAnswersOneMV;
    }

    return form;
  }

  get repeatPassword(): any {
    const repeatP = this.formOne.passwordRepeat;
    repeatP.vRule = `required|confirmed:${this.password}`;
    repeatP.value =
      this.passwordRepeatValue !== '' ? this.passwordRepeatValue : undefined;
    return repeatP;
  }

  setInputValue(inputValue: CustomInput) {
    // check if validation available
    if (this.$validator.errors) {
      // check if field has been validated else set empty
      if (!this.$validator.errors.has(inputValue.name)) {
        this.setFormOption({
          name: inputValue.name,
          value: neatifyString(inputValue.value),
        });
      } else {
        this.setFormOption({
          name: inputValue.name,
          value: '',
        });
      }
    }

    // needed specificly for validation
    switch (inputValue.name) {
      case 'email':
        this.companyEmail = inputValue.value;
        this.formOne.companyEmailRepeat.vRule = `required|confirmed:${this.companyEmail}`;
        this.formOne.companyEmailRepeat.value =
          this.companyEmailRepeatValue !== ''
            ? this.companyEmailRepeatValue
            : undefined;
        this.eventBus.$emit('reloadInput', this.formOne.companyEmailRepeat);
        break;
      case 'companyEmailRepeat':
        this.companyEmailRepeatValue = inputValue.value;
        break;
      case 'password':
        this.password = inputValue.value;
        this.eventBus.$emit('reloadInput', this.repeatPassword);
        break;
      case 'repeatPassword':
        this.passwordRepeatValue = inputValue.value;
        break;
      default:
        break;
    }
  }

  // handle file input components
  setInputFileValue(inputValue: CustomFileInput) {
    // check if validation available
    if (this.$validator.errors) {
      // check if field has been validated else set empty
      if (!this.$validator.errors.has(inputValue.inputName)) {
        this.setFormOption({
          name: inputValue.inputName,
          value: inputValue.fileValue,
        });
      } else {
        this.setFormOption({
          name: inputValue.inputName,
          value: '',
        });
      }
    }
  }

  // cant call static functions or router push from the template
  // eslint-disable-next-line class-methods-use-this
  asMarketviewer() {
    router.push({
      name: 'signupnew',
      params: { accountType: 'marketviewer', step: '1' },
    });
  }
}
