









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'LoadingIndicator',
})
export default class LoadingIndicator extends Vue {
  // options: tiny, small, medium, large
  @Prop({}) size!: string;
  // Element will have no height
  @Prop({ default: false }) noSpace!: boolean;

  displayLoader: boolean = true;

  mounted() {
    setTimeout(() => (this.displayLoader = false), 40000);
  }
}
