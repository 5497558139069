






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import PieChart from '@/components/charts/PieChart.vue';
import { namespace } from 'vuex-class';
const AuthenticationNameSpace = namespace('authentication/');
@Component({
  components: { PieChart },
  name: 'MunicipalityCircleChart',
})
export default class MunicipalityCircleChart extends Vue {
  @AuthenticationNameSpace.Getter('windowWidth') windowWidth: any;

  get datasets() {
    return {
      labels: [
        this.$t('components.muniPieChart.afs'),
        this.$t('components.muniPieChart.banks'),
        this.$t('components.muniPieChart.nonDisclosed'),
        this.$t('components.muniPieChart.otherBrokers'),
      ],
      datasets: [
        {
          label: 'GitHub Commits',
          backgroundColor: ['#1a7ed6', '#e17055', '#00b894', '#b2bec3'],
          data: [23, 28, 46, 3],
        },
      ],
    };
  }

  pieOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    lineTension: 1,
    legend: {
      display: true,
      position: 'right',
      align: 'left',
      fullWidth: false,

      labels: {
        fontSize: 16,
        fontFamily: "'Montserrat'",
        fontColor: 'rgb(0, 0, 0)',
        boxWidth: 25,
        padding: 15,
        marginBlockEnd: 100,
      },
    },
    plugins: {
      labels: {
        render: 'percentage',
        showZero: true,
        fontSize: 15,
        fontColor: '#fff',
        fontStyle: 'normal',
        fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        textShadow: true,
        shadowBlur: 10,
        shadowOffsetX: -3,
        shadowOffsetY: 2,
        shadowColor: 'rgba(0,0,0,0.75)',
        arc: false,
        position: 'border',
        showActualPercentages: true,
        outsidePadding: 1,
        textMargin: 4,
      },
    },
  };

  get options() {
    if (this.windowWidth > 800) {
      return this.pieOptions;
    } else {
      return {
        ...this.pieOptions,
        legend: { ...this.pieOptions.legend, position: 'top' },
      };
    }
  }
}
