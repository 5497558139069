import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { orderBy } from 'lodash';
import { Deal, DealState, DealAdditional } from '@/types/dealTypes';
import { RootState } from '@/types/rootState';
import backOfficeApi from '@/api/backOfficeApi';
import { getApiError } from '@/api/util';
import sentry from '@/util/sentry';

const ADD_NOTIFICATION = 'notifications/add';

export const state: DealState = {
  pendingDeals: [],
  closedDeals: [],
  accountDeals: [],
  requestedDealNumber: '',
  additionalDetails: {
    effectiveAllInYield: null,
    brokerageAFS: null,
    documentation: null,
    maturityDate: '',
    interestPaymentDates: '',
  },
  detailDeal: {
    dealId: '',
    dealNumber: '',
    indicativeType: '',
    instrumentType: '',
    issuerCompanyId: '',
    issuerCompanyName: '',
    issuerIndustry: '',
    investorCompanyName: '',
    investorId: '',
    dealDate: '',
    settlementDate: '',
    brokenCouponDate: 0,
    brokerageAFS: '',
    coupon: '',
    couponFrequency: '',
    effectiveAllInYield: 0,
    acceptedIssueSize: '',
    dayCount: '',
    documentationForm: 0,
    ecaInsuredPercentage: 0,
    issueMaturity: 0,
    maturityDate: 0,
    recipient: 0,
    redemptionForm: '',
    repaymentFrequency: '',
    tradeConfirmationInvestor: '',
    tradeConfirmationIssuer: '',
  },
};

// getters
export const getters: GetterTree<DealState, RootState> = {
  pendingDeals: state => state.pendingDeals,
  accountDeals: state => state.accountDeals,
  closedDeals: state => state.closedDeals,
  detailDeal: state => state.detailDeal,
  detailDealNumber: state => state.requestedDealNumber,
};

// mutations
export const mutations: MutationTree<DealState> = {
  SET_PENDING_DEALS(state, deals): void {
    state.pendingDeals = deals;
  },

  SET_CLOSED_DEALS(state, deals): void {
    state.closedDeals = deals;
  },

  SET_REQUESTED_DEAL_NUMBER(state, requestedDealNumber: string): void {
    state.requestedDealNumber = requestedDealNumber;
  },

  SET_DEAL_DETAIL_VIEW(state, detailDeal: Deal): void {
    state.detailDeal = detailDeal;
  },

  SET_ADDITIONAL_DEAL_DETAIL_VIEW(state, detailDeal: DealAdditional): void {
    state.additionalDetails = detailDeal;
  },
};

// actions
export const actions: ActionTree<DealState, RootState> = {
  getPendingDeals({ commit, dispatch }) {
    return backOfficeApi
      .getPendingDeals()
      .then((response: any) => {
        let pendingDeals = response.data.map((deal: any) => ({
          ...deal,
          issuer: deal.issuerName,
          investor: deal.investorName,
        }));
        pendingDeals = orderBy(pendingDeals, 'date', 'desc');
        commit('SET_PENDING_DEALS', pendingDeals);
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            label: 'Error fetching the Pending Deals: ',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  getClosedDeals({ commit, dispatch }) {
    return backOfficeApi
      .getClosedDeals()
      .then((response: any) => {
        let closedDeals = response.data.map((deal: any) => ({
          ...deal,
          issuer: deal.issuerName,
          investor: deal.investorName,
        }));
        closedDeals = orderBy(closedDeals, 'date', 'desc');
        commit('SET_CLOSED_DEALS', closedDeals);
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            label: 'Error fetching the Closed Deals: ',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  getDeal({ commit, dispatch, rootState }, dealId: string) {
    // set deal number to compare success later
    commit('SET_REQUESTED_DEAL_NUMBER', dealId);
    return backOfficeApi
      .getDeal(dealId)
      .then((response: any) => {
        const dealTyped: Deal = {
          ...response.data,
          brokerageAFS:
            response.data.brokerageAFS === null
              ? ''
              : response.data.brokerageAFS,
        };
        commit('SET_DEAL_DETAIL_VIEW', dealTyped);
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            label: 'Error fetching the deal',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },

  setDealOption({ commit, rootState }, change: any) {
    const additionalDeal: DealAdditional = rootState.deals.additionalDetails;
    const changedProperty: string = change.property;
    additionalDeal[changedProperty.toString()] = change.value;
    commit('SET_ADDITIONAL_DEAL_DETAIL_VIEW', additionalDeal);
  },

  generateTradeConfirmations({ dispatch, rootState }) {
    const {
      documentation,
      effectiveAllInYield,
      brokerageAFS,
      maturityDate,
      interestPaymentDates,
    } = rootState.deals.additionalDetails;

    const payload = {
      dealId: rootState.deals.detailDeal.dealId,
      maturityDate,
      documentationForm: documentation,
      effectiveAllInYield,
      brokerageAFS,
      interestPaymentDates,
    };
    return backOfficeApi
      .generateTradeConfirmations(payload)
      .then((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            message: getApiError(error),
            label: 'Succeeded generating trade confirmations',
            type: 'success',
          },
          { root: true },
        );
        sentry(error);
      })
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            message: getApiError(error),
            label: 'Failed generating trade confirmations',
            type: 'error',
          },
          { root: true },
        );
        // the error needs to be propagated so the form takes different actions
        sentry(error);
        throw new Error(error.message);
      });
  },
};

export const deals = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
