

























































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { namespace } from 'vuex-class';
import NotificationBell from 'vue-notification-bell';
import { checkIfPageResponsive, redirectUserPage } from '@/util/generalUtils';
import ContactLeadManager from '../utils/ContactLeadManager.vue';
import CountryFlag from '../utils/CountryFlag.vue';
import LanguageSelector from '../utils/LanguageSelector.vue';
import { EventBus } from '@/main';
import { setLanguage } from '@/translation/index';
import ModalWindow from '@/components/utils/Modal.vue';
import desktopSizes from '../../assets/screen-sizes';

const AuthenticationNameSpace = namespace('authentication/');
const AcNs = namespace('actionCenter/');
const ModalNameSpace = namespace('modals/');

interface navSubmenu {
  submenuName: string;
  submenuLink: string;
  submenuAction: any;
}

interface navItem {
  id: number;
  name: TranslateResult;
  path: string;
  show: boolean;
  submenus: navSubmenu[];
  class: string;
  image: string;
  event: string;
  tooltip: string;
  component?: boolean;
  dataTestId?: string;
}

@Component({
  name: 'Navigation',
  components: {
    ContactLeadManager,
    CountryFlag,
    NotificationBell,
    ModalWindow,
    LanguageSelector,
  },
})
export default class Navigation extends Vue {
  // Authentication store
  @AuthenticationNameSpace.Getter('checkUserLoggedIn')
  checkUserLoggedIn: boolean;

  @AuthenticationNameSpace.Getter('userLoggedName') userLoggedName: string;
  @AuthenticationNameSpace.Getter('language') language: string;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Getter('isBackOffice') isBackOffice: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @AuthenticationNameSpace.Getter('windowWidth') windowSize: number;
  @AuthenticationNameSpace.Action('setLanguageStore') setLanguageStore: any;

  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  // Events store
  @AcNs.Getter('unreadEvents') unreadEvents: number;

  eventBus = EventBus;
  redirectUserPage = redirectUserPage;
  showSubMenu: boolean = false;
  showNameSubMenu: boolean = false;
  selectedMenu: number = 0;
  hasChanged: boolean = false;
  hideMenu: boolean = true;

  $refs!: {
    dropdownMenu: HTMLFormElement;
  };

  languageSubMenus: any[] = [
    {
      submenuName: 'en',
      submenuAction: {
        actionName: 'changeLanguage',
        actionParams: ['en'],
      },
    },
    {
      submenuName: 'nl',
      submenuAction: {
        actionName: 'changeLanguage',
        actionParams: ['nl'],
      },
    },
  ];

  // ****************************************
  // First level menus
  // ****************************************
  get firstLevelMenus(): navItem[] {
    return [
      {
        id: 1,
        name: `${this.$t('components.navigation.currentInvestments')}`,
        path: this.ioiPathPerProfile,
        show: this.showIOIOverview,
        submenus: [],
        class: 'nav-items__with-seperator',
        image: '',
        event: '',
        tooltip: '',
      },
      {
        id: 2,
        name: `${this.$t('views.dashboard.title')}`,
        path: this.myAccountPathPerProfile,
        show: !this.isMarketViewer && !this.isBackOffice,
        submenus: [],
        class: 'nav-items__with-seperator',
        image: '',
        event: '',
        tooltip: '',
      },
      {
        id: 3,
        name: 'accounts',
        path: '/backoffice',
        show: this.showBackoffice,
        submenus: [],
        class: 'tc-switch nav-items__with-seperator',
        image: '',
        event: '',
        tooltip: 'To backoffice',
        dataTestId: 'dealspage',
      },
      {
        id: 4,
        name: 'deals',
        path: '/tc-dashboard',
        show: this.showBackoffice,
        submenus: [],
        class: 'tc-switch',
        image: '',
        event: '',
        tooltip: 'To deals',
        dataTestId: 'accountspage',
      },
      {
        id: 5,
        name: this.$t('components.navigation.login'),
        path: '/',
        show: this.showLogin,
        submenus: [],
        class: '',
        image: '',
        event: '',
        tooltip: '',
      },
      {
        id: 8,
        name: this.$t('components.navigation.newsAndResearch'),
        path: '/news-and-research',
        show: true,
        submenus: [],
        class: 'nav-items__with-seperator',
        image: '',
        event: '',
        tooltip: '',
      },
      {
        id: 6,
        name: this.$t('components.navigation.irp'),
        path: '/irpchart',
        show: this.showIRP,
        submenus: this.isLeadManager ? this.IRPSubMenus : [],
        class: 'nav-items__with-seperator',
        image: '',
        event: '',
        tooltip: '',
      },
      {
        id: 10,
        name: this.language,
        path: '',
        show: this.showLanguageInformation && !this.userLoggedIn,
        submenus: this.languageSubMenus,
        class: 'menu-languages',
        image: '',
        event: '',
        tooltip: this.changeLanguageTooltip,
      },
    ];
  }

  get sortedFirstLevelMenus() {
    return this.firstLevelMenus.filter(menu => menu.show);
  }

  get showLogin(): boolean {
    return (
      !this.userLoggedIn &&
      this.$route.path !== '/' &&
      this.$route.path !== '/home'
    );
  }

  get showContact(): boolean {
    return (
      this.userLoggedIn &&
      this.$route.path !== '/' &&
      !this.isLeadManager &&
      this.$route.path !== '/home' &&
      !this.isBackOffice
    );
  }

  get userLoggedIn(): boolean {
    return this.checkUserLoggedIn;
  }

  get showIRP(): boolean {
    return this.isLeadManager || this.isInvestor || this.isIssuer;
  }

  get showIOIOverview(): boolean {
    return (
      this.userLoggedIn &&
      this.$route.path !== '/' &&
      (this.isInvestor || this.isMarketViewer) &&
      this.$route.path !== '/home'
    );
  }

  get ioiPathPerProfile(): string {
    return this.isMarketViewer ? '/ioi-list-viewer' : '/ioi-list-investor';
  }

  get showBackoffice(): boolean {
    return this.userLoggedIn && this.isBackOffice;
  }

  get myAccountPathPerProfile(): string {
    if (this.isLeadManager) {
      return '/irpform';
    } else if (this.isIssuer) {
      return '/myaccount-issuer';
    } else if (this.isInvestor) {
      return '/myaccount-investor';
    }
    return '';
  }

  get userRole(): string {
    let response: string = ' ';

    if (this.isIssuer) {
      response = 'Issuer';
    } else if (this.isInvestor) {
      response = 'Investor';
    } else if (this.isLeadManager) {
      response = 'Lead Manager';
    } else if (this.isBackOffice) {
      response = 'Back Office';
    } else if (this.isMarketViewer) {
      response = 'Market Viewer';
    }

    return response;
  }

  get changeLanguageTooltip(): string {
    return `${this.$t('components.navigation.languageTooltip')}`;
  }

  get showLanguageInformation(): boolean {
    return !this.isBackOffice;
  }

  get myUnreadEvents(): number {
    return this.unreadEvents;
  }

  get showNotificationsInformation(): boolean {
    return this.userLoggedIn;
  }

  // ****************************************
  // Second level menus
  // ****************************************
  get IRPSubMenus(): navSubmenu[] {
    const subMenus: navSubmenu[] = [
      {
        submenuName: `${this.$t('components.navigation.irpChart')}`,
        submenuLink: '/irpchart',
        submenuAction: '',
      },
    ];
    if (this.isLeadManager) {
      subMenus.push({
        submenuName: `${this.$t('components.navigation.irpForm')}`,
        submenuLink: '/irpform',
        submenuAction: '',
      });
    }
    return subMenus;
  }

  /* **************************************************************************
   *  ********************* Title creation *************************************
   *  ************************************************************************** */
  get logoTitle(): string {
    let logoTitle = 'AFS Blue ';

    if (process.env.VUE_APP_ENVIRONMENT === 'alpha') {
      logoTitle += '(ALPHA) ';
    } else if (process.env.VUE_APP_ENVIRONMENT === 'beta') {
      logoTitle += '(BETA)';
    }
    logoTitle = `${logoTitle} - ${this.$t('components.navigation.title')}`;

    return logoTitle;
  }

  menuEvent(event: string): void {
    if (event === 'showSupport') {
      this.showSupport();
    }
  }

  menuClicked(page: navItem) {
    this.hasChanged = true;
    if (page.submenus.length > 0) {
      this.selectedMenu = 0;
      this.selectedMenu = page.id;
    }
  }

  onClickOutside(event: any) {
    if (!this.$el.contains(event.target)) {
      this.selectedMenu = 0;
      this.showNameSubMenu = false;
      this.hideMenu = true;
    }
  }

  showSupport(): void {
    this.toggleModal('support');
  }

  closeMenu() {
    this.selectedMenu = 0;
  }

  submenuClick(path: string, actions: any): void {
    this.selectedMenu = 0;
    if (path === '') {
      // take action
      const { actionName } = actions;
      const param: string = actions.actionParams[0];
      // @ts-ignore
      this[actionName](param);
    } else {
      this.$router.push({ path });
    }
  }

  changeLanguage(newLanguage: string): void {
    this.$i18n.locale = newLanguage.toLowerCase();
    this.setLanguageStore(newLanguage);
    setLanguage(this.language);
    this.showNameSubMenu = false;
  }

  @Watch('windowSize')
  onWindowSizeChange(newSize: number) {
    if (
      newSize < desktopSizes.medium &&
      checkIfPageResponsive(this.$route.name)
    ) {
      this.$router.push({ name: 'home' }).catch(err => {});
    } else if (this.$route.path === '/home') {
      const lastPage: string | null = localStorage.getItem('lastPage');
      if (lastPage === '' || lastPage === 'home') {
        this.$router.push({ name: 'login' });
      } else {
        this.$router.go(-1);
      }
    }
  }

  mounted() {
    document.addEventListener('click', this.onClickOutside);
  }

  destroyed() {
    document.removeEventListener('click', this.onClickOutside);
  }
}
