




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import CountryFlag from '@/components/utils/CountryFlag.vue';

const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'LanguageSelector',
  components: {
    CountryFlag,
  },
})
export default class LanguageSelector extends Vue {
  // options: tiny, small, medium, large
  @Prop({}) size!: string;
  @AuthenticationNameSpace.Action('setLanguageStore') setLanguageStore: any;
  @AuthenticationNameSpace.Getter('language') language: string;
  languageSubMenus: any[] = [
    {
      submenuName: 'en',
    },
    {
      submenuName: 'nl',
    },
  ];

  changeLanguage(newLanguage: string): void {
    this.$i18n.locale = newLanguage.toLowerCase();
    this.setLanguageStore(newLanguage);
    this.$emit('menu-selected');
  }
}
