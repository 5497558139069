
























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CountryFlag from '@/components/utils/CountryFlag.vue';
import LineChart from '@/components/charts/LineChart.vue';

const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'DealExampleGraph',
  components: {
    CountryFlag,
    LineChart,
  },
})
export default class DealExampleGraph extends Vue {
  @AuthenticationNameSpace.Getter('windowWidth') windowWidth: number;

  chartData: Object = {
    labels: [
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '12',
      '15',
      '20',
      '25',
      '30',
      '40',
    ],
    datasets: [
      {
        label: 'EUR IRS Curve',
        data: [
          '-0.17',
          '-0.05',
          '0.08',
          '0.22',
          '0.35',
          '0.49',
          '0.62',
          '0.74',
          '0.85',
          '1.04',
          '1.25',
          '1.42',
          '1.48',
          '1.51',
          '1.51',
        ],
        backgroundColor: 'transparent',
        borderColor: 'rgb(26, 126, 214)',
        pointBackgroundColor: 'rgb(24, 126, 214)',
        pointStyle: 'rect',
        pointRadius: 4,
        fontColor: 'rgb(26, 126, 214)',
      },
      {
        label: 'AFS Dutch Public Sector Curve',
        data: [
          '-0.16',
          '-0.03',
          '0.12',
          '0.29',
          '0.45',
          '0.61',
          '0.76',
          '0.9',
          '1.03',
          '1.25',
          '1.48',
          '1.69',
          '1.8',
          '1.88',
          '1.99',
        ],
        backgroundColor: 'transparent',
        borderColor: 'rgb(37, 26, 117)',
        pointBackgroundColor: 'rgba(37, 26, 117)',
        pointStyle: 'rect',
        pointRadius: 4,
      },
      {
        label: 'Dutch Sovereign Curve',
        data: [
          '-0.64',
          '-0.56',
          '-0.42',
          '-0.31',
          '-0.09',
          '0.08',
          '0.25',
          '0.4',
          '0.55',
          '0.78',
          '0.84',
          '0.95',
          '1.11',
          '1.23',
        ],
        backgroundColor: 'transparent',
        borderColor: 'rgb(190, 190, 190)',
        pointBackgroundColor: 'rgb(190, 190, 190)',
        pointStyle: 'rect',
        pointRadius: 4,
      },
    ],
  };

  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: false,
    },
    lineTension: 1,
    tooltips: {
      backgroundColor: 'rgb(190, 190, 190)',
      titleFontColor: 'rgb(10, 10, 10)',
      bodyFontColor: 'rgb(10, 10, 10)',
      callbacks: {
        title: (tooltipItem: any) => `Tenor: ${tooltipItem[0].xLabel}  years`,
        label: (tooltipItem: any, data: any) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += tooltipItem.yLabel;
          return label;
        },
      },
    },
    legend: {
      display: true,
      align: 'center',
      labels: {
        fontColor: 'rgb(0, 0, 0)',
        boxWidth: 20,
        padding: 5,
      },
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Years',
            fontStyle: 'bold',
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Percentages',
            fontStyle: 'bold',
          },
        },
      ],
    },
  };
}
