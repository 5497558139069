










































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import CookieCheckBoxes from '@/components/utils/CookieCheckBoxes.vue';

@Component({
  name: 'CookieConsentBar',
  components: { ButtonComponent, CookieCheckBoxes },
})
export default class CookieConsentBar extends Vue {
  cookieAccepted: boolean = true;
  customizeCookies: boolean = false;
  cookiesAcceptPressed: boolean = false;

  get acceptButton(): any {
    return {
      containerClass: 'cookie-bar__accept',
      buttonClass: 'button-icon button-icon-large button-scale-medium',
      description: this.$t('components.cookieBar.accept'),
    };
  }

  acceptCookies() {
    this.cookiesAcceptPressed = true;
  }

  mounted() {
    this.cookieAccepted = !!localStorage.getItem('afsCookieStatus');
  }
}
