





























































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IOI, FilterIOI } from '@/types/instrumentsTypes';
import InputComponent from '@/components/inputs/InputComponent.vue';
import IoiRow from '@/components/ioi/IOIRow.vue';
import confirm from '@/components/utils/Confirm.vue';
import IoiConfirm from '@/components/ioi/IOIConfirm.vue';
import MakeOffers from '@/components/ioi/MakeOffers.vue';
import CountDown from '@/components/utils/CountDown.vue';
import desktopSizes from '@/assets/screen-sizes';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import { EventBus } from '@/main';
import IoiRowDayColumn from '@/components/ioi/IOIRowDayColumn.vue';
import IoiRowMaturityColumn from '@/components/ioi/IOIRowMaturityColumn.vue';
import ViewDeals from '@/components/ioi/ViewDeals.vue';
import NoDataAvailable from '@/components/utils/NoDataAvailable.vue';
import PreferencesMail from '@/components/user/PreferencesMail.vue';
import ModalWindow from '@/components/utils/Modal.vue';
import EmailInputs from '@/components/ioi/EmailInputs.vue';
import Tabs from '@/components/utils/Tabs.vue';
import IoiList from '@/components/ioi/IoiList.vue';

const IOINameSpace = namespace('iois/');
const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');
const ModalNameSpace = namespace('modals/');
const tabState = namespace('tabState/');

@Component({
  name: 'MyAccountInvestor',
  components: {
    IoiList,
    NoDataAvailable,
    InputComponent,
    IoiRow,
    confirm,
    IoiConfirm,
    CountDown,
    ButtonComponent,
    MakeOffers,
    IoiRowDayColumn,
    IoiRowMaturityColumn,
    ViewDeals,
    PreferencesMail,
    ModalWindow,
    EmailInputs,
    Tabs,
  },
})
export default class MyAccountInvestor extends Vue {
  // Indication of Interest store
  @IOINameSpace.Getter('allIOIs') iois: IOI[];
  @IOINameSpace.Getter('ioiWithOffers') ioiWithOffers: IOI[];
  @IOINameSpace.Getter('matchedIOIs') matchedIOIs: IOI[];
  @IOINameSpace.Getter('wishListComputed') wishListComputed: IOI[];
  @IOINameSpace.Action('getIOIs') getIOIs: any;
  @IOINameSpace.Action('withdrawOffer') withdrawOffer: any;
  @IOINameSpace.Action('removeFromWishList') removeFromWishList: any;

  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isHAM') isHAM: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @AuthenticationNameSpace.Action('signUpFullUser') signUpFullUser: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  @tabState.Action('setTab') setTab: any;
  @tabState.Getter('tabState') tabState: any;

  eventBus = EventBus;
  myAccount: string = this.getUserAccountId;
  tabId: string = 'myAccountInvestorTabs';
  windowSize: number = 0;
  showExtended: boolean = true;
  screenMediumBig: number = desktopSizes.mediumBig;
  showDaysColumn: boolean = true;

  // Remove interested events
  get deleteInterestedButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button',
      description: this.$t('views.myInvestorAccount.deleteInterestedIOIButton'),
      extendedDescription: this.$t(
        'views.myInvestorAccount.deleteInterestedIOIButton',
      ),
      icon: 'star-off.png',
      showExtended: this.showExtended,
    };
  }

  deleteInterestedIOI(ioiId: string) {
    this.removeFromWishList({
      userAccountId: this.myAccount,
      indicationOfInterestId: ioiId,
    }).then(() => this.setTab({ key: this.tabId, value: 0 }));
  }

  changeTab(tabNumber: number): void {
    const ioiFilters: any = {};
    if (this.isUserLoggedIn && this.isInvestor) {
      if (tabNumber === 0) {
        // get open IOIs
        ioiFilters.state = ['open', 'closing'];
        ioiFilters.interested = 'true';
        ioiFilters.offers = 'false';
        this.getIOIs({
          userAccountId: this.myAccount,
          filters: ioiFilters,
          state: 'outstanding',
          withOffers: '1',
        });
      } else if (tabNumber === 1) {
        // get all my iois with offers
        ioiFilters.state = ['open', 'closing'];
        ioiFilters.offers = 'true';
        this.getIOIs({
          userAccountId: this.myAccount,
          filters: ioiFilters,
          state: 'outstanding',
          withOffers: '1',
        });
      } else if (tabNumber === 2) {
        // get matched iois (iois with deals)
        ioiFilters.state = ['matched'];
        ioiFilters.offers = 'true';
        this.getIOIs({
          userAccountId: this.myAccount,
          filters: ioiFilters,
          state: 'matched',
          withOffers: '1',
        });
      }
    } else {
      const accountErrorMessage: string = `${this.$t(
        'views.myInvestorAccount.accountErrorMessage',
      )}`;
      this.addNotification({
        id: 0,
        message: `${accountErrorMessage}`,
        type: 'error',
      });
    }
  }

  mounted() {
    // Window size
    if (this.tabState.myAccountInvestorTabs) {
      this.changeTab(this.tabState.myAccountInvestorTabs);
    } else {
      this.changeTab(0);
    }
    window.addEventListener('resize', () => {
      this.windowSize = window.innerWidth;
      this.showExtended = this.windowSize >= desktopSizes.large;
      this.showDaysColumn = this.windowSize >= this.screenMediumBig;
    });
  }

  @Watch('windowSize')
  onWindowSizeChange(newSize: number) {
    this.showExtended = newSize >= desktopSizes.large;
    this.showDaysColumn = this.windowSize >= this.screenMediumBig;
  }

  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.windowSize = window.innerWidth;
    });
  }
}
