




















































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import MunicipalityCircleChart from '@/components/landing/MunicipalityCircleChart.vue';

@Component({
  name: 'AfsExplained',
  components: { MunicipalityCircleChart },
})
export default class AfsExplained extends Vue {}
