import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/types/rootState';
import { actionCenterState, EventAc, EventPayload } from '@/types/types';
import eventApi from '@/api/eventApi';
import { getApiError } from '@/api/util';
import { buildEvent, makeWsUrl } from '@/util/generalUtils';
import sentry, { sentryAdditional } from '@/util/sentry';
import { getNotificationToken } from '@/api/authenticationApi';
import Vue from 'vue';
import vm from '@/main';
const ADD_NOTIFICATION = 'notifications/add';
export const EventTypes = {
  IOI_CREATED: 'IndicationOfInterestWasCreated',
  IOI_WITHDRAWN: 'IndicationOfInterestWasWithdrawn',
  IOI_EXPIRED: 'IndicationOfInterestExpired',
  IOI_EDITED: 'IndicationOfInterestWasEdited',
  IOI_CLOSED: 'IndicationOfInterestWasClosed',
  MULTI_EYE_REQUEST_OFFER: 'MultiEyeRequestForOffer',
};

export const state: actionCenterState = {
  events: [],
  wsActive: false,
  wsEvents: '',
};

// getters
export const getters: GetterTree<actionCenterState, RootState> = {
  unreadEvents: state => state.events.filter(event => !event.read).length, // does not work
  events: state => state.events,
};

// mutations
export const mutations: MutationTree<actionCenterState> = {
  ADD_EVENT(state, newEvent: EventAc): void {
    state.events = [...state.events, newEvent];
  },
  EDIT_EVENT_STATE(state, payload): void {
    state.events = state.events.map((event: EventAc) => {
      if (payload.notificationIds.indexOf(event.notificationId) !== -1) {
        return {
          ...event,
          read: payload.newState,
        };
      } else {
        return event;
      }
    });
  },
  SET_AC_UNREAD_EVENTS(state, eventsPayload): void {
    state.events = eventsPayload;
  },
  SET_AC_ALL_EVENTS(state, eventsPayload): void {
    state.events = eventsPayload;
  },
  TOGGLE_WS_ACTIVE(state, wsState: boolean): void {
    state.wsActive = wsState;
  },
};

// actions
export const actions: ActionTree<actionCenterState, RootState> = {
  activateCenter({ dispatch }) {
    dispatch('setNotificationEventListener');
    dispatch('getMyUnreadEvents').then(() => {
      if (state.events.length < 1) {
        dispatch('getMyEvents');
      }
    });
  },

  setNotificationEventListener({ commit, dispatch }) {
    if (!state.wsActive) {
      commit('TOGGLE_WS_ACTIVE', true);
      getNotificationToken()
        .then((response: any) => {
          // @ts-ignore VueNativeSock adds this function, no ts available
          vm.$connect(makeWsUrl(response).href);
        })
        .then(() => {
          Vue.prototype.$socket.onmessage = (data: any) => {
            dispatch('managePushedEvent', JSON.parse(data.data)).catch(error =>
              sentryAdditional(error, data.data),
            );
          };

          Vue.prototype.$socket.addEventListener('close', (response: any) => {
            commit('TOGGLE_WS_ACTIVE', false);
            dispatch('setNotificationEventListener');
          });
        });
    }
  },

  getMyUnreadEvents({ commit, dispatch, rootState }, accountId: string) {
    return eventApi
      .getMyUnreadEvents(accountId)
      .then((response: any) => {
        if (response.length > 0) {
          commit(
            'SET_AC_UNREAD_EVENTS',
            Object.entries(response.data).map((event: any) =>
              buildEvent(event[1]),
            ),
          );
        }
      })
      .catch((error: any) =>
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.events.getEventErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        ),
      );
  },

  getMyEvents({ commit, dispatch }) {
    return eventApi
      .getMyEvents()
      .then((response: any) => {
        commit(
          'SET_AC_ALL_EVENTS',
          Object.entries(response.data).map((event: any) =>
            buildEvent(event[1]),
          ),
        );
      })
      .catch((error: any) =>
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.events.getEventErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        ),
      );
  },

  managePushedEvent({ commit, dispatch, rootState }, eventPayload: any) {
    // when a notification is added from the service worker, this event dispatches the proper
    // action based on the event type and adds the event to the event collection
    const type: string = eventPayload.type ? eventPayload.type : '';
    switch (type) {
      case EventTypes.IOI_CREATED:
        dispatch('iois/savePushedIOI', eventPayload.payload, { root: true });
        break;
      case EventTypes.IOI_WITHDRAWN || EventTypes.IOI_EXPIRED:
        dispatch('iois/removeIOINotification', eventPayload.payload, {
          root: true,
        });
        break;
      case EventTypes.IOI_EDITED:
        dispatch('iois/savePushedIOI', eventPayload.payload, { root: true });
        break;
      case EventTypes.IOI_CLOSED:
        dispatch('iois/removeIOINotification', eventPayload.payload, {
          root: true,
        });
        break;
      default:
        break;
    }
    dispatch('getMyUnreadEvents');
  },

  markEventAsRead({ commit, dispatch, rootState }, eventPayload: EventPayload) {
    return eventApi
      .markEventAsRead(eventPayload)
      .then(() =>
        commit('EDIT_EVENT_STATE', {
          notificationIds: eventPayload.notificationIds,
          newState: true,
        }),
      )
      .catch((error: any) => {
        dispatch(
          ADD_NOTIFICATION,
          {
            labelToTranslate: 'views.events.markAsReadErrorMessage',
            message: getApiError(error),
            type: 'error',
          },
          { root: true },
        );
        sentry(error);
      });
  },
};

export const actionCenter = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
