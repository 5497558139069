






















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LinkedCompanies from '@/components/settings/LinkedCompanies.vue';
import PreferencesMail from '@/components/user/PreferencesMail.vue';
import ChangePasswordForm from '@/components/authentication/ChangePasswordForm.vue';
import ModalWindow from '@/components/utils/Modal.vue';
import SideMenu from '@/components/utils/SideMenu.vue';
import HistoryLog from '@/components/utils/HistoryLog.vue';
import { HistoryLogType } from '@/types/enums.ts';

const AuthenticationNameSpace = namespace('authentication/');
const ModalNameSpace = namespace('modals/');
@Component({
  name: 'PersonalSettings',
  components: {
    SideMenu,
    ModalWindow,
    ChangePasswordForm,
    PreferencesMail,
    LinkedCompanies,
    HistoryLog,
  },
})
export default class PersonalSettings extends Vue {
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  menuTitles: { title: string; ref: HTMLHeadingElement }[] = [];

  get logType(): HistoryLogType {
    return HistoryLogType.USER;
  }

  mounted() {
    Vue.nextTick(() => {
      Object.keys(this.$refs).forEach(key => {
        // check if titles are applicable
        if (
          (this.$refs[key] as HTMLHeadingElement).style.cssText !==
          'display: none;'
        ) {
          this.menuTitles.push({
            title: (this.$refs[key] as HTMLHeadingElement).innerHTML,
            ref: this.$refs[key] as HTMLHeadingElement,
          });
        }
      });
    });
  }
}
