



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LinkedCompanies from '@/components/settings/LinkedCompanies.vue';
import PreferencesMail from '@/components/user/PreferencesMail.vue';
import ChangePasswordForm from '@/components/authentication/ChangePasswordForm.vue';
import ModalWindow from '@/components/utils/Modal.vue';
const AuthenticationNameSpace = namespace('authentication/');
const ModalNameSpace = namespace('modals/');
@Component({
  name: 'SubMenu',
  components: {
    ModalWindow,
    ChangePasswordForm,
    PreferencesMail,
    LinkedCompanies,
  },
})
export default class SubMenu extends Vue {
  @Prop({ required: true }) menuTitles: {
    ref: HTMLHeadingElement;
    title: string;
  }[];

  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  randomNumber = Math.random();

  get availableMenuTitles() {
    return this.menuTitles.filter(title => title && title.ref);
  }

  scrollToHead(ref: any) {
    ref.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}
