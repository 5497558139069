import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { CompaniesState, CompanyItem } from '@/types/types';
import { RootState } from '@/types/rootState';
import { CustomInput } from '@/components/inputs/inputTypes';
import {
  getCompanyHamUserId,
  getCompanyUserId,
  getRoles,
} from '@/api/companyApi';

export const state: CompaniesState = {
  companyList: [],
  roleList: [],
  cache: {
    behalfOff: {
      value: '',
      label: '',
    },
    companySettings: {
      value: '',
      label: '',
    },
    companyName: {
      value: '',
      label: '',
    },
  },
  selectedCompanyId: '',
  noVotingCompanies: [
    'f162f67d-ed3c-40af-aa0b-5f4eb27b9397',
    '3cfaa551-fb15-47ea-a4c2-e1b75053c013',
    '8bcb26c5-155a-444c-b0d9-ae49a3dcf481',
    '402f1b50-8901-47dd-b4fd-9bb5a0d85860',
  ],
};

// getters
export const getters: GetterTree<CompaniesState, RootState> = {
  selectedCompanyIdGetter: (state): string => state.selectedCompanyId,
  multipleCompanies: (state): boolean => state.companyList.length > 1,
  companyListGetter: (state): CompanyItem[] => state.companyList,
  roleListGetter: (state): string[] => state.roleList,
  companyListGetterSelect: (state): object[] =>
    state.companyList
      .filter(company => company.inviteAccepted)
      .map(company => {
        return {
          value: company.companyId,
          label: company.companyName,
        };
      }),
  cacheBehalfOff: (state): { value: string; label: string } => {
    // return previously selected company if available
    if (
      state.cache.behalfOff.value &&
      state.companyList.filter(
        company => company.companyId === state.cache.behalfOff.value,
      ).length > 0
    ) {
      return state.cache.behalfOff;
    } else {
      return defaultCache();
    }
  },
  cacheIoiCompanyName: (state): { value: string; label: string } => {
    // return previously selected company if available
    if (
      state.cache.companyName.value &&
      state.companyList.filter(
        company => company.companyId === state.cache.companyName.value,
      ).length > 0
    ) {
      return state.cache.companyName;
    } else {
      return defaultCache();
    }
  },
  cacheCompanySettings: (state): { value: string; label: string } => {
    // return previously selected company if available
    if (
      state.cache.companySettings.value &&
      state.companyList.filter(
        company => company.companyId === state.cache.companySettings.value,
      ).length > 0
    ) {
      return state.cache.companySettings;
    } else {
      return defaultCache();
    }
  },
  selectedCompanyData: (state): any => {
    let companyData: any = {};
    if (
      state.selectedCompanyId &&
      state.companyList.filter(
        company => company.companyId === state.selectedCompanyId,
      ).length > 0
    ) {
      companyData = state.companyList.filter(
        company => company.companyId === state.selectedCompanyId,
      )[0];
      return formatCompany(companyData);
    }
    return {};
  },
  companyCantVote: (state): boolean => {
    return (
      state.noVotingCompanies.filter(
        (companyId: string) => companyId === state.selectedCompanyId,
      ).length > 0
    );
  },
};

// mutations
export const mutations: MutationTree<CompaniesState> = {
  SET_COMPANY(state, payload: { slot: string; company: CustomInput }): void {
    state.cache[payload.slot] = payload.company;
    state.selectedCompanyId = payload.company.value;
  },
  SET_COMPANY_LIST(state, payload: any): void {
    state.companyList = payload.map(({ name: companyName, ...rest }: any) => ({
      companyName,
      ...rest,
    }));
  },
  SET_ROLE_LIST(state, payload: any): void {
    state.roleList = payload;
  },
};

export const actions: ActionTree<CompaniesState, RootState> = {
  getCompanyList({ commit, rootState }, type: string): Promise<unknown> {
    commit('SET_COMPANY_LIST', []);
    return new Promise((resolve, reject) => {
      const userAccountId = rootState.authentication.token.userAccountId;
      if (type === 'linked') {
        getCompanyUserId(userAccountId)
          .then(data => {
            commit('SET_COMPANY_LIST', data.data);
          })
          .then(() => resolve());
      }
      if (type === 'ham') {
        getCompanyHamUserId(userAccountId)
          .then(data => {
            commit('SET_COMPANY_LIST', data.data);
          })
          .then(() => {
            resolve();
          });
      }
    });
  },

  getRoleList({ commit, state }) {
    getRoles(state.selectedCompanyId || state.cache.companySettings.value).then(
      data => {
        commit('SET_ROLE_LIST', data.data);
      },
    );
  },

  setCompany({ commit }, payload: { slot: string; company: CustomInput }) {
    commit('SET_COMPANY', payload);
  },
};

export const companies = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

function formatCompany(companyData: any) {
  if (typeof companyData.industryOrSector === 'string') {
    companyData.industryOrSector = {
      value: companyData.industryOrSector,
      label: `components.IOIForm.industry${companyData.industryOrSector}`,
    };
  }
  if (!companyData.country) {
    companyData.country = {
      value: companyData.countryCode,
      label: `countries.${companyData.countryCode.toLowerCase()}`,
    };
  }
  return companyData;
}

// returns default cache options
function defaultCache() {
  if (state.companyList[0]) {
    return {
      value: state.companyList[0].companyId,
      label: state.companyList[0].companyName,
    };
  } else {
    return {
      value: 'placeholder',
      label: 'placeholder',
    };
  }
}
