


































import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import {
  noOffersExpiring,
  offersExpiring,
  smallColumnClass,
} from '@/views/myAccounts/myAccounts';
import { IOI } from '@/types/instrumentsTypes';

const ModalNameSpace = namespace('modals/');

@Component({
  name: 'IoiOfferCount',
})
export default class IoiOfferCount extends Vue {
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  @Prop({}) ioi!: IOI;

  ioiSmallColumnClass = smallColumnClass;
  ioiNoOffersExpiring = noOffersExpiring;
  ioiOffersExpiring = offersExpiring;
}
