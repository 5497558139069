

























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DateDto } from '@/util/date';
import { FilterStateIOI } from '@/types/instrumentsTypes';
import moment, { Moment } from 'moment';

const IOINameSpace = namespace('iois/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'CountDown',
})
export default class CountDown extends Vue {
  @Prop({}) dateTimeProp!: DateDto;
  @Prop({}) ioiId!: string;

  @IOINameSpace.Action('getIOIs') getIOIs: any;
  @IOINameSpace.Getter('prevIoiPayload') prevIoiPayload: any;
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('serverDateDifference')
  serverDateDifference: number;

  date: number = 0;
  now: number = Math.trunc(new Date().getTime() / 1000);

  fullDateTime: string = '';
  show: boolean = false;

  get seconds(): string {
    const sec: number = (this.date - this.now) % 60;
    if (sec < 0) {
      return '00';
    }
    if (sec < 10) {
      return `0${sec}`;
    }

    return `${sec}`;
  }

  get minutes(): string {
    const min: number = Math.trunc((this.date - this.now) / 60) % 60;
    if (min < 0) {
      return '00';
    }
    if (min < 10) {
      return `0${min}`;
    }

    return `${min}`;
  }

  get secondsAndminutes(): string {
    return `${this.minutes}:${this.seconds}`;
  }

  get hours(): string {
    const hours: number = Math.trunc((this.date - this.now) / 60 / 60) % 24;
    if (hours < 0) {
      return '00';
    }
    if (hours < 10) {
      return `0${hours}`;
    }
    return `${hours}`;
  }

  get days() {
    return Math.trunc((this.date - this.now) / 60 / 60 / 24);
  }

  @Watch('secondsAndminutes')
  onPropertyChanged(value: string) {
    if (value === '00:00') {
      this.$emit('timerOver', true);
      setTimeout(() => {
        this.getIOIs(this.prevIoiPayload);
      }, 4000);
    }
  }

  created() {
    // The parameters expected are:
    //  - dateProp: 2019-04-09
    //  - timeProp: 10:30:00
    const expiringDate = new Date(this.dateTimeProp.date);
    const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
    this.fullDateTime = `${expiringDate.toLocaleString('en', options)} ${
      this.dateTimeProp.time
    }`;
    this.date = Math.trunc(Date.parse(this.fullDateTime) / 1000);
    window.setInterval(() => {
      this.now = moment()
        .add(this.serverDateDifference / 1000, 'seconds')
        .unix();
      this.show = this.date - this.now <= 3600;
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.now);
  }
}
