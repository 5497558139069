import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { NotificationState, Notification } from '@/types/types';
import { RootState } from '@/types/rootState';
import router from '@/router';

export const state: NotificationState = {
  notifications: [],
  nextNotification: 0,
};

// getters
export const getters: GetterTree<NotificationState, RootState> = {
  allNotifications: state => state.notifications,
  errorNotifications: state =>
    state.notifications.filter(notification => notification.type === 'error'),
};

// mutations
export const mutations: MutationTree<NotificationState> = {
  PUSH(state, newNotification) {
    let messageTranslated: string = '';
    if (
      newNotification.labelToTranslate === undefined &&
      newNotification.label !== undefined
    ) {
      messageTranslated = newNotification.label;
    } else if (newNotification.labelToTranslate !== undefined) {
      messageTranslated = `${router.app.$t(newNotification.labelToTranslate)}`;
    }
    const copyNotification = Object.assign({}, newNotification);
    copyNotification.id = state.nextNotification;
    copyNotification.message =
      newNotification.message === undefined
        ? `${messageTranslated}`
        : `${messageTranslated} ${newNotification.message}`;
    state.notifications.push(copyNotification);
  },

  DELETE(state, notificationToRemove) {
    state.notifications = state.notifications.filter(
      (notification: Notification) =>
        notification.id !== notificationToRemove.id,
    );
  },

  INC_NEXT_NOTIFICATION(state) {
    state.nextNotification += 1;
  },
};

// actions
export const actions: ActionTree<NotificationState, RootState> = {
  add({ commit, rootState }, newNotification: Notification): void {
    commit('INC_NEXT_NOTIFICATION');
    commit('PUSH', newNotification);
  },
  remove({ commit }, notificationToRemove: Notification): void {
    commit('DELETE', notificationToRemove);
  },
};

export const notifications = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
