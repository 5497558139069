





















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import {
  approveSignup,
  approveUpgrade,
  denySignup,
  denyUpgrade,
} from '@/api/authenticationApi';

const NotificationsNameSpace = namespace('notification/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'BackofficeConfirmation',
  props: {
    action: String,
    email: String,
  },
})
export default class BackofficeConfirmation extends Vue {
  @NotificationsNameSpace.Action('add') addNotification: any;
  @AuthenticationNameSpace.Action('approveUpgrade') approveUpgrade: boolean;
  @AuthenticationNameSpace.Action('denyUpgrade') denyUpgrade: boolean;

  processed: boolean = false;
  success: boolean = false;
  greeting: TranslateResult = '';
  successTitle: TranslateResult = '';
  successDescription: TranslateResult = '';
  failedTitle: TranslateResult = '';
  failedDescription: TranslateResult = '';

  created() {
    this.greeting = `${this.$t('views.confirmationPage.backofficeGreeting')}`;

    if (this.$props.action === 'approve-signup') {
      this.processApproveSignup();
    } else if (this.$props.action === 'deny-signup') {
      this.processDenySignup();
    } else if (this.$props.action === 'approve-upgrade') {
      this.processApproveUpgrade();
    } else if (this.$props.action === 'deny-upgrade') {
      this.processDenyUpgrade();
    }
  }

  processApproveSignup() {
    this.successTitle = `${this.$t(
      'views.confirmationPage.approvedUserAccount.titleSuccess',
    )}`;
    this.successDescription = `${this.$t(
      'views.confirmationPage.approvedUserAccount.descriptionSuccess',
    )}`;
    this.failedTitle = `${this.$t(
      'views.confirmationPage.approvedUserAccount.titleFailed',
    )}`;
    this.failedDescription = `${this.$t(
      'views.confirmationPage.approvedUserAccount.descriptionFailed',
    )}`;

    approveSignup({ email: this.$props.email })
      .then(() => {
        this.processed = true;
        this.success = true;
      })
      .catch(() => {
        this.processed = true;
        this.success = false;
      });
  }

  processDenySignup() {
    this.successTitle = `${this.$t(
      'views.confirmationPage.deniedUserAccount.titleSuccess',
    )}`;
    this.successDescription = `${this.$t(
      'views.confirmationPage.deniedUserAccount.descriptionSuccess',
    )}`;
    this.failedTitle = `${this.$t(
      'views.confirmationPage.deniedUserAccount.titleFailed',
    )}`;
    this.failedDescription = `${this.$t(
      'views.confirmationPage.deniedUserAccount.descriptionFailed',
    )}`;

    denySignup({ email: this.$props.email })
      .then(() => {
        this.processed = true;
        this.success = true;
      })
      .catch(() => {
        this.processed = true;
        this.success = false;
      });
  }

  processApproveUpgrade() {
    this.successTitle = `${this.$t(
      'views.confirmationPage.upgradeAccount.titleSuccess',
    )}`;
    this.successDescription = `${this.$t(
      'views.confirmationPage.upgradeAccount.descriptionSuccess',
    )}`;
    this.failedTitle = `${this.$t(
      'views.confirmationPage.upgradeAccount.titleFailed',
    )}`;
    this.failedDescription = `${this.$t(
      'views.confirmationPage.upgradeAccount.descriptionFailed',
    )}`;

    approveUpgrade({ email: this.$props.email })
      .then(() => {
        this.processed = true;
        this.success = true;
      })
      .catch(() => {
        this.processed = true;
        this.success = false;
      });
  }

  processDenyUpgrade() {
    this.successTitle = `${this.$t(
      'views.confirmationPage.deniedAccountUpgrade.titleSuccess',
    )}`;
    this.successDescription = `${this.$t(
      'views.confirmationPage.deniedAccountUpgrade.descriptionSuccess',
    )}`;
    this.failedTitle = `${this.$t(
      'views.confirmationPage.deniedAccountUpgrade.titleFailed',
    )}`;
    this.failedDescription = `${this.$t(
      'views.confirmationPage.deniedAccountUpgrade.descriptionFailed',
    )}`;

    denyUpgrade({ email: this.$props.email })
      .then(() => {
        this.processed = true;
        this.success = true;
      })
      .catch(() => {
        this.processed = true;
        this.success = false;
      });
  }
}
