
































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'SocialIcons',
})
export default class SocialIcons extends Vue {}
