




























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IRP } from '@/types/irpTypes';

@Component({
  name: 'IRPTable',
})
export default class IRPTable extends Vue {
  @Prop({}) irpCurve!: IRP;

  showTable: boolean = false;

  created() {
    this.showTable = typeof this.irpCurve.name !== 'undefined';
  }
}
