







































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CookieCheckBoxes from '@/components/utils/CookieCheckBoxes.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';

@Component({
  name: 'PrivacyStatement',
  components: { CookieCheckBoxes, ButtonComponent },
})
export default class PrivacyStatement extends Vue {
  cookiesAcceptPressed: boolean = false;

  get acceptButton(): any {
    return {
      containerClass: 'privacy-accept',
      buttonClass: 'button-icon button-icon-large',
      description: this.$t('components.privacyPolicy.edit'),
    };
  }
}
