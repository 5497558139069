




















































import Vue from 'vue';
import { Component, Provide, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import InputPhoneNumberComponent from '@/components/inputs/InputPhoneNumberComponent.vue';
import InputComponent from '@/components/inputs/InputComponent.vue';
import InputFileComponent from '@/components/inputs/InputFileComponent.vue';
import InputSelectComponent from '@/components/inputs/InputSelectComponent.vue';
import InputRadioComponent from '@/components/inputs/InputRadioComponent.vue';
import { CustomFileInput, ReturnInput } from '@/components/inputs/inputTypes';
import { EventBus } from '@/main';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import { getIndustry } from '@/util/ioiUtils';
import { neatifyString } from '@/util/generalUtils';

const SignupNameSpace = namespace('signup/');
const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'InvitationForm',
  components: {
    InputPhoneNumberComponent,
    InputComponent,
    InputSelectComponent,
    InputRadioComponent,
    ButtonComponent,
    InputFileComponent,
  },
})
export default class InvitationForm extends Vue {
  @SignupNameSpace.Action('setFormOption') setFormOption: any;
  @SignupNameSpace.Getter('step') currentStep: number;
  @SignupNameSpace.Getter('accountType') accountType: any;
  @SignupNameSpace.Action('setStep') setStep: any;
  @SignupNameSpace.Action('setAccountType') setAccountType: any;

  @Provide() $validator: any;
  @Prop({ required: true }) invitationData: {
    email: string;
    companyName: string;
    industryOrSector: string;
  };

  @Watch('invitationData')
  onPropGood() {
    if (this.invitationData.email !== '') {
      this.formOne.email.value = this.invitationData.email;
      this.setInputValue({
        name: this.formOne.email.name,
        label: this.formOne.email.name,
        value: this.invitationData.email,
      });
    }
  }

  getIndustry = getIndustry;

  eventBus = EventBus;
  companyEmail: string | null = null;
  companyEmailRepeatValue: string | null = '';
  password: string | null = '';
  passwordRepeatValue: string | null = '';
  prepopData: {
    companyName: string;
    firstName: string;
    industryOrSector: string;
    lastName: string;
    middleName: string;
  } = {
    companyName: '',
    firstName: '',
    industryOrSector: '',
    lastName: '',
    middleName: '',
  };

  formOne: any = {
    email: {
      compType: 'InputComponent',
      name: 'email',
      ref: 'email',
      placeHolder: 'components.signUpForm.email',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      vRule: 'required|email',
      value: this.invitationData.email,
    },
    companyEmailRepeat: {
      compType: 'InputComponent',
      name: 'companyEmailRepeat',
      placeHolder: 'components.signUpForm.emailRepeat',
      vRule: 'required|confirmed:',
      vLabel: 'components.signUpForm.emailRepeatVLabel',
      containerClass: 'input-inner',
      class: 'form-input',
      value: this.invitationData.email,
      showLabel: true,
    },
  };

  formTwo: any = {
    firstName: {
      compType: 'InputComponent',
      name: 'firstName',
      vLabel: 'components.signUpForm.firstName',
      placeHolder: 'components.signUpForm.firstName',
      vRule: 'required|alpha',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      value: undefined,
      formClass: 'three-fifth',
    },
    middleName: {
      compType: 'InputComponent',
      name: 'middleName',
      placeHolder: 'components.signUpForm.middleName',
      vRule: 'alpha',
      containerClass: 'input-inner',
      class: 'form-input',
      hideVLabel: true,
      showLabel: true,
      value: undefined,
      formClass: 'two-fifth',
    },
    lastName: {
      compType: 'InputComponent',
      name: 'lastName',
      placeHolder: 'components.signUpForm.lastName',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      vRule: 'required|alpha_spaces',
    },
    phoneNumber: {
      compType: 'InputPhoneNumberComponent',
      name: 'phoneNumber',
      placeHolder: 'components.signUpForm.telephone',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      vRule: 'phone_number|phone_number_required',
      value: undefined,
    },
    password: {
      compType: 'InputComponent',
      name: 'password',
      ref: 'password',
      type: 'password',
      placeHolder: 'components.signUpForm.password',
      vRule: 'required|min:8|max:35',
      containerClass: 'input-inner',
      showLabel: true,
      class: 'form-input',
      value: undefined,
    },
    passwordRepeat: {
      name: 'passwordRepeat',
      compType: 'InputComponent',
      placeHolder: 'components.signUpForm.passwordRepeat',
      vRule: 'required|confirmed:',
      vLabel: 'components.signUpForm.passwordRepeatVLabel',
      type: 'password',
      showLabel: true,
      containerClass: 'input-inner',
      class: 'form-input',
      value: undefined,
    },
    terms: {
      name: 'terms',
      compType: 'InputRadioComponent',
      optionList: [
        {
          id: 'terms',
          value: '1',
          labelCode: 'components.signUpFullForm.termsOfUse',
          static: false,
        },
      ],
      vRule: 'required',
      wrapperClass: 'input-container radio-no-stuff',
      verticalRadioButton: false,
      showImage: true,
    },
  };

  get repeatPassword(): any {
    const repeatP = this.formTwo.passwordRepeat;
    repeatP.vRule = `required|confirmed:${this.password}`;
    repeatP.value =
      this.passwordRepeatValue !== '' ? this.passwordRepeatValue : undefined;
    return repeatP;
  }

  setInputValue(inputValue: ReturnInput) {
    if (this.$validator.errors) {
      // check if field has been validated else set empty
      if (!this.$validator.errors.has(inputValue.name)) {
        this.setFormOption({
          name: inputValue.name,
          value: neatifyString(inputValue.value),
        });
      } else {
        this.setFormOption({
          name: inputValue.name,
          value: '',
        });
      }
    }

    // needed specificly for validation
    switch (inputValue.name) {
      case 'email':
        this.companyEmail = inputValue.value;
        this.formOne.companyEmailRepeat.vRule = `required|confirmed:${this.companyEmail}`;
        this.formOne.companyEmailRepeat.value =
          this.invitationData.email === inputValue.value
            ? this.invitationData.email
            : this.companyEmailRepeatValue;
        this.eventBus.$emit('reloadInput', this.formOne.companyEmailRepeat);
        break;
      case 'companyEmailRepeat':
        this.companyEmailRepeatValue = inputValue.value;
        break;
      case 'password':
        this.password = inputValue.value;
        this.eventBus.$emit('reloadInput', this.repeatPassword);
        break;
      case 'repeatPassword':
        this.passwordRepeatValue = inputValue.value;
        break;
      default:
        break;
    }
  }

  // handle file input components
  setInputFileValue(inputValue: CustomFileInput) {
    // check if validation available
    if (this.$validator.errors) {
      // check if field has been validated else set empty
      if (!this.$validator.errors.has(inputValue.inputName)) {
        this.setFormOption({
          name: inputValue.inputName,
          value: inputValue.fileValue,
        });
      } else {
        this.setFormOption({
          name: inputValue.inputName,
          value: '',
        });
      }
    }
  }
}
