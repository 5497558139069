













































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { PrismicAPIPromise } from '@/types/prismic';
import moment from 'moment';
import LoadingIndicator from '@/components/utils/LoadingIndicator.vue';
import { compareValues } from '@/util/generalUtils';

@Component({
  name: 'BlogOverview',
  components: { LoadingIndicator },
})
export default class BlogOverview extends Vue {
  pages: any[] = [];
  activeCategory: string = '';

  get blogPages() {
    if (this.pages.length > 0) {
      return this.pages.map((page: any) => {
        return {
          id: page.id,
          image: page.data.image.url ? page.data.image : '',
          date: page.data.display_date
            ? moment(page.data.display_date).format('DD-MM-YYYY')
            : moment(page.first_publication_date).format('DD-MM-YYYY'),
          sortDate: page.data.display_date
            ? moment(page.data.display_date).format('YYYY-MM-DD')
            : moment(page.first_publication_date).format('YYYY-MM-DD'),
          title: page.data.title,
          body: `${page.data.body
            .filter((slice: any) => slice.slice_type === 'news_')[0]
            .primary.news_article_text[0].text.slice(0, 180)}...`,
          category: page.data.category,
          pinned: page.data.pinned,
        };
      });
    } else {
      return [];
    }
  }

  get filteredBlogPages() {
    if (this.blogPages.length > 0) {
      return this.blogPages.reduce((pages: any[], page: any) => {
        if (!this.activeCategory) {
          pages.push(page);
        }
        if (this.activeCategory && page.category === this.activeCategory) {
          pages.push(page);
        }
        return pages;
      }, []);
    } else {
      return [];
    }
  }

  get sortedBlogPages() {
    const sortedPages = this.filteredBlogPages.sort(
      compareValues('sortDate', 'desc'),
    );
    return [
      ...sortedPages.filter(page => page.pinned),
      ...sortedPages.filter(page => !page.pinned),
    ];
  }

  get categories() {
    // make a set out of al the categories, only true values and no doubles
    return [
      ...new Set(
        this.blogPages.reduce((ids: String[], page) => {
          if (page.category) {
            ids.push(page.category);
          }
          return ids;
        }, []),
      ),
    ];
  }

  mounted() {
    this.getContent();
  }

  getContent() {
    this.$prismic.client
      .query(this.$prismic.Predicates.at('document.type', 'blog_page'))
      .then((response: PrismicAPIPromise) => {
        // response is the response object, response.results holds the documents
        this.pages = response.results;
      });
  }

  setCategory(category: string) {
    if (this.activeCategory === category) {
      this.activeCategory = '';
    } else {
      this.activeCategory = category;
    }
  }
}
