var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.formClass,
    _vm.isInputCorrect ? 'select-input-correct' : '',
    _vm.inputHasError ? 'select-input-error' : '',
    _vm.openDirection === 'bottom' ? 'open-bottom' : '',
    !_vm.inputParam.multi ? 'single-mode' : '' ]},[(_vm.inputParam.showLabel)?_c('label',{attrs:{"for":_vm.inputParam.name}},[_vm._v(" "+_vm._s(_vm.$t(_vm.inputParam.placeHolder))+" "),(_vm.required)?_c('small',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.inputTooltip),expression:"inputTooltip"}]},[_c('multiselect',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.inputParam.vRule),expression:"inputParam.vRule"}],attrs:{"track-by":"value","label":"label","name":_vm.inputParam.name,"disabled":_vm.inputParam.disabled,"open-direction":_vm.openDirection,"options":_vm.optionList,"placeholder":_vm.$t(_vm.inputParam.placeHolder),"searchable":_vm.search,"multiple":_vm.inputParam.multi,"select-label":_vm.$t('components.selectComponent.selectLabel'),"select-group-label":_vm.$t('components.selectComponent.selectGroupLabel'),"deselect-label":_vm.$t('components.selectComponent.deselectLabel'),"deselect-group-label":_vm.$t('components.selectComponent.deselectGroupLabel'),"selected-label":_vm.$t('components.selectComponent.selectedLabel'),"close-on-select":_vm.closeOnSelect,"allow-empty":_vm.allowEmpty},on:{"input":function($event){return _vm.inputChange()}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
  var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(_vm.translateOptions ? option.label ? _vm.$t(option.label) : _vm.$t(_vm.inputParam.placeHolder) : option.label))])]}},{key:"option",fn:function(ref){
  var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(_vm.translateOptions ? _vm.$t(option.label) : option.label))])]}},{key:"noResult",fn:function(ref){
  var option = ref.option;
return [_vm._v(" "+_vm._s(option)+" "),(_vm.inputParam.name.indexOf('company') > -1)?_c('strong',[_vm._v(_vm._s(_vm.$t('components.selectComponent.noResultCompany')))]):_c('strong',[_vm._v(_vm._s(_vm.$t('components.selectComponent.noResult')))])]}},{key:"tag",fn:function(ref){
  var props = ref.props;
  var option = ref.option;
  var remove = ref.remove;
return [_c('span',{staticClass:"multiselect__tag"},[_c('span',[_vm._v(_vm._s(_vm.translateValue ? _vm.$t(option.label) : option.label))]),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"aria-hidden":"true","tabindex":"1"},on:{"click":function($event){return remove(option)}}})])]}},{key:"selection",fn:function(ref){
  var values = ref.values;
return (_vm.limitHeight)?[(_vm.windowWidth > 1800 ? values.length > 2 : values.length > 1)?_c('span',{staticClass:"multiselect__single"},[_c('small',[_vm._v(_vm._s(_vm.$t(_vm.inputParam.placeHolder))+":")]),_vm._v(" "+_vm._s(values.length)+" selected")]):_vm._e()]:undefined}}],null,true),model:{value:(_vm.vmodelValue),callback:function ($$v) {_vm.vmodelValue=$$v},expression:"vmodelValue"}})],1),(_vm.inputHasError)?_c('div',{staticClass:"validation-error"},[_c('span',[_vm._v(_vm._s(_vm.errors.items.filter(function (error) { return error.field === _vm.inputParam.name; })[0].msg))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }