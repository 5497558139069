
























































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IOI } from '@/types/instrumentsTypes';
import InputSelect from '@/components/inputs/InputSelect.vue';
import { CustomInput } from '@/components/inputs/inputTypes';
import NoDataAvailable from '@/components/utils/NoDataAvailable.vue';
import IOIDetail from '@/views/ioi/IOIDetail.vue';
import { compareValues } from '@/util/generalUtils';
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'IoiList',
  components: { IOIDetail, NoDataAvailable, InputSelect },
})
export default class IoiList extends Vue {
  @Prop({ required: true }) iois: IOI[];
  @Prop({}) id!: string;
  @Prop({ default: 'asc', required: false }) startOrderDirection!:
    | 'asc'
    | 'desc';

  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;

  filteredIOIList: IOI[] = [];
  ioiReference: string = '';
  randomNumber: number = Math.random();
  orderDirection: 'asc' | 'desc' = 'asc';
  orderByValue:
    | {
        value: string;
        label: string;
      }
    | false = false;

  filterByIssuerCompany: any = {
    name: 'filterByIssuerCompany',
    placeHolder: 'components.IOIFilters.company',
    optionList: this.companyList,
    value: [],
    multi: true,
  };

  filterByIndustry: any = {
    name: 'filterByIndustry',
    placeHolder: 'components.IOIFilters.industry',
    optionList: this.companyList,
    value: [],
    multi: true,
  };

  filterByCountry: any = {
    name: 'filterByCountry',
    placeHolder: 'components.preferenceInvestor.country',
    optionList: this.countryList,
    value: [],
    multi: true,
  };

  get orderBy() {
    return {
      name: 'orderBy',
      optionList: this.optionList,
      value: this.orderByValue || this.optionList[0],
    };
  }

  get companyParam() {
    return {
      ...this.filterByIssuerCompany,
      optionList: this.companyList,
    };
  }

  get industryParam() {
    return {
      ...this.filterByIndustry,
      optionList: this.industryList,
    };
  }

  get countryParam() {
    return {
      ...this.filterByCountry,
      optionList: this.countryList,
    };
  }

  get activeFilters() {
    return {
      company: this.companyList.length > 1,
      industry: this.industryList.length > 1,
      country: this.countryList.length > 1,
      any:
        this.companyList.length > 1 ||
        this.industryList.length > 1 ||
        this.countryList.length > 1,
    };
  }

  get optionList() {
    if (this.isMarketViewer) {
      return [
        {
          value: 'proposedTransactionDateStrAndTime',
          label: 'components.IOIFilters.transactionDate',
        },
        {
          value: 'bucketSize',
          label: 'components.IOIOrders.nominalSize',
        },
        { value: 'industryOrSector', label: 'components.IOIFilters.industry' },
        {
          value: 'referenceNumber',
          label: 'components.IOIFilters.referenceNumber',
        },
        {
          value: 'maturityBucket',
          label: 'components.IOIFilters.maturityBucket',
        },
      ];
    } else {
      return [
        {
          value: 'proposedTransactionDateStrAndTime',
          label: 'components.IOIFilters.transactionDate',
        },
        {
          value: 'issueNominalSize',
          label: 'components.IOIOrders.nominalSize',
        },
        { value: 'industryOrSector', label: 'components.IOIFilters.industry' },
        {
          value: 'referenceNumber',
          label: 'components.IOIFilters.referenceNumber',
        },
        {
          value: 'issueMaturityInDays',
          label: 'components.IOIFilters.maturityBucket',
        },
      ];
    }
  }

  get companyList() {
    const companyList = [
      ...new Set(this.iois.map(ioi => ioi.issuerCompanyName)),
    ];
    return companyList.map(company => {
      return {
        value: company,
        label: company,
      };
    });
  }

  get industryList() {
    const industryList = [
      ...new Set(this.iois.map(ioi => ioi.industryOrSector)),
    ];
    return industryList.map(industry => {
      return {
        value: industry,
        label: `components.IOIForm.industry${industry}`,
      };
    });
  }

  get countryList() {
    const countryList = [...new Set(this.iois.map(ioi => ioi.issuerCountry))];
    return countryList.map(country => {
      return {
        value: country,
        label: `countries.${country.toLowerCase()}`,
      };
    });
  }

  // @Watch('iois')
  get filteredList() {
    // add date + time field for ioi sorting
    let iois: IOI[] = this.iois.map(
      (ioi: IOI) =>
        (ioi = {
          ...ioi,
          proposedTransactionDateStrAndTime:
            ioi.proposedTransactionDateStr +
            ioi.proposedPricingAndTransactionTime,
        }),
    );
    if (this.filterByIssuerCompany.value.length > 0) {
      let lFilter: IOI[] = [];
      this.filterByIssuerCompany.value.forEach((filter: any) => {
        lFilter = [
          ...lFilter,
          ...iois.filter(ioi => ioi.issuerCompanyName === filter.value),
        ];
      });
      iois = [...lFilter];
    }

    if (this.filterByIndustry.value.length > 0) {
      let lFilter: IOI[] = [];
      this.filterByIndustry.value.forEach((filter: any) => {
        lFilter = [
          ...lFilter,
          ...iois.filter(ioi => ioi.industryOrSector === filter.value),
        ];
      });
      iois = [...lFilter];
    }
    if (this.filterByCountry.value.length > 0) {
      let lFilter: IOI[] = [];
      this.filterByCountry.value.forEach((filter: any) => {
        lFilter = [
          ...lFilter,
          ...iois.filter(ioi => ioi.issuerCountry === filter.value),
        ];
      });
      iois = [...lFilter];
    }
    return iois;
  }

  get sortedList() {
    return this.filteredList.sort(
      compareValues(this.orderBy.value.value, this.orderDirection),
    );
  }

  created() {
    this.orderDirection = this.startOrderDirection;
  }

  setInputValue(inputValue: CustomInput) {
    if (inputValue.name === 'orderBy') {
      this.orderByValue = inputValue;
    }
    if (inputValue.name === 'filterByIssuerCompany') {
      this.filterByIssuerCompany.value = inputValue.value;
    }
    if (inputValue.name === 'filterByIndustry') {
      this.filterByIndustry.value = inputValue.value;
    }
    if (inputValue.name === 'filterByCountry') {
      this.filterByCountry.value = inputValue.value;
    }
  }

  clearFilters() {
    this.filterByIssuerCompany.value = [];
    this.filterByIndustry.value = [];
    this.filterByCountry.value = [];
  }

  slotClick(ioiReference: string) {
    if (!this.isMarketViewer) {
      if (this.ioiReference === ioiReference) {
        this.ioiReference = '';
      } else {
        this.ioiReference = ioiReference;
        this.scrollToIoi();
      }
    }
  }

  scrollToIoi() {
    setTimeout(() => {
      const element = document.getElementById('open-ioi-id');
      const yOffset = -20;
      if (element) {
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        // @ts-ignore for isIE check, if ie dont scroll fancy
        if (window.document.documentMode) {
          window.scrollTo(0, y);
        } else {
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }
    }, 650);
  }
}
