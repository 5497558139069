var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ioi-buttons-container"},[(
      _vm.isIssuer && ['open', 'closing', 'closed', 'draft'].includes(_vm.ioi.state)
    )?_c('button-component',{attrs:{"button-param":_vm.editIOIButton},on:{"onClickSubmit":function($event){return _vm.editIOIConfirmation(_vm.ioi.indicationOfInterestId, _vm.ioi.offerCount)}}}):_vm._e(),(_vm.isIssuer && ['open', 'closing', 'closed'].includes(_vm.ioi.state))?_c('button-component',{attrs:{"button-param":_vm.withdrawIOIButton},on:{"onClickSubmit":function($event){return _vm.withdrawIOIConfirmation()}}}):_vm._e(),(_vm.isIssuer && !['draft'].includes(_vm.ioi.state))?_c('button-component',{attrs:{"button-param":_vm.copyIOIButton},on:{"onClickSubmit":function($event){return _vm.copyIOIDataSubmit()}}}):_vm._e(),(_vm.isIssuer && ['withdrawn', 'expired', 'draft'].includes(_vm.ioi.state))?_c('button-component',{attrs:{"button-param":_vm.deleteIOIButton},on:{"onClickSubmit":function($event){return _vm.deleteIOIConfirmation()}}}):_vm._e(),(_vm.isIssuer && ['draft'].includes(_vm.ioi.state))?_c('button-component',{attrs:{"button-param":_vm.sendToMarketIOIButton},on:{"onClickSubmit":function($event){return _vm.sendToMarketIOIConfirmation()}}}):_vm._e(),(
      _vm.isInvestor &&
        _vm.ioi.ioiOffers.length > 0 &&
        !_vm.companyCantVote &&
        ['open', 'closing'].includes(_vm.ioi.state)
    )?_c('button-component',{attrs:{"button-param":_vm.removeAllOffersButton},on:{"onClickSubmit":function($event){return _vm.removeAllOffersConfirmation()}}}):_vm._e(),_c('button-component',{attrs:{"button-param":{
      containerClass: 'ioi-row-inline-button',
      buttonClass:
        'button-icon button-icon-grid full-width-button center-left',
      description: _vm.$t('views.myIssuerAccount.downloadPdf'),
      toolTip: _vm.$t('views.myIssuerAccount.printIOIOverview'),
      icon: 'download.svg',
      svg: true,
      showExtended: false,
    }},on:{"onClickSubmit":function($event){return _vm.printPDF()}}}),_c('button-component',{attrs:{"button-param":{
      containerClass: 'ioi-row-inline-button',
      buttonClass:
        'button-icon button-icon-grid full-width-button center-left',
      description: _vm.$t('views.myIssuerAccount.requestIndication'),
      toolTip: _vm.$t('views.myIssuerAccount.requestIndicationFromAgent'),
      icon: 'information-outline.svg',
      svg: true,
      showExtended: false,
    }},on:{"onClickSubmit":function($event){return _vm.requestIndication()}}}),_c('confirm',{attrs:{"show-modal-param":_vm.showButtonModal,"content-param":_vm.buttonModalParam},on:{"onCancel":_vm.onCancel,"onConfirm":_vm.onConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }