


























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'CookieCheckBoxes',
})
export default class CookieCheckBoxes extends Vue {
  @Prop({ required: false }) acceptCookies: boolean;

  essential: boolean = true;
  statistics: boolean = true;
  random: number = Math.random();

  @Watch('acceptCookies')
  setCookies() {
    if (this.acceptCookies) {
      const cookiestring = `essential:${this.essential};statistics:${this.statistics};`;
      localStorage.setItem('afsCookieStatus', cookiestring);
      this.$emit('accepted');
    }
  }

  mounted() {
    const cookie = localStorage.getItem('afsCookieStatus');
    if (cookie) {
      this.essential = cookie.indexOf('essential:false') === -1;
      this.statistics = cookie.indexOf('statistics:false') === -1;
    }
  }
}
