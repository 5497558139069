



















import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';
import { isEmpty, forEach } from 'lodash';
import vSelect from 'vue-select';
import { Validator } from 'vee-validate';
import { InputObject } from './inputTypes';
import { EventBus } from '@/main';
import { getIndustry } from '@/util/ioiUtils';

@Component({
  name: 'InputCompanyComponent',
  components: { vSelect },
})
export default class InputCompanyComponent extends Vue {
  @Prop({}) inputParam!: InputObject;
  @Inject('$validator') $validator!: Validator;

  eventBus = EventBus;
  vmodelValue: any | null = {};
  initialValue: any | null = '';
  inputField: InputObject = {
    id: '',
    name: '',
    ref: '',
    type: 'text',
    placeHolder: '',
    class: '',
    vRule: '',
    vLabel: '',
    disabled: false,
    optionList: [],
    containerClass: '',
    value: '',
    hideVLabel: false,
    isValid: null,
  };

  get inputName() {
    return isEmpty(this.inputField.name) ? '' : this.inputField.name;
  }

  get inputVRule() {
    if (this.inputField.vRule === null) {
      return '';
    }
    return this.inputField.vRule;
  }

  get vLabel() {
    if (
      isEmpty(this.inputField.vLabel) &&
      isEmpty(this.inputField.placeHolder)
    ) {
      return '';
    }
    return isEmpty(this.inputField.vLabel)
      ? this.inputPlaceHolder
      : this.inputField.vLabel;
  }

  get inputPlaceHolder(): string {
    return isEmpty(this.inputField.placeHolder)
      ? ' '
      : `${this.$t(this.inputField.placeHolder)}`;
  }

  get containerClass(): string[] {
    const classes: string[] = [];
    classes.push('form-select');
    if (this.isInputCorrect) {
      classes.push('select-correct');
    }
    if (this.inputHasError) {
      classes.push('select-has-error');
    }
    if (this.inputField.containerClass === 'select-dropdown-long') {
      classes.push('select-dropdown-long');
    }
    return classes;
  }

  get wrapperClass(): string[] {
    const classes: string[] = [];
    if (this.inputField.value === undefined || this.inputField.value === '') {
      classes.push('select-border-empty');
    }
    if (this.inputHasError) {
      classes.push('select-has-error-border');
    }
    if (this.isInputCorrect) {
      classes.push('select-correct-border');
    }
    return classes;
  }

  get showVLabel(): boolean {
    return (
      !this.inputField.hideVLabel &&
      this.$validator.errors.has(this.inputField.name)
    );
  }

  get optionList(): any {
    if (
      isEmpty(this.inputField.optionList) ||
      this.inputField.optionList === null ||
      this.inputField.optionList.length === 0
    ) {
      return [{ value: ' ', label: '' }];
    }
    const companyOptions: any = [];
    forEach(this.inputField.optionList, (company: any) => {
      companyOptions.push({
        value: company.id,
        label: company.name,
        industry: company.industryOrSector,
        countryCode: company.countryCode,
        countryName: company.countryName,
        currency: company.currencyType,
      });
    });
    return companyOptions;
  }

  get inputHasError(): boolean {
    return this.$validator.errors.has(this.inputField.name);
  }

  get isInputCorrect(): boolean {
    return (
      !isEmpty(this.vmodelValue) &&
      !this.$validator.errors.has(this.inputField.name)
    );
  }

  get inputTooltip(): any {
    if (this.inputField.defaultTooltip) {
      return { placement: 'bottom-center', content: this.inputPlaceHolder };
    }
    if (
      this.inputField.tooltip === undefined ||
      this.inputField.tooltip === null
    ) {
      return null;
    }

    const tooltipContent: string | null = `${this.$t(this.inputField.tooltip)}`;
    return tooltipContent === null
      ? null
      : { placement: 'bottom-center', content: tooltipContent };
  }

  inputChange() {
    if (
      this.vmodelValue === undefined ||
      this.vmodelValue === null ||
      this.vmodelValue.value.value === ''
    ) {
      const returnCompany: any = {
        name: this.inputField.name,
        value: '',
        label: '',
        industry: '',
        countryCode: '',
        currency: '',
      };
      this.$emit('setCompany', returnCompany);
    } else {
      const returnCompany: any = {
        name: this.inputField.name,
        value:
          this.vmodelValue.value === undefined ? '' : this.vmodelValue.value,
        label:
          this.vmodelValue.label === undefined ? '' : this.vmodelValue.label,
        industry:
          this.vmodelValue.industry === undefined
            ? ''
            : this.vmodelValue.industry,
        countryCode:
          this.vmodelValue.countryCode === undefined
            ? ''
            : this.vmodelValue.countryCode,
        countryName:
          this.vmodelValue.countryName === undefined
            ? ''
            : this.vmodelValue.countryName,
        currency:
          this.vmodelValue.currency === undefined
            ? ''
            : this.vmodelValue.currency,
      };
      this.$emit('setCompany', returnCompany);
    }
  }

  created() {
    this.inputField = Object.assign({}, this.inputParam);
    this.vmodelValue = this.inputParam.value;
    this.initialValue =
      this.vmodelValue === null || isEmpty(this.vmodelValue)
        ? ''
        : this.vmodelValue;
    this.eventBus.$on('reloadCompanyInput', (inputInformation: InputObject) => {
      if (inputInformation.name === this.inputParam.name) {
        this.vmodelValue = inputInformation.value;
        this.inputField = {
          id: inputInformation.id,
          name: inputInformation.name,
          ref: inputInformation.ref,
          value: this.vmodelValue,
          type: inputInformation.type,
          placeHolder: inputInformation.placeHolder,
          class: inputInformation.class,
          vRule: inputInformation.vRule,
          vLabel: inputInformation.vLabel,
          disabled: inputInformation.disabled,
          optionList: inputInformation.optionList,
          containerClass: inputInformation.containerClass,
          hideVLabel: inputInformation.hideVLabel,
          isValid: inputInformation.isValid,
          defaultTooltip: inputInformation.defaultTooltip,
          tooltip: inputInformation.tooltip,
        };
        if (
          inputInformation.value === undefined ||
          inputInformation.value === ''
        ) {
          this.vmodelValue = null;
        }
        this.initialValue = inputInformation.value;
      }
    });
  }
}
