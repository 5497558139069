'use strict';

import { getCurrentLanguage } from '@/translation/index';

export function currencyFormat(num: number, decimals: number) {
  // this function converts a number (which can have decimals too) into a formatted value
  // separates the decimals with a comma and the thousands with a dot
  // it assumes that the input will have a "." to separate the decimal points,
  // as javascript does by default
  // Example: currencyFormat(1234567.89)) => output 1.234.567,89
  if (num === undefined || num === null) {
    return 0;
  }
  return num
    .toFixed(decimals) // always as many decimals digits as required
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // use . as a separator
}

export function enterNumberLocale(value: any, decimals: number) {
  // this function converts a decimal string into the formatted value
  // the input string can have both "." or "," as a decimal separator
  // the first replacement is necessary to consider the "." case and CAN NOT be removed
  if (value === undefined || value === null) {
    return '';
  }
  const locale: string = getCurrentLanguage();
  return parseFloat(value).toLocaleString(locale);
}

export function formatNumberLocale(value: any, decimals: number) {
  // this function converts a decimal string into the formatted value
  // the input string can have both "." or "," as a decimal separator
  // the first replacement is necessary to consider the "." case and CAN NOT be removed
  if (value === undefined || value === null) {
    return '';
  }
  const locale: string = getCurrentLanguage();
  // return value.toLocaleString(locale);
  return parseFloat(value).toLocaleString(locale);
}

export function formatNumberLocaleMinMax(
  value: any,
  minDecimals: number = 0,
  maxDecimals: number = 4,
) {
  // this function converts a decimal string into the localized value with an amount of decimals
  if (value === undefined || value === null) {
    return '';
  }
  const locale: string = getCurrentLanguage();
  return value.toLocaleString(locale, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  });
}

export function formatNumberPHP(value: any, decimals: number): string {
  const locale: string = getCurrentLanguage();
  let unformatted: string = value.toString();
  if (value === undefined || value === null || value === '' || value === 0) {
    return '';
  }
  if (locale === 'en') {
    unformatted = unformatted.replace(',', '');
    // English locale uses "." as decimal separator and "," as grouping separator
    if (unformatted.includes('.')) {
      // there is a decimal separator
      return parseFloat(unformatted).toFixed(decimals);
    }
    return parseInt(unformatted.replace(/,/g, ''), 10).toString();
  }
  if (locale === 'nl') {
    // Dutch locale uses "," as decimal separator and "." as grouping separator
    if (unformatted.includes(',')) {
      // there is a decimal separator
      unformatted = unformatted.replace(',', '.');
      return parseFloat(unformatted).toFixed(decimals);
    }
    return parseInt(unformatted.replace(/\./g, ''), 10).toString();
  }
  return '';
}
