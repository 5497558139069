'use strict';

import api from './index';

export default {
  getInterestRatePage() {
    return api.get('/curve-snapshot');
  },

  submitInterestRatePage(filePayload: any) {
    return api.post('/curve-snapshot', filePayload);
  },
};
