














































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { forEach, startsWith, findIndex } from 'lodash';
import { EventBus } from '@/main';
import {
  CustomInput,
  InputCheckboxObject,
  InputFormatObject,
} from '@/components/inputs/inputTypes';
import { Offer } from '@/types/instrumentsTypes';
import IoiRow from '@/components/ioi/IOIRow.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import InputCheckboxComponent from '@/components/inputs/InputCheckboxComponent.vue';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import CollapsibleComponent from '@/components/utils/CollapsibleComponent.vue';
import confirm from '@/components/utils/Confirm.vue';
import { getInstrumentType, multipleInstruments } from '@/util/ioiUtils';
const tabState = namespace('tabState/');
const NotificationsNameSpace = namespace('notifications/');
const IOINameSpace = namespace('iois/');
const AuthenticationNameSpace = namespace('authentication/');
const ModalNameSpace = namespace('modals/');
@Component({
  name: 'AcceptOffers',
  components: {
    IoiRow,
    InputComponentFormat,
    InputCheckboxComponent,
    ButtonComponent,
    confirm,
    CollapsibleComponent,
  },
})
export default class AcceptOffers extends Vue {
  @Prop({}) offerInputInformation!: any;
  @Prop({ default: true }) showIoiRow!: boolean;

  @NotificationsNameSpace.Action('add') addNotification: any;
  @IOINameSpace.Action('acceptOffer') acceptOffer: any;
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  @tabState.Action('setTab') setTab: any;

  eventBus = EventBus;
  inputIOI: any = {};
  showModal: boolean = false;
  selectedOffersArray: any = [];
  offer: any = {};
  total: number = 0;
  maxOfferKey: number = 1;
  totalSizeKey: number = 1;
  currencySymbol: string = '';
  showConfirmAcceptModal: boolean = false;
  confirmAcceptData: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  get headings() {
    return {
      titleSelect: 'titleSelect',
      titleCoupon: 'titleCoupon',
      titleInstrument: 'titleInstrument',
      titleMinSize: 'titleMinSize',
      titleMaxSize: 'titleMaxSize',
      titleMySize: 'titleMySize',
      titleTotalSize: 'titleTotalSize',
    };
  }

  columns = [
    'titleSelect',
    'titleCoupon',
    'titleInstrument',
    'titleMinSize',
    'titleMaxSize',
    'titleMySize',
    'titleTotalSize',
  ];

  inputSelectedOffer(index: number): any {
    const offer: any =
      this.selectedOffersArray === undefined
        ? {}
        : this.selectedOffersArray[index];
    const selected: boolean =
      offer.mySize !== undefined &&
      offer.mySize !== null &&
      parseFloat(offer.mySize) !== 0;

    return {
      id: offer.offerId,
      name: `selectOffer_${offer.offerId}`,
      labelCode: '',
      containerClass: 'checkbox-item table-checkbox',
      static: false,
      disabled: false,
      value: selected,
    };
  }

  inputCoupon(index: number): InputFormatObject {
    const offer: any = this.selectedOffersArray[index];
    return {
      name: `coupon_${offer.offerId}`,
      placeHolder: '',
      class: 'form-tiny-inline-item',
      validationClasses: false,
      vRule: '',
      disabled: true,
      value: offer.couponPercentage,
      hideVLabel: true,
      precision: 3,
      showCurrency: false,
      showNegative: true,
    };
  }

  inputMinimumSize(index: number): InputFormatObject {
    const offer: any = this.selectedOffersArray[index];
    return {
      name: `minSize_${offer.offerId}`,
      placeHolder: '',
      class: 'form-tiny-inline-item',
      validationClasses: false,
      vRule: '',
      disabled: true,
      value: offer.minSize,
      hideVLabel: true,
      currencySymbol: this.currencySymbol,
      precision: 0,
    };
  }

  inputMaximumSize(index: number): InputFormatObject {
    const offer: any = this.selectedOffersArray[index];
    return {
      name: `maxSize_${offer.offerId}`,
      placeHolder: '',
      class: 'form-tiny-inline-item',
      validationClasses: false,
      vRule: '',
      disabled: true,
      value: offer.maxSize,
      hideVLabel: true,
      currencySymbol: this.currencySymbol,
      precision: 0,
    };
  }

  validateMySize(index: number): string {
    const offer: any =
      this.selectedOffersArray === undefined
        ? {}
        : this.selectedOffersArray[index];
    if (!offer.selected) {
      return '';
    }
    return (
      'required|isNumber' +
      `|min_value:${offer.minSize}` +
      `|max_value:${offer.maxSize}`
    );
  }

  inputMyOffer(offer: any, index: number): InputFormatObject {
    return {
      id: this.maxOfferKey,
      name: `mySize_${offer.offerId}`,
      placeHolder: '',
      class: 'form-tiny-inline-item',
      value: offer.mySize,
      vRule: this.validateMySize(index),
      vLabel: 'components.IOIOffer.OfferGrid.errorMySize',
      hideVLabel: true,
      currencySymbol: this.currencySymbol,
      precision: 0,
    };
  }

  get inputTotalSize(): InputFormatObject {
    return {
      name: 'totalSize',
      value: this.total.toString(),
      placeHolder: '',
      vRule: '',
      containerClass: 'ioi-offer-total-title',
      class: 'ioi-offer-input',
      hideVLabel: true,
      disabled: true,
      isValid: true,
      format: false,
      validationClasses: false,
      currencySymbol: this.currencySymbol,
      precision: 0,
    };
  }

  calculateTotal(): void {
    this.total = 0;
    forEach(this.selectedOffersArray, (offer: any) => {
      if (offer.selected) {
        this.total += offer.mySize;
      }
    });
    this.inputTotalSize.value = this.total.toString();
    this.totalSizeKey += 1;
  }

  // handle the input format components changes from the grid
  setInputValue(inputValue: CustomInput) {
    if (startsWith(inputValue.name, 'mySize_')) {
      const offerId: string | null = inputValue.name.slice(7);
      let offerSize;
      if (
        inputValue.value === undefined ||
        inputValue.value === null ||
        parseFloat(inputValue.value) === 0
      ) {
        offerSize = 0;
      } else {
        offerSize = parseFloat(inputValue.value);
      }

      const indexOffer: number = findIndex(
        this.selectedOffersArray,
        (iterOffer: Offer) => iterOffer.offerId.trim() === offerId.trim(),
      );
      if (indexOffer !== -1) {
        this.selectedOffersArray[indexOffer].mySize = offerSize;
        this.selectedOffersArray[indexOffer].selected = offerSize !== 0;
        this.eventBus.$emit(
          'reloadCheckboxInput',
          this.inputSelectedOffer(indexOffer),
        );
        this.calculateTotal();
      }
    }
  }

  setInputCheckbox(inputValue: InputCheckboxObject) {
    const name: string = inputValue.name === null ? '' : inputValue.name;
    if (startsWith(name, 'selectOffer')) {
      const offerId: string | null = name.slice(12);
      const indexOffer: number = findIndex(
        this.selectedOffersArray,
        (iterOffer: Offer) => iterOffer.offerId.trim() === offerId.trim(),
      );
      if (indexOffer !== -1) {
        this.maxOfferKey += 1;
        const selectedOffer: any = {};
        selectedOffer.offerKey = this.maxOfferKey;
        selectedOffer.offerId = this.selectedOffersArray[
          indexOffer
        ].offerId.trim();
        selectedOffer.couponPercentage = this.selectedOffersArray[
          indexOffer
        ].couponPercentage;
        selectedOffer.instrumentType = this.selectedOffersArray[
          indexOffer
        ].instrumentType;
        selectedOffer.instrumentLabel = this.selectedOffersArray[
          indexOffer
        ].instrumentLabel;
        selectedOffer.maxSize = this.selectedOffersArray[indexOffer].maxSize;
        selectedOffer.minSize = this.selectedOffersArray[indexOffer].minSize;
        selectedOffer.mySize = this.selectedOffersArray[indexOffer].mySize;
        selectedOffer.selected = inputValue.value;
        if (selectedOffer.selected) {
          selectedOffer.mySize = selectedOffer.maxSize;
        } else {
          selectedOffer.mySize = 0;
        }
        this.selectedOffersArray[indexOffer] = selectedOffer;
        this.inputMyOffer(selectedOffer, indexOffer);
      }
      this.calculateTotal();
    }
  }

  // Accept Offer events
  get acceptOfferButton(): any {
    return {
      containerClass: 'button-center',
      buttonClass: 'button-icon',
      description: `${this.$t('components.IOIOffer.acceptButton')}`,
      toolTip: `${this.$t('components.IOIOffer.acceptButton')}`,
      icon: 'currency-euro-blue.png',
      showExtended: false,
    };
  }

  acceptOfferConfirmation(): void {
    let count: number = 0;
    forEach(this.selectedOffersArray, (offer: any) => {
      if (offer.selected) {
        count += 1;
      }
    });
    if (count > 0) {
      this.showConfirmAcceptModal = true;
      this.confirmAcceptData.genericId = '';
      this.confirmAcceptData.title = 'components.IOIOffer.acceptOfferTitle';
      this.confirmAcceptData.body = 'components.IOIOffer.acceptOfferBody';
      this.confirmAcceptData.confirmButton = 'components.IOIOffer.acceptButton';
      this.confirmAcceptData.cancelButton = 'components.IOIOffer.cancelButton';
    } else {
      this.addNotification({
        message: `${this.$t('views.myLMAccount.selectOneOfferErrorMessage')}`,
        type: 'error',
      });
    }
  }

  acceptOfferCancellation(): void {
    this.showConfirmAcceptModal = false;
    this.confirmAcceptData.genericId = '';
    this.toggleModal('acceptOffer');
  }

  acceptOfferSubmit() {
    if (
      this.selectedOffersArray !== null &&
      this.selectedOffersArray.length > 0
    ) {
      const selectedOffers: string[][] = [];
      forEach(this.selectedOffersArray, (offer: any) => {
        if (offer.selected) {
          selectedOffers.push([offer.offerId, offer.mySize.toString()]);
        }
      });
      if (selectedOffers.length > 0) {
        // submit the offers
        this.acceptOffer({
          offers: selectedOffers,
          acceptedBy: this.getUserAccountId,
        })
          .then(() => {
            // here ioi should be rereshed/removed
            this.confirmAcceptData.genericId = '';
            this.showConfirmAcceptModal = false;
            this.$emit('onSubmitOffer');
            this.setTab({ key: 'myAccountIssuerTabs', value: 1 });
          })
          .catch(() => {
            this.$emit('onSubmitOffer');
          });
      } else {
        this.addNotification({
          message: `${this.$t('views.myLMAccount.selectOneOfferErrorMessage')}`,
          type: 'error',
        });
      }
    } else {
      this.addNotification({
        message: `${this.$t('views.myLMAccount.selectOneOfferErrorMessage')}`,
        type: 'error',
      });
    }
  }

  created() {
    this.inputIOI = {
      ...this.offerInputInformation,
      instrumentsList: multipleInstruments(
        this.offerInputInformation.instrumentTypes,
      ),
      state: '',
      ioiOffers: this.offerInputInformation.ioiOffers.map((offer: Offer) => ({
        ...offer,
        instrumentLabel: getInstrumentType(Number(offer.instrumentType)),
      })),
    };

    // TODO: this should change when the midswap merge request is merged to
    // use the currencySymbol method
    this.currencySymbol =
      this.inputIOI.currencyType === undefined ||
      this.inputIOI.currencyType === 'EUR'
        ? '€'
        : this.inputIOI.currencyType;
    this.selectedOffersArray = [];
    this.maxOfferKey = 1;
    for (let i = 0; i < this.inputIOI.ioiOffers.length; i += 1) {
      if (this.inputIOI.ioiOffers[i].couponPercentage === null) {
        this.inputIOI.ioiOffers[i].couponPercentage = '-';
      }
      if (this.inputIOI.ioiOffers[i].state === 'outstanding') {
        this.selectedOffersArray = [
          ...this.selectedOffersArray,
          {
            offerKey: this.maxOfferKey,
            offerId: this.inputIOI.ioiOffers[i].offerId.trim(),
            selected: false,
            couponPercentage: this.inputIOI.ioiOffers[i].couponPercentage,
            instrumentType: this.inputIOI.ioiOffers[i].instrumentType,
            instrumentLabel: getInstrumentType(
              this.inputIOI.ioiOffers[i].instrumentType,
            ),
            minSize: this.inputIOI.ioiOffers[i].minSize,
            maxSize: this.inputIOI.ioiOffers[i].maxSize,
            mySize: 0,
          },
        ];
      }

      this.maxOfferKey += 1;
    }
  }
}
