



















import { Component, Inject, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import zxcvbn from 'zxcvbn';
import { ValidationProvider } from 'vee-validate';
import InputComponent from './InputComponent.vue';
import { CustomInput, InputObject } from './inputTypes';

@Component({
  name: 'InputPasswordComponent',
  components: { InputComponent, ValidationProvider },
})
export default class InputPasswordComponent extends Vue {
  @Prop({}) inputParam: InputObject;

  strength: number = 0;

  calcStrength(inputValue: CustomInput) {
    const { value } = inputValue;
    const { score } = zxcvbn(value);
    this.strength = score;
  }

  forwardSendInput(inputValue: CustomInput) {
    this.$emit('sendInput', inputValue);
  }

  get strengthText(): string {
    return this.strengthTexts[this.strength];
  }

  get strengthTexts(): { [n: number]: string } {
    return {
      0: `${this.$t('components.inputPasswordComponent.veryWeak')}`,
      1: `${this.$t('components.inputPasswordComponent.weak')}`,
      2: `${this.$t('components.inputPasswordComponent.average')}`,
      3: `${this.$t('components.inputPasswordComponent.strong')}`,
      4: `${this.$t('components.inputPasswordComponent.veryStrong')}`,
    };
  }
}
