export default function(doc: any) {
  // Return the path depending on Prismic Document's type
  // If it is a Repeatable Custom Type with the API ID of "page" (must contain a UID field)
  if (doc.type === 'blog_page') {
    return '/blog/' + doc.uid;
  }

  // Default to the root
  return '/';
}
