




























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ModalWindow from '@/components/utils/Modal.vue';
import countriesJson from '@/assets/data/countries';
import { CustomInput } from '@/components/inputs/inputTypes';
import InputPhoneNumberComponent from '@/components/inputs/InputPhoneNumberComponent.vue';
import InputComponent from '@/components/inputs/InputComponent.vue';
import InputSelectComponent from '@/components/inputs/InputSelectComponent.vue';
import InputRadioComponent from '@/components/inputs/InputRadioComponent.vue';
import { industrySectorList } from '@/api/authenticationApi';
import router from '@/router';
import { signup } from '@/store/signup';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import InputSelect from '@/components/inputs/InputSelect.vue';
import api from '@/api';
import { createCompany, editCompany } from '@/api/companyApi';
import { CompanyItem } from '@/types/types';
import { getApiError } from '@/api/util';
const ModalNameSpace = namespace('modals/');
const SignupNameSpace = namespace('signup/');
const NotificationsNameSpace = namespace('notifications/');
const CompanyStore = namespace('companies/');
const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'AddEditCompany',
  components: {
    ModalWindow,
    InputPhoneNumberComponent,
    InputComponent,
    InputSelectComponent,
    InputRadioComponent,
    InputSelect,
  },
})
export default class AddEditCompany extends Vue {
  @Prop({ required: true }) mode: string;
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  @NotificationsNameSpace.Action('add') addNotification: any;
  @CompanyStore.Getter('selectedCompanyData')
  selectedCompanyData: CompanyItem[];

  @CompanyStore.Action('getCompanyList') getCompanyList: any;

  errorMessage: string = '';
  formOne: { [key: string]: any } = {
    companyName: {
      name: 'companyName',
      compType: 'InputComponent',
      placeHolder: 'components.signUpFullForm.companyName',
      vRule: 'required',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: '',
      marketviewerOnly: true,
    },
    industryOrSector: {
      compType: 'InputSelect',
      name: 'industryOrSector',
      placeHolder: 'components.signUpFullForm.industry',
      vRule: 'required',
      optionList: industrySectorList(),
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      value: undefined,
      formClass: 'input-container',
      marketviewerOnly: true,
      openDirection: 'bottom',
      translate: false,
    },
    street: {
      compType: 'InputComponent',
      name: 'street',
      placeHolder: 'components.signUpFullForm.street',
      vRule: 'required|alpha_spaces',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: '',
      formClass: 'three-fifth',
    },
    houseNumber: {
      compType: 'InputComponent',
      name: 'houseNumber',
      placeHolder: 'components.signUpFullForm.houseNumber',
      vRule: 'required|numeric',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: '',
      formClass: 'one-fifth',
    },
    houseNumberSuffix: {
      compType: 'InputComponent',
      name: 'houseNumberSuffix',
      placeHolder: 'components.signUpFullForm.numberSuffix',
      vRule: 'alpha_num',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: '',
      formClass: 'one-fifth',
    },
    postalCode: {
      compType: 'InputComponent',
      name: 'postalCode',
      placeHolder: 'components.signUpFullForm.postalCode',
      vRule: { required: true, regex: /^[\w\-\s]+$/ }, // regex => alpha_num + spaces
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: '',
      formClass: 'two-fifth',
    },
    city: {
      compType: 'InputComponent',
      name: 'city',
      placeHolder: 'components.signUpFullForm.city',
      vRule: 'required|alpha_spaces',
      containerClass: 'input-inner',
      class: 'form-input',
      showLabel: true,
      hideVLabel: true,
      value: '',
      formClass: 'three-fifth',
    },
    country: {
      compType: 'InputSelect',
      name: 'country',
      placeHolder: 'components.signUpFullForm.country',
      vRule: 'required',
      containerClass: 'input-inner',
      class: 'form-input',
      optionList: countriesJson.countries,
      value: undefined,
      showLabel: true,
      formClass: 'input-container',
      openDirection: 'top',
    },
  };

  setInputValue(inputValue: CustomInput) {
    // check if validation available
    if (this.$validator.errors) {
      // check if field has been validated else set empty
      if (!this.$validator.errors.has(inputValue.name)) {
        if (inputValue.selectInput) {
          this.formOne[inputValue.name].value = {
            value: inputValue.value,
            label: inputValue.label,
          };
        } else {
          this.formOne[inputValue.name].value = inputValue.value;
        }
      }
    }
  }

  validate() {
    const promises = [];
    for (let i = 0; i < Object.keys(this.formOne).length; i += 1) {
      promises.push(this.$validator.validate(Object.keys(this.formOne)[i]));
    }
    return Promise.all(promises);
  }

  createCompany() {
    const data: any = {};
    this.errorMessage = '';

    this.validate().then(result => {
      if (!result.includes(false)) {
        Object.keys(this.formOne).forEach((key: any) => {
          data[this.formOne[key].name] = this.formOne[key].value;
        });
        data.userAccountId = this.getUserAccountId;
        data.houseNumber = Number(data.houseNumber);
        data.industryOrSector = data.industryOrSector.value;
        data.countryCode = data.country.value;
        createCompany(data)
          .then(() => {
            this.getCompanyList('ham');
            this.addNotification({
              message: this.$t('components.addEditCompany.succesAdd'),
              type: 'success',
            });
            this.toggleModal('addEditCompany');
          })
          .catch(error => {
            this.errorMessage = error.response.data.message;
            this.addNotification({
              message: `${this.$t('components.addEditCompany.errorAdd')}`,
              type: 'error',
            });
          });
      }
    });
  }

  editCompany() {
    const data: any = { ...this.selectedCompanyData };
    Object.keys(this.formOne).forEach((key: string) => {
      data[this.formOne[key].name] = this.formOne[key].value;
    });

    this.validate().then(result => {
      if (!result.includes(false)) {
        // format for api
        data.houseNumber = Number(data.houseNumber);
        data.industryOrSector = data.industryOrSector.value;
        data.countryCode = data.country.value;
        editCompany(data)
          .then(() => {
            this.getCompanyList('ham').then(() => {
              this.toggleModal('addEditCompany');
              this.addNotification({
                message: this.$t('components.addEditCompany.succesEdit'),
                type: 'success',
              });
            });
          })
          .catch(() => {
            this.addNotification({
              message: this.$t('components.addEditCompany.errorEdit'),
              type: 'error',
            });
          });
      }
    });
  }

  created() {
    if (this.mode === 'edit') {
      Object.keys(this.formOne).forEach((key: any) => {
        this.formOne[key].value = this.selectedCompanyData[key];
      });
    }
  }
}
