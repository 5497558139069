'use strict';

import moment from 'moment';

export interface DateDto {
  date: string;
  time: string;
}

export function daysBetween(referenceDate: string): string {
  const now = moment();
  const exp = moment(referenceDate);
  if (now > exp) {
    return '0';
  }
  const days: number = exp.diff(now, 'days') + 1;
  return days.toString();
}

export function hoursToExpire(referenceDate: string): number {
  const expirationDate: string = `${referenceDate}${process.env.VUE_APP_EXPIRED}`;
  const now = moment();
  const exp = moment(expirationDate);
  const days: number = exp.diff(now, 'days');
  const hours: number = exp.subtract(days, 'days').diff(now, 'hours');
  let minutes: number = exp.subtract(hours, 'hours').diff(now, 'minutes');
  if (hours > 0) {
    minutes = hours * 60 + minutes;
  }
  return minutes;
}

export function addMinutes(
  referenceDate: string,
  referenceTime: string,
  minutes: number,
): DateDto {
  const referenceDateTime: string = `${referenceDate}T${referenceTime}`;
  const newDateTime = moment(referenceDateTime).add(minutes, 'minutes');
  return {
    date: newDateTime.format('YYYY-MM-DD'),
    time: newDateTime.format('HH:mm:ss'),
  };
}
