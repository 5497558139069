



















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ModalWindow from '@/components/utils/Modal.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';

const ModalNameSpace = namespace('modals/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'TokenExpired',
  components: { ButtonComponent, ModalWindow },
})
export default class TokenExpired extends Vue {
  @AuthenticationNameSpace.Getter('checkUserLoggedIn')
  checkUserLoggedIn: boolean;

  @ModalNameSpace.Action('toggleModal') toggleModal: any;

  navigateLogin() {
    this.$router.push({ name: 'login' });
    this.toggleModal('tokenExpired');
  }

  mounted() {
    // check if token truly expired, then activate modal
    this.toggleModal('tokenExpired');
  }
}
