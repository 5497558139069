import api from './index';

export function getUserAccountHistoryLog(payload: { userAccountId: string }) {
  return api.get('/logging/user');
}

export function getCompanyHistoryLog(payload: { companyId: string }) {
  return api.get(`/logging/company/${payload.companyId}`);
}

export function getIOIHistoryLog(payload: { ioiId: string }) {
  return api.get(`/logging/ioi/${payload.ioiId}`);
}
