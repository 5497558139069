

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Account } from '@/types/accountTypes';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import BaseMailTo from '@/components/utils/BaseMailTo.vue';
import { EventBus } from '@/main';

@Component({
  name: 'AccountRow',
  components: { ButtonComponent, BaseMailTo },
})
export default class AccountRow extends Vue {
  @Prop({}) accountInput!: Account;

  eventBus = EventBus;
  showConfirmUpdateModal: boolean = false;
  showAccountFormModal: any = { showModal: false, account: {} };
  viewButton: any = {
    containerClass: 'ioi-row-inline-button',
    buttonClass: 'button-icon button-icon-resize',
    description: 'View Account',
    extendedDescription: 'Display Account Full Information',
    icon: 'edit-blue.png',
    showExtended: false,
  };

  get fullName(): string {
    let name: string = this.accountInput.firstName.trim();
    if (this.accountInput.middleName) {
      name = `${name} ${this.accountInput.middleName}`;
    }
    name = `${name} ${this.accountInput.lastName} `;

    return name;
  }

  // View Account events
  get viewButtonObject(): any {
    return this.viewButton;
  }

  viewAccount(account: Account): void {
    this.showAccountFormModal.showModal = true;
    this.showAccountFormModal.account = account;
    this.eventBus.$emit('reloadViewAccountForm', this.showAccountFormModal);
  }
}
