export default {
  countries: [
    {
      value: 'NL',
      label: 'countries.nl',
      name: 'Netherlands',
      currencyType: 'EUR',
    },
    {
      value: 'AD',
      label: 'countries.ad',
      name: 'Andorra',
      currencyType: 'EUR',
    },
    {
      value: 'AT',
      label: 'countries.at',
      name: 'Austria',
      currencyType: 'EUR',
    },
    {
      value: 'BE',
      label: 'countries.be',
      name: 'Belgium',
      currencyType: 'EUR',
    },
    {
      value: 'DK',
      label: 'countries.dk',
      name: 'Denmark',
      currencyType: 'DKK',
    },
    {
      value: 'FI',
      label: 'countries.fi',
      name: 'Finland',
      currencyType: 'EUR',
    },
    {
      value: 'FR',
      label: 'countries.fr',
      name: 'France',
      currencyType: 'EUR',
    },
    {
      value: 'DE',
      label: 'countries.de',
      name: 'Germany',
      currencyType: 'EUR',
    },
    {
      value: 'GR',
      label: 'countries.gr',
      name: 'Greece',
      currencyType: 'EUR',
    },
    {
      value: 'IS',
      label: 'countries.is',
      name: 'Iceland',
      currencyType: 'ISK',
    },
    {
      value: 'IE',
      label: 'countries.ie',
      name: 'Ireland',
      currencyType: 'EUR',
    },
    {
      value: 'IT',
      label: 'countries.it',
      name: 'Italy',
      currencyType: 'EUR',
    },
    {
      value: 'LI',
      label: 'countries.li',
      name: 'Liechtenstein',
      currencyType: 'CHF',
    },
    {
      value: 'LU',
      label: 'countries.lu',
      name: 'Luxembourg',
      currencyType: 'EUR',
    },
    {
      value: 'MT',
      label: 'countries.mt',
      name: 'Malta',
      currencyType: 'EUR',
    },
    {
      value: 'MC',
      label: 'countries.mc',
      name: 'Monaco',
      currencyType: 'EUR',
    },
    {
      value: 'NO',
      label: 'countries.no',
      name: 'Norway',
      currencyType: 'NOK',
    },
    {
      value: 'PT',
      label: 'countries.pt',
      name: 'Portugal',
      currencyType: 'EUR',
    },
    {
      value: 'SM',
      label: 'countries.sm',
      name: 'San Marino',
      currencyType: 'EUR',
    },
    {
      value: 'ES',
      label: 'countries.es',
      name: 'Spain',
      currencyType: 'EUR',
    },
    {
      value: 'SE',
      label: 'countries.se',
      name: 'Sweden',
      currencyType: 'SEK',
    },
    {
      value: 'CH',
      label: 'countries.ch',
      name: 'Switzerland',
      currencyType: 'CHF',
    },
    {
      value: 'TR',
      label: 'countries.tr',
      name: 'Turkey',
      currencyType: 'TRY',
    },
    {
      value: 'GB',
      label: 'countries.gb',
      name: 'United Kingdom',
      currencyType: 'GBP',
    },
  ],
};
