













































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IOI } from '@/types/instrumentsTypes';
import {
  isDutchStateIndustry,
  getBucketSize,
  getMaturityBucket,
  buildGridFullFirstLine,
  buildGridFullSecondLine,
  getIndustry,
  getRankingGuarantee,
  createMaturityString,
} from '@/util/ioiUtils';
import ShareEmail from '@/components/ioi/ShareEmail.vue';
import CountryFlag from '../utils/CountryFlag.vue';
import InputComponentFormat from '@/components/inputs/InputComponentFormat.vue';
import { InputFormatObject } from '@/components/inputs/inputTypes';
import { EventBus } from '@/main';
import sentry from '@/util/sentry';

const AuthenticationNameSpace = namespace('authentication/');
const IOINameSpace = namespace('iois/');

@Component({
  name: 'IOIRow',
  components: {
    ShareEmail,
    CountryFlag,
    InputComponentFormat,
  },
})
export default class IOIRow extends Vue {
  @Prop({}) myAccount: string;
  @Prop({}) ioiInput!: IOI;
  @Prop({}) anonymizedData: boolean;
  @Prop({}) showDays: boolean;
  @Prop({}) extended: boolean;
  @Prop({}) containerClass: string;
  @Prop({}) showFavoriteParam: boolean;

  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  // IOI store
  @IOINameSpace.Action('addToWishList') addToWishList: any;
  @IOINameSpace.Action('removeFromWishList') removeFromWishList: any;
  @IOINameSpace.Action('toggleHighlightIOI') toggleHighlightIOI: any;

  eventBus = EventBus;
  favoriteIOI: boolean = this.ioiInput.isFavorite || false;
  createMaturityString = createMaturityString;

  get showExpiringDays(): boolean {
    return this.showDays && this.ioiInput.alertExpired;
  }

  get ioiGridClass(): string {
    if (this.containerClass !== undefined) {
      return this.containerClass;
    }
    if (this.extended) {
      return 'ioi-row-grid-extended';
    }
    return 'ioi-row-grid';
  }

  get informationClass(): any {
    return {
      'ioi-highlight': this.ioiInput.highlight,
      'ioi-row-information-container': true,
      'ioi-expiring-information':
        this.ioiInput.alertExpired &&
        (this.ioiInput.state === 'open' ||
          this.ioiInput.state === 'closing' ||
          this.ioiInput.state === 'closed'),
    };
  }

  get industryOrSectorTitle(): string {
    return getIndustry(this.ioiInput.industryOrSector);
  }

  get anonymizedTitle(): string {
    return `${getRankingGuarantee(this.ioiInput.rankingGuarantee)} | ${
      this.ioiInput.instrumentTypeLabel
    }`;
  }

  get anonymizedAmountTitle(): string {
    const { bucketSize, currencyType, maturityBucket } = this.ioiInput;
    return `${getBucketSize(bucketSize)} ${currencyType} - ${getMaturityBucket(
      maturityBucket,
    )}`;
  }

  get fullTitle(): string {
    const {
      industryOrSector,
      rankingGuarantee,
      instrumentTypeLabel,
    } = this.ioiInput;
    if (isDutchStateIndustry(this.ioiInput.industryOrSector)) {
      return `(${getRankingGuarantee(
        rankingGuarantee,
      )} | ${instrumentTypeLabel})`;
    }
    return `(${getIndustry(industryOrSector)}) ${getRankingGuarantee(
      rankingGuarantee,
    )} | ${instrumentTypeLabel}`;
  }

  get inputSize(): any {
    return {
      id: 'issueNominalSize_',
      name: 'issueNominalSize',
      value: this.ioiInput.issueNominalSize,
      placeHolder: '',
      containerClass: '',
      vRule: '',
      precision: 0,
      validationClasses: false,
      disabled: true,
    };
  }

  // old way of displaying maturity
  get fullAmountTitle(): string {
    const { issueMaturity } = this.ioiInput;
    return ` - ${issueMaturity} ${this.$t('components.IOIRow.years')}`;
  }

  get buildFirstLine(): string[] {
    return buildGridFullFirstLine(this.ioiInput).filter(
      string => string !== '',
    );
  }

  get buildSecondLine(): string[] {
    return buildGridFullSecondLine(this.ioiInput).filter(
      string => string !== '',
    );
  }

  get inputTooltip(): any {
    const tooltip: string = this.ioiInput.isFavorite
      ? `${this.$t('components.IOIRow.removeFromMyFavorites')}`
      : `${this.$t('components.IOIRow.saveToMyFavorites')}`;
    return { placement: 'top-left', content: tooltip };
  }

  get showFavorite(): boolean {
    return (
      this.showFavoriteParam &&
      this.isInvestor &&
      this.ioiInput.state === 'open' &&
      this.ioiInput.ioiOffers.length === 0
    );
  }

  get favoriteImage(): string {
    return this.ioiInput.isFavorite
      ? '/images/star.png'
      : '/images/star-outline.png';
  }

  toggleFavorite() {
    if (this.favoriteIOI) {
      this.removeFromWishList({
        userAccountId: this.myAccount,
        indicationOfInterestId: this.ioiInput.indicationOfInterestId,
      }).then(() => {
        this.favoriteIOI = false;
      });
    } else {
      this.addToWishList({
        userAccountId: this.myAccount,
        indicationOfInterestId: this.ioiInput.indicationOfInterestId,
        ioi: this.ioiInput,
      })
        .then((ioi: IOI) => {
          this.favoriteIOI = true;
        })
        .catch((error: any) => {
          sentry(error);
        });
    }
    this.favoriteIOI = !this.favoriteIOI;
  }

  setIoINumber() {
    this.$emit('setIoINumber');
  }

  removeHighlight() {
    if (this.ioiInput.highlight) {
      this.toggleHighlightIOI(this.ioiInput.indicationOfInterestId);
    }
  }
}
