































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import InputComponent from '@/components/inputs/InputComponent.vue';
import { CustomInput } from '@/components/inputs/inputTypes';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import { EventBus } from '@/main';
import sentry from '@/util/sentry';

const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'ForgotPasswordForm',
  components: { InputComponent, ButtonComponent },
})
export default class ForgotPasswordForm extends Vue {
  @NotificationsNameSpace.Action('add') addNotification: any;
  @AuthenticationNameSpace.Action('requestUserPasswordReset')
  requestUserPasswordReset: any;

  email: string | null = '';

  setInputValue(inputValue: CustomInput) {
    if (inputValue.name === 'email') {
      this.email = inputValue.value;
    }
  }

  get resetPasswordButton(): any {
    return {
      containerClass: 'button-end',
      buttonClass: 'button-icon button-icon-large',
      description: `${this.$t(
        'components.forgotPasswordForm.resetPasswordButton',
      )}`,
      toolTip: `${this.$t(
        'components.forgotPasswordForm.resetPasswordButton',
      )}`,
      showExtended: false,
    };
  }

  validateForm() {
    this.$validator
      .validateAll()
      .then(result => {
        if (!result) {
          const validationError: string = `${this.$t(
            'components.forgotPasswordForm.validationError',
          )}`;
          this.addNotification({
            id: 0,
            message: `${validationError}`,
            type: 'error',
          });
        } else {
          this.submitRequestPasswordReset();
        }
      })
      .catch((error: any) => {
        const forgotPasswordError: string = `${this.$t(
          'components.forgotPasswordForm.forgotPasswordError',
        )}`;
        this.addNotification({
          id: 0,
          message: `${forgotPasswordError} ${error}`,
          type: 'error',
        });
        sentry(error);
      });
  }

  submitRequestPasswordReset() {
    const forgotPasswordError: string = `${this.$t(
      'components.forgotPasswordForm.forgotPasswordError',
    )}`;
    this.requestUserPasswordReset({ email: this.email })
      .then(() => {
        const successLoginMessage: string = `${this.$t(
          'components.forgotPasswordForm.successForgetPasswordMessage',
          { user: this.email },
        )}`;
        this.addNotification({
          id: 0,
          message: ` ${successLoginMessage}`,
          type: 'success',
        });
        EventBus.$emit('reloadHome', '');
      })
      .catch((error: any) => {
        this.addNotification({
          id: 0,
          message: `${forgotPasswordError} ${error}`,
          type: 'error',
        });
        this.$router.push({ name: 'login' });
      });
  }
}
