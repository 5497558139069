

















































import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import ModalWindow from '@/components/utils/Modal.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import LoadingIndicator from '@/components/utils/LoadingIndicator.vue';
import { getApiError } from '@/api/util';
import { EventBus } from '@/main';

const DealNameSpace = namespace('deals/');
const IOINameSpace = namespace('iois/');
const ModalNameSpace = namespace('modals/');
const AuthenticationNameSpace = namespace('authentication/');
const CompanyStore = namespace('companies/');
@Component({
  name: 'EmailInputs',
  components: { LoadingIndicator, ButtonComponent, ModalWindow },
})
export default class EmailInputs extends Vue {
  @DealNameSpace.Action('getDeal') getDeal: any;
  @IOINameSpace.Action('sendIOIEmails') sendIOIEmails: any;
  @AuthenticationNameSpace.Action('inviteUsers') inviteUsers: any;
  @ModalNameSpace.Action('toggleModal') toggleModal: any;
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @CompanyStore.Getter('selectedCompanyIdGetter')
  selectedCompanyIdGetter: any;

  @Prop({ required: false }) id!: string;
  @Prop({ required: true }) endPoint!: string;
  @Prop({ required: true }) sendButtonTitle!: string;
  eventBus = EventBus;
  emailInput: string = '';
  sendingMail: boolean = false;
  emailApiMessageShow: boolean = false;
  emailApiMessage: string = '';
  emailApiMessageClass: string = 'succes-message';

  get emailInputConverted() {
    // split and remove spaces
    let emails = this.emailInput
      .split(',')
      .map(email => email.replace(/\s/g, ''));
    // filter < signs
    emails = emails.map(email => {
      if (email.includes('<')) {
        return email.substring(
          email.lastIndexOf('>'),
          email.lastIndexOf('<') + 1,
        );
      }
      return email;
    });
    // email validation
    emails = emails.filter(mail => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail));
    // double email check
    return emails.filter((item, index) => emails.indexOf(item) >= index);
  }

  sendEmails() {
    if (this.emailInputConverted.length > 0) {
      if (this.endPoint === 'shareIoI') {
        this.sendIOIEmails({
          indicationOfInterestId: this.id,
          emailAddresses: this.emailInputConverted,
        }).then(() => {
          this.toggleModal('issuerEmailIoi');
        });
      }
      if (this.endPoint === 'invite') {
        this.emailApiMessageShow = false;
        this.sendingMail = true;
        this.inviteUsers({
          userAccountId: this.getUserAccountId,
          companyId: this.selectedCompanyIdGetter,
          emailAddresses: this.emailInputConverted,
        })
          .then(() => {
            this.emailInput = '';
            this.emailApiMessage = this.$t(
              'components.emailInputs.emailsSent',
            ).toString();
            this.emailApiMessageClass = 'succes-message';
            this.emailApiMessageShow = true;
            this.sendingMail = false;
            this.eventBus.$emit('reloadCompanyUsers');
          })
          .catch((error: any) => {
            this.emailApiMessageShow = true;
            this.emailApiMessageClass = 'error-message';
            this.emailApiMessage = `${getApiError(error)}`;
            this.sendingMail = false;
          });
      }
    }
  }
}
