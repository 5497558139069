









































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { forEach } from 'lodash';
import { EventBus } from '@/main';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import IoiRow from '@/components/ioi/IOIRow.vue';
import PdfViewer from '@/components/utils/PdfViewer.vue';
import { getInstrumentType } from '@/util/ioiUtils';
import { formatNumberLocale } from '@/util/digits';
import sentry from '@/util/sentry';
import LoadingIndicator from '@/components/utils/LoadingIndicator.vue';

const NotificationsNameSpace = namespace('notifications/');
const IOINameSpace = namespace('iois/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'ViewDeals',
  components: {
    LoadingIndicator,
    ButtonComponent,
    IoiRow,
    PdfViewer,
  },
})
export default class ViewDeals extends Vue {
  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;
  // IOI store
  @IOINameSpace.Action('getIOIDeals') getIOIDeals: any;
  // Authentication
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;

  @Prop({}) showInvestor!: boolean;
  @Prop({}) inputData!: any;
  @Prop({ default: true }) showIoiRow!: boolean;

  showModal: boolean = true;
  ioiId: string = '';
  deals: any = [];
  gettingDeals: boolean = true;
  brokerageAFS: string = '';
  brokerageAFSTitle: string = '';
  effectiveAllInYield: string = '';
  showCommissionYield: boolean = false;
  getInstrumentType = getInstrumentType;

  get columns() {
    return [
      'tradeConfirmationFile',
      'investorCompanyName',
      'issuerCompanyName',
      'coupon',
      'instrumentType',
      'acceptedIssueSize',
      'effectiveAllInYield',
      'brokerageAFSWithFormat',
    ];
  }

  get showCommission(): boolean {
    return this.isIssuer || this.isLeadManager;
  }

  get options() {
    return {
      filterable: false,
      headings: {
        tradeConfirmationFile: this.$t(
          'components.IOIViewOffers.OfferGrid.tradeConfirmationTitle',
        ),
        investorCompanyName: this.$t(
          'components.IOIViewOffers.OfferGrid.investorTitle',
        ),
        issuerCompanyName: this.$t(
          'components.IOIViewOffers.OfferGrid.issuerTitle',
        ),
        coupon: this.$t('components.IOIViewOffers.OfferGrid.couponTitle'),
        instrumentType: this.$t(
          'components.IOIOffer.OfferGrid.titleInstrument',
        ),
        acceptedIssueSize: this.$t(
          'components.IOIViewOffers.OfferGrid.sizeTitle',
        ),
        effectiveAllInYield: this.$t(
          'components.IOIViewOffers.OfferGrid.effectiveAllInYieldTitle',
        ),
        brokerageAFSWithFormat: this.$t(
          'components.IOIViewOffers.OfferGrid.commissionTitle',
        ),
      },
      columnsClasses: {
        tradeConfirmationFile: 'cell-wide',
        instrumentType: 'cell-wide',
      },
      uniqueId: 'offerId',
      sortable: [],
      pagination: {
        chunk: 15,
      },
      texts: {
        noResults: !this.gettingDeals
          ? this.$t('components.IOIViewOffers.noTransactions')
          : '',
      },
      hiddenColumns: [
        !this.showInvestor ? '' : 'investorCompanyName',
        !this.showInvestor ? '' : 'issuerCompanyName',
        this.showCommission ? '' : 'effectiveAllInYield',
        this.showCommission ? '' : 'brokerageAFSWithFormat',
      ],
    };
  }

  onCancelOffer(): void {
    this.$emit('onCloseViewDeals');
  }

  created() {
    this.showCommissionYield = false;
    this.gettingDeals = true;
    this.getIOIDeals(this.inputData.indicationOfInterestId)
      .then((tc: any) => {
        this.deals = tc.data;
        this.gettingDeals = false;
        this.brokerageAFS = '';
        let total: number = 0;
        this.deals = tc.data.map((deal: any) => ({
          id: deal.id,
          issuerCompanyName: deal.issuerCompanyName,
          investorCompanyName: deal.investorCompanyName,
          coupon: `${deal.coupon} %`,
          instrumentType: getInstrumentType(deal.instrumentType),
          acceptedIssueSize: formatNumberLocale(deal.acceptedIssueSize, 0),
          effectiveAllInYield: deal.effectiveAllInYield
            ? `${deal.effectiveAllInYield} %`
            : '',
          brokerageAFSWithFormat: formatNumberLocale(deal.brokerageAFS, 3),
          brokerageAFS: deal.brokerageAFS,
          tradeConfirmationFile:
            this.isIssuer || this.isLeadManager
              ? deal.tradeConfirmationIssuer
              : deal.tradeConfirmationInvestor,
        }));
        forEach(this.deals, (deal: any) => {
          if (deal.brokerageAFS !== null) {
            total += deal.brokerageAFS;
          }
        });

        if ((this.isIssuer || this.isLeadManager) && total > 0) {
          this.brokerageAFSTitle = `${this.$t(
            'components.IOIOffer.commissionTCTitle',
          )}`;
          this.brokerageAFS = `${formatNumberLocale(total, 3)}`;
          this.showCommissionYield = true;
        }
      })
      .catch((error: any) => {
        this.deals = [];
        this.gettingDeals = false;
        sentry(error);
      });
  }
}
