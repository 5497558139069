


















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IOI } from '@/types/instrumentsTypes';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import { ButtonTypes } from '@/types/enums';
import Confirm from '@/components/utils/Confirm.vue';
import { createIOIOverviewPDF } from '@/api/pdf';
import { EventBus } from '@/main';
import { Permission } from '@/types/types';
import { permAvailable } from '@/util/generalUtils';
const AuthenticationNameSpace = namespace('authentication/');
const IOINameSpace = namespace('iois/');
const CompanyStore = namespace('companies/');
@Component({
  name: 'IoiButtons',
  components: {
    Confirm,
    ButtonComponent,
  },
})
export default class IoiButtons extends Vue {
  @Prop({}) ioi!: IOI;
  @IOINameSpace.Action('getIOIs') getIOIs: any;
  @IOINameSpace.Action('withdrawIOI') withdrawIOI: any;
  @IOINameSpace.Action('acceptOffer') acceptOffer: any;
  @IOINameSpace.Action('copyIOI') copyIOI: any;
  @IOINameSpace.Action('requestIndication') sendRequestIndication: any;
  @IOINameSpace.Action('deleteIOI') deleteIOI: any;
  @IOINameSpace.Action('sendToMarketIOI') sendToMarketIOI: any;
  @IOINameSpace.Action('withdrawOffer') withdrawOffer: any;
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @CompanyStore.Getter('companyCantVote') companyCantVote: boolean;
  permAvailable = permAvailable;
  Permission = Permission;
  eventBus = EventBus;
  showButtonModal: boolean = false;
  buttonModalParam: any = {
    title: '',
    body: '',
    confirmButton: '',
    cancelButton: '',
    genericId: '',
  };

  pdfPrintRequest: boolean = false;
  formattedIOI: any = '';

  // inputs
  get editIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button center-left',
      description: this.$t('views.myIssuerAccount.update'),
      extendedDescription: this.$t('views.myIssuerAccount.updateIOI'),
      icon: 'edit-blue.png',
      showExtended: true,
    };
  }

  get withdrawIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button center-left',
      description: this.$t('views.myIssuerAccount.withdraw'),
      extendedDescription: this.$t('views.myIssuerAccount.withdrawIOI'),
      icon: 'remove-blue.png',
      showExtended: true,
    };
  }

  get copyIOIButton() {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button center-left',
      description: `${this.$t('views.myIssuerAccount.copyIOIButton')}`,
      extendedDescription: `${this.$t('views.myIssuerAccount.copyIOIButton')}`,
      icon: 'content-copy.svg',
      showExtended: true,
    };
  }

  get deleteIOIButton() {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass: 'button-icon button-icon-grid full-width-button center-left',
      description: `${this.$t('views.myIssuerAccount.deleteIOIButton')}`,
      extendedDescription: `${this.$t(
        'views.myIssuerAccount.deleteIOIButton',
      )}`,
      icon: 'remove-blue.png',
      showExtended: true,
    };
  }

  get sendToMarketIOIButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass:
        'button-icon button-icon-grid button-icon-resize full-width-button center-left',
      description: this.$t('views.myIssuerAccount.sendToMarketButton'),
      extendedDescription: this.$t('views.myIssuerAccount.sendToMarketButton'),
      icon: 'send-to-market-blue.png',
      showExtended: true,
    };
  }

  get removeAllOffersButton(): any {
    return {
      containerClass: 'ioi-row-inline-button',
      buttonClass:
        'button-icon button-icon-grid button-icon-resize full-width-button center-left',
      description: `${this.$t(
        'views.myInvestorAccount.withdrawMultipleOfferButton',
      )}`,
      icon: 'clear.png',
      showExtended: false,
    };
  }

  created() {
    EventBus.$on('sendPdfData', (ioi: any) => {
      if (this.pdfPrintRequest) {
        this.pdfPrintRequest = false;
        this.formattedIOI = {
          accountId: this.getUserAccountId,
          indicationOfInterestId: ioi.indicationOfInterestId,
          companyName: ioi.issuerCompanyName,
          industryOrSector: ioi.industryOrSector,
          countryName: ioi.countryName,
          currencyType: ioi.currencyType,
          securityInformation: ioi.securityInformation,
          instrumentTypes: ioi.instrumentTypes,
          issueNominalSize: ioi.issueNominalSize,
          bucketSize: ioi.bucketSize,
          issueMaturity: ioi.issueMaturity,
          issueMaturityInDays: ioi.issueMaturityInDays,
          maturityBucket: ioi.maturityBucket,
          maturityDate: ioi.maturityDate,
          transactionDate: ioi.proposedTransactionDateStr,
          transactionTime: ioi.proposedTransactionTime,
          minimumPiece: ioi.minimumPiece,
          settlementDate: ioi.proposedSettlementDate,
          brokenCouponDate: ioi.brokenCouponDate,
          couponFrequency: ioi.couponFrequency,
          redemptionForm: ioi.redemptionForm,
          repaymentFrequency: ioi.repaymentFrequency,
          indicativeCoupon: ioi.indicativeCoupon
            ? ioi.indicativeCoupon.toString()
            : ioi.indicativeCoupon,
          spreadMinValue: ioi.spreadMinValue,
          spreadMaxValue: ioi.spreadMaxValue,
          spreadOverWhat: ioi.spreadOverWhat,
          guarantee: ioi.rankingGuarantee,
          rankingType: ioi.rankingType,
          type: ioi.indicativeType,
          documentationForm: ioi.documentationForm,
          dayCount: ioi.dayCount,
          additionalInformation: ioi.additionalInformation,
          ratingSP: ioi.creditRatingS,
          ratingF: ioi.creditRatingF,
          ratingM: ioi.creditRatingM,
          referenceNumber: ioi.referenceNumber,
        };
        createIOIOverviewPDF(this.formattedIOI);
      }
    });
  }

  removeAllOffersConfirmation() {
    this.showButtonModal = true;
    this.buttonModalParam.genericId = ButtonTypes.withdrawAllOffers;
    this.buttonModalParam.title =
      'views.myInvestorAccount.withdrawMultipleOfferTitle';
    this.buttonModalParam.body =
      'views.myInvestorAccount.withdrawMultipleOfferBody';
    this.buttonModalParam.confirmButton =
      'views.myInvestorAccount.withdrawMultipleOfferConfirmButton';
    this.buttonModalParam.cancelButton =
      'views.myInvestorAccount.withdrawMultipleOfferCancelButton';
  }

  sendToMarketIOIConfirmation(): void {
    const stringStart: string = 'views.myIssuerAccount';
    this.showButtonModal = true;
    this.buttonModalParam.genericId = ButtonTypes.sendToMarket;
    this.buttonModalParam.title = `${stringStart}.sendToMarketIOITitle`;
    this.buttonModalParam.body = `${stringStart}.sendToMarketIOIBody`;
    this.buttonModalParam.confirmButton = `${stringStart}.sendToMarketIOIConfirmButton`;
    this.buttonModalParam.cancelButton = `${stringStart}.sendToMarketIOICancelButton`;
  }

  onCancel() {
    this.showButtonModal = false;
  }

  // functions
  editIOIConfirmation(ioiId: string, cntOffers: number): void {
    if (cntOffers === 0) {
      this.editIOISubmit();
    } else {
      this.showButtonModal = true;
      this.buttonModalParam.genericId = ButtonTypes.edit;
      this.buttonModalParam.title = 'views.myIssuerAccount.updateIOITitle';
      this.buttonModalParam.body = 'views.myIssuerAccount.updateIOIBody';
      this.buttonModalParam.confirmButton =
        'views.myIssuerAccount.updateIOIConfirmButton';
      this.buttonModalParam.cancelButton =
        'views.myIssuerAccount.updateIOICancelButton';
    }
  }

  withdrawIOIConfirmation(): void {
    this.showButtonModal = true;
    this.buttonModalParam.genericId = ButtonTypes.withdraw;
    this.buttonModalParam.title = 'views.myIssuerAccount.withdrawIOITitle';
    this.buttonModalParam.body = 'views.myIssuerAccount.withdrawIOIBody';
    this.buttonModalParam.confirmButton =
      'views.myIssuerAccount.withdrawIOIConfirmButton';
    this.buttonModalParam.cancelButton =
      'views.myIssuerAccount.withdrawIOICancelButton';
  }

  deleteIOIConfirmation(): void {
    this.showButtonModal = true;
    this.buttonModalParam.genericId = ButtonTypes.delete;
    this.buttonModalParam.title = 'views.myIssuerAccount.deleteIOITitle';
    this.buttonModalParam.body = 'views.myIssuerAccount.deleteIOIBody';
    this.buttonModalParam.confirmButton =
      'views.myIssuerAccount.deleteIOIConfirmButton';
    this.buttonModalParam.cancelButton =
      'views.myIssuerAccount.deleteIOICancelButton';
  }

  async onConfirm(genericId: string) {
    switch (genericId) {
      case ButtonTypes.withdraw:
        this.withdrawIOI(this.ioi.indicationOfInterestId).then(() => {
          this.showButtonModal = false;
          this.$emit('onReloadIoi');
        });
        break;
      case ButtonTypes.edit:
        this.editIOISubmit();
        break;
      case ButtonTypes.delete:
        this.deleteIOI(this.ioi.indicationOfInterestId).then(() => {
          this.$emit('onReloadIoi');
        });
        this.showButtonModal = false;
        break;
      case ButtonTypes.sendToMarket:
        this.sendToMarketIOI(this.ioi.indicationOfInterestId).then(() => {
          this.$emit('onReloadIoi');
        });
        this.showButtonModal = false;
        break;
      case ButtonTypes.withdrawAllOffers:
        await Promise.all(
          this.ioi.ioiOffers.map(async offer => {
            this.withdrawOffer({ ioi: this.ioi, newOffer: offer });
          }),
        );
        setTimeout(() => this.$emit('onReloadIoi'), 500);
        this.showButtonModal = false;
        break;
      case ButtonTypes.requestIndication:
        this.sendRequestIndication({
          indicationOfInterestId: this.ioi.indicationOfInterestId,
        });
        break;
      default:
        break;
    }
  }

  editIOISubmit() {
    const ioiId: string = this.ioi.indicationOfInterestId;
    this.$router.push({ name: 'ioi', params: { ioiId } });
  }

  copyIOIDataSubmit() {
    this.copyIOI({
      indicationOfInterestId: this.ioi.indicationOfInterestId,
      state: this.ioi.state,
      userAccountId: this.getUserAccountId,
    });
  }

  printPDF() {
    this.pdfPrintRequest = true;
    // load formatted ioi data from ioi summary screen
    EventBus.$emit('getpdfdata', this.ioi.indicationOfInterestId);
  }

  requestIndication() {
    // display confirmation dialog.
    this.showButtonModal = true;
    this.buttonModalParam.genericId = ButtonTypes.requestIndication;
    this.buttonModalParam.title =
      'views.myIssuerAccount.requestIndicationFromAgentDialogTitle';
    this.buttonModalParam.body =
      'views.myIssuerAccount.requestIndicationFromAgentDialogBody';
    this.buttonModalParam.confirmButton =
      'views.myInvestorAccount.requestIndicationFromAgentConfirmButton';
    this.buttonModalParam.cancelButton =
      'views.myInvestorAccount.requestIndicationFromAgentCancelButton';
  }
}
