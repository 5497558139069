








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import LoadingIndicator from '@/components/utils/LoadingIndicator.vue';

@Component({
  name: 'StatusIndicator',
  components: { LoadingIndicator },
})
export default class StatusIndicator extends Vue {
  // options: tiny, small, medium, large
  @Prop({}) size!: string;
  // options: loading completed failed
  @Prop({}) status!: string;
}
