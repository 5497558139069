
























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { namespace } from 'vuex-class';
import {
  getCompanyHistoryLog,
  getUserAccountHistoryLog,
  getIOIHistoryLog,
} from '@/api/historyLog';
import { EventLogEntry } from '@/types/historyTypes';
import { HistoryLogType } from '@/types/enums.ts';
import _ from 'lodash';

const AuthenticationNameSpace = namespace('authentication/');
const CompanyStore = namespace('companies/');

@Component({
  name: 'HistoryLog',
})
export default class HistoryLog extends Vue {
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @CompanyStore.Getter('selectedCompanyIdGetter')
  selectedCompanyIdGetter: string;

  @Prop({ required: true }) logType: HistoryLogType;
  @Prop() ioiId: string;
  @Prop() orientation: string;

  eventLog: EventLogEntry[] = [];

  @Watch('selectedCompanyIdGetter')
  checkIfCompanyLog() {
    if (this.logType === HistoryLogType.COMPANY) {
      this.getLog();
    }
  }

  mounted() {
    this.getLog();
  }

  get log(): EventLogEntry[] {
    return this.eventLog;
  }

  getLog() {
    if (this.logType === HistoryLogType.USER) {
      getUserAccountHistoryLog({
        userAccountId: this.getUserAccountId,
      }).then(res => this.buildLog(res.data));
    } else if (this.logType === HistoryLogType.COMPANY) {
      const companyId: string = this.selectedCompanyIdGetter;
      if (companyId) {
        getCompanyHistoryLog({ companyId }).then(companyLogPromise =>
          this.buildLog(companyLogPromise.data),
        );
      }
    } else if (this.logType === HistoryLogType.IOI) {
      const ioiId: string = this.ioiId;

      getIOIHistoryLog({ ioiId }).then(ioiLogPromise =>
        this.buildLog(ioiLogPromise.data),
      );
    }
  }

  eventLogIsEmpty(): boolean {
    return 0 === this.eventLog.length;
  }

  private buildLog(data: any) {
    this.eventLog = [];
    data.forEach((entry: any) => {
      this.eventLog.push({
        key: HistoryLog.parseEvent(entry.key.split('\\').reverse()[0]),
        timestamp: HistoryLog.buildTimestamp(entry.timestamp),
      });
    });
  }

  private static parseEvent(eventKey: string): string {
    return _.capitalize(_.startCase(eventKey));
  }

  private static buildTimestamp(timestamp: number) {
    const date = moment(new Date(timestamp * 1000));

    return `${date.format('LL')} ${date.format('LTS')}`;
  }
}
