// @ts-ignore
const crypto = window.crypto || window.msCrypto;

function stringToUint8Array(string: string): Uint8Array {
  return new Uint8Array([...string].map(char => char.charCodeAt(0)));
}

function base64ToUint8Array(string: string): Uint8Array {
  const asciiString = atob(string);
  return new Uint8Array([...asciiString].map(char => char.charCodeAt(0)));
}

function uint8ArrayToString(array: Uint8Array): string {
  return String.fromCharCode(...array);
}

async function encrypt(data: string, key: CryptoKey): Promise<string> {
  const iv: Uint8Array = crypto.getRandomValues(new Uint8Array(16));
  const encryptedDataBuffer: ArrayBuffer = await crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv,
    },
    key,
    stringToUint8Array(data),
  );

  return (
    uint8ArrayToString(new Uint8Array(encryptedDataBuffer)) +
    uint8ArrayToString(iv)
  );
}

async function decrypt(data: string, key: CryptoKey): Promise<string> {
  const ivString: string = data.slice(-16);
  const encryptedData: string = data.slice(0, -16);

  if (ivString) {
    const iv: Uint8Array = stringToUint8Array(ivString);
    const dataBuffer: ArrayBuffer = await crypto.subtle.decrypt(
      {
        name: 'AES-CBC',
        iv,
      },
      key,
      stringToUint8Array(encryptedData),
    );

    return uint8ArrayToString(new Uint8Array(dataBuffer));
  }

  return '';
}

async function handleWithKey(
  data: string,
  key: string,
  call: (data: string, key: CryptoKey) => Promise<string>,
): Promise<string> {
  if (!key) {
    return data;
  }

  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    base64ToUint8Array(key),
    'AES-CBC',
    true,
    ['encrypt', 'decrypt'],
  );

  return call(data, cryptoKey);
}

export class EncryptedString {
  encryptedData: string;
  encrypted: boolean;
  key: string;

  constructor(encryptedData: string, encrypted: boolean, key: string) {
    this.encryptedData = encryptedData;
    this.encrypted = encrypted;
    this.key = key;
  }

  static async encryptString(
    data: string,
    key: string,
  ): Promise<EncryptedString> {
    let encryptedData = data;
    let encrypted = false;
    if (crypto.subtle) {
      encryptedData = await handleWithKey(data, key, encrypt);
      encrypted = true;
    }
    return new this(encryptedData, encrypted, key);
  }

  static fromString(data: string, key: string): EncryptedString {
    return new this(data, true, key);
  }

  decrypt(): Promise<string> {
    if (!this.encrypted || !crypto.subtle) {
      return new Promise(resolve => resolve(this.encryptedData));
    }
    return handleWithKey(this.encryptedData, this.key, decrypt);
  }

  encryptedString(): string {
    return this.encryptedData;
  }
}

export class EncryptedStorage {
  storage: Storage;
  key: string;

  constructor(storage: Storage, key: string) {
    this.storage = storage;
    this.key = key;
  }

  async store(storageKey: string, data: string): Promise<void> {
    const encryptedString: EncryptedString = await EncryptedString.encryptString(
      data,
      this.key,
    );
    this.storage.setItem(storageKey, encryptedString.encryptedString());
  }

  async retrieve(storageKey: string): Promise<string | null> {
    const storageItem: string | null = this.storage.getItem(storageKey);
    if (storageItem === null) {
      return null;
    }
    const encryptedString: EncryptedString = EncryptedString.fromString(
      storageItem,
      this.key,
    );
    return encryptedString.decrypt();
  }
}
