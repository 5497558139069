/* ***********************************************************
    Global variables for screen sizes and responsiveness
 *********************************************************** */

'use strict';

const desktopSizes = {
  mobile: 360,
  tablet: 720,
  small: 600,
  medium: 1336,
  mediumBig: 1600,
  large: 1920,
};

export default desktopSizes;
