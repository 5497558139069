





















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ExpandElement from '@/components/utils/ExpandElement.vue';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';

@Component({
  name: 'RoleColumns',
  components: {
    ExpandElement,
    ButtonComponent,
  },
})
export default class RoleColumns extends Vue {
  expanded: boolean = false;
  columns: object[] = [
    {
      role: 'components.roleColumns.marketViewer.role',
      subTitle: 'components.roleColumns.marketViewer.subTitle',
      href: './account/sign-up/marketviewer/1',
      usps: [
        {
          uspTitle: 'components.roleColumns.marketViewer.usps.oneTitle',
          uspText: 'components.roleColumns.marketViewer.usps.oneP',
        },
        {
          uspTitle: 'components.roleColumns.marketViewer.usps.twoTitle',
          uspText: 'components.roleColumns.marketViewer.usps.twoP',
        },
        {
          uspTitle: 'components.roleColumns.general.marketUpdatesTitle',
          uspText: 'components.roleColumns.marketViewer.usps.marketUpdatesP',
        },
      ],
    },
    {
      role: 'components.roleColumns.issuer.role',
      subTitle: 'components.roleColumns.issuer.subTitle',
      href: './account/sign-up/issuer/1',
      usps: [
        {
          uspTitle: 'components.roleColumns.issuer.usps.oneTitle',
          uspText: 'components.roleColumns.issuer.usps.oneP',
        },
        {
          uspTitle: 'components.roleColumns.issuer.usps.twoTitle',
          uspText: 'components.roleColumns.issuer.usps.twoP',
        },
        {
          uspTitle: 'components.roleColumns.issuer.usps.threeTitle',
          uspText: 'components.roleColumns.issuer.usps.threeP',
        },
        {
          uspTitle: 'components.roleColumns.issuer.usps.fourTitle',
          uspText: 'components.roleColumns.issuer.usps.fourP',
        },
        {
          uspTitle: 'components.roleColumns.general.marketUpdatesTitle',
          uspText: 'components.roleColumns.issuer.usps.marketUpdatesP',
        },
      ],
    },
    {
      role: 'components.roleColumns.investor.role',
      subTitle: 'components.roleColumns.investor.subTitle',
      href: './account/sign-up/investor/1',
      usps: [
        {
          uspTitle: 'components.roleColumns.investor.usps.oneTitle',
          uspText: 'components.roleColumns.investor.usps.oneP',
        },
        {
          uspTitle: 'components.roleColumns.investor.usps.twoTitle',
          uspText: 'components.roleColumns.investor.usps.twoP',
        },
        {
          uspTitle: 'components.roleColumns.investor.usps.threeTitle',
          uspText: 'components.roleColumns.investor.usps.threeP',
        },
        {
          uspTitle: 'components.roleColumns.investor.usps.fourTitle',
          uspText: 'components.roleColumns.investor.usps.fourP',
        },
        {
          uspTitle: 'components.roleColumns.general.marketUpdatesTitle',
          uspText: 'components.roleColumns.investor.usps.marketUpdatesP',
        },
      ],
    },
  ];
}
