








































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  PermissionInvestor,
  PermissionIssuer,
  roleObject,
} from '@/types/types';
import { permAvailable } from '@/util/generalUtils';
import { namespace } from 'vuex-class';
import StatusIndicator from '@/components/utils/statusIndicator.vue';
import { getRoles, setRoles, updateRoles, deleteRoles } from '@/api/companyApi';
const CompanyStore = namespace('companies/');
const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');
@Component({
  name: 'PermissionManager',
  components: {
    StatusIndicator,
  },
})
export default class PermissionManager extends Vue {
  @Prop({}) roles!: roleObject[];
  @CompanyStore.Getter('selectedCompanyIdGetter')
  selectedCompanyIdGetter: string;

  @NotificationsNameSpace.Action('add') addNotification: any;
  @AuthenticationNameSpace.Getter('isInvestor') isInvestor: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;

  alreadyRoleMessage: string = '';
  permAvailable = permAvailable;
  PermissionInvestor = PermissionInvestor;
  PermissionIssuer = PermissionIssuer;

  get activePermissionSet() {
    if (this.isIssuer) {
      return this.PermissionIssuer;
    }

    return this.PermissionInvestor;
  }

  createNewRoleCard() {
    this.alreadyRoleMessage = '';
    if (
      this.roles.filter((role: roleObject) => role.id === 'new').length === 0
    ) {
      const checkboxReadyPermissions: { [key: string]: boolean } = {};
      Object.keys(this.activePermissionSet).forEach(key => {
        checkboxReadyPermissions[key] = true;
      });

      this.roles.unshift({
        companyId: this.selectedCompanyIdGetter,
        id: 'new',
        name: 'New role',
        permissions: checkboxReadyPermissions,
      });
    } else {
      this.alreadyRoleMessage = 'components.companySettings.alreadyANewRole';
    }
  }

  setNewRole(role: roleObject) {
    setRoles({
      name: role.name,
      companyId: role.companyId,
      permissions: [
        ...Object.keys(
          this.filterObjects(
            role.permissions,
            ([roleName, allowed]: [string, boolean]) => allowed,
          ),
        ),
      ],
    }).then(() => {
      this.alreadyRoleMessage = '';
      this.$emit('getRoles');
    });
  }

  filterObjects(obj: {}, predicate: any) {
    return Object.fromEntries(Object.entries(obj).filter(predicate));
  }

  updateRoles(index: number) {
    const permissionArray = [];
    for (const [key, value] of Object.entries(this.roles[index].permissions)) {
      if (value) {
        permissionArray.push(key);
      }
    }
    Vue.set(this.roles, index, {
      ...this.roles[index],
      updateStatus: 'loading',
    });

    updateRoles({
      name: this.roles[index].name,
      companyId: this.selectedCompanyIdGetter,
      permissions: permissionArray,
      id: this.roles[index].id,
    })
      .then(() => {
        Vue.set(this.roles, index, {
          ...this.roles[index],
          updateStatus: 'completed',
        });
      })
      .catch(() => {
        Vue.set(this.roles, index, {
          ...this.roles[index],
          updateStatus: 'failed',
        });
      });
  }

  deleteRoles(index: number) {
    if (this.roles[index].id !== 'new') {
      deleteRoles({
        companyId: this.selectedCompanyIdGetter,
        id: this.roles[index].id,
      })
        .then(() => {
          this.roles.splice(index, 1);
        })
        .catch(error => {
          this.addNotification({
            message: { error }.error.response.data.message,
            type: 'error',
          });
        });
    }
  }
}
