




























































































import Vue from 'vue';
import { Component, Prop, Inject, Watch } from 'vue-property-decorator';
import { isEmpty, forEach } from 'lodash';
import Multiselect from 'vue-multiselect';
import { Validator } from 'vee-validate';
import { InputMultiSelectObject, ReturnInput } from './inputTypes';
import { EventBus } from '@/main';
import { namespace } from 'vuex-class';
const AuthenticationNameSpace = namespace('authentication/');
@Component({
  name: 'InputSelect',
  components: { Multiselect },
})
export default class InputSelect extends Vue {
  @Prop({}) inputParam!: InputMultiSelectObject;
  @Prop({ default: false }) search!: boolean;
  @Prop({ default: true }) translateOptions!: boolean;
  @Prop({ default: false }) filterOptions!: boolean;
  @Prop({ default: true }) translateValue!: boolean;
  @Prop({ default: true }) closeOnSelect!: boolean;
  @Prop({ default: true }) allowEmpty!: boolean;
  @Prop({ default: false }) limitHeight!: boolean;
  @Prop({}) dataTestId!: string;
  @Prop({ default: 'bottom' }) openDirection!: string;
  @Prop({}) formClass!: string;
  @Inject('$validator') validator!: Validator;
  @AuthenticationNameSpace.Getter('windowWidth') windowWidth: any;

  @Watch('inputParam.value')
  setValue() {
    this.vmodelValue = this.inputParam.value;
  }

  eventBus = EventBus;
  vmodelValue: any = {};

  get required() {
    if (typeof this.inputParam.vRule === 'string') {
      return this.inputParam.vRule.indexOf('required') !== -1;
    }
    return false;
  }

  get inputDisabled(): boolean {
    return this.inputParam.disabled === undefined
      ? false
      : this.inputParam.disabled;
  }

  get containerClass(): string[] {
    const classes: string[] = [];
    if (this.isInputCorrect) {
      classes.push('select-correct');
    }
    if (this.inputHasError) {
      classes.push('select-has-error');
    }
    if (this.inputDisabled) {
      classes.push('select-disable-actions');
    }
    return classes;
  }

  // currently only filters for instruments on the ioi form
  get filteredOptionList() {
    if (
      this.filterOptions &&
      this.inputParam.value &&
      this.inputParam.value.length > 0
    ) {
      // @ts-ignore
      if (['1', '2', '3'].includes(this.inputParam.value[0].value)) {
        return this.inputParam.optionList.filter(selectedOption => {
          if (['1', '2', '3'].includes(selectedOption.value)) {
            return selectedOption;
          }
        });
      } else {
        return [];
      }
    }
    return this.inputParam.optionList;
  }

  get optionList(): any {
    if (
      isEmpty(this.inputParam.optionList) ||
      this.inputParam.optionList === null ||
      this.inputParam.optionList.length === 0
    ) {
      return [{ value: ' ', label: '' }];
    }
    if (this.inputParam.translate === undefined || this.inputParam.translate) {
      const selectOptions: any = [];
      forEach(this.filteredOptionList, (element: any) => {
        const label: string = `${this.$t(element.label)}`;
        selectOptions.push({ value: element.value, label: label.trim() });
      });
      return selectOptions;
    }

    return this.filteredOptionList;
  }

  get isInputCorrect(): boolean {
    return (
      this.vmodelValue !== null &&
      this.vmodelValue !== undefined &&
      this.vmodelValue.value !== '' &&
      !!this.inputParam.vRule &&
      this.vmodelValue.length !== 0 &&
      !this.$validator.errors.has(this.inputParam.name)
    );
  }

  get inputHasError(): boolean {
    return this.$validator.errors.has(this.inputParam.name);
  }

  get inputTooltip(): any {
    if (this.inputParam.defaultTooltip) {
      return {
        placement: 'bottom-center',
        content: this.$t(this.inputParam.placeHolder),
      };
    }
    if (
      this.inputParam.tooltip === undefined ||
      this.inputParam.tooltip === null
    ) {
      return null;
    }

    const tooltipContent: string | null = `${this.$t(this.inputParam.tooltip)}`;
    return tooltipContent === null
      ? null
      : { placement: 'bottom-center', content: tooltipContent };
  }

  inputChange() {
    let returnInput: ReturnInput = {
      name: this.inputParam.name,
      value: '',
    };
    if (this.vmodelValue && this.inputParam.multi) {
      returnInput = {
        name: this.inputParam.name,
        value: this.vmodelValue,
      };
    }
    if (this.vmodelValue && !this.inputParam.multi) {
      returnInput = {
        name: this.inputParam.name,
        value: this.vmodelValue.value === null ? '' : this.vmodelValue.value,
        label: this.vmodelValue.label === null ? '' : this.vmodelValue.label,
        selectInput: true,
      };
    }
    this.$emit('sendInput', returnInput);
  }

  created() {
    this.vmodelValue = this.inputParam.value
      ? this.inputParam.value
      : undefined;
    if (!this.vmodelValue && !this.inputParam.placeHolder) {
      [this.vmodelValue] = this.inputParam.optionList;
    }

    EventBus.$on('selectAll', (selectAllParameter: boolean) => {
      if (!selectAllParameter) {
        this.vmodelValue = this.inputParam.optionList;
      }
      if (selectAllParameter) {
        this.vmodelValue = [];
      }
      this.inputChange();
    });
  }
}
