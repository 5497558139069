



















































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { namespace } from 'vuex-class';
import { IOIState, FilterStateIOI } from '@/types/instrumentsTypes';
import IoiRow from '@/components/ioi/IOIRow.vue';
import desktopSizes from '@/assets/screen-sizes';
import { AWAITING_UPGRADE_APPROVAL } from '@/types/accountTypes';
import IoiRowDayColumn from '@/components/ioi/IOIRowDayColumn.vue';
import IoiSort from '../../components/ioi/IOISort.vue';
import NoDataAvailable from '@/components/utils/NoDataAvailable.vue';
import sentry from '@/util/sentry';
import IoiList from '@/components/ioi/IoiList.vue';

const IOINameSpace = namespace('iois/');
const AuthenticationNameSpace = namespace('authentication/');
const NotificationsNameSpace = namespace('notifications/');

@Component({
  name: 'IOIListMarketViewer',
  components: {
    IoiList,
    IoiRow,
    IoiRowDayColumn,
    IoiSort,
    NoDataAvailable,
  },
})
export default class IOIListMarketViewer extends Vue {
  // Indication of Interest store
  @IOINameSpace.Getter('allIOIs') iois: IOIState;
  @IOINameSpace.Getter('ioisLength') ioisLength: number;
  @IOINameSpace.Action('getIOIs') getIOIs: any;

  // Authentication store
  @AuthenticationNameSpace.Getter('userAccountId') getUserAccountId: string;
  @AuthenticationNameSpace.Getter('isAccountStatus') isAccountStatus: {};
  @AuthenticationNameSpace.Getter('isUserLoggedIn') isUserLoggedIn: boolean;
  @AuthenticationNameSpace.Action('getUpgradeAccountType')
  getUpgradeAccountType: any;

  @AuthenticationNameSpace.Action('setUpgradeAccountType')
  setUpgradeAccountType: any;

  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @AuthenticationNameSpace.Action('authenticateLogin') authenticateLogin: any;
  @AuthenticationNameSpace.Action('signUpFullUser') signUpFullUser: any;

  // Notifications store
  @NotificationsNameSpace.Action('add') addNotification: any;

  myAccount: string = this.getUserAccountId;
  windowSize: number = 0;
  showExtended: boolean = true;
  screenMediumBig: number = desktopSizes.mediumBig;
  showDaysColumn: boolean = true;
  showPreferred: boolean = false;

  get showIOIs(): boolean {
    return this.ioisLength > 0;
  }

  get isUpgrading() {
    return this.isAccountStatus === AWAITING_UPGRADE_APPROVAL;
  }

  upgradeAccount(accountType: string): void {
    this.setUpgradeAccountType('UPGRADE_ACCOUNT_REQUEST')
      .then(() =>
        this.$router.push({
          name: 'signupnew',
          params: { flow: 'upgrade', accountType, step: '1' },
        }),
      )
      .catch((error: any) => {
        this.addNotification({
          message: `${this.$t('views.ioiList.upgradeAccountError')}`,
          type: 'error',
        });
        sentry(error);
      });
  }

  populateIOIList() {
    const ioiFilters: FilterStateIOI = {};
    ioiFilters.state = ['open', 'closing'];
    this.getIOIs({
      userAccountId: this.myAccount,
      filters: ioiFilters,
      preferred: this.showPreferred,
      state: 'outstanding',
      withOffers: '1',
    });
  }

  created() {
    // control if the user is logged in; if not, then the user will be sent to login page
    const accountErrorMessage: TranslateResult = this.$t(
      'views.ioiList.errorMessageMV',
    );
    const ioiErrorMessage: TranslateResult = this.$t(
      'views.ioiList.ioiErrorMessage',
    );
    this.authenticateLogin(accountErrorMessage)
      .then(() => {
        this.myAccount = this.getUserAccountId;
        this.windowSize = window.innerWidth;
        this.showExtended = this.windowSize >= desktopSizes.large;
        this.showDaysColumn = this.windowSize >= this.screenMediumBig;
        if (this.isUserLoggedIn && this.isMarketViewer) {
          // get open IOIs
          this.populateIOIList();
        } else if (this.isUserLoggedIn && !this.isMarketViewer) {
          this.addNotification({
            id: 0,
            message: `${accountErrorMessage}`,
            type: 'error',
          });
          this.$router.push({ name: 'login' });
        }
      })
      .catch(() =>
        this.addNotification({
          message: `${ioiErrorMessage}`,
          type: 'error',
        }),
      );
  }

  mounted() {
    // Window size
    window.addEventListener('resize', () => {
      this.windowSize = window.innerWidth;
      this.showExtended = this.windowSize >= desktopSizes.large;
      this.showDaysColumn = this.windowSize >= this.screenMediumBig;
    });
  }

  @Watch('windowSize')
  onWindowSizeChange(newSize: number) {
    this.showExtended = newSize >= desktopSizes.large;
    this.showDaysColumn = this.windowSize >= this.screenMediumBig;
  }

  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.windowSize = window.innerWidth;
    });
  }
}
