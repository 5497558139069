














import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import SortElement from '@/components/utils/SortElement.vue';

const FiltersNameSpace = namespace('filters/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'IOISort',
  components: { SortElement },
})
export default class IOISort extends Vue {
  // IOI store
  @FiltersNameSpace.Action('applyOrders') applyOrders: any;
  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;

  get dateTitle(): string {
    return `${this.$t('components.IOIOrders.transactionDate')}`;
  }

  get sizeTitle(): string {
    return `${this.$t('components.IOIOrders.nominalSize')}`;
  }

  get referenceNumberTitle(): string {
    return `${this.$t('components.IOIOrders.referenceNumber')}`;
  }

  get sizeKey(): string {
    if (this.isMarketViewer) {
      return 'bucketSize';
    }
    return 'issueNominalSize';
  }
}
