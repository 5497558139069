














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  CustomInput,
  InputMultiSelectObject,
} from '@/components/inputs/inputTypes';
import { EventBus } from '@/main';
import InputSelect from '@/components/inputs/InputSelect.vue';
import { namespace } from 'vuex-class';
import { CompanyItem } from '@/types/types';
const CompanyStore = namespace('companies/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'InputSelectCompany',
  components: { InputSelect },
})
export default class InputSelectCompany extends Vue {
  @Prop({}) inputParam!: InputMultiSelectObject;
  @Prop({ default: false }) search!: boolean;
  @Prop({ default: true }) closeOnSelect!: boolean;
  @Prop({ default: true }) allowEmpty!: boolean;
  @Prop({ default: true }) getCompany!: boolean;
  @Prop({ required: true }) getType!: string;
  @Prop({}) dataTestId!: string;
  @Prop({}) formClass!: string;

  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @CompanyStore.Action('getCompanyList') getCompanyList: any;
  @CompanyStore.Action('setCompany') setCompany: any;
  @CompanyStore.Getter('companyListGetterSelect')
  companyListGetterSelect: any[];

  @CompanyStore.Getter('selectedCompanyData')
  selectedCompanyData: CompanyItem;

  @CompanyStore.Getter('cacheBehalfOff')
  cacheBehalfOff: any;

  @CompanyStore.Getter('cacheIoiCompanyName')
  cacheIoiCompanyName: any;

  @CompanyStore.Getter('cacheCompanySettings')
  cacheCompanySettings: any;

  eventBus = EventBus;

  get cache(): any {
    switch (this.inputParam.name) {
      case 'behalfOff':
        return this.cacheBehalfOff;
      case 'companyName':
        return this.cacheIoiCompanyName;
      case 'companySettings':
        return this.cacheCompanySettings;
    }
  }

  get sortedCompanyListGetterSelect() {
    return this.companyListGetterSelect.sort((a, b) =>
      a.label.localeCompare(b.label),
    );
  }

  get GettedInputParam() {
    const inputParam = this.inputParam;
    if (this.getCompany) {
      inputParam.optionList = this.sortedCompanyListGetterSelect;
      inputParam.value = this.cache;
    } else {
      inputParam.disabled = true;
    }
    return inputParam;
  }

  sendInput(output: CustomInput) {
    if (output.value !== 'placeholder') {
      this.setCompany({
        slot: this.inputParam.name,
        company: output,
      });
      this.$emit('sendInput', output);
      if (this.selectedCompanyData) {
        this.$emit('setCompany', {
          value: this.cache.value,
          label: this.cache.label,
          industry: this.selectedCompanyData.industryOrSector
            ? this.selectedCompanyData.industryOrSector.value
            : '',
          countryCode: this.selectedCompanyData.countryCode,
          countryName: this.selectedCompanyData.countryCode
            ? this.$root.$t(
                `countries.${this.selectedCompanyData.countryCode.toLowerCase()}`,
              )
            : this.selectedCompanyData.countryCode,
          currency: 'EUR',
        });
      }
    }
  }

  mounted() {
    this.getCompanyList(this.getType).then(() => this.sendInput(this.cache));
  }
}
