















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import { EventBus } from '@/main';
import InputComponent from '@/components/inputs/InputComponent.vue';
import InputCheckboxComponent from '@/components/inputs/InputCheckboxComponent.vue';
import {
  CustomInput,
  InputCheckboxObject,
} from '@/components/inputs/inputTypes';
import ButtonComponent from '@/components/utils/ButtonComponent.vue';
import sentry from '@/util/sentry';

const NotificationsNameSpace = namespace('notifications/');
const AuthenticationNameSpace = namespace('authentication/');

@Component({
  name: 'LoginFormMobile',
  components: { InputComponent, ButtonComponent, InputCheckboxComponent },
})
export default class LoginMobile extends Vue {
  @NotificationsNameSpace.Action('add') addNotification: any;
  @AuthenticationNameSpace.Getter('rememberedAccount')
  rememberedAccount: string;

  @AuthenticationNameSpace.Getter('isMarketViewer') isMarketViewer: boolean;
  @AuthenticationNameSpace.Getter('isIssuer') isIssuer: boolean;
  @AuthenticationNameSpace.Getter('isLeadManager') isLeadManager: boolean;
  @AuthenticationNameSpace.Action('loginUser') loginUser: any;

  eventBus = EventBus;
  emailInput: any = {
    name: 'email',
    placeHolder: 'components.login.email',
    vRule: 'required|email',
  };

  passwordInput: any = {
    name: 'password',
    type: 'password',
    placeHolder: 'components.login.password',
    vRule: 'required',
  };

  rememberMeInput: InputCheckboxObject = {
    id: 'rememberMe',
    name: 'rememberMe',
    labelCode: 'components.login.rememberMe',
    containerClass: 'checkbox-item',
    static: false,
    disabled: false,
    value: false,
  };

  setInputValue(inputValue: CustomInput) {
    switch (inputValue.name) {
      case 'email':
        this.emailInput.value = inputValue.value;
        break;
      case 'password':
        this.passwordInput.value = inputValue.value;
        break;
      default:
        break;
    }
  }

  setInputCheckbox(inputValue: InputCheckboxObject) {
    if (inputValue.name === 'rememberMe') {
      this.rememberMeInput.value = inputValue.value;
    }
  }

  get loginButton(): any {
    return {
      containerClass: 'button-start',
      buttonClass: 'button-icon-mobile',
      description: `${this.$t('components.login.submit')}`,
      toolTip: `${this.$t('components.login.submit')}`,
      icon: 'login.png',
      showExtended: false,
    };
  }

  validateForm() {
    this.$validator
      .validateAll()
      .then(result => {
        if (!result) {
          const validationError: TranslateResult = this.$t(
            'components.login.validationError',
          );
          this.addNotification({
            id: 0,
            message: `${validationError}`,
            type: 'error',
          });
        } else {
          this.submitLogin();
        }
      })
      .catch((error: any) => {
        const loginError: TranslateResult = this.$t(
          'components.login.loginError',
        );
        this.addNotification({
          id: 0,
          message: `${loginError} ${error}`,
          type: 'error',
        });
        sentry(error);
      });
  }

  submitLogin() {
    const errorLoginMessage: TranslateResult = this.$t(
      'components.login.loginError',
    );
    this.loginUser({
      email: this.emailInput.value,
      password: this.passwordInput.value,
      rememberMe: this.rememberMeInput.value,
    })
      .then(() => {
        const successLoginMessage: TranslateResult = this.$t(
          'components.login.successLoginMessage',
          {
            user: this.emailInput.value,
          },
        );
        this.addNotification({
          id: 0,
          message: ` ${successLoginMessage}`,
          type: 'success',
        });
        let ioiPath: string = '';
        if (this.isIssuer) {
          ioiPath = 'myaccount-issuer';
        } else if (this.isMarketViewer) {
          ioiPath = 'ioi-list-viewer';
        } else if (this.isLeadManager) {
          ioiPath = 'myaccount-lm';
        } else {
          ioiPath = 'ioi-list-investor';
        }
        this.$router.push({ name: ioiPath });
        this.eventBus.$emit('reloadNav'); // REMOVE AFTER RESPONSIVENESS IS DONE
      })
      .catch((error: any) => {
        this.addNotification({
          id: 0,
          message: `${errorLoginMessage} ${error}`,
          type: 'error',
        });
        this.$router.push({ name: 'login' });
        sentry(error);
      });
  }

  forgotPasswordReload() {
    this.eventBus.$emit('reloadHome', 'forgotPassword');
  }

  created() {
    if (this.rememberedAccount !== '') {
      this.emailInput.value = this.rememberedAccount;
      this.rememberMeInput.value = true;
      this.eventBus.$emit('reloadCheckboxInput', this.rememberMeInput);
      this.eventBus.$emit('reloadInput', this.emailInput);
    }
  }
}
